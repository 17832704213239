import React, { Component } from 'react'
import { Drawer, Button, Input, Radio } from 'antd';

const Search = Input.Search;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;


class DrawerFilter extends Component {
  constructor(props){
      super(props);
    this.state = {
        location: null
    }
}


  render() {
    return (
      <div>
            <Drawer
                title= {<h5>Filter By Location</h5>}
                placement="right"
                closable={false}
                onClose={this.props.onClose}
                visible={this.props.visible}
                width={300}
                >
             <div>
                 <Search placeholder="Search Location"/>
{/*             
                 <RadioGroup defaultValue="a" size="small" value ="a">
                    <RadioButton value="a">All</RadioButton>
                    <RadioButton value="b">Region</RadioButton>
                    <RadioButton value="c">Province</RadioButton>
                    <RadioButton value="d">Municipality/City</RadioButton>
                    <RadioButton value="e">Barangay</RadioButton>
                    <RadioButton value="f">District</RadioButton>
                </RadioGroup> */}

             </div>
             
            </Drawer>
      </div>
    )
  }
}


export default DrawerFilter;