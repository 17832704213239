import React, {Component} from 'react';
import {Form, Icon, Input, Button, notification } from 'antd';
import Header from './HeaderForm';

class NormalLoginForm extends Component {
    
    handleSubmit = (e) => {
      e.preventDefault();

      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.getLogin(values)
        }
      });
    }
  
    render() {
      const { getFieldDecorator } = this.props.form;
      return (
        <div className="login-div">
          <Header />

          <Form onSubmit={this.handleSubmit} className="form-main" style={{margin: '80px 0px'}}>
 
              <Form.Item  className="form-fields">
                  {getFieldDecorator('email', {
                  rules: [{ required: true, message: 'Please input your username!' }],
                  })(
                  <Input className="form-fields" prefix={<Icon type="user" style={{ fontSize: '20px', color: 'rgba(0,0,0,.25)'}} />} style={{fontSize: '15px', color: '#77B9FF', fontFamily: 'Montserrat' }} placeholder="Email" />
                  )}
              </Form.Item>
    
              <Form.Item className="form-fields">
                  {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                  <Input className="form-fields" prefix={<Icon type="lock" style={{ fontSize: '20px',color: 'rgba(0,0,0,.25)' }} />} type="password" style={{fontSize: '15px', color: '#77B9FF', fontFamily: 'Montserrat' }} placeholder="Password" />
                  )}
              </Form.Item>
            
              <Button  type="primary" htmlType="submit" style={{height: '50px', width: '450px', fontFamily: 'Montserrat', fontWeight: 'bold'}}>
                  LOGIN
              </Button>
              <a style={{margin: '20px 0px'}} onClick = {()=>{ this.props.history.push('/password')}}> Change Password? </a>
            </Form>
        </div>
      );
    }
  }
  
  export default  Form.create()(NormalLoginForm);