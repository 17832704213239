import React, {Component} from 'react';
import { changePasswordRequest } from '../ActionCreators';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button, notification, Row, Col} from 'antd';
import Logo from '../../assets/Logo.png';
import { timingSafeEqual } from 'crypto';

const WrapperForm = Form.create()(
 class extends Component {

    handleSubmit = (e) => {
      e.preventDefault();

      this.props.form.validateFields((err, values) => {
        if (!err) {
            this.props.changePasswordRequest(values,this.props.errorCallback, this.props.successCallback)
        }
      });
    }
  
    callback = (error) =>{
        if(error){
            notification.error({ message: `${error.message}`})
        }
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      
      return (
        <div className="change-password-div">
             <Row style={{padding: '20px 40px'}}>
                  <Col span={24}>
                    <img src={Logo} style={{ height: '70px', width: '70px'}} />
                     &nbsp; &nbsp; &nbsp; 
                     &nbsp; &nbsp; &nbsp; 
                    <span style={{fontSize: '18px', fontWeight: 'bold'}}>Commission on Human Rights</span>
                  </Col>
              </Row>
              <div>
                <div className="divider-layer-1" /> 
                <div className="divider-layer-2" />
                <div className="divider-layer-1" />
                <div className="divider-layer-2" />
            </div>
          <Form onSubmit={this.handleSubmit} className="form-main" style={{margin: '30px 0px', padding: '30px'}}>
            <Row>
                <Col span={24}>
                    <h1 className="chr-font-gen" style={{color: '#5E5E5E', fontSize: '25px', textAlign: 'center'}}>Change Password</h1>
                    <span style={{color: '#5E5E5E', fontSize: '15px', opacity: '0.8'}}>Please Input your email address and we will send you a link to change your password</span>
               </Col>
            </Row>

            <Row style={{marginTop: '30px'}}>
                <Col span={24}>
                    <Form.Item  className="form-fields" >
                        { getFieldDecorator ('email', {
                        rules: [{ required: true, message: 'Please input your username!' }],
                        })(
                        <Input className="form-fields" prefix={<Icon type="user" style={{ fontSize: '20px', color: 'rgba(0,0,0,.25)' }} />} placeholder="&nbsp;&nbsp;Email" />
                        )}
                    </Form.Item>
               </Col>

               {/* <Col span={24}>
                    <Form.Item  className="form-fields">
                        {getFieldDecorator('change_password_email', {
                        })(
                        <Input hidden/>
                        )}
                    </Form.Item>
               </Col> */}
            </Row>

            <Row> 
                <Col span={24}> 
                <Form.Item className="form-fields">
                    {getFieldDecorator ('change_password_email', {
                        rules: [{ required: true, message: 'Please input your valid email' }], 
                    }) (
                        <Input className="form-fields" prefix={<Icon type="user" style={{ fontSize: '20px', color : 'rgba(0,0,0,.25)'}} />} placeholder="&nbsp;&nbsp;Valid Email"/>
                    )}
                </Form.Item>
                </Col>
            </Row>
             
            <Row>
                <Col span={12}>
                    <Button className="chr-btn"  type="primary" htmlType="submit" style={{height: '50px', width: '150px', margin: '10px 10px'}}>
                        <span className="chr-font-light">Submit</span>
                    </Button>
                </Col>    

                <Col span={12}>
                    <Button className="chr-btn-gen" type="primary" ghost onClick={()=>{this.props.history.goBack()}} style={{height: '50px', width: '150px', margin: '10px 10px'}}>
                        <span className="chr-font-light">Cancel</span>
                    </Button>
                </Col>   
            </Row>
             
            </Form>
        </div>
      );
    }
  }
);


class NormalLoginForm extends Component {

    errorCallback(error) {
        console.log(error)
        if(error.httpStatus !== 0) {
            notification.warning({ message: `${error.message}` })
        }
    }

    successCallback(response) {
        console.log(response)
        if(response.status === 200 ) {
            notification.success({ message: "Your request is now being processed. Please wait for the admin's approval on your email." })
        }
    }

    render() {
        return (
            <div>
                <WrapperForm 
                    changePasswordRequest = {this.props.changePasswordRequest}
                    errorCallback={this.errorCallback} 
                    successCallback={this.successCallback}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        
    }
}

const mapDispatchToProps = {
  changePasswordRequest
}


export default connect(mapStateToProps, mapDispatchToProps)(NormalLoginForm);
  