import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Icon, Button} from 'antd';

import Report from './components/report';
import Charts from './components/killings_per_region';
import Profile from './components/profile_victim';
import Age from './components/age';
import Drug from './components/drug_trade';
import Operation from './components/operation';
import Perptrator from './components/perpetrator';
import { killingReports , enforcementReport, profileReports, drugReport, ageReport, perpetratorReport } from './actionCreators';
// import { CSVLink, CSVDownload } from "react-csv";
 
const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];

class Trash extends Component {

state = {
    type: 0,
}

componentDidMount(){
   this.props.killingReports(); 
   this.props.enforcementReport();
   this.props.drugReport();
   this.props.ageReport();
   this.props.perpetratorReport();
   this.props.profileReports();
}

typeReports = (value) => {
    this.setState({ type: value})
}

render() {
    return (
            
            <div style={{margin: '30px 10px'}}>
               <h1 className="chr-font">
                  {/* <Icon  type="line-chart" style={{margin: "0px 0px", fontSize: "25px" }} /> */}
                  Reports
               </h1>
                  <Report typeReports={this.typeReports}/>
               {
                  this.state.type == 1 &&
                  <Charts killings={this.props.killings}/>
                  // <div></div>
               }    
               {
                  this.state.type == 2 &&
                  <Profile profile={this.props.profile}/>       
               }   
               {
                  this.state.type == 3 &&
                  <Age age={this.props.age}/>
               }  
               {
                  this.state.type == 4 &&
                  <Drug drug={this.props.drug} />
               }    
               {
                  this.state.type == 5 &&
                  <Operation enforcement={this.props.enforcement} />
               }    
               {
                  this.state.type == 7 &&
                  <Perptrator perpetrator={this.props.perpetrator} />
               }   
            </div>
        )
    }
}

 const mapStateToProps = (state) => {
    return {
       killings: state.reports.killing.data,
       profile: state.reports.profile.data,
       age: state.reports.age.data,
       drug: state.reports.drug.data,
       enforcement: state.reports.enforcement.data,
       perpetrator: state.reports.perpetrator,
    }
}

const mapDispatchToProps = {
   killingReports, enforcementReport, profileReports, drugReport, ageReport, perpetratorReport
}

export default connect(mapStateToProps, mapDispatchToProps)(Trash);