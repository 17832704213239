import React, { Component } from 'react'
import TableUser from './components/table';
import { connect } from 'react-redux';
import {trashList, DocumentRestored} from './action';
import {userList} from '../users/actionCreators';
import {assign} from 'lodash';

class Trash extends Component {

    componentDidMount(){
        this.props.trashList();
        this.props.userList();
    }

    // componentDidUpdate(props, states){
    //     if(props.trash !== this.props.trash){
    //        setTimeout(()=>{
    //         this.props.trashList();
    //        }, 1000)
    //     }
    // }

    sortersAndFilters = (filters, sorters) => {
        const params = assign({sort_dir: sorters.order, sort_key: sorters.columnKey });
        
        for(let [key, value] of Object.entries(filters)) {
            if(value.length > 0) {
                filters[`${key}`] = value;
            }
        }

        this.props.trashList(params);
    }

    render() {
        return (
            <div>
                <TableUser 
                    list = {this.props.trash}
                    users={this.props.users}
                    DocumentRestored = {this.props.DocumentRestored}
                    sortersAndFilters={this.sortersAndFilters}
                    />
            </div>
        )
    }
}
 const mapStateToProps = (state) => {
    return {
        trash: state.trash,
        users: state.user.users
    }
}

const mapDispatchToProps = {
    trashList, 
    userList, 
    DocumentRestored
}

export default connect(mapStateToProps, mapDispatchToProps)(Trash);