import React, { Component } from 'react'
import TableTypes from './components/accountSourceTable';
import { connect } from 'react-redux';
import { SourceTypeList, SourceType, configAccountTypeEdit } from '../actionCreator';

class TableType extends Component {

componentDidMount(){
    this.props.SourceTypeList();
}

componentDidUpdate(prevProps){
    if(prevProps != this.props.status) {
        this.props.SourceTypeList();
    }
}

    render() {
        return (
            <div>
                <TableTypes 
                    status = { this.props.status }
                    caseTypeAdd = { this.props.SourceType }
                    configAccountTypeEdit={this.props.configAccountTypeEdit}
                />
            </div>
        )
    }
}
 const mapStateToProps = (state) => {
    return {
        status: state.case.accountSourceType.data
    }
}

const mapDispatchToProps = {
    SourceTypeList , SourceType, configAccountTypeEdit
}

export default connect(mapStateToProps, mapDispatchToProps)(TableType);