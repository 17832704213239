import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, Select, notification } from 'antd';
import { connect } from 'react-redux';
import SourceTabs from "../../../../shared/components/addSourceTab";
import { victimSourceAccountAdd, victimAcccountGet, source_edit, Account_source_edit, SourceTypeList, caseList, victimList } from '../../../cases/actionCreator';

const Option = Select.Option;

const CaseForm =  Form.create()(

  class extends Component {
    
    handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          { this.props.edit == "true" ? this.props.editSave(values) : this.props.onSave(values)  }
        } 
      });
    }

    render() {
      const List = this.props.accountSourceType !== undefined ? this.props.accountSourceType : [];
      const finalList = [];
      const active_data = List.forEach(item=>{
        if(item.is_account_source_type_active === 1 ){
            finalList.push(item);
        }
      });
      const { getFieldDecorator } = this.props.form;
      const edit = this.props.edit;
      const data = this.props.data;
      {sessionStorage.setItem('account_source_id', data !=undefined ? data.account_source_id: null)}
      return (
        <Form onSubmit={this.handleSubmit}>

          <Row style={{padding: '20px 20px 0px 20px'}}>
             <Col span={14}>
               <h1 className="chr-font">{edit == "true" ? "Modify Account" : "Adding Account"}</h1>
             </Col>

             <Col span={10}>
                 <Form.Item className="form-case-add">
                    <Button className="chr-btn" type="primary" htmlType="submit" style={{margin: '0px 20px'}} >
                         Continue
                    </Button>
                    <Button className="chr-close-btn" icon="cross" title="close" onClick={this.props.onCancel} />
                 </Form.Item>
             </Col>
         </Row>

         <Row>
          <Col className="form-case-add-row"></Col>
         </Row>

         <div style={{margin: "50px 10px 0px 10px"}}> 
          { edit=="true" ? <div></div> : <SourceTabs history={this.props.history}/> }
        </div>
      
    
        <div style={{margin: '0px 20px'}}>
          <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Case Name:">
                  { getFieldDecorator('case_id', {
                    rules: [{ required: true, message: 'Please input your fields' }],
                    initialValue: <span style={{color: "#c1c3c3"}}>Select Case Name</span>
                    })(
                      
                      <Select onChange = {(value)=>{ this.props.componentStateId(value)}}>
                        {
                          this.props.caseListAll !== undefined ? this.props.caseListAll.map(e=>{
                            return <Select.Option key={e.id} value={e.id}>{e.case_identifier} </Select.Option>
                          })
                          :
                          []
                        }
                      </Select> 
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Victim Name:">
                  {getFieldDecorator('vitim_id', {
                    rules: [{ required: true, message: 'Please input your fields' }],
                    initialValue: <span style={{color: "#c1c3c3"}}>Select Victim Name</span>
                    })(
                      
                      <Select onChange = {(value)=>{ this.props.componentStateVictim(value)}}>
                        {
                          this.props.victimListAll !== undefined ? this.props.victimListAll.map(e=>{
                            return <Select.Option key={e.id} value={e.id}>{e.first_name} &nbsp; {e.last_name}</Select.Option>
                          })
                          :
                          []
                        }
                      </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="Account Source Type:">
                    {getFieldDecorator('account_source_type', {
                      rules: [{ required: true, message: 'Please input your fields' }],
                      initialValue: edit == "true" ? data !=undefined ? data.account_source_type : "": <span style={{color: "#c1c3c3"}}>Select Account Name</span>,
                    })(

                      // <Input placeholder="Account Source Type"></Input>
                      <Select>
                        {
                          List.map(item=>{
                            return <Select.Option key={item.id} value={item.name}>{item.name}</Select.Option>
                          })
                        }
                      </Select>

                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Age Source Type:">
                    {getFieldDecorator('account_source_age', {
                      rules: [{ required: true, message: 'Please input your fields' }],
                      initialValue: edit == "true" ? data !=undefined ? data.account_source_age : "": "",
                    })(

                      <Input placeholder="Age Source Type"></Input>

                    )}
                  </Form.Item>
                </Col>
            </Row>

            <Row gutter={16} >
                <Col span={5}>
                  <Form.Item label="Name" >
                    {getFieldDecorator('last_name', {
                      rules: [{ required: true, message: 'Please input your fields' }],
                      initialValue: edit == "true" ? data !=undefined ? data.account_sources_lastname : "": "",
                    })(
                      <Input placeholder="Last Name"></Input>
                    )}
                  </Form.Item>
                </Col>
              
              <div style={{margin: '38px 0px'}} >
                <Col span={5}>
                  <Form.Item  >
                    {getFieldDecorator('first_name', {
                      rules: [{ required: true, message: 'Please input your fields' }],
                      initialValue: edit == "true" ? data !=undefined ? data.account_sources_firstname : "": "",
                    })(
                      <Input placeholder="First Name"></Input>
                    )}
                  </Form.Item>
                </Col>
                
                <Col span={5}>
                  <Form.Item  >
                    {getFieldDecorator('middle_name', {
                      rules: [{ required: false, message: 'Please input your fields' }],
                      initialValue: edit == "true" ? data !=undefined ? data.account_sources_middlename : "": "",
                    })(
                      <Input placeholder="Middle Name"></Input>
                    )}
                  </Form.Item>
                </Col>

                <Col span={5}>
                  <Form.Item  >
                    {getFieldDecorator('alias', {
                      rules: [{ required: false, message: 'Please input your fields' }],
                      initialValue: edit == "true" ? data !=undefined ? data.account_sources_alias : "": "",
                    })(
                      <Input placeholder="Alias (Optional)"></Input>
                    )}
                  </Form.Item>
                </Col>
              </div>
            </Row>
          </div>
      </Form>
      );
    }
  }
)

class addVictimSource extends Component {
  
  state = {
    case_id: null,
    victim_id: null,  
  }

  componentDidMount(){
    this.props.victimAcccountGet();
    this.props.SourceTypeList();
    this.props.caseList();
    this.props.victimList();
  }

  componentDidUpdate(prevProps, prevState) {
    const case_id = this.state.case_id;

    if(prevState.case_id !== this.state.case_id ){
      this.props.victimList(case_id);
    } 
  }

  componentStateId = (case_id)=>{
    this.setState({ case_id: case_id })
  }

  componentStateVictim = (victim_id)=>{
    this.setState({  victim_id: victim_id })
  }

  onCancel = () => {
    this.props.history.goBack();
  }

  onSave = (params)=> {
    this.props.victimSourceAccountAdd(params, this.callback);
    sessionStorage.setItem('case_id', this.state.case_id);
    sessionStorage.setItem('victim_id', this.state.victim_id);
  }

  callback = (response, error) => {
      if(response.status =='200'){
      this.props.history.push('/chr/quick/add/source');
      sessionStorage.setItem('source_id', response.data.accountsource.id)

      return;
    }
  }

  editCallback = (response) => {
      if(response.status =='200'){
      this.props.history.push({ pathname : '/chr/quick/add/source' })
      return;
    }
  }

  editSave = (params)=> {
    this.props.Account_source_edit(params, this.editCallback);
  }

  render(){
    const history = this.props.history;
    const edit = sessionStorage.getItem('account_edit');
    const data = this.props.accountGet;

    return(
        <div>
          <CaseForm
            onCancel = {this.onCancel}
            onSave = {this.onSave}
            history = {history}
            edit = {edit}
            data = {data}
            editSave = {this.editSave}
            accountSourceType = {this.props.accountSourceType}
            componentStateVictim = {this.componentStateVictim}
            componentStateId = {this.componentStateId}
            caseListAll= {this.props.caseListAll}
            victimListAll={this.props.victimListAll}
          />
        </div>
    );
  }
}


const mapStateToProps = (state)=> {
  return {
    accountGet: state.case.actGet,
    accountSourceType: state.case.accountSourceType.data,
    caseListAll: state.case.case.data,
    victimListAll: state.case.ListVictim.data,
  }
}

const mapDispatchToProps = {
  victimSourceAccountAdd, victimAcccountGet, source_edit, Account_source_edit, SourceTypeList, caseList, victimList
}

export default connect(mapStateToProps, mapDispatchToProps)(addVictimSource); 