import React, { Component } from 'react'
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    } from 'react-vis';
    
const data = [
    {y: 5, x: "Car"},
    {y: 10, x: "NCR"},
    {y: 15, x: "R01"},
    {y: 20, x: "R02"},
    {y: 25, x: "R03"},
    {y: 30, x: "R04"},
    {y: 35, x: "R05"},
    {y: 40, x: "R06"},
    {y: 45, x: "R07"},
    {y: 50, x: "R08"},
    {y: 55, x: "R09"},
    {y: 60, x: "R010"},
    {y: 65, x: "R011"},
    {y: 70, x: "R012"},
    {y: 75, x: "R013"},
    {y: 80},
    {y: 85},
    {y: 90},
    {y: 95},
    {y: 100},
  ];
  

class ChartsAndTable extends Component {

  render() {
    var arr = this.props.profile === undefined ? [] : this.props.profile;

    var API1  = arr.map(e=>{
        return { x: e.region, y: e.male }
    })
   
    var API2  = arr.map(e=>{
        return { x: e.region, y: e.female }
    })

    var API3  = arr.map(e=>{
        return { x: e.region, y: e.no_info }
    })

    return (
        <div>
            <XYPlot xType="ordinal" width={600} height={310} xDistance={10}>
            <VerticalGridLines />
            <HorizontalGridLines />
            <VerticalBarSeries data={data} color= "transparent" />
            <XAxis />
            <YAxis />
            <VerticalBarSeries data={API2} color = 'pink' style={{width: '15px', }}/>
            <VerticalBarSeries data={API1} color = '#8fdbe2' style={{width: '15px', }}/>
            <VerticalBarSeries data={API3} color = '#cbcbcb' style={{width: '15px', }}/>
            </XYPlot>
        </div>

     
    )
  }
}

export default ChartsAndTable;