import React, { Component } from 'react'
import {Table, Button} from 'antd';
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    } from 'react-vis';
import { CSVLink, CSVDownload } from "react-csv";
const data = [
    {y: 5, x: "CAR"},
    {y: 10, x: "NCR"},
    {y: 15, x: "R01"},
    {y: 20, x: "R02"},
    {y: 25, x: "R03"},
    {y: 30, x: "R04"},
    {y: 35, x: "R05"},
    {y: 40, x: "R06"},
    {y: 45, x: "R07"},
    {y: 50, x: "R08"},
    {y: 55, x: "R09"},
    {y: 60, x: "R010"},
    {y: 65, x: "R011"},
    {y: 70, x: "R012"},
    {y: 75, x: "R013"},
    {y: 80},
    {y: 85},
    {y: 90},
    {y: 95},
    {y: 100},
  ];
  


class ChartsAndTable extends Component {
    constructor(props){
        super(props);

        this.columns = [
            {
                title: "Region",
                dataIndex: "region"
            },
            {
                title: "Drug List",
                dataIndex: "drug_trade"
            },
            {
                title: "Surrenderee",
                dataIndex: "drug_trade"
            },
            { 
                title: "Other Link",
                dataIndex: "drug_trade"
            },
            {
                title: "No Known/Apparent Link",
                dataIndex: "drug_trade"
            },
            {
                title: "No Information",
                dataIndex: "drug_trade"
            }
        ]
    }


      
    donwloadClick = () => {
        return <CSVDownload data={this.props.drug} target="_blank" />
    }
    
  render() {
      const drug = this.props.drug;
      console.log(drug)
      var API  = drug.map(e=>{
          return { x: e.region, y: e.drug_trade }
      })
     
    return (
      <div style={{margin: '20px 60px'}}>

        <div>
            <h4>Overview Results</h4>
            <h2>Victims Link to Illegal Drug Trade</h2>
            <h4>as of Date:</h4>
           
           <div style={{display: 'flex', flexDirection: 'columns'}}>
            <div style={{margin: '0px 20px', borderTop: '5px solid #ffa394', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: '#ffa394' , float: 'left'}}></div> 
                <h4>Drug List</h4>
                <center><h1>0</h1></center>
            </div>
            <div style={{ margin: '0px 20px', borderTop: '5px solid #ffc28d', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: '#ffc28d' , float: 'left'}}></div> 
                <h4>Surrenderee</h4>
                <center><h1>0</h1></center>
            </div>
            <div style={{margin: '0px 20px', borderTop: '5px solid #8fdbe2', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: '#8fdbe2' , float: 'left'}}></div> 
                <h4>Other Link</h4>
                <center><h1>0</h1></center>
            </div>
            <div style={{margin: '0px 20px', borderTop: '5px solid #b6e5b6', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 10px', width: '18px', height: '18px' , background: '#b6e5b6' , float: 'left'}}></div> 
                <h5>No Known/Apparent Link</h5>
                <center><h1>0</h1></center>
            </div>
            <div style={{margin: '0px 20px', borderTop: '5px solid #cbcbcb', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: '#cbcbcb' , float: 'left'}}></div> 
                <h4>No Information</h4>
                <center><h1>0</h1></center>
            </div>
            </div>
        </div>

        <div style={{textAlign: 'right', margin: '20px 0px'}}>
            <Button icon="download"> 
                &nbsp;  
                 <CSVLink data={drug} filename="Victims Link to Illegal Drug Trade.csv" onClick={() => {  this.donwloadClick(); }} > 
                     Download Report
                  </CSVLink>
            </Button>
        </div>


        <div style={{margin: ' 30px 0px'}}>
            <XYPlot xType="ordinal" width={900} height={500} xDistance={100}>
            <VerticalGridLines />
            <HorizontalGridLines />
            <VerticalBarSeries data={data} color= "transparent" />
            <XAxis />
            <YAxis />
            <VerticalBarSeries data={API} color = '#ffa394' style={{width: '10px', }}/>
            <VerticalBarSeries data={API} color = '#ffc28d' style={{width: '10px', }}/>
            <VerticalBarSeries data={API} color = '#8fdbe2' style={{width: '10px', }}/>
            <VerticalBarSeries data={API} color = '#b6e5b6' style={{width: '10px', }}/>
            <VerticalBarSeries data={API} color = '#cbcbcb' style={{width: '10px', }}/>
            </XYPlot>
        </div>

        <Table 
            columns={this.columns}
            dataSource={this.props.drug}
            style={{margin: '20px 0px'}}/>
      </div>
    )
  }
}

export default ChartsAndTable;