import React, { Component } from 'react'
import {connect} from 'react-redux';
import '../../../css/style.css';
import FormLogin from '../../components/LoginForm';
import { getLogin } from '../../ActionCreators';
import { Redirect } from 'react-router-dom';
import { notification } from 'antd';


 class Main extends Component {

  loginAction = (params) =>{
    this.props.getLogin(params, this.callback, this.token);
  }

  callback = (response, error) => {
    if (error) {
      notification.warning({ message: 'ERROR', description: error.message})
      return;
    } else if(response.data.user.status == "Inactive"){
      notification.warning({ message: 'ERROR', description: "User is Disabled!"})
      return;
    }
  }
  

  render() {
    const active = this.props.login.user.status;  
    const token  = this.props.login.token;
    
    if (token && active == "Active") {
        return <Redirect to={'/chr/dashboard'} />
    }

    return (
 

        <div className=" background-login">
          <FormLogin getLogin = { this.loginAction } history = {this.props.history}/>
        </div>

    )
  }
}

const mapStateToProps = (state)=>{
    return { 
      login: state.login,
    }
}

const mapDispatchToProps = {
  getLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);