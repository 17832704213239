import { axios, getAuthHeader,  getError } from '../../shared/utils/axios';
import { API_CASE_ADD, API_CASE_LIST, API_DOCUMENT_ADD, API_HISTORY_LIST,API_VICTIM_ADD, API_VICTIM_LIST, API_VICTIM_DOCUMENT_ADD, API_FILES_LIST, API_DOWNLOAD_FILE, API_VICTIM_GET, API_CASE_GET, API_VICTIM_ACCOUNT_LIST, API_VICTIM_SOURCE_ADD, API_VICTIM_ACCOUNT_ADD, API_VICTIM_ACT_LIST, API_VICTIM_ACT_ADD, API_VICTIM_ACT_ASSAILANT, API_VICTIM_ACT_VIEW, API_VICTIM_ACCOUNT_GET, API_VICTIM_ACT_UPLOAD, API_CASE_STATUS_LIST, API_CASE_STATUS_ADD, API_CASE_TYPE_LIST, API_CASE_TYPE_ADD, API_VICTIM_ACT_ASSAILANT_LIST, API_VICTIM_ACCOUNT_SOURCE_LIST, API_DOCUMENTS, API_LINK_TO_DRUG_TRADE,  BASE_URL } from '../../shared/constant/url';

export const CASE = {
    START: "CASE_ADD_START",
    SUCCESS: "CASE_ADD_SUCCESS",
    FAILED: "CASE_ADD_FAILED",
    
    LIST_START: "CASE_LIST_START",
    LIST_SUCCESS: "CASE_LIST_SUCCESS",
    LIST_FAILED: "CASE_LIST_FAILED",

    DOCUMENT_START: "CASE_DOCUMENT_START",
    DOCUMENT_SUCCESS: "CASE_DOCUMENT_SUCCESS",
    DOCUMENT_FAILED: "CASE_DOCUMENT_FAILED",

    DOCUMENT_VERSION_LIST_START: "CASE_DOCUMENT_VERSION_LIST_START",
    DOCUMENT_VERSION_LIST_SUCCESS: "CASE_DOCUMENT_VERSION_LIST_SUCCESS",
    DOCUMENT_VERSION_LIST_FAILED: "CASE_DOCUMENT_VERSION_LIST_FAILED",

    HISTORY_START: "CASE_HISTORY_START",
    HISTORY_SUCCESS: "CASE_HISTORY_SUCCESS",
    HISTORY_FAILED: "CASE_HISTORY_FAILED",
    
    FILES_START: "CASE_FILES_START",
    FILES_SUCCESS: "CASE_FILES_SUCCESS",
    FILES_FAILED: "CASE_FILES_FAILED",

    VICTIM_ADD_START: "CASE_VICTIM_START",
    VICTIM_ADD_SUCCESS: "CASE_VICTIM_SUCCESS",
    VICTIM_ADD_FAILED: "CASE_VICTIM_FAILED",
    
    VICTIM_LIST_START: "LIST_VICTIM_START",
    VICTIM_LIST_SUCCESS: "LIST_VICTIM_START_SUCCESS",
    VICTIM_LIST_FAILED: "LIST_VICTIM_START_FAILED",

    VICTIM_LIST_MAIN_START: "CASE_LIST_MAIN_VICTIM_START",
    VICTIM_LIST_MAIN_SUCCESS: "CASE_LIST_MAIN_VICTIM_SUCCESS",
    VICTIM_LIST_MAIN_FAILED: "CASE_LIST_MAIN_VICTIM_FAILED",
     
    VICTIM_LIST_ALL_START: "CASE_LIST_ALL_VICTIM_START",
    VICTIM_LIST_ALL_SUCCESS: "CASE_LIST_ALL_VICTIM_SUCCESS",
    VICTIM_LIST_ALL_FAILED: "CASE_LIST_ALL_VICTIM_FAILED",
    
    VICTIM_DOCUMENT_START: "CASE_DOCUMENT_VICTIM_START",
    VICTIM_DOCUMENT_SUCCESS: "CASE_DOCUMENT_VICTIM_SUCCESS",
    VICTIM_DOCUMENT_FAILED: "CASE_DOCUMENT_VICTIM_FAILED",

    VICTIM_DETAILS_START: "CASE_DETAILS_VICTIM_START",
    VICTIM_DETAILS_SUCCESS: "CASE_DETAILS_VICTIM_SUCCESS",
    VICTIM_DETAILS_FAILED: "CASE_DETAILS_VICTIM_FAILED",

    VICTIM_ACCOUNT_ALL_START: "VICTIM_ACCOUNT_ALL_START",
    VICTIM_ACCOUNT_ALL_SUCCESS: "VICTIM_ACCOUNT_ALL_SUCCESS",
    VICTIM_ACCOUNT_ALL_FAILED: "VICTIM_ACCOUNT_ALL_FAILED",

    CASE_GET_START: "CASE_GET_START",
    CASE_GET_SUCCESS: "CASE_GET_SUCCESS",
    CASE_GET_FAILED: "CASE_GET_FAILED",

    VICTIM_SOURCE_ACCOUNT_START: "CASE_SOURCE_ACCOUNT_VICTIM_START",
    VICTIM_SOURCE_ACCOUNT_SUCCESS: "CASE_SOURCE_ACCOUNT_VICTIM_SUCCESS",
    VICTIM_SOURCE_ACCOUNT_FAILED: "CASE_SOURCE_ACCOUNT_VICTIM_FAILED",

    VICTIM_SOURCE_ACCOUNT_DETAILS_START: "CASE_SOURCE_ACCOUNT_DETAILS_VICTIM_START",
    VICTIM_SOURCE_ACCOUNT_DETAILS_SUCCESS: "CASE_SOURCE_ACCOUNT_DETAILS_VICTIM_SUCCESS",
    VICTIM_SOURCE_ACCOUNT_DETAILS_FAILED: "CASE_SOURCE_ACCOUNT_DETAILS_VICTIM_FAILED",
    
    VICTIM_ACT_LIST_START: "CASE_ACT_LIST_VICTIM_START",
    VICTIM_ACT_LIST_SUCCESS: "CASE_ACT_LIST_VICTIM_SUCCESS",
    VICTIM_ACT_LIST_FAILED: "CASE_ACT_LIST_VICTIM_FAILED",
    
    VICTIM_ACT_ACCOUNT_LIST_START: "CASE_ACT_ACCOUNT_LIST_VICTIM_START",
    VICTIM_ACT_ACCOUNT_LIST_SUCCESS: "CASE_ACT_ACCOUNT_LIST_VICTIM_SUCCESS",
    VICTIM_ACT_ACCOUNT_LIST_FAILED: "CASE_ACT_ACCOUNT_LIST_VICTIM_FAILED",

    VICTIM_ACT_GET_START: "CASE_ACT_GET_VICTIM_START",
    VICTIM_ACT_GET_SUCCESS: "CASE_ACT_GET_VICTIM_SUCCESS",
    VICTIM_ACT_GET_FAILED: "CASE_ACT_GET_VICTIM_FAILED",
    
    VICTIM_ACT_ADD_START: "CASE_ACT_ADD_VICTIM_START",
    VICTIM_ACT_ADD_SUCCESS: "CASE_ACT_ADD_VICTIM_SUCCESS",
    VICTIM_ACT_ADD_FAILED: "CASE_ACT_ADD_VICTIM_FAILED",

    VICTIM_ASSAILANT_ADD_START: "CASE_ASSAILANT_ADD_VICTIM_START",
    VICTIM_ASSAILANT_ADD_SUCCESS: "CASE_ASSAILANT_ADD_VICTIM_SUCCESS",
    VICTIM_ASSAILANT_ADD_FAILED: "CASE_ASSAILANT_ADD_VICTIM_FAILED",

    VICTIM_ASSAILANT_LIST_START: "CASE_ASSAILANT_LIST_VICTIM_START",
    VICTIM_ASSAILANT_LIST_SUCCESS: "CASE_ASSAILANT_LIST_VICTIM_SUCCESS",
    VICTIM_ASSAILANT_LIST_FAILED: "CASE_ASSAILANT_LIST_VICTIM_FAILED",

    VICTIM_ACCOUNT_SOURCE_LIST_START: "CASE_ACCOUNT_SOURCE_LIST_VICTIM_START",
    VICTIM_ACCOUNT_SOURCE_LIST_SUCCESS: "CASE_ACCOUNT_SOURCE_LIST_VICTIM_SUCCESS",
    VICTIM_ACCOUNT_SOURCE_LIST_FAILED: "CASE_ACCOUNT_SOURCE_LIST_VICTIM_FAILED",

    ACCOUNT_ADD_DOCUMENTS_START: "CASE_ACCOUNT_ADD_DOCUMENTS_VICTIM_START",
    ACCOUNT_ADD_DOCUMENTS_SUCCESS: "CASE_ACCOUNT_ADD_DOCUMENTS_VICTIM_SUCCESS",
    ACCOUNT_ADD_DOCUMENTS_FAILED: "CASE_ACCOUNT_ADD_DOCUMENTS_VICTIM_FAILED",

    ACCOUNT_GET_DOCUMENTS_START: "CASE_ACCOUNT_GET_DOCUMENTS_VICTIM_START",
    ACCOUNT_GET_DOCUMENTS_SUCCESS: "CASE_ACCOUNT_GET_DOCUMENTS_VICTIM_SUCCESS",
    ACCOUNT_GET_DOCUMENTS_FAILED: "CASE_ACCOUNT_GET_DOCUMENTS_VICTIM_FAILED",

    STATUS_START: "CASE_STATUS_START",
    STATUS_SUCCESS: "CASE_STATUS_SUCCESS",
    STATUS_FAILED: "CASE_STATUS_FAILED",

    STATUS_ADD_START: "CASE_STATUS_ADD_START",
    STATUS_ADD_SUCCESS: "CASE_STATUS_ADD_SUCCESS",
    STATUS_ADD_FAILED: "CASE_STATUS_ADD_FAILED",

    TYPE_START: "CASE_TYPE_START",
    TYPE_SUCCESS: "CASE_TYPE_SUCCESS",
    TYPE_FAILED: "CASE_TYPE_FAILED",

    TYPE_ADD_START: "CASE_TYPE_ADD_START",
    TYPE_ADD_SUCCESS: "CASE_TYPE_ADD_SUCCESS",
    TYPE_ADD_FAILED: "CASE_TYPE_ADD_FAILED",
    
    LIST_OF_VICTIM_TABLE_START: "CASE_LIST_OF_VICTIM_TABLE_START",
    LIST_OF_VICTIM_TABLE_SUCCESS: "CASE_LIST_OF_VICTIM_TABLE_SUCCESS",
    LIST_OF_VICTIM_TABLE_FAILED: "CASE_LIST_OF_VICTIM_TABLE_FAILED",

    LIST_OF_DOCUMENTS_ALL_START: "CASE_LIST_OF_DOCUMENTS_ALL_START",
    LIST_OF_DOCUMENTS_ALL_SUCCESS: "CASE_LIST_OF_DOCUMENTS_ALL_SUCCESS",
    LIST_OF_DOCUMENTS_ALL_FAILED: "CASE_LIST_OF_DOCUMENTS_ALL_FAILED",

    LIST_OF_FILES_ACT_START: "CASE_LIST_OF_FILES_ACT_START",
    LIST_OF_FILES_ACT_SUCCESS: "CASE_LIST_OF_FILES_ACT_SUCCESS",
    LIST_OF_FILES_ACT_FAILED: "CASE_LIST_OF_FILES_ACT_FAILED",

    LIST_OF_HISTORY_ACT_START: "CASE_LIST_OF_HISTORY_ACT_START",
    LIST_OF_HISTORY_ACT_SUCCESS: "CASE_LIST_OF_HISTORY_ACT_SUCCESS",
    LIST_OF_HISTORY_ACT_FAILED: "CASE_LIST_OF_HISTORY_ACT_FAILED",

    LIST_OF_FILES_ACCOUNT_START: "CASE_LIST_OF_FILES_ACCOUNT_START",
    LIST_OF_FILES_ACCOUNT_SUCCESS: "CASE_LIST_OF_FILES_ACCOUNT_SUCCESS",
    LIST_OF_FILES_ACCOUNT_FAILED: "CASE_LIST_OF_FILES_ACCOUNT_FAILED",

    LIST_OF_HISTORY_ACCOUNT_START: "CASE_LIST_OF_HISTORY_ACCOUNT_START",
    LIST_OF_HISTORY_ACCOUNT_SUCCESS: "CASE_LIST_OF_HISTORY_ACCOUNT_SUCCESS",
    LIST_OF_HISTORY_ACCOUNT_FAILED: "CASE_LIST_OF_HISTORY_ACCOUNT_FAILED",

    LIST_OF_VICTIM_FILES_LIST_START: "CASE_LIST_OF_VICTIM_FILES_LIST_START",
    LIST_OF_VICTIM_FILES_LIST_SUCCESS: "CASE_LIST_OF_VICTIM_FILES_LIST_SUCCESS",
    LIST_OF_VICTIM_FILES_LIST_FAILED: "CASE_LIST_OF_VICTIM_FILES_LIST_FAILED",

    LIST_OF_VICTIM_HISTORY_LIST_START: "CASE_LIST_OF_VICTIM_HISTORY_LIST_START",
    LIST_OF_VICTIM_HISTORY_LIST_SUCCESS: "CASE_LIST_OF_VICTIM_HISTORY_LIST_SUCCESS",
    LIST_OF_VICTIM_HISTORY_LIST_FAILED: "CASE_LIST_OF_VICTIM_HISTORY_LIST_FAILED",

    ADD_LINK_TO_DRUG_TRADE_START: "ADD_LINK_TO_DRUG_TRADE_START",
    ADD_LINK_TO_DRUG_TRADE_SUCCESS: "ADD_LINK_TO_DRUG_TRADE_SUCCESS",
    ADD_LINK_TO_DRUG_TRADE_FAILED: "ADD_LINK_TO_DRUG_TRADE_FAILED",

    LIST_LINK_TO_DRUG_TRADE_START: "LIST_LINK_TO_DRUG_TRADE_START", 
    LIST_LINK_TO_DRUG_TRADE_SUCCESS: "LIST_LINK_TO_DRUG_TRADE_SUCCESS",
    LIST_LINK_TO_DRUG_TRADE_FAILED: "LIST_LINK_TO_DRUG_TRADE_FAILED",

    ASSAILANT_GET_START: "ASSAILANT_GET_START",
    ASSAILANT_GET_SUCCESS: "ASSAILANT_GET_SUCCESS",
    ASSAILANT_GET_FAILED: "ASSAILANT_GET_FAILED",

    ACCOUNT_GET_START: "ACCOUNT_GET_START",
    ACCOUNT_GET_SUCCESS: "ACCOUNT_GET_SUCCESS",
    ACCOUNT_GET_FAILED: "ACCOUNT_GET_FAILED",

    NEW_LIST_FOR_FILES_VICTIM_MODULE_START: "NEW_LIST_FOR_FILES_VICTIM_MODULE_START",
    NEW_LIST_FOR_FILES_VICTIM_MODULE_SUCCESS: "NEW_LIST_FOR_FILES_VICTIM_MODULE_SUCCESS",
    NEW_LIST_FOR_FILES_VICTIM_MODULE_FAILED: "NEW_LIST_FOR_FILES_VICTIM_MODULE_FAILED",
    
    ACT_TYPE_LIST_START: "ACT_TYPE_LIST_START",
    ACT_TYPE_LIST_SUCCESS: "ACT_TYPE_LIST_SUCCESS",
    ACT_TYPE_LIST_FAILED: "ACT_TYPE_LIST_FAILED",

    ASSAILLANT_TYPE_LIST_START: "ASSAILLANT_TYPE_LIST_START",
    ASSAILLANT_TYPE_LIST_SUCCESS: "ASSAILLANT_TYPE_LIST_SUCCESS",
    ASSAILLANT_TYPE_LIST_FAILED: "ASSAILLANT_TYPE_LIST_FAILED",

    ACCOUNT_SOURCE_TYPE_LIST_START: "ACCOUNT_SOURCE_TYPE_LIST_START",
    ACCOUNT_SOURCE_TYPE_LIST_SUCCESS: "ACCOUNT_SOURCE_TYPE_LIST_SUCCESS",
    ACCOUNT_SOURCE_TYPE_LIST_FAILED: "ACCOUNT_SOURCE_TYPE_LIST_FAILED",

    VICTIM_ACCOUNT_START: "VICTIM_ACCOUNT_START",
    VICTIM_ACCOUNT_SUCCESS: "VICTIM_ACCOUNT_SUCCESS",
    VICTIM_ACCOUNT_FAILED: "VICTIM_ACCOUNT_FAILED",

    EMAIL_CONFIGURATION_START: "EMAIL_CONFIGURATION_START",
    EMAIL_CONFIGURATION_SUCCESS: "EMAIL_CONFIGURATION_SUCCESS",
    EMAIL_CONFIGURATION_FAILED: "EMAIL_CONFIGURATION_FAILED",

} 

export const caseAdd = (params, callback=null) => {
    return (dispatch)=> {   
        dispatch({ type: CASE.START });
        
        axios.post(API_CASE_ADD, params, getAuthHeader())
        .then(response => { callback(response, null); dispatch({ type: CASE.SUCCESS, payload: response.data}) })
        .catch(error=> {  callback(null, getError(error)); dispatch({type: CASE.FAILED, payload: getError(error) }) })
    }
}

export const caseList = (params)=> {
    return(dispatch)=> {
        dispatch({ type: CASE.LIST_START});

        axios.get(API_CASE_LIST, { params, ...getAuthHeader() })
        .then(response=> {  dispatch({type: CASE.LIST_SUCCESS, payload: response.data}) })
        .catch(error=> { dispatch({type: CASE.LIST_FAILED, payload: getError(error)}) })
    }
}

export const caseListArchived = (params, tagsSeach)=> {
    const value = tagsSeach !== undefined ? tagsSeach: null;
    const data = getAuthHeader();

    data.params = {
        ...params,
        q: value
    }

    return(dispatch)=> {
        dispatch({ type: CASE.LIST_START});

        axios.get(`${API_CASE_LIST}?is_arvchived=false`, data )
        .then(response=> {  dispatch({type: CASE.LIST_SUCCESS, payload: response.data}) })
        .catch(error=> { dispatch({type: CASE.LIST_FAILED, payload: getError(error)}) })
    }
}

export const caseAddDocument = (params, id , callback = null )=>{
    return(dispatch)=> {
        dispatch({ type: CASE.DOCUMENT_START}); 

        axios.post(`${API_DOCUMENT_ADD}/${id}/add_documents`, params, getAuthHeader())
        .then(response=> { callback(response, null);  dispatch({type: CASE.DOCUMENT_SUCCESS, payload: response.data}) })
        .catch(error=> {  callback(null, getError(error)); dispatch({type: CASE.DOCUMENT_FAILED, payload: getError(error)}) })
    }
}


export const historyList = ()=>{
    const case_id =   sessionStorage.getItem('case_id')
    return(dispatch)=>{
        dispatch({ type: CASE.HISTORY_START });

        axios.get(`${API_HISTORY_LIST}?case_id=${case_id}`, getAuthHeader())
        .then(response=>{ dispatch({ type: CASE.HISTORY_SUCCESS, payload: response.data }) })
        .catch(error=>{ dispatch({type: CASE.HISTORY_FAILED, payload: getError(error) }) })
    }
}

export const FilesList = ()=>{ 
    const case_id = sessionStorage.getItem('case_id')
    return(dispatch)=>{
        dispatch({ type: CASE.FILES_START });

        axios.get(`${API_DOCUMENTS}?is_archived=false&case_id=${case_id}`, getAuthHeader())
        .then(response=>{ dispatch({ type: CASE.FILES_SUCCESS, payload: response.data }) })
        .catch(error=>{ dispatch({type: CASE.FILES_FAILED, payload: getError(error) }) })
    }
} 

export const caseListVictimModule = ()=>{ 
    const case_id = sessionStorage.getItem('case_id');
    return(dispatch)=>{
        dispatch({ type: CASE.NEW_LIST_FOR_FILES_VICTIM_MODULE_START });

        axios.get(`${BASE_URL}/api/documents?is_archived=false&cases_id=${case_id}`, getAuthHeader())
        .then(response=>{ dispatch({ type: CASE.NEW_LIST_FOR_FILES_VICTIM_MODULE_SUCCESS, payload: response.data }) })
        .catch(error=>{ dispatch({type: CASE.NEW_LIST_FOR_FILES_VICTIM_MODULE_FAILED, payload: getError(error) }) })
    }
} 


export const downloadFile = (location, type, fileName, id ) => {
    return () => {
        axios.get(`${API_DOWNLOAD_FILE}=${location}`, { responseType: 'blob' }, getAuthHeader() )
           
        .then((response) =>{ 
            dowload_success(response, type, fileName); 
            axios.get(`${BASE_URL}/api/case/${id}/documents`, getAuthHeader())
        })
        .catch((error) => getError(error));
    }
}

export const downloadFileVersion = (location, type, fileName, id ) => {
    return () => {
        axios.get(`${API_DOWNLOAD_FILE}=${location}`, { responseType: 'blob' }, getAuthHeader() )
           
        .then((response) =>{ 
            dowload_success(response, type, fileName); 
            axios.get(`${BASE_URL}/api/case/${id}/documents`, getAuthHeader())
        })
        .catch((error) => getError(error));
    }
}

export const multipleDownloadFile = (params) => {
    let location = params.location;
    let type = params.type;
    let fileName = params.name;
    let id = params.id;
    return () => {
        axios.get(`${API_DOWNLOAD_FILE}=${location}`, { responseType: 'blob' }, getAuthHeader() )
           
        .then((response) =>{ 
            dowload_success(response, type, fileName); 
            axios.get(`${BASE_URL}/api/case/${id}/documents`, getAuthHeader())
        })
        .catch((error) => getError(error));
    }
}

export const dowload_success = (response, type, fileName) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.${type}`); 
    document.body.appendChild(link);
    link.click();
}


export const victimAdd = (params, callback = null, case_id)=>{
    const id = sessionStorage.getItem('case_id');
    const main_id = id ==null ? case_id : id;
    
    return(dispatch)=>{
        dispatch({ type: CASE.VICTIM_ADD_START});
        // allow add victim without a case_id
        let url = `${API_VICTIM_ADD}`;
        if (main_id !== null) {
            url = `${API_VICTIM_ADD}?case_id=${main_id}&is_archived=0`;
        }
        axios.post(url, params, getAuthHeader())
        .then(response=> { callback(response); dispatch({type: CASE.VICTIM_ADD_SUCCESS, payload: response.data}) })
        .catch(error=> { dispatch({type: CASE.VICTIM_ADD_FAILED , payload: getError(error)}) })
    }
}

export const victimListAll = ()=>{
    return(dispatch)=>{
        dispatch({ type: CASE.VICTIM_LIST_ALL_START });

        axios.get(`${API_VICTIM_LIST}`, getAuthHeader())
        .then(response=>{ dispatch({ type: CASE.VICTIM_LIST_ALL_SUCCESS, payload: response.data }) })
        .catch(error =>{ dispatch({ type: CASE.VICTIM_LIST_ALL_FAILED, payload: getError(error)}) })
    }
}

export const victimListAllArchived = ()=>{
    return(dispatch)=>{
        dispatch({ type: CASE.VICTIM_LIST_ALL_START });

        axios.get(`${API_VICTIM_LIST}?is_archived=false`, getAuthHeader())
        .then(response=>{ dispatch({ type: CASE.VICTIM_LIST_ALL_SUCCESS, payload: response.data }) })
        .catch(error =>{ dispatch({ type: CASE.VICTIM_LIST_ALL_FAILED, payload: getError(error)}) })
    }
}


export const victimList = (id, params)=>{
    const main = sessionStorage.getItem('case_id') === null ? id : sessionStorage.getItem('case_id');
    return(dispatch)=>{
        dispatch({ type: CASE.VICTIM_LIST_START });

        axios.get(`${API_VICTIM_LIST}?is_archived=false&case_id=${main}`, { params, ...getAuthHeader() })
        .then(response=>{  dispatch({ type: CASE.VICTIM_LIST_SUCCESS, payload: response.data }) })
        .catch(error =>{ dispatch({ type: CASE.VICTIM_LIST_FAILED, payload: getError(error)}) })
    }
}

export const victimAddDocument = (params, id, callback = null )=>{
    return(dispatch)=> {
        dispatch({ type: CASE.VICTIM_DOCUMENT_START});

        axios.post(`${API_VICTIM_DOCUMENT_ADD}/${id}/add_documents`, params, getAuthHeader())
        .then(response=> { callback(response, null); dispatch({type: CASE.VICTIM_DOCUMENT_SUCCESS, payload: response.data}) })
        .catch(error=> { callback(null, getError(error)); dispatch({type: CASE.VICTIM_DOCUMENT_FAILED, payload: getError(error)}) })
    }
}


export const victimGet = (id)=>{
    
    const id_case_victim = sessionStorage.getItem('victim_id');
    const main_id = id_case_victim == undefined ? id : id_case_victim;
    const case_id = sessionStorage.getItem('case_id');
    return(dispatch)=> {
        dispatch({ type: CASE.VICTIM_DETAILS_START});

        axios.get(`${API_VICTIM_GET}/${main_id}?case_id=${case_id}`,  getAuthHeader())
        .then(response=> { dispatch({type: CASE.VICTIM_DETAILS_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.VICTIM_DETAILS_FAILED, payload: getError(error)}) })
    }
}

export const caseGet = (case_id)=>{
    const id= sessionStorage.getItem('case_id');
    const main_id = id == null ? case_id : id;
    return(dispatch)=> {
        dispatch({ type: CASE.CASE_GET_START});

        axios.get(`${API_CASE_GET}/${main_id}`,  getAuthHeader())
        .then(response=> { dispatch({type: CASE.CASE_GET_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.CASE_GET_FAILED, payload: getError(error)}) })
    }
} 

export const victimAccountList = (a, b)=>{
     // const main_id = victim_id == null ? id : victim_id;
    const case_id = sessionStorage.getItem('case_id') !== null ? sessionStorage.getItem('case_id'): a;;
    const victim_id = sessionStorage.getItem('victim_id') !== null ? sessionStorage.getItem('victim_id') : b;

    return(dispatch)=> {
        dispatch({ type: CASE.VICTIM_ACCOUNT_START});
        
        axios.get(`${API_VICTIM_ACCOUNT_LIST}?victim_id=${victim_id}&case_id=${case_id}`,  getAuthHeader())
        .then(response=> {  dispatch({type: CASE.VICTIM_ACCOUNT_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.VICTIM_ACCOUNT_FAILED, payload: getError(error)}) })
    }
}

export const victimAccountListAll = ()=>{

   return(dispatch)=> {
       dispatch({ type: CASE.VICTIM_ACCOUNT_ALL_START});
       
       axios.get(`${API_VICTIM_ACCOUNT_LIST}`,  getAuthHeader())
       .then(response=> {  dispatch({type: CASE.VICTIM_ACCOUNT_ALL_SUCCESS, payload: response.data}) })
       .catch(error=> {  dispatch({type: CASE.VICTIM_ACCOUNT_ALL_FAILED, payload: getError(error)}) })
   }
}

export const victimAccountListAllArchived = ()=>{

    return(dispatch)=> {
        dispatch({ type: CASE.VICTIM_ACCOUNT_ALL_START});
        
        axios.get(`${API_VICTIM_ACCOUNT_LIST}?is_archived`,  getAuthHeader())
        .then(response=> {  dispatch({type: CASE.VICTIM_ACCOUNT_ALL_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.VICTIM_ACCOUNT_ALL_FAILED, payload: getError(error)}) })
    }
 }

export const victimSourceAccountAdd = (params, callback = null) => {
    return(dispatch)=> {
        dispatch({ type: CASE.VICTIM_SOURCE_ACCOUNT_START});

        axios.post(API_VICTIM_SOURCE_ADD, params, getAuthHeader())
        .then(response=> { callback(response); dispatch({type: CASE.VICTIM_SOURCE_ACCOUNT_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.VICTIM_SOURCE_ACCOUNT_FAILED, payload: getError(error)}) })
    }
}


export const AddAccountDocument = (params, id, callback = null )=>{
    return(dispatch)=> {
        dispatch({ type: CASE.ACCOUNT_ADD_DOCUMENTS_START});

        axios.post(`${API_VICTIM_ACCOUNT_ADD}/${id}/add_documents`, params, getAuthHeader())
        .then(response=> { callback(response, null); dispatch({type: CASE.ACCOUNT_ADD_DOCUMENTS_SUCCESS, payload: response.data}) })
        .catch(error=> { callback(null, getError(error)); dispatch({type: CASE.ACCOUNT_ADD_DOCUMENTS_FAILED, payload: getError(error)}) })
    }
}

export const victimActList = (id1, id2) => {
    const victim_id = sessionStorage.getItem('victim_id');
    const case_id = sessionStorage.getItem('case_id');
    
    if(id1 !== undefined && id2 !== undefined){
        return(dispatch)=> {
            dispatch({ type: CASE.VICTIM_ACT_LIST_START});
    
             axios.get(`${API_VICTIM_ACT_LIST}`, getAuthHeader())
            .then(response=> {   dispatch({type: CASE.VICTIM_ACT_LIST_SUCCESS, payload: response.data}) })
            .catch(error=> {  dispatch({type: CASE.VICTIM_ACT_LIST_FAILED, payload: getError(error)}) })
      }

    } else {

    return(dispatch)=> {
        dispatch({ type: CASE.VICTIM_ACT_LIST_START});

        axios.get(`${API_VICTIM_ACT_LIST}?victim_id=${victim_id}&case_id=${case_id}`, getAuthHeader())
        .then(response=> {  dispatch({type: CASE.VICTIM_ACT_LIST_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.VICTIM_ACT_LIST_FAILED, payload: getError(error)}) })
    }
  }
}


export const victimAccountAct = ()=>{
    const victim_id = sessionStorage.getItem('victim_id');
    const case_id = sessionStorage.getItem('case_id');
    const account_id = sessionStorage.getItem('account_id');

    return(dispatch)=> {
        dispatch({ type: CASE.VICTIM_ACT_ACCOUNT_LIST_START});

        axios.get(`${API_VICTIM_ACT_LIST}?victim_id=${victim_id}&case_id=${case_id}&account_id=${account_id}`, getAuthHeader())
        .then(response=> {  dispatch({type: CASE.VICTIM_ACT_ACCOUNT_LIST_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.VICTIM_ACT_ACCOUNT_LIST_FAILED, payload: getError(error)}) })
    }
}


export const victimActAdd = (params, callback = null, )=>{
    const id = sessionStorage.getItem('victim_id') !== null ?  sessionStorage.getItem('victim_id'):  sessionStorage.getItem('quick_victim_id');
    const account_id = params.account_id != undefined ? params.account_id : sessionStorage.getItem('account_id');
    return(dispatch)=> {
        dispatch({ type: CASE.VICTIM_ACT_ADD_START});

        axios.post(`${API_VICTIM_ACT_ADD}?victim_id=${id}&account_id=${account_id}`, params, getAuthHeader())
        .then(response=> { callback(response, null); dispatch({type: CASE.VICTIM_ACT_ADD_SUCCESS, payload: response.data}) })
        .catch(error=> {  callback(null, getError(error)); dispatch({type: CASE.VICTIM_ACT_ADD_FAILED, payload: getError(error)}) })
    }
}
 
export const victimActAssailant= (params, callback = null)=>{
    return(dispatch)=> {
        dispatch({ type: CASE.VICTIM_ASSAILANT_ADD_START});

        axios.post(API_VICTIM_ACT_ASSAILANT, params, getAuthHeader())
        .then(response=> { callback(response); dispatch({type: CASE.VICTIM_ASSAILANT_ADD_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.VICTIM_ASSAILANT_ADD_FAILED, payload: getError(error)}) })
    }
}

export const victimActGet = (act_id)=>{
    const id = sessionStorage.getItem('act_id');
    const main_id = id == null ? act_id : id;
    return(dispatch)=> {
        dispatch({ type: CASE.VICTIM_ACT_GET_START});

        axios.get(`${API_VICTIM_ACT_VIEW}/${main_id}`,  getAuthHeader())
        .then(response=> {  dispatch({type: CASE.VICTIM_ACT_GET_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.VICTIM_ACT_GET_FAILED, payload: getError(error)}) })
    }
}
 
export const victimAcccountGet = (id)=>{
    const account_id = sessionStorage.getItem('account_id');
    const main_id = account_id == null ? id : account_id;
    return(dispatch)=> {
        dispatch({ type: CASE.ACCOUNT_GET_DOCUMENTS_START});

        axios.get(`${API_VICTIM_ACCOUNT_GET}/${main_id}`,  getAuthHeader())
        .then(response=> {  dispatch({type: CASE.ACCOUNT_GET_DOCUMENTS_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.ACCOUNT_GET_DOCUMENTS_FAILED, payload: getError(error)}) })
    }
}
 

export const victimActAddDocument = (params, callback = null )=>{
    const main_id = sessionStorage.getItem('act_id');
    const act_add_id_upload = sessionStorage.getItem('act_add_id_upload');
    const act_add_id = main_id == null ? act_add_id_upload : main_id ;

    return(dispatch)=> {
        dispatch({ type: CASE.VICTIM_DOCUMENT_START});

        axios.post(`${API_VICTIM_ACT_UPLOAD}/${act_add_id}/add_documents`, params, getAuthHeader())
        .then(response=> { callback(response, null); dispatch({type: CASE.VICTIM_DOCUMENT_SUCCESS, payload: response.data}) })
        .catch(error=> { callback(null, getError(error)); dispatch({type: CASE.VICTIM_DOCUMENT_FAILED, payload: getError(error)}) })
    }
}

export const actFileList = (act_id)=>{
    const id = sessionStorage.getItem('act_id');
    const main_id = id == null ? act_id: id;
    return(dispatch)=> {
        dispatch({ type: CASE.LIST_OF_FILES_ACT_START});

        axios.get(`${API_DOCUMENTS}?is_archived=false&act_id=${main_id}`,  getAuthHeader())
        .then(response=> {  dispatch({type: CASE.LIST_OF_FILES_ACT_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.LIST_OF_FILES_ACT_FAILED, payload: getError(error)}) })
    }
}

export const actHistoryList = (act_id)=>{
    const id = sessionStorage.getItem('act_id');
    const main_id = id == null ? act_id: id;
    return(dispatch)=> {
        dispatch({ type: CASE.LIST_OF_HISTORY_ACT_START});

        axios.get(`${API_HISTORY_LIST}?act_id=${main_id}`,  getAuthHeader())
        .then(response=> {  dispatch({type: CASE.LIST_OF_HISTORY_ACT_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.LIST_OF_HISTORY_ACT_FAILED, payload: getError(error)}) })
    }
}


export const accountFileList = (id)=>{
    const account_id = sessionStorage.getItem('account_id');
    const main_id = account_id == null ? id: account_id;
    return(dispatch)=> {
        dispatch({ type: CASE.LIST_OF_FILES_ACCOUNT_START});

        axios.get(`${API_DOCUMENTS}?is_archived=false&account_id=${main_id}`,  getAuthHeader())
        .then(response=> {  dispatch({type: CASE.LIST_OF_FILES_ACCOUNT_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.LIST_OF_FILES_ACCOUNT_FAILED, payload: getError(error)}) })
    }
}

export const accountHistoryList = (id)=>{
    const account_id = sessionStorage.getItem('account_id');
    const main_id = account_id == null ? id: account_id;
    return(dispatch)=> {
        dispatch({ type: CASE.LIST_OF_HISTORY_ACCOUNT_START});

        axios.get(`${API_HISTORY_LIST}?account_id=${main_id}`,  getAuthHeader())
        .then(response=> {  dispatch({type: CASE.LIST_OF_HISTORY_ACCOUNT_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.LIST_OF_HISTORY_ACCOUNT_FAILED, payload: getError(error)}) })
    }
}

export const victimFileList = (id)=>{
    const victim_id = sessionStorage.getItem('victim_id');
    const main_id = victim_id != null ? victim_id : id;
    return(dispatch)=> {
        dispatch({ type: CASE.LIST_OF_VICTIM_FILES_LIST_START});

        axios.get(`${API_DOCUMENTS}?is_archived=false&victims_id=${main_id}`,  getAuthHeader())
        .then(response=> {  dispatch({type: CASE.LIST_OF_VICTIM_FILES_LIST_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.LIST_OF_VICTIM_FILES_LIST_FAILED, payload: getError(error)}) })
    }
}

export const victimFileListMain = (id)=>{
    const victim_id = sessionStorage.getItem('victim_id');
    const main_id = victim_id != null ? victim_id : id;
    
    return(dispatch)=> {
        dispatch({ type: CASE.VICTIM_LIST_MAIN_START});

        axios.get(`${API_DOCUMENTS}?is_archived=false&victim_id=${main_id}`,  getAuthHeader())
        .then(response=> {  dispatch({type: CASE.VICTIM_LIST_MAIN_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.VICTIM_LIST_MAIN_FAILED, payload: getError(error)}) })
    }
}

export const victimHistoryList = (id)=>{
    const victim_id = sessionStorage.getItem('victim_id');
    const main_id = victim_id != null ? victim_id : id;
    return(dispatch)=> {
        dispatch({ type: CASE.LIST_OF_VICTIM_HISTORY_LIST_START});

        axios.get(`${API_HISTORY_LIST}?victim_id=${main_id}`,  getAuthHeader())
        .then(response=> {  dispatch({type: CASE.LIST_OF_VICTIM_HISTORY_LIST_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.LIST_OF_VICTIM_HISTORY_LIST_FAILED, payload: getError(error)}) })
    }
}




export const caseStatusList = ()=>{ 
    return(dispatch)=> {
        dispatch({ type: CASE.STATUS_START});

        axios.get(API_CASE_STATUS_LIST, getAuthHeader())
        .then(response=> { dispatch({type: CASE.STATUS_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.STATUS_FAILED, payload: getError(error)}) })
    }
}

export const caseStatusAdd = (rows, callback=null)=>{
    return(dispatch)=> {
        dispatch({ type: CASE.STATUS_ADD_START});

        axios.post(API_CASE_STATUS_ADD, rows, getAuthHeader())
        .then(response=> { callback(response.data); dispatch({type: CASE.STATUS_ADD_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.STATUS_ADD_FAILED, payload: getError(error)}) })
    }
}

export const  caseTypeAdd = (rows)=>{ 
    return(dispatch)=> {
        dispatch({ type: CASE.TYPE_ADD_START});

        axios.post(API_CASE_TYPE_ADD, rows, getAuthHeader())
        .then(response=> { dispatch({type: CASE.TYPE_ADD_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.TYPE_ADD_FAILED, payload: getError(error)}) })
    }
}

export const caseTypeList = ()=>{
    return(dispatch)=> {
        dispatch({ type: CASE.TYPE_START});

        axios.get(API_CASE_TYPE_LIST, getAuthHeader())
        .then(response=> { dispatch({type: CASE.TYPE_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.TYPE_FAILED, payload: getError(error)}) })
    }
}

export const victimTableList = (id)=>{
    return(dispatch)=>{
        dispatch({ type: CASE.LIST_OF_VICTIM_TABLE_START });

        axios.get(`${API_VICTIM_LIST}?is_archived=false&case_id=${id}`, getAuthHeader())
        .then(response=>{ dispatch({ type: CASE.LIST_OF_VICTIM_TABLE_SUCCESS, payload: { data: response.data, id } }) })
        .catch(error =>{ dispatch({ type: CASE.LIST_OF_VICTIM_TABLE_FAILED, payload: getError(error)}) })
    }
}

export const assaillantList= ()=>{
    return(dispatch)=>{
        dispatch({ type: CASE.VICTIM_ASSAILANT_LIST_START });

        axios.get(API_VICTIM_ACT_ASSAILANT_LIST, getAuthHeader())
        .then(response=>{  dispatch({ type: CASE.VICTIM_ASSAILANT_LIST_SUCCESS, payload: response.data }) })
        .catch(error =>{ dispatch({ type: CASE.VICTIM_ASSAILANT_LIST_FAILED, payload: getError(error)}) })
    }
}

export const accountSourceList= ()=>{
    return(dispatch)=>{
        dispatch({ type: CASE.VICTIM_ACCOUNT_SOURCE_LIST_START });

        axios.get(API_VICTIM_ACCOUNT_SOURCE_LIST, getAuthHeader())
        .then(response=>{  dispatch({ type: CASE.VICTIM_ACCOUNT_SOURCE_LIST_SUCCESS, payload: response.data }) })
        .catch(error =>{ dispatch({ type: CASE.VICTIM_ACCOUNT_SOURCE_LIST_FAILED, payload: getError(error)}) })
    }
}

export const DocumentsList = ()=>{
    return(dispatch)=> {
        dispatch({ type: CASE.LIST_OF_DOCUMENTS_ALL_START});

        axios.get(`${BASE_URL}/api/documents?is_archived=false`, getAuthHeader())
        .then(response=> {  dispatch({type: CASE.LIST_OF_DOCUMENTS_ALL_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.LIST_OF_DOCUMENTS_ALL_FAILED, payload: getError(error)}) })
    }
}

export const DocumentArchived = (docs_id, callback = null)=>{
    return()=> {
        axios.post(`${BASE_URL}/api/document/${docs_id}/archive_document`, docs_id, getAuthHeader())
        .catch(error=> {  if(error){console.log(getError(error))} })
    }
}   

export const ActArchived = (act_id, callback = null)=>{
    return()=> {
        axios.post(`${BASE_URL}/api/act/${act_id}`, act_id, getAuthHeader())
        .catch(error=> {  if(error){console.log(getError(error))} })
    }
}   

export const AccountArchived = (account_id, callback = null)=>{
    return()=> {
        axios.post(`${BASE_URL}/api/account/${account_id}`, account_id, getAuthHeader())
        .catch(error=> {  if(error){console.log(getError(error))} })
    }
}   


export const DocumentsVersionList = ()=>{
    const id = sessionStorage.getItem('docs_id');

    return(dispatch)=> {
        dispatch({ type: CASE.DOCUMENT_VERSION_LIST_START});
        
        axios.get(`${BASE_URL}/api/documents/versions?document_id=${id}`, getAuthHeader())
        .then(response=> {  dispatch({type: CASE.DOCUMENT_VERSION_LIST_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.DOCUMENT_VERSION_LIST_FAILED, payload: getError(error)}) })
    }
}

export const DocumentsVersionAdd= (params)=>{
    return()=> {
        
        axios.post(`${BASE_URL}/api/documents/${params.document_id}/add_version_documents`, params, getAuthHeader())
        .then(response=> { console.log(response)  })
        .catch(error=> {  console.log(getError(error));  })
    }
}

export const linkToDrugTradeAdd = (params)=>{
    const id = sessionStorage.getItem('account_id');
    return(dispatch)=> {
        dispatch({ type: CASE.ADD_LINK_TO_DRUG_TRADE_START});

        axios.post(`${API_LINK_TO_DRUG_TRADE}?account_id=${id}`, params, getAuthHeader())
        .then(response=> { dispatch({type: CASE.ADD_LINK_TO_DRUG_TRADE_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.ADD_LINK_TO_DRUG_TRADE_FAILED, payload: getError(error)}) })
    }
}
 
export const victimAccountAdd = (params, links, callback) => {
   const id = sessionStorage.getItem('source_id');
   const victim_id = sessionStorage.getItem('victim_id') !== null ?  sessionStorage.getItem('victim_id'): sessionStorage.getItem('quick_victim_id');
   const case_id = sessionStorage.getItem('case_id') !== null ? sessionStorage.getItem('case_id') : sessionStorage.getItem('quick_case_id');

   
    return(dispatch)=> {
        dispatch({ type: CASE.VICTIM_SOURCE_ACCOUNT_DETAILS_START});

        axios.post(`${API_VICTIM_ACCOUNT_ADD}?case_id=${case_id}&victim_id=${victim_id}&account_source_id=${id}`, params, getAuthHeader())
        .then((response) => { callback(response);   
            return  axios.post(`${API_LINK_TO_DRUG_TRADE}?account_id=${response.data.account.id}`, links, getAuthHeader())
         })
         
        .then((response)=> {  dispatch({type: CASE.VICTIM_SOURCE_ACCOUNT_DETAILS_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.VICTIM_SOURCE_ACCOUNT_DETAILS_FAILED, payload: getError(error)}) })
    }
}

export const victimActAssailantGet= ( )=>{  
    const id = sessionStorage.getItem('assailant_id');
    return(dispatch)=> {
        dispatch({ type: CASE.ASSAILANT_GET_START});

        axios.get(`${BASE_URL}/api/assailant/${id}`,  getAuthHeader())
        .then(response=> { dispatch({type: CASE.ASSAILANT_GET_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.ASSAILANT_GET_FAILED, payload: getError(error)}) })
    }
}     


export const victimSourceGet= ()=>{
    const id = sessionStorage.getItem('source_id');
    return(dispatch)=> {
        dispatch({ type: CASE.ASSAILANT_GET_START});

        axios.get(`${BASE_URL}/api/accountsource/${id}`,  getAuthHeader())
        .then(response=> { dispatch({type: CASE.ASSAILANT_GET_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.ASSAILANT_GET_FAILED, payload: getError(error)}) })
    }
}    

export const victimAccountGet = ()=>{
    const id = sessionStorage.getItem('account_id');
    return(dispatch)=> {
        dispatch({ type: CASE.ACCOUNT_GET_START});

        axios.get(`${BASE_URL}/api/account/${id}`,  getAuthHeader())
        .then(response=> { dispatch({type: CASE.ACCOUNT_GET_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.ACCOUNT_GET_FAILED, payload: getError(error)}) })
    }
}    

export const caseEdit = (params, callback = null) => {
    const id = sessionStorage.getItem('case_id');
     return()=> {

         axios.put(`${BASE_URL}/api/case/${id}`, params, getAuthHeader())
         .then(response=> { callback(response);  })
         .catch(error=> { if(error) { console.log(getError(error)) } })
     }
 }
 
 export const victimEdit = (params, callback = null) => {
    const id = sessionStorage.getItem('victim_id');
    const case_id = sessionStorage.getItem('case_id');

    // allow update victim without a case_id
    let url = `${BASE_URL}/api/victim/${id}`;
    if (case_id !== null) {
        url = `${BASE_URL}/api/victim/${id}?case_id=${case_id}`
    }
    
     return()=> {
         axios.put(url, params, getAuthHeader())
         .then(response=> { callback(response);  })
         .catch(error=> { if(error) { console.log(getError(error)) } })
     }
 }

 
 export const actEdit = (params, editCallback = null) => {
    const id = sessionStorage.getItem('act_id');
    const victim_id = sessionStorage.getItem('victim_id');
    const account_id = sessionStorage.getItem('account_id');

    if(account_id == null){
        return()=> {
            axios.put(`${BASE_URL}/api/act/${id}?victim_id=${victim_id}`, params, getAuthHeader())
            .then(response=> { editCallback(response);  })
            .catch(error=> { if(error) { console.log(getError(error)) } })
        }
    } else {
        return()=> {
            const data = params;
            params.account_id = account_id;

            axios.put(`${BASE_URL}/api/act/${id}?victim_id=${victim_id}`, data, getAuthHeader())
            .then(response=> { editCallback(response);  })
            .catch(error=> { if(error) { console.log(getError(error)) } })
        }
    }
 }

 export const assailantEdit = (params, editCallback = null) => {
    const id = sessionStorage.getItem('assailant_id');
    const victim_id = sessionStorage.getItem('victim_id');
     return()=> {
         axios.put(`${BASE_URL}/api/assailant/${id}?victim_id=${victim_id}`, params, getAuthHeader())
         .then(response=> { editCallback(response);  })
         .catch(error=> { if(error) { console.log(getError(error)) } })
     }
 }

 export const source_edit = (params, editCallback = null) => {
    const id = sessionStorage.getItem('account_id');


     return()=> {
         axios.put(`${BASE_URL}/api/account/${id}`, params, getAuthHeader())
         .then(response=> { editCallback(response);  })
         .catch(error=> { if(error) { console.log(getError(error)) } })
     }
 }
  
 export const Account_source_edit = (params, editCallback = null) => {
    const id = sessionStorage.getItem('account_source_id_edit');

    return()=> {
         axios.put(`${BASE_URL}/api/accountsource/${id}`, params, getAuthHeader())
         .then(response=> { editCallback(response);  })
         .catch(error=> { if(error) { console.log(getError(error)) } })
     }
 }
  
 export const links_drug_list = ()=>{
    return(dispatch)=> {
        dispatch({ type: CASE.LIST_LINK_TO_DRUG_TRADE_START});

        axios.get(`${BASE_URL}/api/link_to_drug_trades`,  getAuthHeader())
        .then(response=> { dispatch({type: CASE.LIST_LINK_TO_DRUG_TRADE_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.LIST_LINK_TO_DRUG_TRADE_FAILED, payload: getError(error)}) })
    }
}    

export const links_drug_list_archived = ()=>{
    return(dispatch)=> {
        dispatch({ type: CASE.LIST_LINK_TO_DRUG_TRADE_START});

        axios.get(`${BASE_URL}/api/link_to_drug_trades?is_archived=false`,  getAuthHeader())
        .then(response=> { dispatch({type: CASE.LIST_LINK_TO_DRUG_TRADE_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.LIST_LINK_TO_DRUG_TRADE_FAILED, payload: getError(error)}) })
    }
}    


export const link_edit = (params, editCallback = null ) => {
    const id = sessionStorage.getItem('account_id');
    const link = sessionStorage.getItem('link_drug_id');
     return()=> {
         axios.put(`${BASE_URL}/api/link_to_drug_trade/${link}?account_id=${id}`, params, getAuthHeader())
         .then(response=> { editCallback(response);  })
         .catch(error=> { if(error) { console.log(getError(error)) } })
     }
 }
 
 
export function saveRoute(reponse, path) {
    return {
        type: "PREVIOUS ROUTE SAVE",
        payload: reponse, path
    }
  }


export const ActType = (rows, callback = null) => {

     return()=> {
         axios.post(`${BASE_URL}/api/act_type`,  rows, getAuthHeader())
         .then(response=> { callback(response);  })
         .catch(error=> { if(error) { console.log(getError(error)) } })
     }
 }
  

export const ActTypeList = ()=>{
    return(dispatch)=> {
        dispatch({ type: CASE.ACT_TYPE_LIST_START});

        axios.get(`${BASE_URL}/api/act_types`, getAuthHeader())
        .then(response=> { dispatch({type: CASE.ACT_TYPE_LIST_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.ACT_TYPE_LIST_FAILED, payload: getError(error)}) })
    }
}

export const AssaillantType = (row, callback = null) => {
    return()=> {
        axios.post(`${BASE_URL}/api/assailant_type`, row, getAuthHeader())
        .then(response=> { callback(response);  })
        .catch(error=> { if(error) { console.log(getError(error)) } })
    }
}
 

export const AssaillantTypeList = ()=>{
   return(dispatch)=> {
       dispatch({ type: CASE.ASSAILLANT_TYPE_LIST_START});

       axios.get(`${BASE_URL}/api/assailant_types`, getAuthHeader())
       .then(response=> { dispatch({type: CASE.ASSAILLANT_TYPE_LIST_SUCCESS, payload: response.data}) })
       .catch(error=> {  dispatch({type: CASE.ASSAILLANT_TYPE_LIST_FAILED, payload: getError(error)}) })
   }
}


export const SourceType = (rows, callback = null) => {

    return()=> {
        axios.post(`${BASE_URL}/api/account_source_type`,  rows, getAuthHeader())
        .then(response=> { callback(response);  })
        .catch(error=> { if(error) { console.log(getError(error)) } })
    }
}
 

export const SourceTypeList = ()=>{
   return(dispatch)=> {
       dispatch({ type: CASE.ACCOUNT_SOURCE_TYPE_LIST_START});

       axios.get(`${BASE_URL}/api/account_source_types`, getAuthHeader())
       .then(response=> { dispatch({type: CASE.ACCOUNT_SOURCE_TYPE_LIST_SUCCESS, payload: response.data}) })
       .catch(error=> {  dispatch({type: CASE.ACCOUNT_SOURCE_TYPE_LIST_FAILED, payload: getError(error)}) })
   }
}

export const configStatusEdit = (params, id, editCallback = null) => {
     return()=> {
         axios.put(`${BASE_URL}/api/case_status/${id}`, params, getAuthHeader())
         .then(response=> { editCallback(response);  })
         .catch(error=> { if(error) { console.log(getError(error)) } })
     }
 }
 
 export const configTypeEdit = (params, id, editCallback = null) => {
    return()=> {
        axios.put(`${BASE_URL}/api/case_type/${id}`, params, getAuthHeader())
        .then(response=> { editCallback(response);  })
        .catch(error=> { if(error) { console.log(getError(error)) } })
    }
}

export const configAccountTypeEdit = (params, id, editCallback = null) => {
    return()=> {
        axios.put(`${BASE_URL}/api/account_source_type/${id}`, params, getAuthHeader())
        .then(response=> { editCallback(response);  })
        .catch(error=> { if(error) { console.log(getError(error)) } })
    }
}

export const configassailantTypeEdit = (params, id, editCallback = null) => {
    return()=> {
        axios.put(`${BASE_URL}/api/assailant_type/${id}`, params, getAuthHeader())
        .then(response=> { editCallback(response);  })
        .catch(error=> { if(error) { console.log(getError(error)) } })
    }
}

export const configActTypeEdit = (params, id, editCallback = null) => {
    return()=> {
        axios.put(`${BASE_URL}/api/act_type/${id}`, params, getAuthHeader())
        .then(response=> { editCallback(response);  })
        .catch(error=> { if(error) { console.log(getError(error)) } })
    }
}

export const emailList = ()=>{
    return(dispatch)=> {
        dispatch({ type: CASE.EMAIL_CONFIGURATION_START});
 
        axios.get(`${BASE_URL}/api/config_emails`, getAuthHeader())
        .then(response=> { dispatch({type: CASE.EMAIL_CONFIGURATION_SUCCESS, payload: response.data}) })
        .catch(error=> {  dispatch({type: CASE.EMAIL_CONFIGURATION_FAILED, payload: getError(error)}) })
    }
 }

 export const emailUpdate = ( params, callback = null) => {
     let id = 1 ;
    return()=> {
        axios.put(`${BASE_URL}/api/config_email/${id}`, params, getAuthHeader())
        .then(response=> { callback(response);  })
        .catch(error=> { if(error) { console.log(getError(error)) } })
    }
}
