import React, { Component } from 'react'
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    } from 'react-vis';
import Location from './location';
import Ratio from './ratio';
import {Input, Select} from 'antd';
import moment from 'moment';
import { css, cx } from 'emotion';

const data = [
    {y: 5,  x: "0_12" },
    {y: 10, x: "13_17"},
    {y: 15, x: "18_24"},
    {y: 20, x: "25_34"},
    {y: 25, x: "35_44"},
    {y: 30, x: "45_54"},
    {y: 35, x: "55_64"},
    {y: 40, x: "65_74"},
    {y: 45, x: "45_older"},
    {y: 50, x: "no_info"},
    {y: 55 },
    {y: 60 },
    {y: 65 },
    {y: 70 },
    {y: 75 },
    {y: 80 },
    {y: 85 },
    {y: 90 },
    {y: 95 },
    {y: 100},
  ];
  

class ChartsAndTable extends Component {
    
    state = {
        value: 1
    }

  perAgeGraph = () => {
    var arr = this.props.age;

    var API  = arr.map(item=>{
        return  { y: item['0_12'], x: "0_12" }
    })

    var API2  = arr.map(item=>{
        return  { y: item['13_17'], x: "13_17" }
    })

    var API3  = arr.map(item=>{
        return  { y: item['18_24'], x: "18_24" }
    })

    var API4  = arr.map(item=>{
        return  { y: item['25_34'], x: "25_34" }
    })

    var API5  = arr.map(item=>{
        return  { y: item['35_44'], x: "35_44" }
    })

    var API6  = arr.map(item=>{
        return  { y: item['45_54'], x: "45_54" }
    })

    var API7  = arr.map(item=>{
        return  { y: item['55_64'], x: "55_64" }
    })

    var API8  = arr.map(item=>{
        return  { y: item['65_74'], x: "65_74" }
    })

    var API9 = arr.map(item=>{
        return  { y: item['45_older'], x: "45_older" }
    })

    var API10  = arr.map(item=>{
        return  { y: item['no_info'], x: "no_info" }
    })

    return ( 
        <XYPlot xType="ordinal" width={600} height={300} xDistance={10}>
            <VerticalGridLines style={{color: 'red'}}/>
            <HorizontalGridLines style={{color: 'red'}}/>
            <XAxis />
            <YAxis />
            <VerticalBarSeries data={data} color= "transparent" />
            <VerticalBarSeries data={API}  color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API2} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API3} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API4} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API5} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API6} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API7} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API8} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API9} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API10} color = '#6998dc' style={{width: '20px', }}/>
        </XYPlot>
        )
  }

  graphs = () => {
    if( this.state.value == 1 ){
       return this.perAgeGraph()
    } else if (this.state.value == 2) {
       return <Location killings={this.props.killings}/>
    } else if (this.state.value == 3) {
       return <Ratio profile={this.props.profile}/>
    }
  }
  
  render() {
    return (
      <div style={{ margin: '20px 0px' }}>
       
        <h4 style={{padding:'27px 20px 10px 20px', color: "#22568f", fontWeight: "bold"}}>
           <Select style={{width: '200px', color: "#22568f", fontWeight: "bold"}} defaultValue="1" onChange={(value)=>{ this.setState({ value })}}>
               <Select.Option style={{color: "#22568f", fontWeight: "bold"}} key="1" value="1"> Victim's per Age</Select.Option>
               <Select.Option style={{color: "#22568f", fontWeight: "bold"}} key="2" value="2"> Victim's per Location</Select.Option>
               <Select.Option style={{color: "#22568f", fontWeight: "bold"}} key="3" value="3"> Victim's per Sex Ratio</Select.Option>
           </Select>
        </h4>
{/*         
        <div style={{margin: '30px 20px 0px 20px', textAlign: 'right'}}>
            <input value={moment.month() +" "+ moment(Date()).format("YYYY")} />
        </div> */}

            
        <hr style={{width: '95%'}} />

        <div style={{ margin: ' 30px 0px', padding: '20px'}}>
          { this.graphs() }  
        </div>

      </div>
    )
  }
}

export default ChartsAndTable;