import React, { Component } from 'react'

export default class SourceTab extends Component {


    tabsCase = ()=>{
        var pathconsole = this.props.history.location.pathname;

        if( pathconsole == '/chr/case/victim/add/source' || pathconsole == "/chr/case/victim/act/add" || pathconsole == `/chr/victim/add/act/1`){
            return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <div style={backgroundTabs}> 
                <center ><p style={{fontSize: '25px', fontWeight: 'bold 200px', margin: '5px 0px'}}>1</p></center>
            </div>
            
            <div style={lineDiv}></div>


            <div style={backgroundPlain}> 
                <center >
                <p style={{fontSize: '25px', fontWeight: 'bold 200px'}}>2</p></center>
            </div>

            <div style={lineDiv}></div>

            <div style={backgroundPlain}> 
                <center >
                <p style={{fontSize: '25px', fontWeight: 'bold 200px'}}>3</p></center>
            </div>
        </div>

        }else if( pathconsole == '/chr/case/victim/add/account' || pathconsole == "/chr/case/victim/act/add/assailant" || pathconsole == `/chr/victim/add/act/2`) {
           return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <div style={backgroundTabs}> 
                <center ><p style={{fontSize: '25px', fontWeight: 'bold 200px', margin: '5px 0px'}}>1</p></center>
            </div>
            
            <div style={lineDiv2}></div>


            <div style={backgroundTabs}> 
                <center >
                    <p style={{fontSize: '25px', fontWeight: 'bold 200px', margin: '5px 0px'}}>2</p></center>
            </div>

            <div style={lineDiv}></div>

            <div style={backgroundPlain}> 
                <center >
                <p style={{fontSize: '25px', fontWeight: 'bold 200px'}}>3</p></center>
            </div>
        </div>

        }else{
        
        return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <div style={backgroundTabs}> 
                <center ><p style={{fontSize: '25px', fontWeight: 'bold 200px', margin: '5px 0px'}}>1</p></center>
            </div>
            
            <div style={lineDiv2}></div>


            <div style={backgroundTabs}> 
                <center >
                    <p style={{fontSize: '25px', fontWeight: 'bold 200px', margin: '5px 0px'}}>2</p></center>
            </div>

            <div style={lineDiv2}></div>

            <div style={backgroundTabs}> 
                <center >
                    <p style={{fontSize: '25px', fontWeight: 'bold 200px', margin: '5px 0px'}}>3</p></center>
            </div>
        </div>
        }
    }

    render() {
        var pathconsole = this.props.history.location.pathname;
        var Act_info = <span>&nbsp; &nbsp; &nbsp; Act Information</span>
        return (
        <div>
            { this.tabsCase() }

            <div style={styleName}>
                <div style={{margin: '20px 0px'}}>
                <span > 
                    <label style={labelStyle1}> 
                        { pathconsole == "/chr/case/victim/act/add" ?  "Act Information"  : "Account Source Info" }
                    </label>
                </span>

                <span style={{margin: '0px 120px'}}>
                    <label style={ 
                        pathconsole == '/chr/case/victim/add/account' ||
                        pathconsole == '/chr/case/victim/act/add/assailant/upload' || 
                        pathconsole == '/chr/case/victim/add/files' || 
                        pathconsole == '/chr/case/victim/act/add/assailant' || 
                        pathconsole == `/chr/victim/add/act/2`||
                        pathconsole == `/chr/victim/add/act/3`
                        ? labelStyle1 : labelStyle2}> 
                    { 
                        pathconsole == "/chr/case/victim/act/add" ? "Assailant Information" :  "Account Information" 
                    } 
                    </label>
                </span>

                <span style={{margin: '0px 20px'}}> 
                    <label style={ 
                        pathconsole == '/chr/case/victim/add/files' || 
                        pathconsole == '/chr/case/victim/act/add/assailant/upload' || 
                        pathconsole == `/chr/victim/add/act/3`? labelStyle1 : labelStyle2} >  
                    Attachments
                    </label>
                </span>
                </div>
            </div>
        </div>
        )
    }
}

const backgroundTabs = {
   background: '#77B9FF',
    borderRadius: '50%', 
    width: '50px', 
    height: '50px',
    color: 'white'
}

const backgroundPlain = {
    borderRadius: '50%', 
    width: '50px', 
    height: '50px', 
    border: '5px solid rgb(241 241 241)'
}

const lineDiv = {
    width: '230px',
    border: '2px solid rgb(241 241 241)',
}

const lineDiv2 = {
    width: '230px',
    border: '2px solid #bbdcff',
    
}

const styleName= {
  display: 'flex',
  justifyContent: 'center'
}


const labelStyle1 = {
    color: '#77B9FF', 
    fontWeight: '900',
    fontFamily: 'Montserrat',
    fontSize: '18px'
}

const labelStyle2 = {
    color: '#D2D2D2', 
    fontWeight: '900',
    fontFamily: 'Montserrat',
    fontSize: '18px'
}