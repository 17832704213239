import { USER } from './actionCreators';


const INITIAL_STATE = {
    loading: false,
    error: '',
    user: {},
    users:{},
    logs: {},
    history: {},
    getUser: {}, 
    userRequests: {},
    positionList: {}
};


 const userReducer = (state = INITIAL_STATE, action)=> {
    switch (action.type) {
        
        case USER.START:
            return {...state, loading: true, error: ''};
        case USER.SUCCESS:
            return {...state, loading: false, users: action.payload};
        case USER.FAILED:
            return {...state, loading: false, error: action.payload};

        case USER.USER_ADD_START:
            return {...state, loading: true, error: ''};
        case USER.USER_ADD_SUCCESS:
            return {...state, loading: false, user: action.payload};
        case USER.USER_ADD_FAILED:
            return {...state, loading: false, error: action.payload};
   
        case USER.USER_EDIT_START:
            return {...state, loading: true, error: ''};
        case USER.USER_EDIT_SUCCESS:
            // const newData = action.payload;
            // const data = state.users;
            // for(var i=0; i < data.length; i++){
            //     if(data[i].id == newData.id ){
            //      data[i] = newData;
            //     }
            // }
            return {...state, loading: false, users: action.payload};
        case USER.USER_EDIT_FAILED:
            return {...state, loading: false, error: action.payload};   

        case USER.USER_LOG_START: 
            return {...state, loading: true, error: action.payload};
        case USER.USER_LOG_SUCCESS: 
            return {...state, loading: false, logs: action.payload};
        case USER.USER_LOG_FAILED: 
            return {...state, loading: false, error: action.payload};

        case USER.LIST_USER_LOG_START: 
            return {...state, loading: true, error: action.payload};
        case USER.LIST_USER_LOG_SUCCESS: 
            return {...state, loading: false, history: action.payload};
        case USER.LIST_USER_LOG_FAILED: 
            return {...state, loading: false, error: action.payload};

        case USER.GET_LIST_USER_LOG_START: 
            return {...state, loading: true, error: action.payload};
        case USER.GET_LIST_USER_LOG_SUCCESS: 
            return {...state, loading: false, getUser: action.payload};
        case USER.GET_LIST_USER_LOG_FAILED: 
            return {...state, loading: false, error: action.payload};

        case USER.GET_LIST_USER_REQUEST_START: 
            return {...state, loading: true, error: action.payload};
        case USER.GET_LIST_USER_REQUEST_SUCCESS: 
            return {...state, loading: false, userRequests: action.payload};
        case USER.GET_LIST_USER_REQUEST_FAILED: 
            return {...state, loading: false, error: action.payload};

        case USER.POSITION_LIST_START: 
            return {...state, loading: true, error: action.payload};
        case USER.POSITION_LIST_SUCCESS: 
            return {...state, loading: false, positionList: action.payload};
        case USER.POSITION_LIST_FAILED: 
            return {...state, loading: false, error: action.payload};
            
            
        default:
            return state;
    }
}

export default userReducer;


