export const BASE_URL = 'https://api-chr.staging.acctechnology.ph';
// export const BASE_URL = 'http://chr-backend.test';
// export const BASE_URL = 'http://192.163.3.154:80';

export const API_LOGIN = `${BASE_URL}/api/login`;
export const API_FILE_UPLOAD = `${BASE_URL}/api/file`;

export const API_CASE_ADD = `${BASE_URL}/api/case`;
export const API_CASE_LIST = `${BASE_URL}/api/cases`;
export const API_CASE_GET = `${BASE_URL}/api/case`;

export const API_DOCUMENT_ADD = `${BASE_URL}/api/case`;
export const API_FILES_LIST = `${BASE_URL}/api/case`; 
export const API_HISTORY_LIST = `${BASE_URL}/api/history`;

export const API_VICTIM_ADD = `${BASE_URL}/api/victim`;
export const API_VICTIM_LIST = `${BASE_URL}/api/victims`;
export const API_VICTIM_DOCUMENT_ADD = `${BASE_URL}/api/victim`;
export const API_VICTIM_GET = `${BASE_URL}/api/victim`;

export const API_VICTIM_ACCOUNT_LIST = `${BASE_URL}/api/accounts`;
export const API_VICTIM_SOURCE_ADD = `${BASE_URL}/api/accountsource`;
export const API_VICTIM_ACCOUNT_SOURCE_LIST = `${BASE_URL}/api/accountsources`;
export const API_VICTIM_ACCOUNT_ADD = `${BASE_URL}/api/account`; 
export const API_VICTIM_ACCOUNT_GET = `${BASE_URL}/api/account`;

export const API_VICTIM_ACT_VIEW = `${BASE_URL}/api/act`;
export const API_VICTIM_ACT_LIST = `${BASE_URL}/api/acts`;
export const API_VICTIM_ACT_ADD = `${BASE_URL}/api/act`;
export const API_VICTIM_ACT_ASSAILANT = `${BASE_URL}/api/assailant`; 
export const API_VICTIM_ACT_ASSAILANT_LIST = `${BASE_URL}/api/assailants`; 
export const API_VICTIM_ACT_UPLOAD = `${BASE_URL}/api/act`;  
export const API_LINK_DRUG_TRADE = `${BASE_URL}/api/link_to_drug_trade`;

export const API_USER_LIST = `${BASE_URL}/api/users`;
export const API_USER_ADD= `${BASE_URL}/api/user`;
export const API_USER_EDIT= `${BASE_URL}/api/user`; 

export const API_CASE_STATUS_LIST= `${BASE_URL}/api/case_statuses`; 
export const API_CASE_STATUS_ADD= `${BASE_URL}/api/case_status`;

export const API_CASE_TYPE_LIST= `${BASE_URL}/api/case_types`; 
export const API_CASE_TYPE_ADD= `${BASE_URL}/api/case_type`;
export const API_LINK_TO_DRUG_TRADE = `${BASE_URL}/api/link_to_drug_trade`
export const API_DOCUMENTS= `${BASE_URL}/api/documents`;

export const API_DOWNLOAD_FILE = `${BASE_URL}/api/file?location`;