
import React, { Component } from 'react'
import { Icon, Button, Row, Col, Tabs  } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { userList } from '../../../../users/actionCreators';
import { 
          victimActGet,
          assaillantList, 
          actFileList, 
          actHistoryList, 
          downloadFile,
          victimActAssailantGet, 
          multipleDownloadFile } from '../../../../cases/actionCreator';
import Breadcrumbs from '../../../../../shared/components/breadcrumpsVictim';
import Files from './tabs_act/files';
import History from './tabs_act/history';

const TabPane = Tabs.TabPane;

 class viewForm extends Component {

  componentDidMount(){
    this.props.victimActGet();
    this.props.assaillantList();
    this.props.actFileList();
    this.props.actHistoryList();
    this.props.victimActAssailantGet();
    this.props.userList();
  }

  componentDidUpdate(props, states){
    if(props.act_files !== this.props.act_files){
      setTimeout(()=>{
        this.props.actFileList();
      }, 500)
    }
  }


  userPermission = () => { 
    const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;

      if (userRole === "viewer" || userRole==="contributor" ){
          return(
            <Button 
                  className="chr-font-light"
                  disabled={true}
                  type="primary"
                  style={{ float: 'right', margin: '20px 30px', height: '40px'}} 
                  icon="edit">
                  Edit
            </Button>
          ) 
      } else {
          return (
            <Link to={'/chr/victim/add/act/1'} >
            <Button 
              className="chr-font-light"
              onClick={()=>{  
                sessionStorage.setItem('path_add_act', this.props.history.location.pathname) ;
                sessionStorage.setItem('act_edit', true)}}
              type="primary" ghost
              style={{ float: 'right', margin: '20px 30px', height: '40px'}} 
              icon="edit">
                Edit
            </Button>
          </Link>
          )
      }
  }

  CaseDetails = () =>{
    const act = this.props.act;
    const assaillant = this.props.assailantList.data != undefined ? this.props.assailantList.data : [];
    const act_id = get(act, 'id')
    const data = assaillant.find(item => item.act_id == act_id);
    sessionStorage.setItem('assailant_id', get(data, 'id'))
    const chunk_1 = act.act_tags ? act.act_tags.slice(0, 12) : [] ;
    const chunk_2 = act.act_tags ? act.act_tags.slice(12) : [] ;

  return<div style={{width: '97%', height: '100%',  boxShadow: '1px 1px 7px #D1D1D1', borderRadius: '5px', margin: '40px 20px 0px 20px ', background: 'white'}}>
          <div style={{padding: "20px 10px"}}>
            <div>
              <Icon type='stop' className="icon-folder" theme="filled" style={{margin: '20px 6px 20px 20px', fontSize: '25px', fontWeight: "bold", color: "#22568f" }} />
              {this.userPermission()}

              <span style={{ fontSize: '20px', fontWeight: "bold", color: "#22568f"}}> Act Information </span>  
            
            </div>

            <div style={{margin: "20px 20px"}}>
            
              <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                Act Name:
                </Col>
              
                <Col span={5} className="row-details-name">
                { get(act, "act_name") }
                </Col>

                <Col span={5} className="row-label-name">
                  Police Operation:
                </Col>
              
                <Col span={5} className="row-details-name">
                {get(act, "police_operation") }
                </Col>
              </Row>

              <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                Account Name:
                </Col>
              
                <Col span={5} className="row-details-name">
                {get(act.account, "account_name") }
                </Col>

                <Col span={5} className="row-label-name">
                  Date of Act:
                </Col>
              
                <Col span={5} className="row-details-name">
                {get(act, "date_of_act") }
                </Col>
              </Row>

              <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                  Type of Act:
                </Col>
              
                <Col span={5} className="row-details-name">
                {get(act, "type_of_act") }
                </Col>
              
                <Col span={5} className="row-label-name">
                  Time of Act:
                </Col>
              
                <Col span={5} className="row-details-name">
                {get(act, "time_of_act") }
                </Col>
              </Row>

              <Row style={{paddingLeft: "10px"}}>
                <h3 className="chr-font-ss"style={{paddingTop: '30px'}}>Location</h3>
              </Row>

              <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                Region:
                </Col>
              
                <Col span={5} className="row-details-name">
                {get(act, "region") }
                </Col>

                <Col span={5} className="row-label-name">
                Municipality/City:
                </Col>
                
                <Col span={5} className="row-details-name">
                {get(act, "municipality") }
                </Col>
              </Row>

              <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                  Province:
                </Col>
              
                <Col span={5} className="row-details-name">
                {get(act, "province") }
                </Col>

                <Col span={5} className="row-label-name">
                Barangay:
                </Col>
              
                <Col span={5} className="row-details-name">
                {get(act, "barangay") }
                </Col>
              </Row>

              <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                Area/District:
                </Col>
              
                <Col span={5} className="row-details-name">
                {get(act, "district") }
                </Col>
              </Row>

            <Row style={{paddingLeft: "10px"}}>
              <h3 className="chr-font-ss"style={{paddingTop: '30px'}}>Assailant Information</h3>
            </Row>

              
            <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                Type of Assailant:
                </Col>
              
                <Col span={5} className="row-details-name">
                {
                  data !=undefined ? data.type_of_assailant : ''
                }
                </Col>
            </Row>
                  
            <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                Attire of Assailant:
                </Col>
              
                <Col span={5} className="row-details-name">
                {
                  data !=undefined ? data.attire_of_assailant : ''
                }
                </Col>
            </Row>
                  
            <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                  Weapon/s of Assailant:
                </Col>

                <Col span={12} className="row-details-name">
                  {
                    data !=undefined ? 
                      data.weapons != undefined ? data.weapons.map(e=>{
                      return <span key={e}>{e}</span>
                    }).reduce((prev, curr) => [prev, ', ', curr])
                    :
                    ""
                    :
                    ""
                  }
                </Col>
            </Row>
          
            <Row style={{padding: "10px"}}>
                <hr style={{borderTop: '2px dashed #EAEAEA', width: '60%', float: 'left'}} />
            </Row>

            <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                Circumtances:
                </Col>
                
                <Col span={12} className="row-details-name">
                {
                  data !=undefined ? data.circumstances : ''
                }
                </Col>
            </Row>
                  
            <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                Narrative:
                </Col>
              
                <Col span={12} className="row-details-name">
                {
                  data !=undefined ? data.narrative : ''
                }
                </Col>
            </Row>
                  
            <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                Basis:
                </Col>
              
                <Col span={12} className="row-details-name">
                {
                  data !=undefined ? data.basis : ''
                }
                </Col>
            </Row>

            <Row >
              <Col span={12} style={{margin: "20px 10px"}}>
                  { 
                    chunk_1.map(function(item){
                      return <span key={item} className="tags-for-card">{item}</span>
                    }) 
                  }
              </Col>

              <Col span={12} style={{margin: "10px 10px"}}>
                  { 
                    chunk_2.map(function(item){
                      return <span key={item} className="tags-for-card">{item}</span>
                    }) 
                  }
              </Col>
              
            </Row>
          </div>
        </div>  
      </div> 
  }

  TabsPanesList =()=>{
    const act_files = this.props.act_files;
    const act_history = this.props.act_history;
    const downloadFile = this.props.downloadFile;
    const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;

    if( userRole === "viewer" || userRole==="contributor" || userRole === "editor") {
      return (
        <div className="card-container" style={{margin: "10px 20px"}}>
           <Tabs type="card">
             <TabPane tab="Files" key="2">
               <Files multipleDownloadFile = {this.props.multipleDownloadFile } login={this.props.login} act_files ={act_files} downloadFile={downloadFile} history={this.props.history } />
             </TabPane>
         </Tabs>
     </div>
     ) 
    } else {
      return (
             <div className="card-container" style={{margin: "10px 20px"}}>
                <Tabs type="card">
                  <TabPane tab="Files" key="2">
                    <Files multipleDownloadFile = {this.props.multipleDownloadFile } login={this.props.login} act_files ={act_files} downloadFile={downloadFile} history={this.props.history } />
                  </TabPane>
                  <TabPane tab="History" key="3">
                    <History users={this.props.users} login={this.props.login} act_history ={act_history}/>
                  </TabPane>
              </Tabs>
          </div>
          ) 
    }
  }
   
  render() {

    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
            <div><Breadcrumbs history ={this.props.history} victim = {this.props.victim} match = {this.props.match}/></div>
            <div >{ this.CaseDetails() } </div>
            <div>{ this.TabsPanesList() }</div>
      </div>
    )
  }
}

 const mapStateToProps = (state) => {
    return {
      act: state.case.act,
      assailantList: state.case.assailant_list,
      act_files: state.case.act_files_list,
      act_history: state.case.act_history_list,
      assailant: state.case.assailantGet,
      users: state.user.users,
      login: state.login
    }
}

 const mapDispatchToProps = {
        victimActGet, 
        assaillantList, 
        actFileList, 
        actHistoryList, 
        downloadFile,
        victimActAssailantGet, 
        userList, 
        multipleDownloadFile
 }
  
export default connect(mapStateToProps, mapDispatchToProps)(viewForm);