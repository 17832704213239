import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, notification, message  } from 'antd';
import { connect } from 'react-redux';
import { victimActAddDocument, saveRoute } from '../../../../cases/actionCreator';
import FileUpload from '../../../../../shared/components/fileUploadCase';
// import SourceTabs from "../../../../../shared/components/addSourceTab";
// import IsActive from './../../../../../shared/components/status';

const CaseForm =  Form.create()(

  class extends Component {
    
    
    handleSubmit = (e) => {
      // e.preventDefault();
      let routeValue = e.routeValue;

      this.props.form.validateFields((err, values) => {

        if (!err) {
          this.props.onSave(values, routeValue) 
        }
      });
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      return (
        <Form  style={{margin: '20px 20px'}} >

         <Row className="form-case-add-row">
             <Col span={12}>
              <h1 style={{color: "#22568f", fontWeight: "bold" }}>Adding Act </h1>
             </Col> 

             <Col span={12}>
                 <Form.Item className="form-case-add">
                    <div>
                      <Button type="default"  onClick={()=>{ this.handleSubmit({routeValue : "act"}) }} >
                          Save and Add more Act
                      </Button>
                    
                      <Button style={{margin: '0px 20px'}} type="primary" onClick={()=>{ this.handleSubmit({routeValue : "null"}) }} >
                          Save
                      </Button>
                    </div>
                 </Form.Item>
             </Col>
         </Row>
         <Row>
           <Col>
  
                
      <div style={{margin: '50px 10px' }}>
      {/* {
        this.props.act_quick_upload == "true"  ? <div></div> : <SourceTabs history={this.props.history}/> 
      } */}

      <div style={{  display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
        {/* Form Start */}
              <Row  >
                <Col span={20}>
                  <Form.Item>
                    {getFieldDecorator('dev_notes', {
                      rules: [{ required: false }],
                      initialValue: { file_metadata: null , 1: null}
                    })(
                        <Input  hidden                      
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>          
            
              <Row  >
                <Col span={20}>
                  <Form.Item >
                    {getFieldDecorator('documents', {
                      rules: [{ required: false }],
                    })(
                      <FileUpload dataID={this.props.dataID} 
                      
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

            </div>
          </div>
        </Col>
      </Row>
    </Form>
      );
    }
  }
)

class VictimAdd extends Component {

  onCancel = () => {
    this.props.history.goBack();
  }

  onSave = (params, routeValue)=> {
    if(routeValue == "act" ){
      this.props.victimActAddDocument(params, this.routeCallback)
      this.props.history.push(`/chr/quick/add/mulitple/act`);
    } else {
      this.props.victimActAddDocument(params, this.callback)
    }
  }

  routeCallback = ()=>{ }

  callback = (response, error) => {
    
    if(!error) {
        notification.success({message: `Successfully Added` })
        this.props.history.push('/chr/case')
    } else {
      notification.success({message: `${error.message}` })
    }
  }

  render() {
    const history = this.props.history;
    const act_quick_upload = sessionStorage.getItem('act_quick_upload');
    return (
      
        <div className="contentDiv">
          <CaseForm
            onCancel = {this.onCancel}
            onSave = {this.onSave}
            backButton= {this.backButton}
            history = {history}
            act_quick_upload = {act_quick_upload}

          />
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = {
  victimActAddDocument, 
}

export default connect(mapStateToProps, mapDispatchToProps)(VictimAdd); 
