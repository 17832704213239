import React, { Component } from 'react'
import { Button, Row, Col, Switch, Tabs } from 'antd';
import UserLog from '../components/userLog';
// import UserRequest from '../components/userRequest';
import {connect} from 'react-redux';
import ViewerPrivileges from '../components/viewerPrivileges';
import ContributorPrivileges from '../components/contributorPrivileges';
import EditorPrivileges from '../components/editorPrivileges';
import AdminPrivileges from '../components/adminPrivileges';
import {userLogList, userGet} from '../actionCreators';
import IsActive from '../../../shared/components/status';
import { assign } from 'lodash';

const TabPane = Tabs.TabPane;

class UserView extends Component {
    
    state = {
        tabsDefault: "1"
    }
    
    componentDidMount(){
        this.props.userLogList();
        this.props.userGet();
    }

    UserPrivileges = () => { 
        const userRole = this.props.getUser.role === undefined ? false : this.props.getUser.role ;
    
        if (userRole === "admin" || userRole === "Admin"){
            return(
                <AdminPrivileges />
            ) 
        } else if(userRole === "editor") {
            return (
                <EditorPrivileges/>
            )
        } else if(userRole === "contributor") {
            return (
                <ContributorPrivileges />
            )
        } else {
            return(
                <ViewerPrivileges />
            )
        } 
    }

    IsActive = () => {
        const users = this.props.getUser.is_active === undefined ? false : this.props.getUser.is_active
        if (users === true){
            return(
                <IsActive value={true}/>
            ) 
        } else {
            return(
                <IsActive value={false}/>
            ) 
        }
    }
    
    showButton = () => {
        let user_id = sessionStorage.getItem('user_id');
        return(
            <div>
                <Button type="primary" ghost onClick={()=>{ this.props.history.push( {pathname: `/password/${user_id}`} ) }} style={{zIndex: 10, height: '40px'}}>
                    Change Password
                </Button>
                <Button onClick={()=>{ this.props.history.push( {pathname: `/chr/user/${user_id}` }) }}  className="chr-btn" type="primary" htmlType="submit" style={{zIndex: 10, margin: '0px 10px'}} >
                    Edit
                </Button>
                <Button className="chr-close-btn" type="default" onClick={()=>{ this.props.history.goBack(); }} style={{height: '40px', margin: '0px 10px'}} icon="cross"></Button>
            </div>
        )
        
    }

    UserDetails = () =>{
        let users = this.props.getUser === undefined ? [] : this.props.getUser;
        return(
            <div style={{padding: "20px 10px"}}>
            <div>
                <Row style={{padding: '20px 20px'}}>
                    <Col span={18}>
                        <h1 className="chr-font">{users.first_name}&nbsp;{users.last_name}</h1>
                    </Col>
                    <Col span={6}>
                        {this.showButton()}
                    </Col>
                </Row>

                <Row>
                <Col className="form-case-add-row"></Col>
                </Row>

                <div style={{margin: '70px 40px'}}>
                    <Row>
                        <Col span={2}>
                            <h1 className="chr-font-secondary">Status</h1>
                        </Col>
                        <Col span={6}>
                            {/* <Switch checked ={users.status === "Active" ? true : false}/> */}
                            {/* <Status isActive={this.props.IsActive.value} /> */}
                            {this.IsActive()}
                        </Col>
                        <Col span={1}></Col>
                        <Col span={3}>
                            <h1 className="chr-font-secondary">Gender</h1>
                        </Col>
                        <Col span={6}>
                            <h1 className="chr-font-black">{users.gender}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={2}>
                            <h1 className="chr-font-secondary">Email</h1>
                        </Col>
                        <Col span={6}>
                            <h1 className="chr-font-black">{users.email}</h1>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={3}>
                            <h1 className="chr-font-secondary">Position</h1>
                        </Col>
                        <Col span={6}>
                            <h1 className="chr-font-black">{users.position}</h1>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
        )
    }

    callback = (key) => {
        this.setState({ tabsDefault: key})
    }

    TabsPrivilege = () => {
        let myAccountRoles = this.props.history.location.search.slice(1);
        let userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;

        if(userRole === "viewer" || userRole === "contributor" || userRole === "editor") {
            return(
            <div>
                <Tabs type="card" activeKey={this.state.tabsDefault} onChange={this.callback}>
                    <TabPane tab="User Role" key="1">
                        {this.UserPrivileges()}
                    </TabPane>
                </Tabs>
            </div>
            )    
        } else {
            if(myAccountRoles == "account"){
                return (
                    <div>
                    
                    <Tabs type="card" activeKey={this.state.tabsDefault} onChange={this.callback}>
                        <TabPane tab="User Role" key="1">
                            {this.UserPrivileges()}
                        </TabPane>
                    </Tabs>
                </div>
                )
            } else {
            return (
                <div>
                
                <Tabs type="card" activeKey={this.state.tabsDefault} onChange={this.callback}>
                    <TabPane tab="User Role" key="1">
                        {this.UserPrivileges()}
                    </TabPane>
                    <TabPane tab="User Logs" key="2">
                        <UserLog data = {this.props.historyList} filterSorters={this.filterSorters}/>
                    </TabPane>
                    {/* { users.role === "Admin" &&
                    <TabPane tab="User Requests" key="3">
                        <UserRequest />
                    </TabPane>
                    } */}
                </Tabs>
            </div>
            )
        }
        }
    }
    
    filterSorters = (params) =>{
        const data = assign({ sort_dir: params.order, sort_key: params.columnKey})
        this.props.userLogList(data)
    }

    TabsPanesList =()=>{
        return (
           <div className="card-container" style={{margin: "10px 20px"}}>
                  { this.TabsPrivilege() }
           </div>
        )
      }

    render() {
        let myAccountRoles = this.props.history.location.search.slice(1);
        return (
            <div style={{display: 'flex', flexDirection: 'column' }}> 
                <div>{this.UserDetails()}</div>
                <div>{this.TabsPanesList()}</div>      
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return {
        login: state.login,
        historyList: state.user.history, 
        getUser: state.user.getUser
    }
}

const mapDispatchToProps = {
    userLogList, userGet
}

export default connect(mapStateToProps, mapDispatchToProps)(UserView);