import {CASE} from './actionCreator';


const INITIAL_STATE = {

    loading: false,
    error: '',
    case: {},
    document: {},
    history: {},
    files: {},
    victim: {},
    victimAll: {},
    table_victim: {},
    ListVictim: {},
    victimDetails: {},
    caseGet: {},
    victimAccount: {},
    source: {}, 
    account: {},
    assailant: {},
    assailantGet: {},
    acts: {},
    act: {},
    actGet: {},
    status: {},
    type: {},
    assailant_list: {},
    account_source: {},
    documents: {},
    act_files_list: {},
    act_history_list: {},
    account_files_list: {},
    account_history_list: {},
    victim_files_list: {},
    victim_history_list: {},
    accountGet: {},
    links_drug_list: {},
    victimActAccount: {},
    routes: {},
    victimListMainFiles: {},
    listVictimModule: {},
    actType: {},
    assaillantType: {},
    accountSourceType: {},
    accountListAll: {},
    emailList: {},
    docVersionList: {}
};


 const caseReducer = (state = INITIAL_STATE, action)=> {
    switch (action.type) {
        
        case CASE.START:
            return {...state, loading: true, error: ''};
        case CASE.SUCCESS:
            return {...state, loading: false, case: action.payload};
        case CASE.FAILED:
            return {...state, loading: false, error: action.payload};

        case CASE.LIST_START:
            return{...state, loading: true, error:''};
        case CASE.LIST_SUCCESS:
            return{...state, loading: false, case: action.payload};
        case CASE.LIST_FAILED:
            return{...state, laoding: false, error: action.payload};

        case CASE.DOCUMENT_START:
            return{...state, loading: true, error:''};
        case CASE.DOCUMENT_SUCCESS:
            return{...state, loading: false, document: action.payload};
        case CASE.DOCUMENT_FAILED:
            return{...state, laoding: false, error: action.payload};

        case CASE.HISTORY_START: 
            return {...state, loading: true, error: '' }
        case CASE.HISTORY_SUCCESS:
            return {...state, loading: false, history: action.payload}
        case CASE.HISTORY_FAILED:
            return {...state, laoding: false, error: action.payload } 
        
        case CASE.FILES_START: 
            return {...state, loading: true, error: '' }
        case CASE.FILES_SUCCESS:
            return {...state, loading: false, files: action.payload}
        case CASE.FILES_FAILED:
            return {...state, laoding: false, error: action.payload } 

        case CASE.VICTIM_ADD_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_ADD_SUCCESS:  
            return {...state, loading: false, victim: action.payload} 
        case CASE.VICTIM_ADD_FAILED:
            return {...state, loading: false, error: action.payload}

        case CASE.VICTIM_LIST_START:
            return {...state, loading: true, error: ''}    
        case CASE.VICTIM_LIST_SUCCESS:  
            return {...state, loading: false, ListVictim: action.payload, } 
        case CASE.VICTIM_LIST_FAILED:
            return {...state, loading: false, error: action.payload}

        case CASE.VICTIM_DETAILS_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_DETAILS_SUCCESS:  
            return {...state, loading: false, victimDetails: action.payload, } 
        case CASE.VICTIM_DETAILS_FAILED:
            return {...state, loading: false, error: action.payload}

        case CASE.CASE_GET_START:
            return {...state, loading: true, error: ''}   
        case CASE.CASE_GET_SUCCESS:  
            return {...state, loading: false, caseGet: action.payload, } 
        case CASE.CASE_GET_FAILED:
            return {...state, loading: false, error: action.payload}

        case CASE.VICTIM_ACCOUNT_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_ACCOUNT_SUCCESS:  
            return {...state, loading: false, victimAccount: action.payload, } 
        case CASE.VICTIM_ACCOUNT_FAILED:
            return {...state, loading: false, error: action.payload}
     
        case CASE.VICTIM_LIST_ALL_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_LIST_ALL_SUCCESS:  
            return {...state, loading: false, victimAll: action.payload, } 
        case CASE.VICTIM_LIST_ALL_FAILED:
        return {...state, loading: false, error: action.payload}
      
        case CASE.VICTIM_SOURCE_ACCOUNT_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_SOURCE_ACCOUNT_SUCCESS:  
            return {...state, loading: false, source: action.payload, } 
        case CASE.VICTIM_SOURCE_ACCOUNT_FAILED:
            return {...state, loading: false, error: action.payload}

        case CASE.VICTIM_SOURCE_ACCOUNT_DETAILS_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_SOURCE_ACCOUNT_DETAILS_SUCCESS:  
            return {...state, loading: false, account: action.payload, } 
        case CASE.VICTIM_SOURCE_ACCOUNT_DETAILS_FAILED:
            return {...state, loading: false, error: action.payload}

        case CASE.VICTIM_ACT_LIST_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_ACT_LIST_SUCCESS:  
            return {...state, loading: false, acts: action.payload, } 
        case CASE.VICTIM_ACT_LIST_FAILED:
            return {...state, loading: false, error: action.payload}

        case CASE.VICTIM_ACT_ADD_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_ACT_ADD_SUCCESS:  
            return {...state, loading: false, act: action.payload, } 
        case CASE.VICTIM_ACT_ADD_FAILED:
            return {...state, loading: false, error: action.payload}
            
        case CASE.VICTIM_ASSAILANT_ADD_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_ASSAILANT_ADD_SUCCESS:  
            return {...state, loading: false, assailant: action.payload, } 
        case CASE.VICTIM_ASSAILANT_ADD_FAILED:
            return {...state, loading: false, error: action.payload}
                      
        case CASE.VICTIM_ACT_GET_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_ACT_GET_SUCCESS:  
            return {...state, loading: false, act: action.payload } 
        case CASE.VICTIM_ACT_GET_FAILED:
            return {...state, loading: false, error: action.payload}
        
        case CASE.ACCOUNT_GET_DOCUMENTS_START:
            return {...state, loading: true, error: ''}   
        case CASE.ACCOUNT_GET_DOCUMENTS_SUCCESS:  
            return {...state, loading: false, actGet: action.payload } 
        case CASE.ACCOUNT_GET_DOCUMENTS_FAILED:
            return {...state, loading: false, error: action.payload}  

        case CASE.STATUS_START:
            return {...state, loading: true, error: ''}   
        case CASE.STATUS_SUCCESS:  
            return {...state, loading: false, status: action.payload } 
        case CASE.STATUS_FAILED:
            return {...state, loading: false, error: action.payload}  
        
        case CASE.TYPE_START:
            return {...state, loading: true, error: ''}   
        case CASE.TYPE_SUCCESS:  
            return {...state, loading: false, type: action.payload } 
        case CASE.TYPE_FAILED:
            return {...state, loading: false, error: action.payload} 
        
        case CASE.LIST_OF_VICTIM_TABLE_START:
            return {...state, loading: true, error: ''}   
        case CASE.LIST_OF_VICTIM_TABLE_SUCCESS:  

            const { data, id } = action.payload;
            const caseData = state.case;
            const specificCase = caseData.data.find(cas => cas.id === id);
            specificCase.victims = data;
            return {...state, loading: false, case: caseData } 

        case CASE.LIST_OF_VICTIM_TABLE_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.VICTIM_ASSAILANT_LIST_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_ASSAILANT_LIST_SUCCESS:  
            return {...state, loading: false, assailant_list: action.payload } 
        case CASE.VICTIM_ASSAILANT_LIST_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.VICTIM_ACCOUNT_SOURCE_LIST_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_ACCOUNT_SOURCE_LIST_SUCCESS:  
            return {...state, loading: false, account_source: action.payload } 
        case CASE.VICTIM_ACCOUNT_SOURCE_LIST_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.LIST_OF_DOCUMENTS_ALL_START:
            return {...state, loading: true, error: ''}   
        case CASE.LIST_OF_DOCUMENTS_ALL_SUCCESS:  
            return {...state, loading: false, documents: action.payload } 
        case CASE.LIST_OF_DOCUMENTS_ALL_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.LIST_OF_FILES_ACT_START:
            return {...state, loading: true, error: ''}   
        case CASE.LIST_OF_FILES_ACT_SUCCESS:  
            return {...state, loading: false, act_files_list: action.payload } 
        case CASE.LIST_OF_FILES_ACT_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.LIST_OF_HISTORY_ACT_START:
            return {...state, loading: true, error: ''}   
        case CASE.LIST_OF_HISTORY_ACT_SUCCESS:  
            return {...state, loading: false, act_history_list: action.payload } 
        case CASE.LIST_OF_HISTORY_ACT_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.LIST_OF_FILES_ACCOUNT_START:
            return {...state, loading: true, error: ''}   
        case CASE.LIST_OF_FILES_ACCOUNT_SUCCESS:  
            return {...state, loading: false, account_files_list: action.payload } 
        case CASE.LIST_OF_FILES_ACCOUNT_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.LIST_OF_HISTORY_ACCOUNT_START:
            return {...state, loading: true, error: ''}   
        case CASE.LIST_OF_HISTORY_ACCOUNT_SUCCESS:  
            return {...state, loading: false, account_history_list: action.payload } 
        case CASE.LIST_OF_HISTORY_ACCOUNT_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.LIST_OF_VICTIM_FILES_LIST_START:
            return {...state, loading: true, error: ''}   
        case CASE.LIST_OF_VICTIM_FILES_LIST_SUCCESS:  
            return {...state, loading: false, victim_files_list: action.payload } 
        case CASE.LIST_OF_VICTIM_FILES_LIST_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.LIST_OF_VICTIM_HISTORY_LIST_START:
            return {...state, loading: true, error: ''}   
        case CASE.LIST_OF_VICTIM_HISTORY_LIST_SUCCESS:  
            return {...state, loading: false, victim_history_list: action.payload } 
        case CASE.LIST_OF_VICTIM_HISTORY_LIST_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.ASSAILANT_GET_START:
            return {...state, loading: true, error: ''}   
        case CASE.ASSAILANT_GET_SUCCESS:  
            return {...state, loading: false, assailantGet: action.payload } 
        case CASE.ASSAILANT_GET_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.ACCOUNT_GET_START:
            return {...state, loading: true, error: ''}   
        case CASE.ACCOUNT_GET_SUCCESS:  
            return {...state, loading: false, accountGet: action.payload } 
        case CASE.ACCOUNT_GET_FAILED:
            return {...state, loading: false, error: action.payload} 
        
        case CASE.LIST_LINK_TO_DRUG_TRADE_START:
            return {...state, loading: true, error: ''}   
        case CASE.LIST_LINK_TO_DRUG_TRADE_SUCCESS:  
            return {...state, loading: false, links_drug_list: action.payload } 
        case CASE.LIST_LINK_TO_DRUG_TRADE_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.VICTIM_ACT_ACCOUNT_LIST_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_ACT_ACCOUNT_LIST_SUCCESS:  
            return {...state, loading: false, victimActAccount: action.payload } 
        case CASE.VICTIM_ACT_ACCOUNT_LIST_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.VICTIM_LIST_MAIN_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_LIST_MAIN_SUCCESS:  
            return {...state, loading: false, victimListMainFiles: action.payload } 
        case CASE.VICTIM_LIST_MAIN_FAILED:
            return {...state, loading: false, error: action.payload} 
            
        case CASE.NEW_LIST_FOR_FILES_VICTIM_MODULE_START:
            return {...state, loading: true, error: ''}   
        case CASE.NEW_LIST_FOR_FILES_VICTIM_MODULE_SUCCESS:  
            return {...state, loading: false, listVictimModule: action.payload } 
        case CASE.NEW_LIST_FOR_FILES_VICTIM_MODULE_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.ACT_TYPE_LIST_START:
            return {...state, loading: true, error: ''}   
        case CASE.ACT_TYPE_LIST_SUCCESS:  
            return {...state, loading: false, actType: action.payload } 
        case CASE.ACT_TYPE_LIST_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.ASSAILLANT_TYPE_LIST_START:
            return {...state, loading: true, error: ''}   
        case CASE.ASSAILLANT_TYPE_LIST_SUCCESS:  
            return {...state, loading: false, assaillantType: action.payload } 
        case CASE.ASSAILLANT_TYPE_LIST_FAILED:
            return {...state, loading: false, error: action.payload} 

        case CASE.ACCOUNT_SOURCE_TYPE_LIST_START:
            return {...state, loading: true, error: ''}   
        case CASE.ACCOUNT_SOURCE_TYPE_LIST_SUCCESS:  
            return {...state, loading: false, accountSourceType: action.payload } 
        case CASE.ACCOUNT_SOURCE_TYPE_LIST_FAILED:
            return {...state, loading: false, error: action.payload} 
     
        case CASE.VICTIM_ACCOUNT_ALL_START:
            return {...state, loading: true, error: ''}   
        case CASE.VICTIM_ACCOUNT_ALL_SUCCESS:  
            return {...state, loading: false, accountListAll: action.payload } 
        case CASE.VICTIM_ACCOUNT_ALL_FAILED:
            return {...state, loading: false, error: action.payload} 
        
        case CASE.EMAIL_CONFIGURATION_START:
            return {...state, loading: true, error: ''}   
        case CASE.EMAIL_CONFIGURATION_SUCCESS:  
            return {...state, loading: false, emailList: action.payload } 
        case CASE.EMAIL_CONFIGURATION_FAILED:
            return {...state, loading: false, error: action.payload} 
       
        case CASE.DOCUMENT_VERSION_LIST_START:
            return {...state, loading: true, error: ''}   
        case CASE.DOCUMENT_VERSION_LIST_SUCCESS:  
            return {...state, loading: false, docVersionList: action.payload } 
        case CASE.DOCUMENT_VERSION_LIST_FAILED:
            return {...state, loading: false, error: action.payload} 

        default:
            return state;
    }
}


export default caseReducer;




