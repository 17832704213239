import React, { Component } from 'react'
import {Button} from 'antd';

class Tag extends Component {
    constructor(props){
        super(props);
        
        this.state ={
            showList: false,
            indexShow: null
        }
    }
    
    render() {
        return (
            <div>
                <div> { this.props.arr1 > 2 ? 
                    <div> { this.state.showList == false && this.state.indexShow == this.props.id ? 
                            this.props.arr2.map( (item, index) => { 
                                // console.log('item', item+"_"+index+"_first")
                                return (
                                    <center>
                                        <div className="tags-for-table" key={item+"_"+index+"_first"+item+index}>
                                         {item}
                                        </div>
                                    </center>
                                )}) 
                                : 
                            <div> { this.props.arr3.slice(0, 2).map( (e, index) => {
                                // console.log('e', e+"_"+index+"_second")
                                    return (
                                        <center> 
                                            <div className="tags-for-table" key={e+"_"+index+"_second"+e }>
                                                {e}
                                            </div> 
                                        </center>) }) 
                                    } 
                            <Button  
                                id="show-hide-tags" 
                                icon="small-dash" 
                                onClick = {e=>{e.stopPropagation(); this.setState({ showList: false, indexShow: this.props.id}) }} /> 
                            </div> 
                        } 
                        { 
                            this.state.showList == false && this.state.indexShow == this.props.id ? 
                            <Button  
                                id="show-hide-tags" 
                                icon="small-dash" 
                                onClick = { e => { e.stopPropagation(); this.setState({ showList: true, indexShow: this.props.id}); }} /> 
                            : 
                            <span></span> 
                        } 
                    </div> 
                            : this.props.arr2.map((item, index)=> { 
                                // console.log('item',  item+"_"+index+"_first")
                            return ( 
                                <center>
                                    <div className="tags-for-table" key={ item+"_"+index+"_last"+item+"_"+index}>
                                      {item}
                                    </div>
                                </center>
                            ) 
                        }) } 
                </div>
            </div>
        )
    }
}

export default Tag;