import React, { Component } from 'react'
import { Table, Icon, Checkbox } from 'antd';
import Tags from '../../../shared/components/tags';
import IconType from '../../../shared/components/icon_file_type';
import {get} from 'lodash';

 class case_table extends Component {
   constructor(props){
    super(props);

 
    this.columns = [
      {
          title: "File Name",
          dataIndex: "file_name",
          width: '300px',
          render: (record, text, index)=>{
            const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
              if( userRole === 'viewer' || userRole === "contributor"){
                return (
                    <div>
                            <IconType file_type={text.file_type} record = {record}/>  
                    </div>
                  )
              } else {
                return (
                    <div>
                        <a onClick={()=>{ this.donwload(text.file_location, text.file_type ) }}> 
                            <IconType file_type={text.file_type} record = {record}/>  
                        </a>
                    </div>
                  )
              }
            }
      }, {
          title: "File Type",
          dataIndex: "file_type",
          render: (record)=>{
              if (record === 'txt') {
                  return <span>Text</span>
              } else if (record === 'xls' || record === 'xlsx') {
                  return <span>Excel</span>
              } else if (record === "pdf") {
                  return <span>Pdf</span>
              } else if (record === "doc" || record === "docx") {
                  return <span>Document</span>
              } else if (record === "jpg" || record === "png" ) {
                  return <span>Image</span>
              }else {
                  return <span>Other Document</span>
              }
          }
      }, {
          title: "Path",
          render: (record)=>{
            if (record.case_id >= 1) {
                return <span>Case</span>
            } else if (record.victim_id >= 1) {
                return <span>Victim</span>
            } else if (record.act_id >= 1) {
                return <span>Act</span>
            } else if (record.account_id >= 1) {
                return <span>Account</span>
            }
        }
      }, {
          title: "Reference",
          dataIndex: "reference"
      }, {
          title: "File Tags",
          dataIndex: "document_tags",
          render: (record, text, index)=>{
              const arr2 = text.document_tags;
              const arr1 = arr2.length;
              const arr3 = arr2.slice(0, 2);
              const id = text.id;
          return (
                  <Tags 
                      arr2={arr2} 
                      arr1={arr1} 
                      arr3={arr3} 
                      id={id} 
                  />
          )
      }
      }, {
          title: "Date Filed",
          dataIndex: "created_at"
      }
    //   }, {
    //       title: "",
    //       dataIndex: "action",
    //       render: ()=>{
    //           return <Checkbox />
    //       }
    //   }
  ]
  
  }

  render() {
    return (
      <div>
        <Table 
          style={{marginTop: '30px'}}
          columns ={this.columns}
          dataSource={this.props.data.data}
          onRowClick = { ()=>{ this.props.history.push('/chr/document'); }}
        />
      </div>
    )
  }
}
 
export default case_table;