import React, { Component } from 'react'
import { Table, Icon, Button, Checkbox} from 'antd';
import { Link } from 'react-router-dom';
import Tags from '../../../../shared/components/tags';
import { connect } from 'react-redux';
import { 
        downloadFile,
        victimFileListMain,
        multipleDownloadFile,
        DocumentArchived } from '../../../cases/actionCreator';
import {get} from 'lodash';
import IconType from '../../../../shared/components/icon_file_type';


class FilesView extends Component {
    constructor(props){
        super(props);

        this.columns = [
            {
                title: "File Name",
                dataIndex: "file_name",
                width: '300px',
                sorter: true,
                render: (record, text, index)=>{
            
                    return <div>
                            {/* <a onClick={()=>{ this.donwload(text.file_location, text.file_type ) }}>  */}
                            <IconType file_type={text.file_type} record = {record}/>  
                            {/* </a> */}
                           </div>
                    }
            }, {
                title: "File Type",
                dataIndex: "file_type",
                filters : [{'value': '', 'text': ''}],
                // sorter: true,
                render: (record)=>{
                    if (record === 'txt') {
                        return <span>Text</span>
                    } else if (record === 'xls' || record === 'xlsx') {
                        return <span>Excel</span>
                    } else if (record === "pdf") {
                        return <span>Pdf</span>
                    } else if (record === "doc" || record === "docx") {
                        return <span>Document</span>
                    } else if (record === "jpg" || record === "png" ) {
                        return <span>Image</span>
                    }else {
                        return <span>Other Document</span>
                    }
                }
            }, {
                title: "Path",
                filters : [{'value': '', 'text': ''}],
                dataIndex: "3",
                // sorter: true,
                render: (record, text, index)=>{
                    const data =  this.props.victim_files_list.data[index];
                    const case_id = get(data, 'case_id');
                    const vicitm_id = get(data, 'victim_id');
                    const act = get(data, 'act_id');
                    const account = get(data, 'account_id');

                    if(case_id != null){
                        return <div>Case</div>
                    }else if(vicitm_id != null ){
                        return <div>Vicitm</div>
                    }else if( act != null){
                        return <div>Act</div>
                    }else if (account != null){
                        return <div>Account</div>
                    }else {
                        return <div>undefined</div>
                    }

                }
            }, {
                title: "Reference",
                dataIndex: "reference",
                sorter: true,
            }, {
                title: "File Tags",
                filters : [{'value': '', 'text': ''}],
                dataIndex: "document_tags",
                render: (record, text, index)=>{
                    const arr2 = text.document_tags;
                    const arr1 = arr2.length;
                    const arr3 = arr2.slice(0, 2);
                    const id = text.id;
                return (
                        <Tags 
                            arr2={arr2} 
                            arr1={arr1} 
                            arr3={arr3} 
                            id={id} 
                        />
                )
            }
            }, {
                title: "Date Filed",
                dataIndex: "created_at",
                sorter: true,
            }, {
                title: "",
                dataIndex: "action",
                render: (record, text, index)=>{
                    return (
                    <Checkbox 
                        onChange={(e)=>{ 
                            let status = e.target.checked;
                            this.donwload( text.file_location, text.file_type, text.file_name, text.id, status, index) }}
                        />)
                }
            }
        ];

        this.state = {
            data: []
        } 
    }

        
    DocumentArchived = () => {
        let dataFiles = this.state.data; 

        for (let a = 0; a < dataFiles.length; a++) {
           setTimeout(()=>{
            this.props.DocumentArchived(dataFiles[a].id)
           }, 1000)
        }
    }

    componentDidMount(){
        if(this.props.victim){
            var id = this.props.victim.id;
            this.props.victimFileListMain(id);
        }
    }

    componentDidUpdate(props, states){
        if(this.props.victim_files_list !== props.victim_files_list){
            setTimeout(()=>{
              this.props.victimFileListMain();
            }, 500)
          }
    }

    donwload = ( location, type, name, id, status, index)=>{
        let data = this.state.data;

        if (status === false) {
            data.splice(index, 1);
            this.setState({ data: data })
        } else {
            data.push({location, type, name, id})
        }
    }
 
    onClickDownload = () => {
        let dataFiles = this.state.data;

        for (let a = 0; a < dataFiles.length; a++) {
            this.props.multipleDownloadFile(dataFiles[a])
        }
    }

    headerFiles = () =>{
        return <div>
                    <div>
                        <a style={{color: "#22568f"}}>
                            <Icon type="link" style={{margin: "45px 0px 10px 0px", fontSize: "25px" }} />
                            &nbsp;
                            <span className="chr-font-sm">Attach Files</span>
                        </a>

                        { this.userPermission() }

                    </div>
                </div>
    }

    userPermission = () => { 
        const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
        const victim_id = sessionStorage.getItem('victim_id');
          if (userRole === "viewer"){
              return(
                    <div style={{ textAlign: 'right'}}>

                        <Button 
                            className="chr-font-light"
                            style={{ float: 'right', height: '40px'}} 
                            disabled={true}
                            type="primary" 
                            >
                            + Add Files
                        </Button> 

                        <a disabled={true}  style={{zIndex: 10, float: "right", fontSize: '23px' , margin: "0px 20px"}}>
                            <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
                        </a>    

                        <a disabled={true} >
                            <Icon type="download" style={{margin: "2px 10px",  float: 'right', color: '#C8C8C8', fontSize: '30px'}}/>
                        </a>
                    
                    </div>
              ) 
          }  else if (userRole === "contributor") {  
            return(
                <div style={{ textAlign: 'right'}}>

<                   Link to={`/chr/case/add/victim/upload/${victim_id}`}>
                        <Button 
                            className="add-case" 
                            style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                            onClick={()=>{ sessionStorage.setItem('add_victim_path', this.props.history.location.pathname) }} 
                            style={{zIndex: 100 , margin: "0px 0px", float: 'right' }} 
                            type="primary"
                            
                            >
                                + Add Files
                        </Button>
                    </Link>

                    <a disabled={true}  style={{zIndex: 10, float: "right", fontSize: '23px' , margin: "0px 20px"}}>
                        <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
                    </a>

                    <a disabled={true} >
                        <Icon type="download" style={{margin: "2px 10px",  float: 'right', color: '#C8C8C8', fontSize: '30px'}}/>
                    </a>
                
                </div>
          ) 
          } else {
              return (
                <div style={{ textAlign: 'right'}}>
                   
                   <Link to={`/chr/case/add/victim/upload/${victim_id}`}>
                            <Button 
                                className="chr-font-light"
                                style={{zIndex: 10, margin: '0px 10px', float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                                onClick={()=>{ sessionStorage.setItem('add_victim_path', this.props.history.location.pathname) }} 
                                type="primary">
                                    + Add Files
                            </Button>
                    </Link>

                    <a onClick={()=>{ this.onClickDownload()}} style={{ fontSize: '23px' , marginRight: "20px"}}>
                        <Icon type="download"  style={{color: '#77B9FF', fontSize: '30px'}}/>
                    </a>
                   
                    <a onClick={()=>{ this.DocumentArchived()}}>
                        <Icon type="delete" theme="filled"  style={{margin: "2px 10px",   color: '#77B9FF', fontSize: '30px'}}/>
                    </a>

                   
                </div>
              )
          }
      }

    render() {  
        const data = this.props.victim_files_list.data;
        return (
            <div style={{margin: '0px 20px'}}>
                <div style={{ fontSize: "20px", fontWeight: "bold" }}> 
                    { this.headerFiles() }
                </div>
                <Table
                    columns={this.columns}
                    dataSource={data}
                    rowKey="id"
                />
                
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        victim_files_list: state.case.victimListMainFiles,
        login: state.login
  }
}
  
const mapDispatchToProps = {
    downloadFile, 
    victimFileListMain, 
    multipleDownloadFile,
    DocumentArchived  
}
    
export default connect(mapStateToProps, mapDispatchToProps)(FilesView);
