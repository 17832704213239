import {LIST} from './actionCreators';

const initialValue = {
    loading: false,
    error: '',
    cases: {},
    victim: {},
    act: {},
    account: {},
    documents: {}
}

 const reducer = (state= initialValue, action)=>{
    switch (action.type) {
       
        case LIST.CASE_START:
            return {...state, loading: false, error: ''}
        case LIST.CASE_SUCCESS:
            return {...state, loading: false, cases: action.payload}
        case LIST.CASE_FAILED:
            return {...state, loading: false, error: ''}

        case LIST.VICTIMS_START:
            return {...state, loading: false, error: ''}
        case LIST.VICTIMS_SUCCESS:
            return {...state, loading: false, victim: action.payload}
        case LIST.VICTIMS_FAILED:
            return {...state, loading: false, error: ''}

        case LIST.ACT_START:
            return {...state, loading: false, error: ''}
        case LIST.ACT_SUCCESS:
            return {...state, loading: false, act: action.payload}
        case LIST.ACT_FAILED:
            return {...state, loading: false, error: ''}

        case LIST.ACCOUNT_START:
            return {...state, loading: false, error: ''}
        case LIST.ACCOUNT_SUCCESS:
            return {...state, loading: false, account: action.payload}
        case LIST.ACCOUNT_FAILED:
            return {...state, loading: false, error: ''}
        
        case LIST.DOCUMENTS_START:
            return {...state, loading: false, error: ''}
        case LIST.DOCUMENTS_SUCCESS:
            return {...state, loading: false, documents: action.payload}
        case LIST.DOCUMENTS_FAILED:
            return {...state, loading: false, error: ''}
    
    
        default:
           return state;
    }
}


export default reducer;

