import React, { Component } from 'react';
import { Button , Select, Icon} from 'antd';
import { connect } from 'react-redux';
import { victimList, caseList } from '../../../actionCreator';
import SearchVictim from './selectSearch';

class AddVictim extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: [],
            value: null,
            case_id: null
        }
    }

    componentDidMount(){
        this.props.victimList();
        this.props.caseList();
        this.onChangeCase();
    }

    callback = (response)=>{
        this.setState({ data: response.data.data })
    }
    
    handleSearch = ( value, )=>{
        this.props.victimList(value, this.callback)
    }

    onChangeCase = (values)=>{
      this.setState({ case_id: values})
      sessionStorage.setItem('case_id_victim', values)
    }

    render() {
        return (
            <div>
                
                <div>   
                    <Button 
                        className="chr-close-btn" 
                        type="link" 
                        icon="cross" 
                        style={{float: "right", margin: '-40px 20px', fontSize: '30px', opacity: '0.5'}}
                        onClick={()=>{this.props.history.goBack(); }}
                    />
                </div>
                
                <div style={{fontSize: "20px", color: "#22568f", fontWeight: "bold", margin: "40px 60px "}}> 
                    { 
                        sessionStorage.getItem('victim_module_add_path') === '/chr/victim' &&
                        <div>
                            <p style={{fontWeight: "bold"}}>Where do you want to add victim?</p> 
                                <Select placeholder="Select Case" style={{width: "600px", margin : "0px 0px 50px 30px"}} onChange={this.onChangeCase} >
                                    {
                                    this.props.case.data !=undefined ? this.props.case.data.map((e, index)=>{
                                            return <Select.Option key={e.id} value={e.id}> { e.case_identifier} </Select.Option>
                                        }) : ""
                                    }
                                </Select>
                        </div>
                    }

                    <p style={{fontWeight: "bold"}}>Search for similar victim..</p> 
                    <Icon type="search" /> Victim Name 
                </div>
                
                <div>
                    <SearchVictim  history = {this.props.history} case_id={this.state.case_id} value={this.state.value}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return {
        victimList: state.case.ListVictim,
        case: state.case.case
    }
}

const mapDispatchToProps = {
    victimList, caseList
}

export default connect(mapStateToProps, mapDispatchToProps)(AddVictim);