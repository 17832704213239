import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, Select, notification } from 'antd';
import { connect } from 'react-redux';
import { caseAdd, caseTypeList, caseStatusList, caseGet, caseEdit } from '../../../actionCreator';
import CaseTabs from "../../../../../shared/components/addCaseTab";
const Option = Select.Option;

const CaseForm =  Form.create()(

  class extends Component {

    state = {
      edit: sessionStorage.getItem('case_edit')
    }

    handleSubmit = (e) => {
      e.preventDefault();
      const edit = this.state.edit;
      
      this.props.form.validateFields((err, values) => {
        if (!err) {
          edit == "false" ? this.props.onSave(values) : this.props.onEdit(values)
        } 
      });
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      const edit = this.state.edit;
      const caseDetails = this.props.case;
      const caseType = !this.props.type ? [] : this.props.type;
      const caseStatus = !this.props.status ? [] : this.props.status;
      const caseListType = [];
      const caseListStatus = [];

      const listType = caseType.forEach(item=>{
        if(item.is_case_type_active === 1 ){
          caseListType.push(item);
        }
      });

      const listStatus = caseStatus.forEach(item=>{
        if(item.is_case_status_active === 1 ){
          caseListStatus.push(item);
        }
      });

      return (
       
       <Form onSubmit={this.handleSubmit}>
        <div>
          <Row style={{padding: '20px 20px 0px 20px'}}>
              <Col span={14}>
              <h1 className="chr-font">{ edit == "true" ? "Modify Case Information" : "Adding Case Information"}</h1>
              </Col>

              <Col span={10}> 
                  <Form.Item className="form-case-add">
                      <Button className="chr-btn" type="primary" htmlType="submit" style={{margin: '0px 10px'}} >
                          { edit == "true" ? "Save" : "Continue"}
                      </Button>
                      <Button className="chr-close-btn" icon="cross" title="close" onClick={this.props.onCancel}/>
                  </Form.Item>
              </Col>
          </Row>
         <Row>
          <Col className="form-case-add-row"></Col>
         </Row>

    <div style={{margin: '50px 10px', }}>

      { edit == "false" && <CaseTabs history = {this.props.history} /> }

      <div style={{  display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>

        <Row  >
          <Col span={20}>
            <Form.Item label="Case Name">
              {getFieldDecorator('case_identifier', {
                rules: [{ required: true, message: 'Please input your fields' }],
                initialValue: edit == "true" ? caseDetails ?caseDetails.case_identifier : "" : "",
              })(
                <Input placeholder="Case Identifier " style={{width: '700px',}}/>
              )}
            </Form.Item>
          </Col>
        </Row>

          <Row>
            <Col span={20}>
              <Form.Item label="Case Type">
                {getFieldDecorator('case_type', {
                  rules: [{ required: true, message: 'Please input your fields' }],
                  initialValue: edit == "true" ? caseDetails ? caseDetails.case_type : "" : <span style={{color: '#9AA0A5'}}>Case Type</span> ,
                })(

                  <Select mode="default" style={{width: '700px',}}>
                      {
                        caseListType.map((e)=>{
                          return <Option key={e.id} value={e.case_type}>{e.case_type}</Option>
                        })
                      }
                  </Select>

                )}
              </Form.Item>
            </Col>
        </Row>

        <Row>
            <Col span={20}>
              <Form.Item label="Case Status" style={{width: '700px',}}>
                {getFieldDecorator('case_status', {
                  rules: [{ required: true, message: 'Please input your fields' }],
                  initialValue:  edit == "true" ? caseDetails ? caseDetails.case_status : "" : <span style={{color: '#9AA0A5'}}>Case Status</span> ,
                })(
               
              <Select mode="default" style={{width: '700px',}} >
                  {
                    caseListStatus.map((e)=>{
                      return <Option key={e.id} value={e.case_status}>{e.case_status}</Option>
                    })
                  }
              </Select>

                )}
              </Form.Item>
            </Col>
        </Row>

        <Row>
            <Col span={20}>
              <Form.Item label="Case Tags" style={{width: '700px',}}>
                {getFieldDecorator('case_tags', {
                  rules: [{ required: true, message: 'Please input your fields' }],
                  initialValue:  edit == "true" ? caseDetails ? caseDetails.case_tags : [] : [],
                })(
                  <Select mode="tags" placeholder="Case Tags " />
                )}
              </Form.Item>
            </Col>
        </Row>

        <Row>
            <Col span={20}>
              <Form.Item >
                {getFieldDecorator('documents', {
                  initialValue:   [],
                })(
                  <Input hidden  />
                )}
              </Form.Item>
            </Col>
        </Row>
        
        </div>          
      </div>
    </div>
  </Form>
      );
    }
  }
)

class CaseAdd extends Component {

  componentDidMount(){
    this.props.caseTypeList();
    this.props.caseStatusList();
    this.props.caseGet();
  }

  onCancel = () => {
    this.props.history.goBack();
  }

  onSave = (params)=> {
    this.props.caseAdd(params, this.callback);
  }

  callback = (response, error) => {
    if (!error) {
      var id = response.data.case.id;
      this.props.history.push(`/chr/case/add/upload/${id}`);
    } else {
      notification.warning({ message: `${error.message}`})
    }
  }

  editCallback = (response) => {
      if(response.status =='200'){
          notification.success({message: "Modify Success!"})
          this.props.history.goBack();
        return;
    }
  }

  onEdit =(params) =>{
    this.props.caseEdit(params, this.editCallback);
  }

  render(){
    const history = this.props.history.location;
    return(
        <div>
          <CaseForm
            onCancel = {this.onCancel}
            onSave = {this.onSave}
            history = {history}
            status = {this.props.status}
            type = {this.props.type}
            case={this.props.caseData}
            onEdit ={this.onEdit}
          />
        </div>
    );
  }
}


const mapStateToProps = (state)=> {
  return {
    status: state.case.status.data,
    type: state.case.type.data,
    caseData: state.case.caseGet,
  }
}

const mapDispatchToProps = {
  caseAdd, caseTypeList, caseStatusList, caseGet, caseEdit
   
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseAdd); 