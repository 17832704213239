import { getAuthHeader, getError, axios } from './../../shared/utils/axios';
import {API_VICTIM_LIST, BASE_URL } from '../../shared/constant/url';

export const VICTIM = {
   VICTIM_LIST_START: "VICTIM_LIST_START",
   VICTIM_LIST_SUCCESS: "VICTIM_LIST_SUCCESS",
   VICTIM_LIST_FAILED: "VICTIM_LIST_FAILED",

   VICTIM_CASE_LIST_START: "VICTIM_CASE_LIST_START",
   VICTIM_CASE_LIST_SUCCESS: "VICTIM_CASE_LIST_SUCCESS",
   VICTIM_CASE_LIST_FAILED: "VICTIM_CASE_LIST_FAILED",

   CASE_ENROLLED_LIST_START: "CASE_ENROLLED_LIST_START",
   CASE_ENROLLED_LIST_SUCCESS: "CASE_ENROLLED_LIST_SUCCESS",
   CASE_ENROLLED_LIST_FAILED: "CASE_ENROLLED_LIST_FAILED",

   CASE_LIST_VICTIM_START : "CASE_LIST_VICTIM_START",
   CASE_LIST_VICTIM_SUCCESS : "CASE_LIST_VICTIM_SUCCESS",
   CASE_LIST_VICTIM_FAILED : "CASE_LIST_VICTIM_FAILED",
}

export const victimListCase = ()=>{
   const case_id = sessionStorage.getItem('case_id');
   return (dispatch)=>{
         dispatch({ type: VICTIM.VICTIM_CASE_LIST_START })

      axios.get(`${API_VICTIM_LIST}?case_id=${case_id}&is_archived=false`, getAuthHeader())
      .then(response => {  dispatch({type: VICTIM.VICTIM_CASE_LIST_SUCCESS, payload: response.data}) })
      .catch(error => { dispatch({ type: VICTIM.VICTIM_CASE_LIST_FAILED, payload: getError(error)}) })
   }
}

export const victims = (params)=>{
   return (dispatch)=>{
         dispatch({ type: VICTIM.VICTIM_LIST_START })

      axios.get(`${BASE_URL}/api/victims?is_archived=false`, { params, ...getAuthHeader() })
      .then(response => {  dispatch({type: VICTIM.VICTIM_LIST_SUCCESS, payload: response.data}) })
      .catch(error => { dispatch({ type: VICTIM.VICTIM_LIST_FAILED, payload: getError(error)}) })
   }
}


export const enrollCase = (params, callback = null)=>{
   const victim_id = sessionStorage.getItem('victim_id')
   return()=>{
      axios.post(`${BASE_URL}/api/enrollcase/${victim_id}`, params, getAuthHeader())
      .then(response=> {callback(response); console.log(response)})
      .catch(error => console.log(error))
   }
}

export const caseEnrolled = () => {
   const victim_id = sessionStorage.getItem('victim_id')

   return (dispatch)=>{
      dispatch({ type: VICTIM.CASE_ENROLLED_LIST_START })

   axios.get(`${BASE_URL}/api/cases?is_archived=false&victim_id=${victim_id}`, getAuthHeader())
   .then(response => {  dispatch({type: VICTIM.CASE_ENROLLED_LIST_SUCCESS, payload: response.data}) })
   .catch(error => { dispatch({ type: VICTIM.CASE_ENROLLED_LIST_FAILED, payload: getError(error)}) })
   }
}

 export const caseListVictim = (id)=>{
   return (dispatch)=>{
         dispatch({ type: VICTIM.CASE_LIST_VICTIM_START })

      axios.get(`${BASE_URL}/api/cases?is_archived=false`, getAuthHeader())
      .then(response => {  dispatch({type: VICTIM.CASE_LIST_VICTIM_SUCCESS, payload: { data: response.data, id } }) })
      .catch(error => { dispatch({ type: VICTIM.CASE_LIST_VICTIM_FAILED, payload: getError(error)}) })
   }
}

export const VictimRestored= (victim_id, callback = null)=>{
   return()=> {
       axios.put(`${BASE_URL}/api/document/${victim_id}/restore_document`, victim_id, getAuthHeader())
       .catch(error=> { if(error) {console.log(getError(error)) } })
   }
}   

export const VicitimArchived= (victim_id, first, last, callback = null)=>{
   
   let data = {
      victim_id: victim_id,
      first_name: first,
      last_name: last,
      is_archived: true
   }

   return()=> {
       axios.post(`${BASE_URL}/api/victim/${victim_id}`, data, getAuthHeader())
       .catch(error=> { if(error) {console.log(getError(error)) } })
   }
}   

export const CaseArchived= (case_id, callback = null)=>{

   return()=> { 
       axios.post(`${BASE_URL}/api/case/${case_id}`, case_id, getAuthHeader())
       .catch(error=> { if(error) {console.log(getError(error)) } })
   }
}   

