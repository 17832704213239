import React, { Component } from 'react'

import _ from 'lodash';
import VictimAccount from './tabs/victimAccount';
import VictimAct from './tabs/victimAct';
import Files from './tabs/files';
import History from './tabs/history';
import Breadcrumps from '../../../../shared/components/breadcrumps';
import { userList } from '../../../users/actionCreators';
import { Link } from 'react-router-dom';
import { Icon, Button, Row, Col, Tabs  } from 'antd';
import { connect } from 'react-redux';
import {  victimGet, 
          victimAccountList, 
          victimActList, 
          victimFileList, 
          victimHistoryList,
          multipleDownloadFile  } from '../../actionCreator';

const TabPane = Tabs.TabPane;

 class viewForm extends Component {

  componentDidMount(){
    this.props.victimGet();
    this.props.victimAccountList();
    this.props.victimActList();
    this.props.victimFileList();
    this.props.victimHistoryList();
    this.props.userList();
  }

  componentDidUpdate(props, states){
    if(props.victimAccount !== this.props.victimAccount){
      setTimeout(()=>{
        this.props.victimAccountList();
      }, 500)
    }

    if(props.victimAct !== this.props.victimAct){
      setTimeout(()=>{
        this.props.victimActList();
      }, 500)
    }

    if(props.victim_files_list !== this.props.victim_files_list){
      setTimeout(()=>{
        this.props.victimFileList();
      }, 500)
    }
  }

  userPermission = () => { 
    const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;

      if (userRole === "viewer" || userRole === "contributor"){
          return(
            <Button 
              disabled={true}
              className="chr-font-light" 
              type="primary" 
              style={{ float: 'right', margin: '20px 30px', height: '40px'}}    
              icon="edit" >
                Edit
            </Button>
          ) 
      } else {
          return (
              <Link to ={'/chr/case/add/victim/form'} >
                    <Button 
                      className="chr-font-light" 
                      type="primary" ghost
                      style={{ float: 'right', margin: '20px 30px', height: '40px'}}  
                      icon="edit" 
                      onClick= {()=>{sessionStorage.setItem('victim_edit', true)}}>
                        Edit
                    </Button>
              </Link>
          )
      }
  }

  CaseDetails = () =>{
    const victim = this.props.victim;
    const firstName = victim.first_name == null ? " " :  victim.first_name;
    const lastName = victim.last_name == null ? " " :  victim.last_name;
    const middleName = victim.middle_name == null ? " " :  victim.middle_name;
    const name = firstName +" "+ middleName +" "+ lastName;
    sessionStorage.setItem('victim_title', name);

    return  <div style={{width: '97%', height: '100%', boxShadow: '1px 1px 7px #D1D1D1', borderRadius: '5px', margin: '40px 20px 0px 20px ', background: 'white'}}>
              <div style={{padding: "20px 10px"}}>
                <div>
                  <a>
                  <Icon type='solution'  style={{margin: '20px 6px 20px 20px', fontSize: '25px', fontWeight: "bold", color: "#22568f" }} />
                    { this.userPermission() }
                  <span className="chr-font-sm"> {name} </span>  
                  </a>
                </div>

                <div style={{margin: "20px 20px"}}>
                
                  <Row style={{padding: "10px"}}>
                    <Col span={5} className="row-label-name">
                    Age of Victim:
                    </Col> 
                    <Col span={5} className="row-details-name">
                    {victim.age}
                    </Col>

                    <Col span={5} className="row-label-name">
                      Educational Attainment:
                    </Col>
                    <Col span={5} className="row-details-name">
                    {victim.educational_attainment}
                    </Col>
                  </Row>

                  <Row style={{padding: "10px"}}>
                    <Col span={5} className="row-label-name">
                    Assigned Sex:
                    </Col>
                    <Col span={5} className="row-details-name">
                    {victim.sex}
                    </Col>

                    <Col span={5} className="row-label-name"> 
                      Source of Income:
                    </Col>
                    <Col span={5} className="row-details-name">
                    {victim.source_of_income}
                    </Col>
                  </Row>

                  <Row style={{padding: "10px"}}>
                    <Col span={5} className="row-label-name">
                      Disability:
                    </Col>
                    <Col span={5} className="row-details-name">
                      {victim.type_of_disability}
                    </Col>
                  </Row>

                  <Row style={{paddingLeft: "10px"}}>
                    <h3 className="chr-font-ss"style={{paddingTop: '30px'}}>Location</h3>
                  </Row>

                  <Row style={{padding: "10px"}}>
                    <Col span={5} className="row-label-name">
                    Region:
                    </Col>
                    <Col span={5} className="row-details-name">
                    {victim.region}
                    </Col>

                    <Col span={5} className="row-label-name">
                    Municipality/City:
                    </Col>
                    <Col span={5} className="row-details-name">
                    {victim.municipality}
                    </Col>
                  </Row>

                  <Row style={{padding: "10px"}}>
                    <Col span={5} className="row-label-name">
                      Province:
                    </Col>
                    <Col span={5} className="row-details-name">
                    {victim.province}
                    </Col>

                    <Col span={5} className="row-label-name">
                    Barangay:
                    </Col>
                    <Col span={5} className="row-details-name">
                    {victim.barangay}
                    </Col>
                  </Row>

                  <Row style={{padding: "10px"}}>
                    <Col span={5} className="row-label-name">
                    Area/District:
                    </Col>
                    <Col span={5} className="row-details-name">
                      {victim.district}
                    </Col>
                  </Row>

                </div>
              
                <div style={{margin: "30px 20px"}}>
                    {
                      ! victim.victim_tags ? [] :
                      victim.victim_tags.map(e=>{
                        return <span key={e} className="tags-for-card">{e}</span>
                      })
                    }
                </div>
              </div>
            </div> 
  }

    TabsPanesList =()=>{
      const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
         
         if(userRole === "viewer" || userRole === "contributor" || userRole === "editor" ) {
          return  (
            <div className="card-container" style={{margin: "10px 20px"}}>
                <Tabs type="card">

                  <TabPane tab="Victim's Act" key="1">
                    <VictimAct login={this.props.login} victimAct ={this.props.victimAct} history ={this.props.history} users={this.props.users}/>
                  </TabPane>

                  <TabPane tab="Victim's Account" key="2">
                    <VictimAccount login={this.props.login} account = {this.props.victimAccount} history ={this.props.history} users={this.props.users}/>
                  </TabPane>

                  <TabPane tab="Files" key="3">
                    <Files multipleDownloadFile = {this.props.multipleDownloadFile } login={this.props.login} history ={this.props.history} victim_files_list={this.props.victim_files_list} />
                  </TabPane>

              </Tabs>
          </div>
          )
        } else {
          return  (
            <div className="card-container" style={{margin: "10px 20px"}}>
                <Tabs type="card">

                  <TabPane tab="Victim's Act" key="1">
                    <VictimAct login={this.props.login} victimAct ={this.props.victimAct} history ={this.props.history} users={this.props.users}/>
                  </TabPane>

                  <TabPane tab="Victim's Account" key="2">
                    <VictimAccount login={this.props.login} account = {this.props.victimAccount} history ={this.props.history} users={this.props.users}/>
                  </TabPane>

                  <TabPane tab="Files" key="3">
                    <Files multipleDownloadFile = {this.props.multipleDownloadFile } login={this.props.login} history ={this.props.history} victim_files_list={this.props.victim_files_list} />
                  </TabPane>

                  <TabPane tab="History" key="4">
                    <History  users={this.props.users} login={this.props.login} history ={this.props.history} victim_history_list = {this.props.victim_history_list}/>
                  </TabPane>
                  
              </Tabs>
          </div>
          )
        }
       
    }
   
  render() {
    return (
      <div  style={{display: 'flex', flexDirection: 'column'}}>
            <div><Breadcrumps history ={this.props.history}/></div>
            <div >{ this.CaseDetails() } </div>
            <div>{ this.TabsPanesList() }</div>
      </div>
    ) 
  }
}

 const mapStateToProps = (state) => {
  return {
    victim: state.case.victimDetails,
    victimAccount: state.case.victimAccount,
    victimAct: state.case.acts, 
    victim_files_list: state.case.victim_files_list,
    victim_history_list: state.case.victim_history_list,
    users: state.user.users, 
    login: state.login,
  }
}

 const mapDispatchToProps = {
    victimGet, victimAccountList, victimActList, victimFileList, victimHistoryList, userList, multipleDownloadFile 
 }
  
export default connect(mapStateToProps, mapDispatchToProps)(viewForm);