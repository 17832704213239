import React, { Component } from 'react'
import { Table, Button,  Icon, Checkbox } from 'antd';
import moment from 'moment';

class TableUser extends Component {
    constructor(props){
        super(props);

        this.columns = [
            {
                title: "Name",
                dataIndex: "first_name",
                width: '250px',
                sorter: true,
            }, {
                title: "Type",
                dataIndex: "type",
                filters: [
                    {
                      text: 'Case',
                      value: 'case',
                    },
                    {
                      text: 'Victim',
                      value: 'victimm',
                    },
                    {
                        text: 'Act',
                        value: 'act',
                    },
                    {
                        text: 'Account',
                        value: 'account',
                    },
                ],
                render: (record, text)=>{
                    if(text.case_id){ 
                        return "Case"
                    } else if(text.victim_id){
                        return "Victim"
                    } else if(text.act_id){
                        return "Act"
                    } else if(text.account_id){
                        return "Account"
                    } else if(text.document_id) {
                        return "Document"
                    } else {
                        return ""
                    }
                }
            },{
                title: "Date Archived",
                dataIndex: "created_at",
                sorter: true,
                render: (date) => moment(date).format('MMMM DD, YYYY HH:mm A'),
            },{
                title: "Created By",
                dataIndex: "created_by",
                sorter: true,
                render: (record , text, index)=> {
                    const users = this.props.users.data;
                    const findData = users == undefined ? "" : users.find(e => e.id == record);
   
                     var user = findData.first_name +" "+ findData.last_name;
                     return <span>{user}</span>
                   },
            },{
                title: "",
                dataIndex: "action",
                render: (record, text, index)=>{
                    return (
                        <Checkbox 
                            onClick={(e)=> { e.stopPropagation();}}
                            onChange={(e)=>{
                                let status = e.target.checked;
                                this.restored( text.document_id, status, index, text) }}
                            />
                        )
                }
            }
        ];

        this.state = {
            data: []
        }   
    }

    restored = ( ids, status, index, text)=> {
      
        let id = null;
        let case_id = text.case_id;
        let vimtim_id = text.vimtim_id;
        let act_id = text.act_id;
        let account_id = text.account_id;
        let data = this.state.data;

        if (case_id !== null, vimtim_id === null, act_id === null, account_id === null) {
            id = text.case_id;
        } else if (case_id === null, vimtim_id !== null, act_id === null, account_id === null) {
            id = text.vimtim_id;
        } else {
            id = text.document_id;
        }


        if (status === false) {
            data.splice(index, 1);
            this.setState({ data: data })
        } else {
            data.push({id})
        }
    }

    
    Restoration=()=>{
        let dataFiles = this.state.data; 
        
        for (let a = 0; a < dataFiles.length; a++) {

            setTimeout(()=>{
             this.props.DocumentRestored(dataFiles[a].id)
            }, 500)
         }

    }

    onChangeTable = (pagination, filters, sorter) =>{ 
        this.props.sortersAndFilters(filters, sorter)
    }
  render() {
    const data = this.props.list.trash.data === undefined ? [] : this.props.list.trash.data;
    console.log(data)
    return (
      <div style={{margin: '30px 0px'}}>
        
        <h1 className="chr-font">
            {/* <Icon  type="delete" theme="filled" style={{marginRight: "10px", fontSize: "25px" }} /> */}
            Trash
        </h1>

            <Button 
                className="chr-font-light"
                style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                onClick={()=>this.Restoration()} 
                type="primary" 
                icon="redo"> 
                Restore
            </Button>

            <Table 
                columns={this.columns}
                dataSource={data}
                onChange = { this.onChangeTable}
                rowKey="id" />
      </div>
    )
  }
}

export default TableUser;