import React, { Component } from 'react'
import { Table, Icon,  Button, Popover, Input } from 'antd';
import Tags from '../../../shared/components/tags';
import  moment from 'moment';

const Search = Input.Search;

class TableList extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            expanded: 0,
            search: ''
        }

        this.columns = () => {
            return [
            {
                title: 'Case Name',
                dataIndex: 'case_identifier',
                key: 'case_identifier',
                sorter: true
            },{
                title: 'Type',
                dataIndex: 'case_type',
                key: 'case_type',
                filters: this.FilterType(),
            },
            {
                title: <span> Tags <Popover content={this.content()} placement="bottomLeft" trigger="click" ><Button icon="setting" style={{border: '1px solid white'}}/> </Popover> </span>,
                dataIndex: 'case_tags',
                key: 'case_tags',
                // filters:[{'value': '', 'text': ''}],

                width: '200px',
                render: (record, text, index)=>{
                    const arr2 = text.case_tags;
                    const arr1 = arr2.length;
                    const arr3 = arr2.slice(0, 2);
                    const id = text.id;
                return (
                    <Tags 
                        arr2={arr2} 
                        arr1={arr1} 
                        arr3={arr3} 
                        id={id} />
                    )
                },
            },
            {
                title: 'Status',
                dataIndex: 'case_status',
                filters: this.FiltersStatus(),
                key: 'case_status',
                
            },{
                title: 'Date Created',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                render: (date) => moment(date).format('MMMM DD, YYYY HH:mm A'),
            },{
                title: 'Created by',
                dataIndex: 'created_by',
                key: 'created_by',
                sorter: true,
                render: (record , text, index)=> {
                 const users = this.props.users.data;
                 const findData = users == undefined ? "" : users.find(e => e.id == record);

                  var user = findData.first_name +" "+ findData.last_name;
                  return <span>{user}</span>
                },
            },
        ]
      }
    }

    content = () => {
        return (
            <div>
              <Search placeholder="Search Tags Here" value={this.state.search} onChange={ (e)=>{ this.setState({ search: e.target.value}) }}/>

                <div style={{textAlign: 'right'}}>
                    <hr />
                    <Button  type="primary" onClick={()=> this.searchTags()} >Ok</Button>
                        &nbsp; &nbsp;
                    <Button onClick={()=> { this.props.onChangeForState(); this.props.history.push({ search :""}); this.setState({ search: ''}); }}>Reset</Button>
                </div>
             
            </div>
        )
    }

    onChangeSorterAndFilter = (pagination, filters, sorter)=>{
        this.props.onCangeSorterAndFilters(pagination, filters, sorter)
    }   

    searchTags = () => {
      this.props.history.push({ pathaname:`/chr/dashboard`, search : this.state.search })
      this.props.onChangeForState();
    }

    FiltersStatus(){
        const data = this.props.status.data === undefined ? [] : this.props.status.data;
        const params =  data.map((item) => {
                return { "value": item.case_status, "text": item.case_status}
            })

        return params;
    }

    FilterType(){
        const data =  this.props.type.data !== undefined ? this.props.type.data : [];
        const params =  data.map((item) => {
                return { "value": item.case_type, "text": item.case_type}
            })

        return params;
    }

    render() {
        let data = this.props.data.data === undefined ? [] : this.props.data.data ;
        let lastData = data.length;
        let rangeData = lastData - 10;
        let datSlice = data.slice(rangeData, lastData)
        return (
            <div>
                <Table 
                    columns = {this.columns()}
                    rowKey="id"
                    dataSource={datSlice}
                    pagination={false}
                    onChange={this.onChangeSorterAndFilter }

                />
            </div>
        )
    }
}

export default TableList;