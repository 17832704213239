import React, { Component } from 'react'
import {connect} from 'react-redux';
import { Redirect } from 'react-router-dom';
import { notification, Button } from 'antd';
import ChangePassword from '../../components/ChangePassword';

class Main extends Component {

  render() {
    return (
        <div className=" background-login">
           <ChangePassword history={this.props.history}/>
        </div>
    )
  }
}

const mapStateToProps = (state)=>{
    return { 
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);