import React, { Component } from 'react'
import Logo from '../../assets/Logo.png';

export default class componentName extends Component {
    render() {
        return (
        <div>
            <div className="logo-div" >
                <img  src={Logo} style={{width: 152, height: 152}}/>
            </div>
            <center><h1 className="chr-font-gen" style={{color: '#5E5E5E', fontSize: '25px', textAlign: 'center'}}> Login your Account</h1></center>  
            <div>
                <div className="divider-layer-1" /> 
                <div className="divider-layer-2" />
                <div className="divider-layer-1" />
                <div className="divider-layer-2" />
            </div>
        </div>
        )
    }
}
