import React, { Component } from 'react'
import { Form, DatePicker, Button, Select, Row, Col, message } from 'antd';
import moment from 'moment';

const REPORTS = [ 
    { title: 'Number of Investigated Killings per Region', value: '1'},
    { title: 'Demographic Profile of Victims', value: '2'},
    { title: 'Percent Distribution of Victims per Age Group', value: '3'},
    { title: 'Victims Link to Illegal Drug Trade', value: '4'},
    { title: 'Law Enforcement Operations vs Non-Law Enforcement Operations', value: '5'},
    { title: 'Type of Law Enforcement', value: '6'},
    { title: 'Type of Perpetrator', value: '7'},
    { title: 'Comparison of Victims Killed by State Agents vs Killed in LEO', value: '8'},
    { title: 'Items Found: LEO vs Non-LEO', value: '9'},
    { title: 'Weapons Found in Law Enforcement Operation', value: '10'},
    { title: 'Drug Found in Law Enforcement Operation', value: '11'},
    { title: 'Placards Found in Law Enforcement Operation', value: '12'},
    { title: 'Victims Brought to the Hospital', value: '13'},
];

const WrapperForm = Form.create()(
 class extends Component {

    onSubmitHandler = (e) => {
        e.preventDefault();
  
        this.props.form.validateFields((err, values) => {
         
          values.start_date = moment(values.start_date).format('DD-MM-YYYY');
          values.end_date = moment(values.end_date).format('DD-MM-YYYY');
            
          if (!err) {   
        //    console.log(values.type_of_reports)
            this.props.typeReports(values.type_of_reports)
          } 
        });
      }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
    <div style={{padding: '10px'}}>
        <Form onSubmit={this.onSubmitHandler} style={{margin: '30px 0px', }}>
            <Row gutter={12}>
                <Col span={9}>
                <p style={{color: '#707070', fontSize: '17px', fontWeight: 'bold'}}> Type of Report </p>
                    <Form.Item>
                        { getFieldDecorator('type_of_reports', {
                            rules: [{ required: "true", message:"Please Select types!"}],
                        })(
                           
                            <Select placeholder="Select Report">
                                {
                                    REPORTS.map(item => {
                                    return <Select.Option key={item.title} value={item.value} style={{ fontSize: "12px"}} >
                                                {item.title}
                                            </Select.Option>
                                    })
                                }
                            </Select>
                           
                        )}
                    </Form.Item>   
                </Col>

                <Col span={3}>
                <p style={{color: '#707070', fontSize: '17px', fontWeight: 'bold'}}> Start Date </p>
                    <Form.Item>
                        { getFieldDecorator('start_date')(
                            <DatePicker placeholder="Start Date " /> 
                        )}
                    </Form.Item>   
                </Col>
                                
                <Col span={3}>
                <p style={{color: '#707070', fontSize: '17px', fontWeight: 'bold'}}> End Date </p>
                    <Form.Item>
                        { getFieldDecorator('end_date')(
                            <DatePicker placeholder="End Date "  /> 
                        )}
                    </Form.Item>   
                </Col>

                <Button className="chr-btn" type="primary" htmlType="submit" style={{margin: '40px 15px'}}> 
                    <span className="chr-font-light" >Generate</span>
                </Button>
            </Row>
        </Form>
    </div>
    )
  }
});

class report extends Component {
   
changeTheValueReports = (value)=>{
    this.props.typeReports(value)
}

  render() {
      
     return (
      <div>
          <WrapperForm 
            typeReports={ this.changeTheValueReports }
          />
      </div>
    )
  }
}



export default report;
