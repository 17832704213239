import { API_LOGIN, BASE_URL } from '../../shared/constant/url';
import {axios, getError, getAuthHeader} from '../../shared/utils/axios';

export const LOGIN = {
    START :  "GET_LOGIN_REQUEST",
    SUCCESS: "GET_LOGIN_SUCCESS",
    ERROR:   "GET_LOGIN_ERROR",
    LOGOUT:  "LOGOUT", 
};

export const getLogin = (params, callback = null) => {
    return (dispatch) => {
        dispatch({ type: LOGIN.START });
            axios.post(API_LOGIN, params)
                .then(response =>{ callback(response, null); dispatch({type: LOGIN.SUCCESS, payload: response.data}, localStorage.setItem('token', response.data.token)) })
                .catch(error => { callback(null, getError(error)); dispatch({ type: LOGIN.ERROR, payload: getError(error) }); 
                }) 
            }
        }

export const logout = () =>{
 return (dispatch)=>{ dispatch({ type: LOGIN.LOGOUT });
            localStorage.clear();
            sessionStorage.clear();
    }
} 

export const changePasswordRequest = (values, errorCallback = null,successCallback = null) => {
    return ()=> {

        axios.post(`${BASE_URL}/api/change_password_request`, values, getAuthHeader() )
        .then(response => { successCallback(response)})
        .catch(error => { errorCallback(getError(error)) })
    }
}


export const loginNewPassword = (values, errorCallback = null, successCallback = null) => {
    return () => {

        axios.put(`${BASE_URL}/api/change_password`, values, getAuthHeader() )
        .then(reponse => { successCallback(reponse) })
        .catch(error => { errorCallback(getError(error)) })
    }
}
