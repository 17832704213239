import React, { Component } from 'react'
import TableUser from './components/table';
import { connect } from 'react-redux';
import {
    DocumentsList,
    downloadFile,
    multipleDownloadFile,
    DocumentArchived
} from '../cases/actionCreator';

class Documents extends Component {

componentDidMount(){
    this.props.DocumentsList();
}

componentDidUpdate(props, states){
    if(states !== this.props.DocumentsList){
        setTimeout(()=>{
            this.props.DocumentsList();
        }, 300)
    }
}
    render() {
        return (
            <div>
                <TableUser 
                    data = {this.props.documents}
                    downloadFile = {this.props.downloadFile}
                    history = {this.props.history}
                    multipleDownloadFile={this.props.multipleDownloadFile}
                    login={this.props.login}
                    DocumentArchived={this.props.DocumentArchived}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        documents: state.case.documents,
        login: state.login
    }
}

const mapDispatchToProps = {
    DocumentsList,
    downloadFile,
    multipleDownloadFile,
    DocumentArchived
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents);