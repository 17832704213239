import React, { Component } from 'react'
import { Table, Icon, Button } from 'antd';
import Tags from '../../../shared/components/tags';
import {get} from 'lodash';

 class case_table extends Component {
   constructor(props){
    super(props);
    
    this.state ={
      visible: false,
      ShowLocation: false,
      indexToShow: null 
     }

     this.columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (record, text)=>{
            const firstName = !text.first_name ? "" : text.first_name;
            const middleName = !text.middle_name ? "" : text.middle_name;
            const lastName = !text.last_name ? "" : text.last_name;

            const List =  firstName + " "+ middleName+" "+ lastName;
            return <div><Icon className="case-icon-table" type="folder-open"  theme="filled"/> <span className="name_folder_icon">{record}</span>{List}</div>
         }
        },
        {
          title: 'Location',
          dataIndex: 'location',
          key: 'location',
          width: '300px',
          render: (record, text, index)=>{
            const region = text.region === null ? " " :  `${text.region}, `;
            const province = text.province === null ? " " : `${text.province}, `;
            const city = text.municipality === null ? " " :`${text.municipality}, `;
            const barangay = text.barangay === null ? " " : `${text.barangay}, `;
            const district = text.district === null ? " " :`${text.district} `;

            const ListShow = region+ " " +`${province}`;
            const ListHide = region+ " " +`${province}`+ " " +`${city}`+ " " +`${barangay}`+ " " +`${district}`;
            const indexToShow = this.state.indexToShow;

            return ( 
                    <div>
                     {
                      this.state.ShowLocation == true && index == indexToShow  ?
                      <div>
                      { ListHide } 
                      <Button  id="show-hide-tags" icon="small-dash" onClick={(e)=>{ e.stopPropagation(); this.setState({ShowLocation: false, indexToShow: index})}}/>
                      </div> 
                      :
                      <div>
                      { ListShow } 
                      <Button  id="show-hide-tags" icon="small-dash" onClick={(e)=>{e.stopPropagation(); this.setState({ShowLocation: true, indexToShow: index})}}/>
                      </div> 
                     }
                    </div>
            
                )
            }
        },
        {
          title: 'Tags',
          dataIndex: 'tags',
          key: 'tags',
          render: (record, text, index)=>{
            const arr2 = text.victim_tags;
            const arr1 = arr2.length;
            const arr3 = arr2.slice(0, 2);
            const id = text.id;
            return (
                    <Tags 
                        arr2={arr2} 
                        arr1={arr1} 
                        arr3={arr3} 
                        id={id} 
                    />
                  )
          }
       
        },{
          title: 'No. of Cases',
          dataIndex: 'id',
             key: 'id',
             render: (text, record)=>{
                return <div>{record.cases.length}</div>
             }
      },{
            title: 'Date Created',
            dataIndex: 'created_at',
            key: 'created_at'
        },{
            title: 'Date Modified',
            dataIndex: 'updated_at',
            key: 'updated_at',
            
        },{
          title: 'Created By:',
          dataIndex: 'created_by',
          render: (record, text)=>{
            const userList = this.props.users.data == undefined ? [] : this.props.users.data;
            const findData = userList.find(e=> e.id == record);
        
            return get(findData, 'first_name') + " " + get(findData, 'last_name')
            
        }
          }
     ] 
  
  }

  render() {
    return (
      <div>
        <Table 
          style={{marginTop: '30px'}}
          columns ={this.columns}
          dataSource={this.props.data.data}
          onRowClick={ (value)=>{
            const firstName = value.first_name ==null? "" : value.first_name;
            const middleName = value.middle_name ==null ? "" : value.middle_name;
            const lastName = value.last_name ==null? "" : value.last_name;
            const name = firstName +" "+ middleName +" "+ lastName;

            this.props.history.push('/chr/victim/view/module'); 
            sessionStorage.setItem('victim_id', value.id);
            sessionStorage.setItem('victim_title', name);
            } 
          }
        />
      </div>
    )
  }
}
 
export default case_table;