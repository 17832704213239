import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, notification, message, Icon } from 'antd';
import { connect } from 'react-redux';
import { victimAddDocument } from '../../../actionCreator';
import FileUpload from '../../../../../shared/components/fileUploadCase';
import VicitimTabs from "../../../../../shared/components/addVictimTabs";

const CaseForm =  Form.create()(

  class extends Component {
    
    handleSubmit = (e) => {
      // e.preventDefault();
      let routeValue = e.routeValue;
      this.props.form.validateFields((err, values) => {

        if (!err) {
          this.props.onSave(values, routeValue) 
        }
      });
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      const case_id = sessionStorage.getItem('case_id_victim');
      const checkingCaseid = sessionStorage.getItem('case_id');
      const Case_Id  = checkingCaseid == "undefined" ? sessionStorage.setItem('case_id', case_id) : checkingCaseid;
      const path = sessionStorage.getItem('add_victim_path');
      return (
        <Form>
            {/* <Form onSubmit={this.handleSubmit}>*/}
          <Row style={{padding: '20px 20px 0px 20px'}}> 
             <Col span={12}>
              { 
                  this.props.component_quick == true || path == "/chr/victim/view/module" ? <h1 className="chr-font">Upload Attachments </h1> :
                  <h1 className="chr-font">Adding of Victim </h1>
              } 
             </Col> 

             <Col span={12}>
                 <Form.Item className="form-case-add">
                   { 
                   this.props.component_quick == true || path == "/chr/victim/view/module"? 
                   <div>
                      <Button className="chr-btn" type="primary" onClick={()=>{ this.handleSubmit({routeValue : "null"}) }} style={{margin: '0px 10px'}} >
                          Save
                      </Button>
                      <Button className="chr-close-btn" type="default" icon="cross" onClick ={this.props.onCancel}></Button>
                    </div>
                   :
                   <div>
                    <Button className="chr-btn-gen" 
                      onClick={()=>{
                          sessionStorage.setItem('case_id', Case_Id);
                          sessionStorage.setItem('victim_id', this.props.dataID);
                          this.handleSubmit({routeValue : "account"}) }}
                      type="primary" 
                      ghost >
                         Save and Add Account
                    </Button>
                  
                    <Button className="chr-btn" onClick={()=>{ this.handleSubmit({routeValue : "null"}) }} type="primary"  style={{margin: '0px 20px'}} >
                         Save
                    </Button>
                    </div>
                   }  
                 </Form.Item>
             </Col>
         </Row>
         <Row>
          <Col className="form-case-add-row"></Col>
         </Row>
         <Row>
           <Col>
          <Row  style={{margin: '50px 10px'}}>
              <Col span={24}>
                { 
                  this.props.component_quick == true || path == "/chr/victim/view/module" ? <div></div> :
                  <VicitimTabs history = {this.props.history} />
                }
      <div style={{  display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
        {/* Form Start */}
              <Row  >
                <Col span={20}>
                  <Form.Item>
                    {getFieldDecorator('dev_notes', {
                      rules: [{ required: false }],
                      initialValue: { file_metadata: null , 1: null}
                    })(
                        <Input  hidden  />
                    )}
                  </Form.Item>
                </Col>
              </Row>          
            
              <Row  >
                <Col span={20}>
                  <Form.Item >
                    {getFieldDecorator('documents', {
                      rules: [{ required: false }],
                      initialValue: []
                    })(
                      <FileUpload dataID={this.props.dataID} 
                      
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              </div>
              </Col>
          </Row>
        </Col>
      </Row>
    </Form>
      );
    }
  }
)

class VictimAdd extends Component {


  onSave = (params, routeValue)=> {
    var dataID = this.props.history.location.pathname;
    var id = dataID.slice(28);

    if(routeValue == "account" ){
      this.props.victimAddDocument(params, id, this.routeCallback)
      this.props.history.push(`/chr/quick/add/mulitple`);
    } else {
      this.props.victimAddDocument(params, id, this.callback)
    }
  }

  routeCallback = ()=>{ }

  callback = (response, error) => {
    const path = sessionStorage.getItem('victim_module_add_path');
    var history = this.props.history.location.state;
    var component_quick = history == undefined ? null : this.props.history.location.state.quick_files_upload ;
    const add_victim_path = sessionStorage.getItem('add_victim_path');

    if(!error){
      notification.success({message: `Victim successfully added` })
      add_victim_path ? this.props.history.push(add_victim_path) : component_quick == true ? this.props.history.goBack() : this.props.history.push(path);
    } else {
      notification.warning({message: `${error.message}` })
    }
  }

  onCancel=()=>{
    this.props.history.goBack();
  }

  render(){
    var id = this.props.history.location.pathname;
    var dataID = id.slice(28);
    var history = this.props.history.location.state;
    var component_quick = history == undefined ? null : this.props.history.location.state.quick_files_upload ;
    return (
        <div className="contentDiv">

          <CaseForm
            onCancel = {this.onCancel}
            onSave = {this.onSave}
            backButton= {this.backButton}
            dataID = {dataID}
            history = {history}
            component_quick= {component_quick}
          />

        </div>
    );
  }
}

const mapDispatchToProps = {
  victimAddDocument
}

export default connect(null, mapDispatchToProps)(VictimAdd); 
