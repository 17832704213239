import React, { Component } from 'react';
import { Tabs } from 'antd';
import {connect} from 'react-redux';
import Case from './components/case_table';
import Victim from './components/victim_table';
import Account from './components/account_table';
import Act from './components/act_table';
import File from './components/file_table';
import {caseList, victimList, actList, accountList, documentList } from './actionCreators';
import qs from 'qs';
import { get } from 'lodash';
import {userList} from '../users/actionCreators';
import styled from '@emotion/styled'

const TabPane = Tabs.TabPane;

class index extends Component {

  componentDidMount(){
      const query = get(qs.parse(get(this.props, 'location.search'), { ignoreQueryPrefix: true }), 'q', '');
    
      this.props.caseList(query);
      this.props.victimList(query);
      this.props.actList(query);
      this.props.accountList(query);
      this.props.documentList(query);
      this.props.userList();
  }

  componentDidUpdate(prevProps) {
      const query = get(qs.parse(get(this.props, 'location.search'), { ignoreQueryPrefix: true }), 'q', '');
      const prevQuery = get(qs.parse(get(prevProps, 'location.search'), { ignoreQueryPrefix: true }), 'q', '');
      
      if (query !== prevQuery) {
          this.props.caseList(query);
          this.props.victimList(query);
          this.props.actList(query);
          this.props.accountList(query);
          this.props.documentList(query);
      }
  }


  render() {
    const users = this.props.users;
    const filter = this.props.location.hash.slice(1);
    const caseList = this.props.case.cases !== undefined && this.props.case.cases;
    
    return (
      <StyledDiv>
      <div>
          <h3 style={{color: 'gray', padding: '10px'}}>Search By:</h3>
         <Tabs type="card">
          { 
             filter == "case" || !filter ?
            <TabPane tab="Case" key="1">
              <Case data = {this.props.case} users={users} history = {this.props.history} />
            </TabPane>
            : 
            ""
          }
          {
            filter == "victim" || !filter ?
            <TabPane tab="Victims" key="2">
              <Victim  data = {this.props.victim} users={users} history = {this.props.history}/>
            </TabPane>
            : 
            ""
          }
          {filter == "account" || !filter ?
            <TabPane tab="Accounts" key="3">
              <Account  data = {this.props.account} caseList ={caseList} users={users} history = {this.props.history}/>
            </TabPane>
             : 
             ""
          }
          {filter == "act" || !filter ?
            <TabPane tab="Acts" key="4">
              <Act data = {this.props.act} users={users} history = {this.props.history}/>
            </TabPane>
             : 
             ""
          }
          {filter == "file" || !filter ?
            <TabPane tab="Files" key="5">
              <File login={this.props.login} data = {this.props.docs} users={users} history = {this.props.history}/>
            </TabPane>
             : 
             ""
          }
        </Tabs>

        
      </div>
      </StyledDiv>
    )
  }
}


const mapStateToProps = (state)=> {
    return {
        case: state.search,
        victim: state.search.victim,
        act: state.search.act,
        account: state.search.account,
        docs: state.search.documents,
        users: state.user.users,
        login: state.login
    }
}

const mapDispatchToProps = {
    caseList, victimList, actList, accountList, documentList, userList
}

export default connect(mapStateToProps, mapDispatchToProps)(index); 

const StyledDiv = styled.div({
  '.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab': {
    backgroundColor: '#FFFFFF',
    borderColor: '#77B9FF',
    borderRadius: '10px', 
    color: '#77B9FF',
    width: '100px', 
    textAlign: 'center', 
    margin: '0px 3px'
  },
  '.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active ': {
    backgroundColor: '#77B9FF',
    color: '#FFFFFF',
  }
})