import { REPORTS } from './actionCreators';

const INITIAL = {
    error: '',
    loading: false,
    killing: {},
    age: {},
    profile: {},
    enforcement: {},
    perpetrator: {},
    drug: {}
}

const reducer = (state = INITIAL, action)=> {
    switch (action.type) {

    case REPORTS.KILLINGS_START:
        return { ...state, loading: false, error: '' }
    case REPORTS.KILLINGS_SUCCESS:
        return { ...state, loading: false, killing: action.payload }
    case REPORTS.KILLINGS_FAILED:
         return { ...state, loading: true, error: action.payload }
    
    case REPORTS.PROFILE_START:
         return { ...state, loading: false, error: '' }
    case REPORTS.PROFILE_SUCCESS:
         return { ...state, loading: false, profile: action.payload }
    case REPORTS.PROFILE_FAILED:
          return { ...state, loading: true, error: action.payload }

    case REPORTS.VICTIM_AGE_START:
          return { ...state, loading: false, error: '' }
    case REPORTS.VICTIM_AGE_SUCCESS:
          return { ...state, loading: false, age: action.payload }
    case REPORTS.VICTIM_AGE_FAILED:
           return { ...state, loading: true, error: action.payload }

    case REPORTS.ILLEGAL_DRUG_TRADE_START:
         return { ...state, loading: false, error: '' }
     case REPORTS.ILLEGAL_DRUG_TRADE_SUCCESS:
         return { ...state, loading: false, drug: action.payload }
     case REPORTS.ILLEGAL_DRUG_TRADE_FAILED:
          return { ...state, loading: true, error: action.payload }

    case REPORTS.PERPETRATOR_START:
          return { ...state, loading: false, error: '' }
    case REPORTS.PERPETRATOR_SUCCESS:
          return { ...state, loading: false, perpetrator: action.payload }
    case REPORTS.PERPETRATOR_FAILED:
           return { ...state, loading: true, error: action.payload }  
           
    case REPORTS.ENFORCEMENT_START:
           return { ...state, loading: false, error: '' }
    case REPORTS.ENFORCEMENT_SUCCESS:
           return { ...state, loading: false, enforcement: action.payload }
    case REPORTS.ENFORCEMENT_FAILED:
            return { ...state, loading: true, error: action.payload }

    default:
        return state ;
  }
}

export default reducer;


