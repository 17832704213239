import React, { Component } from 'react'
import {Table, Button} from 'antd';
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    } from 'react-vis';


const data = [
    {y: 5,  x: "CAR"},
    {y: 10, x: "NCR"},
    {y: 15, x: "R01"},
    {y: 20, x: "R02"},
    {y: 25, x: "R03"},
    {y: 30, x: "R04"},
    {y: 35, x: "R05"},
    {y: 40, x: "R06"},
    {y: 45, x: "R07"},
    {y: 50, x: "R08"},
    {y: 55, x: "R09"},
    {y: 60, x: "R010"},
    {y: 65, x: "R011"},
    {y: 70, x: "R012"},
    {y: 75, x: "R013"},
    {y: 80 },
    {y: 85 },
    {y: 90 },
    {y: 95 },
    {y: 100 },
  ];
  

class ChartsAndTable extends Component {
    state = {
        x: [],
        y: []
    }
  
  render() {
    var arr = this.props.killings === undefined ? [] : this.props.killings;
    var API  = arr.map(e=>{
        return { x: e.region, y: e.victim_total }
    })
   
    return (
         <div >
            <XYPlot xType="ordinal"  width={600} height={320} xDistance={10}>
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <VerticalBarSeries data={data} color= "transparent" />
            <VerticalBarSeries data={API} color = '#6998dc' style={{width: '20px', }}/>
            </XYPlot>
        </div>
    )
  }
}

export default ChartsAndTable;