import React, { Component } from 'react'
import { Row, Col, Button, Input, Table} from 'antd';
import { connect } from 'react-redux';
import Graph from './components/graphBar';
import Radial from './components/radial';
import TableList from './components/table';
import { 
    caseListArchived, 
    victimListAllArchived, 
    victimAccountListAllArchived, 
    links_drug_list_archived, 
    caseStatusList,
    caseTypeList } from '../cases/actionCreator';
import { 
        ageReport, 
        enforcementReport,
        killingReports,
        profileReports } from '../reports/actionCreators';
import { userList } from '../users/actionCreators';
import { assign } from 'lodash';

class componentName extends Component {

    state = {
        leo: 0,
        non_leo: 0,
        no_info: 0,
        search: null,
        params: null
    }

    componentDidMount(){
        this.props.caseListArchived();
        this.props.victimListAllArchived();
        this.props.victimAccountListAllArchived();
        this.props.links_drug_list_archived();
        this.props.ageReport();
        this.props.userList();
        this.props.enforcementReport();
        this.props.killingReports();
        this.props.profileReports();
        this.props.caseStatusList();
        this.props.caseTypeList();
    }



    onChangeForState = () => {
        const tagsSeach = this.props.history.location.search.slice(1) !== undefined ? this.props.history.location.search.slice(1) : null;
        const params = this.state.params;

        this.setState({ value: tagsSeach})
        this.props.caseListArchived(params, tagsSeach)
    }

    onCangeSorterAndFilters = (pagination, filters, sorter) =>{
        const params =  assign({ sort_dir: sorter.order, sort_key: sorter.columnKey });
        const tagsSeach = this.props.history.location.search.slice(1) ;
      
        for(let [key, value] of Object.entries(filters)) {
            if(value.length > 0) {
                params[`${key}`] = value;
            }
        }

        this.setState({ params })
        this.props.caseListArchived(params, tagsSeach)
    }

    tilesBoard(){
        let caseTotal = this.props.caseAll.data !== undefined ? this.props.caseAll.data.length : 0;
        let victimTotal = this.props.victimAll.data !== undefined ? this.props.victimAll.data.length : 0;
        let accountTotal = this.props.account.data !== undefined ? this.props.account.data.length : 0;
        let linkTotal = this.props.linkTotal.data !== undefined ? this.props.linkTotal.data.length : 0;
        let accountLinkTotal = this.props.account.data !== undefined ? this.props.account.data : [];
        let countLink = 0;
        let total = accountLinkTotal.forEach(item=>{
               if(item.links_to_drug_trade === 1){
                    countLink = countLink + 1;
               }
        })

        return (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>

                <div style={{margin: '0px 10px', borderTop: '5px solid #FFC28D', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', boxShadow: '0px 3px 2px gray', width: '25%', height: '150px', padding: '10px'}}>
                    {/* <div style={{margin: '10px 20px', width: '18px', height: '18px' , background: '#FFC28D' , float: 'left'}}></div>  */}
                    <h5 style={{margin: '10px 5px', color: '#FFC28D', fontWeight: 'bold', fontSize: '18px'}}>Total No. Cases</h5>
                    <h1 style={{margin: '10px 5px', color: '#FFC28D', fontWeight: 'bold', fontSize: '35px'}}>{caseTotal}</h1>
                </div>

                <div style={{margin: '0px 10px', borderTop: '5px solid #9CD69C', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px',  boxShadow: '0px 3px 2px gray', width: '25%', height: '150px', padding: '10px'}}>
                    {/* <div style={{margin: '10px 20px', width: '18px', height: '18px' , background: '#9CD69C' , float: 'left'}}></div>  */}
                    <h5 style={{margin: '10px 5px', color: '#9CD69C', fontWeight: 'bold', fontSize: '18px'}}>Total No. Victims</h5>
                    <h1 style={{margin: '10px 5px', color: '#9CD69C', fontWeight: 'bold', fontSize: '35px'}}>{victimTotal}</h1>
                </div>

                <div style={{margin: '0px 10px', borderTop: '5px solid #85AEE3', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px',  boxShadow: '0px 3px 2px gray', width: '25%', height: '150px', padding: '10px'}}>
                    {/* <div style={{margin: '10px 20px', width: '18px', height: '18px' , background: '#85AEE3' , float: 'left'}}></div>  */}
                    <h5 style={{margin: '10px 5px', color: '#85AEE3', fontWeight: 'bold', fontSize: '18px'}}> No. of Accounts</h5>
                    <h1 style={{margin: '10px 5px', color: '#85AEE3', fontWeight: 'bold', fontSize: '35px'}}>{accountTotal}</h1>
                </div>

                <div style={{margin: '0px 10px', borderTop: '5px solid #ffa394', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px',  boxShadow: '0px 3px 2px gray', width: '25%', height: '150px', padding: '10px'}}>
                    {/* <div style={{margin: '10px 20px', width: '18px', height: '18px' , background: '#ffa394' , float: 'left'}}></div>  */}
                    <h5 style={{margin: '10px 5px', color: '#ffa394', fontWeight: 'bold', fontSize: '18px'}}> No. of Link drug trade</h5>
                    <h1 style={{margin: '10px 5px', color: '#ffa394', fontWeight: 'bold', fontSize: '35px'}}>{linkTotal}</h1>
                </div>
            </div>
        )
    }

    leoTotal(){
        let data = this.props.leoReports.data !==undefined ? this.props.leoReports.data : [];
        let leoTotal = 0;
    
        data.forEach((a)=>{
            leoTotal += a.leo;
        })
        
       return leoTotal;
      }


    nonleoTotal(){
        let data = this.props.leoReports.data !==undefined ? this.props.leoReports.data : [];
        let nonLeoTotal = 0;

        data.forEach((a)=>{
            nonLeoTotal += a.non_leo;
        })
    
    return nonLeoTotal;
    } 


    noInfoTotal(){
        let data = this.props.leoReports.data !==undefined ? this.props.leoReports.data : [];
        let noInfoTotal = 0;

        data.forEach((a)=>{
            noInfoTotal += a.no_info;
        })
    
    return noInfoTotal;
    }

    render() {
        let user = this.props.users;
        let last = user.last_name;
        return (
            <div style={{margin: '30px 10px'}}>
                <Row gutter={16}>
                    <Col span={24}>
                        <h1 className="chr-font">Welcome {last}!</h1>
                    </Col>
                </Row>
                <br />
                <Row gutter={16}>
                    <Col span={24}>
                       {this.tilesBoard()}
                    </Col>
                </Row> 

                <Row gutter={1}>
                   
                    <Col span={6}  style={{padding:'20px', margin: '20px 20px', boxShadow: '1px 1px 2px gray'}}>
                      <Radial leoReports={this.props.leoReports} />
                        <Row>
                            <Col span={5}>
                                <div style={{borderRadius:'50px', height: '15px', width:'15px', background:'#1a3177'}}></div>
                            </Col>
                            <Col span={14}>
                                <h4>Leo</h4>
                            </Col>
                            <Col span={5}>
                                <h4>{ this.leoTotal() }</h4>
                            </Col>
                        </Row>
                      
                        <Row>
                            <Col span={5}>
                                <div style={{borderRadius:'50px',height: '15px', width:'15px', background:'#79c7e3'}}></div>
                            </Col>
                            <Col span={14}>
                                <h4>Non-leo</h4>
                            </Col>
                            <Col span={5}>
                            <h4>{ this.nonleoTotal() }</h4>
                            </Col>
                        </Row>
                      
                        <Row>
                            <Col span={5}>
                                <div style={{borderRadius:'50px',height: '15px', width:'15px', background:'#12939a'}}></div>
                            </Col>
                            <Col span={14}>
                                <h4>No-Information</h4>
                            </Col>
                            <Col span={5}>
                                <h4>{ this.noInfoTotal() }</h4>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={16}  style={{ margin: '20px 0px', boxShadow: '1px 1px 2px gray'}}>
                        <Graph  age={this.props.age} killings={this.props.killings} profile={this.props.profile}/>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <div style={{margin: '60px 20px 60px 20px'}}>
                            <h1 className="chr-font">Recently Added Case</h1>
                            <TableList 
                                data = {this.props.caseAll} 
                                users={this.props.usersAll} 
                                onCangeSorterAndFilters={this.onCangeSorterAndFilters}
                                status={this.props.status}
                                type={this.props.type}
                                filterSearchForTags={this.filterSearchForTags}
                                history = {this.props.history}
                                onChangeForState={this.onChangeForState}
                                />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state)=> {
    return {
        users: state.login.user,
        caseAll: state.case.case,
        victimAll: state.case.victimAll,
        linkTotal: state.case.links_drug_list,
        account: state.case.accountListAll,
        age: state.reports.age.data === undefined ? []: state.reports.age.data,
        usersAll: state.user.users,
        leoReports: state.reports.enforcement,
        killings: state.reports.killing.data,
        profile: state.reports.profile.data,
        status: state.case.status,
        type: state.case.type
    }
}

const mapDispatchToProps = {
        profileReports, 
        caseListArchived, 
        victimListAllArchived, 
        victimAccountListAllArchived, 
        links_drug_list_archived,
        ageReport,
        userList,
        enforcementReport, 
        killingReports,
        caseStatusList,
        caseTypeList
    }

export default connect(mapStateToProps, mapDispatchToProps)(componentName); 