import React, { Component } from 'react'

export default class IsActive extends Component {
    render() {
        let status = this.props.status;
        return (
            <div>
                {
                    status === true ?
                    <div>
                        <div style={{margin:'3px 0px', float:'left', borderRadius: '50px', height: '15px', width: '15px', background:' green'}}></div>
                        &nbsp;
                        <span>Active</span>
                    </div>
                    :
                    <div>
                       <div style={{ margin:'3px 0px', float:'left', borderRadius: '50px', height: '15px', width: '15px', background:' red'}}></div>
                       &nbsp;<span>Inactive</span>
                    </div>
                }
            </div>
        )
    }
}
