import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, Select, notification,  } from 'antd';
import { connect } from 'react-redux';
import SourceTabs from "../../../../../shared/components/addSourceTab";
import { victimActAssailant, victimActAssailantGet, assailantEdit, AssaillantTypeList } from '../../../actionCreator';

const CaseForm =  Form.create()(

  class extends Component {

    handleSubmit = (e) => {
      e.preventDefault();

      this.props.form.validateFields((err, values) => {
        values.act_id = parseInt(values.act_id);
        
        if (!err) {
         { this.props.edit == "true" ? this.props.editSave(values): this.props.onSave(values) } 
        } 
      });
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      const edit = this.props.edit;
      const assailant = this.props.assailant;
      const act_id = this.props.act_id;
      const assaillantType = this.props.assaillantType !== undefined ? this.props.assaillantType : [];
      const finalList = [];
      const active_data = assaillantType.forEach(item=>{
        if(item.is_assailant_type_active === 1 ){
            finalList.push(item);
        }
      });
      return (
        <Form onSubmit={this.handleSubmit}>

          <Row style={{padding: '20px 20px 0px 20px'}}>
             <Col span={14}>
               <h1 className="chr-font">{ edit == "true" ? "Modify Act" : " Adding Act" }</h1>
             </Col>
           {
             edit == "true" ?
             <Col span={10}>
                 <Form.Item className="form-case-add">
                    <Button className="chr-btn" type="primary" htmlType="submit" style={{margin: '0px 10px 0px 0px'}} >
                        Save
                    </Button>
                    <Button  title="back" onClick={()=>{this.props.history.push('/chr/case/victim/act/add')}} 
                    style={{  height: '40px' ,  color: ' #D1D1D1',  fontWeight: '900' , margin: '0px 10px 0px 10px'}}> 
                        Back 
                    </Button>
                   
                 </Form.Item>
             </Col>
             :
             <Col span={10}>
                 <Form.Item className="form-case-add">
                    <Button className="chr-btn" type="primary" htmlType="submit" style={{margin: '0px 20px'}}>
                         Continue
                    </Button>
                    <Button className="chr-close-btn" icon="cross" title="close" onClick={this.props.onCancel} />
                 </Form.Item>
             </Col>
            }
         </Row>
         <Row>
          <Col className="form-case-add-row"></Col>
         </Row>

      <div>

    <div style={{margin: '50px 10px', }}>
        { edit == "true" ? <div></div> : <SourceTabs history={this.props.history}/> }
        
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item >
              {getFieldDecorator('act_id', {
                rules: [{ required: false }],
                initialValue: act_id ? act_id : this.props.act_id_edit
              })(
                <Input hidden/>
              )}
            </Form.Item>
          </Col>
        </Row>

              

        <Row  style={{margin: '20px 0px 0px 0px '}}></Row>
          <Row gutter={16}>
            <Col span={7}>
             <Form.Item label="Type of Assailant:">
                {getFieldDecorator('type_of_assailant', {
                  rules: [{ required: true, message: 'Please input your fields' }],
                  initialValue: edit == "true" ? assailant.type_of_assailant : <span style={{color: "#c1c3c3"}}>Select Type of Assailant</span>
                })(

                  <Select >
                  {
                   finalList.map(e=>{
                      return <Select.Option key={e.id} value={e.assailant_type}>{e.assailant_type} </Select.Option>
                    })
                  }
                </Select>
                )}
              </Form.Item>
            </Col>

            <Col span={7}>
              <Form.Item label="Attire of Assailant:">
                {getFieldDecorator('attire_of_assailant', {
                  rules: [{ required: true, message: 'Please input your fields' }],
                  initialValue: edit == "true" ? assailant.attire_of_assailant : ""
                })(

                  <Input placeholder="Enter T-shirt, pants, cap, etc. "></Input>

                )}
              </Form.Item>
            </Col>
          </Row>

          <Row  gutter={16}>
          <Col span={12}>
            <Form.Item label="Weapons:">
              {getFieldDecorator('weapons', {
                rules: [{ required: true, message: 'Please input your fields' }],
                initialValue: edit == "true" ? assailant.weapons : []
              })(
                <Select mode="tags" placeholder="Enter T-shirt, pants, cap, etc.gs"/>
              )}
            </Form.Item>
          </Col>
        </Row>
        
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Circumstances:">
                {getFieldDecorator('circumstances', {
                  rules: [{ required: true, message: 'Please input your fields' }],
                  initialValue: edit == "true" ? assailant.circumstances : ""
                })(

                  <Input placeholder="Enter Circumstances"></Input>

                )}
              </Form.Item>
            </Col>
          </Row>

         <Row gutter={16}>
            <Col span={14}>
              <Form.Item label="Narrative:">
                {getFieldDecorator('narrative', {
                  rules: [{ required: false, message: 'Please input your fields' }],
                  initialValue: edit == "true" ? assailant.narrative : ""
                })(

                  <Input.TextArea rows={4} placeholder="Enter Narrative"></Input.TextArea>

                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={14}>
              <Form.Item label="Basis:">
                {getFieldDecorator('basis', {
                  rules: [{ required: false, message: 'Please input your fields' }],
                  initialValue: edit == "true" ? assailant.basis : ""
                })(

                  <Input.TextArea rows={4} placeholder="Enter Basis"></Input.TextArea>

                )}
              </Form.Item>
            </Col>
          </Row>

      

      </div>
    </div>
  </Form>
      );
    }
  }
)

class addVictimSource extends Component {

  componentDidMount(){
    this.props.victimActAssailantGet();
    this.props.AssaillantTypeList();
  }

  onCancel = () => {
    this.props.history.goBack();
  }

  onSave = (params)=> {
    this.props.victimActAssailant(params, this.callback);
  }

  callback = (response, error) => {
      if(response.status =='200'){
       this.props.history.push('/chr/case/victim/act/add/assailant/upload');
      }
  }

  editCallback=(response)=>{
    if(response.status =='200'){
      this.props.history.push('/chr/case/victim/act/view');
      notification.success({ message: "Modification Success!"})
    } else {
      notification.warning({ message: "Failed Modification!"})
    }
  } 

  editSave = (values)=>{
    this.props.assailantEdit(values, this.editCallback)
  }
  render(){
    const history = this.props.history;
    const act_id = sessionStorage.getItem('act_add_id_upload');
    const act_id_edit = sessionStorage.getItem('act_id');
    const edit = sessionStorage.getItem('act_edit');
    return(
        <div>
          <CaseForm
            onCancel = {this.onCancel}
            onSave = {this.onSave}
            history ={history}
            act_id = {act_id}
            edit = {edit}
            assailant = {this.props.assailant}
            editSave ={this.editSave}
            act_id_edit={act_id_edit}
            assaillantType={this.props.assaillantType}
          />
        </div>
    );
  }
}

const  mapStateToProps = (state) => {
  return {
    assailant: state.case.assailantGet,
    assaillantType: state.case.assaillantType.data
  }
}
const mapDispatchToProps = {
    victimActAssailant, victimActAssailantGet, assailantEdit, AssaillantTypeList
}

export default connect(mapStateToProps, mapDispatchToProps)(addVictimSource); 