import React from 'react';
import { Select } from 'antd';
import {axios, getAuthHeader} from '../../../../shared/utils/axios';
import { BASE_URL } from '../../../../shared/constant/url';

const Option = Select.Option;

let timeout;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
}

function fake() {
    const data = getAuthHeader();
    data.params = {
        q: value
    }
    axios(`${BASE_URL}/api/victims`, data).then(response => callback(response.data))
  }

  timeout = setTimeout(fake, 300);
}

class UserRemoteSelect extends React.Component {
  state = {
    data: [],
    value: undefined,
  };

  handleSearch = value => {
    fetch(value, data => this.setState({ data }));
  };

  handleChange = (value) => {
    const case_id = sessionStorage.getItem('case_id');
    this.setState({ value });
    this.props.history.push({ 
      pathname: "/chr/quick/add/info", 
      state: { case_id: this.props.case_id ? this.props.case_id : case_id , victim_id: value ? value : null 
    } });
  }

  render() {
    const case_id = sessionStorage.getItem('case_id');
    const options = this.state.data.data ? this.state.data.data.map(d => <Option value = {d.id} key={d.id} style={{padding: '10px', fontSize: '20px', fontWeight: 'bold'}}> {d.first_name} {d.last_name}  <span style={{margin: '0px 20px'}}> - </span>  {d.date_of_birth}  <span style={{margin: '0px 20px'}}> - </span> {d.sex} <span style={{margin: '0px 20px'}}> - </span> {d.region}, {d.district}, {d.barangay}, {d.province}</Option>) : [];
    return (
      <Select
        showSearch
        value={this.state.value} 
        placeholder="Enter the name of victim..."
        style={{ width: '70%', margin : "0px 90px", color: "#22568f", fontWeight: "bold", }}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={this.handleSearch}
        onChange={this.handleChange}
        notFoundContent={null}
        // disabled={ case_id == undefined ? this.props.case_id == null ? true : false : false }
        >

        {options}

        <Select.Option  
            onClick={()=>{  this.props.history.push({ 
            pathname: "/chr/quick/add/info", 
            state: { case_id: this.props.case_id ? this.props.case_id : case_id, victim_id: this.state.value ? this.state.value : null, victim_search: true }}); }} 
            key="0" style={{background: "lightblue" }} >

            <div style={{textAlign: "center",  }}>
                <a style={{ fontSize: "15px", color: "#22568f", fontWeight: "bold"}} > + Create Victim</a>
            </div>
        </Select.Option>

      </Select>
    );
  }
}


export default UserRemoteSelect;