import React, { Component } from 'react'
import { Table, Icon } from 'antd';

class historytable extends Component {
    constructor(props){
        super(props);

        this.columns = [
            {
                title: "Action",
                dataIndex: "action"
            },
            {
                title: "User",
                dataIndex: "email",
                key:'1'
            },
            {
                title: "User Role",
                dataIndex: "username",
                key:'2',
                render: ()=>{
                    return <span>Admin</span>
                }
            },
            {
                title: "Data and Time of Action",
                dataIndex: "created_at"
            }
        ]
    }
  render() {
    const data = this.props.account_history_list.data;
    return (
      <div style={{margin: "20px"}}>
        <div style={{margin: "40px 0px", fontSize: "20px", fontWeight: "bold" }}> 

          <a style={{color: "#22568f"}}>
            <Icon type="clock-circle" style={{fontSize: "25px"}}/> &nbsp;&nbsp;&nbsp;
              <span className="chr-font-sm">Recent Activity</span>
          </a>
        
        </div>

        <Table 
          columns={this.columns}
          dataSource={data}
        />
    </div>
    )
  }
}

export default historytable;