import { BASE_URL } from './../../shared/constant/url';
import { axios, getAuthHeader, getError } from '../../shared/utils/axios';

export const LIST = {
    CASE_START: "CASE_START",
    CASE_SUCCESS: "CASE_SUCCESS",
    CASE_FAILED: "CASE_FAILED",

    VICTIMS_START: "VICTIMS_START",
    VICTIMS_SUCCESS: "VICTIMS_SUCCESS",
    VICTIMS_FAILED: "VICTIMS_FAILED",

    ACT_START: "ACT_START",
    ACT_SUCCESS: "ACT_SUCCESS",
    ACT_FAILED: "ACT_FAILED",

    ACCOUNT_START: "ACCOUNT_START",
    ACCOUNT_SUCCESS: "ACCOUNT_SUCCESS",
    ACCOUNT_FAILED: "ACCOUNT_FAILED",

    DOCUMENTS_START: "DOCUMENTS_START",
    DOCUMENTS_SUCCESS: "DOCUMENTS_SUCCESS",
    DOCUMENTS_FAILED: "DOCUMENTS_FAILED",

}

export const caseList = (value)=> {
    return(dispatch)=>{
        dispatch({ type: LIST.CASE_START});
       
        const action = getAuthHeader();
        action.params = {
            q: value
        }

        axios.get(`${BASE_URL}/api/cases?is_archived=0`, action, getAuthHeader())
        .then(response=> dispatch({type: LIST.CASE_SUCCESS, payload: response.data}) )
        .catch(error => dispatch({type: LIST.CASE_FAILED, payload: getError(error)}))
    }
}

export const victimList = (value)=> {
    return(dispatch)=>{
        dispatch({ type: LIST.VICTIMS_START});
       
        const action = getAuthHeader();
        action.params = {
            q: value
        }

        axios.get(`${BASE_URL}/api/victims`, action, getAuthHeader())
        .then(response=> dispatch({type: LIST.VICTIMS_SUCCESS, payload: response.data}) )
        .catch(error => dispatch({type: LIST.VICTIMS_FAILED, payload: getError(error)}))
    }
}

export const actList = (value)=> {
    return(dispatch)=>{
        dispatch({ type: LIST.ACT_START});
       
        const action = getAuthHeader();
        action.params = {
            q: value
        }

        axios.get(`${BASE_URL}/api/acts`, action, getAuthHeader())
        .then(response=> dispatch({type: LIST.ACT_SUCCESS, payload: response.data}) )
        .catch(error => dispatch({type: LIST.ACT_FAILED, payload: getError(error)}))
    }
}

export const accountList = (value)=> {
    return(dispatch)=>{
        dispatch({ type: LIST.ACCOUNT_START});
       
        const action = getAuthHeader();
        action.params = {
            q: value
        }

        axios.get(`${BASE_URL}/api/accounts`, action, getAuthHeader())
        .then(response=> dispatch({type: LIST.ACCOUNT_SUCCESS, payload: response.data}) )
        .catch(error => dispatch({type: LIST.ACCOUNT_FAILED, payload: getError(error)}))
    }
}

export const documentList = (value)=> {
    return(dispatch)=>{
        dispatch({ type: LIST.DOCUMENTS_START});
       
        const action = getAuthHeader();
        action.params = {
            q: value
        }

        axios.get(`${BASE_URL}/api/documents`, action, getAuthHeader())
        .then(response=> dispatch({type: LIST.DOCUMENTS_SUCCESS, payload: response.data}) )
        .catch(error => dispatch({type: LIST.DOCUMENTS_FAILED, payload: getError(error)}))
    }
}
