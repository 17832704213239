import React, { Component } from 'react'
import _ from 'lodash';
import VictimAccount from './tabs/victimAccount';
import VictimAct from './tabs/victimAct';
import Files from './tabs/files';
import History from './tabs/history';
import Breadcrumps from '../../../../shared/components/breadcrumpsVictim';
import { 
        victimGet, 
        caseGet, 
        victimAccountList, 
        victimActList, 
        // victimFileList, 
        victimHistoryList, 
        caseListVictimModule, 
        multipleDownloadFile } from '../../../cases/actionCreator';
import { Link } from 'react-router-dom';
import { userList } from '../../../users/actionCreators';
import {  Icon, Button, Row, Col, Tabs  } from 'antd';
import { connect } from 'react-redux';

const TabPane = Tabs.TabPane;

class viewForm extends Component {

  componentDidMount(){
    this.props.victimActList();
    this.props.caseGet();
    this.props.victimGet();
    this.props.victimAccountList();
    // this.props.victimFileList();
    this.props.victimHistoryList();
    this.props.userList();
    this.props.caseListVictimModule();
  } 

  componentDidUpdate (props, states){

    // if(props.victim_files_list !== this.props.victim_files_list || props.listFilesVictim !== this.props.listFilesVictim){
    //   setTimeout(()=>{
    //    this.props.victimFileList()
    //   }, 500)
    //  }

    if(props.victimAccount !== this.props.victimAccountList){
     setTimeout(()=>{
      this.props.victimAccountList()
     }, 500)
    }

    if(props.victimAct !== this.props.victimAct){
     setTimeout(()=>{
      this.props.victimActList()
     }, 500)
    }
   
  }

  userPermission = () => { 
    const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
    if (userRole === "viewer" || userRole === "contributor" ){
          return(
            <Button 
              className="chr-font-light"
              disabled={true}
              type="primary"
              style={{ float: 'right', margin: '20px 30px', height: '40px'}} 
              icon="edit">Edit
            </Button>
          ) 
      } else {
          return (
            <Link to={{pathname: '/chr/case/add', }} >  
              <Button 
                onClick={()=>{sessionStorage.setItem('case_edit', true) }}
                type="primary" ghost
                style={{ float: 'right', margin: '20px 30px', height: '40px'}} 
                icon="edit">
                  Edit
              </Button>
          </Link>
          )
      }
  }


  CaseDetails = () =>{
    const caseData = this.props.case;
    return <div style={{width: '97%', height: '100%', boxShadow: '1px 1px 7px #D1D1D1', borderRadius: '5px', margin: '40px 20px 0px 20px ', background: 'white'}}>
      <div>
        <div style={{padding: "20px 10px"}}>
          <Icon type='folder-open' className="icon-folder" theme="filled" style={{margin: '20px 6px 0px 20px', fontSize: '25px', fontWeight: "bold", color: "#22568f" }} />
          {this.userPermission()}
          <span style={{ fontSize: '20px', fontWeight: "bold", color: "#22568f"}}> {caseData.case_identifier} </span>  
        </div>

        <div style={{margin: "20px 30px"}}>
          <Row style={{padding: "10px"}}>
            <Col span={5} className="row-label-name">
              Case Type:
            </Col>
            <Col span={5} className="row-details-name">
              {caseData.case_identifier}
            </Col>
          </Row>
          <Row style={{padding: "10px"}} className="row-label-name">
            <Col span={5}>
              Date Created:
            </Col>
            <Col span={5} className="row-details-name">
              {caseData.created_at}
            </Col>
          </Row>
        </div>

        <div style={{margin: "30px 20px"}}>
            { !caseData.case_tags ? "" :
                caseData.case_tags.map((e)=>{
                return <span key={e} className="tags-for-card">
                    {e}
                </span>
              })
            }
        </div>
      </div> 
    </div>
  }


   

    TabsPanesList = ()=>{
      const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;

      if (userRole === "viewer" || userRole === "contributor" || userRole === "editor" ) {
            return (
              <div className="card-container" style={{margin: "10px 20px"}}>
                 <Tabs type="card">
     
                   <TabPane tab="Victim's Act" key="1">
                     <VictimAct  login = {this.props.login } users={this.props.users} victimAct ={this.props.victimAct} history ={this.props.history} match={this.props.match}/>
                   </TabPane>
     
                   <TabPane tab="Victim's Account" key="2">
                     <VictimAccount login = {this.props.login } users={this.props.users} account = {this.props.victimAccount} history ={this.props.history} match={this.props.match}/>
                   </TabPane>
     
                   <TabPane tab="Files" key="3">
                      <Files multipleDownloadFile={this.props.multipleDownloadFile} login = {this.props.login } users={this.props.users} history ={this.props.history}  />
                   </TabPane>
                   
               </Tabs>
           </div>
           )
      } else {
        return (
          <div className="card-container" style={{margin: "10px 20px"}}>
             <Tabs type="card">
 
               <TabPane tab="Victim's Act" key="1">
                 <VictimAct  login = {this.props.login } users={this.props.users} victimAct ={this.props.victimAct} history ={this.props.history} match={this.props.match}/>
               </TabPane>
 
               <TabPane tab="Victim's Account" key="2">
                 <VictimAccount login = {this.props.login } users={this.props.users} account = {this.props.victimAccount} history ={this.props.history} match={this.props.match}/>
               </TabPane>
 
               <TabPane tab="Files" key="3">
                  <Files  multipleDownloadFile={this.props.multipleDownloadFile} login = {this.props.login } users={this.props.users} history ={this.props.history}  />
               </TabPane>
 
               <TabPane tab="History" key="4">
                 <History  login = {this.props.login } users={this.props.users} history ={this.props.history} victim_history_list ={this.props.victim_history_list}/>
               </TabPane>
               
           </Tabs>
       </div>
       )
      }
  }

  render() {
   
    return (
      <div  style={{display: 'flex', flexDirection: 'column'}}>
            <div><Breadcrumps history ={this.props.history} victim = {this.props.victim} match={this.props.match}/></div>
            <div >{ this.CaseDetails() } </div>
            <div>{ this.TabsPanesList() }</div>
      </div>
    )
  }
}

 const mapStateToProps = (state) => {
  return {
    case: state.case.caseGet,
    victim: state.case.victimDetails,
    victimAccount: state.case.victimAccount,
    victimAct: state.case.acts, 
    victim_files_list: state.case.victim_files_list,
    victim_history_list: state.case.victim_history_list,
    users: state.user.users,
    // listFilesVictim: state.case.listVictimModule,
    login: state.login
  }
}

 const mapDispatchToProps = {
        caseGet, 
        victimGet, 
        victimAccountList, 
        victimActList, 
        // victimFileList, 
        victimHistoryList, 
        userList, 
        caseListVictimModule, 
        multipleDownloadFile
    }
  
export default connect(mapStateToProps, mapDispatchToProps)(viewForm);