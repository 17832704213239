import React, { Component } from 'react'
import {Table, Button} from 'antd';
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    } from 'react-vis';

const data = [
    {y: 5, x: "Other State Agent"},
    {y: 10, x: "Other Non Agent"},
    {y: 15, x: "PDEA"},
    {y: 20, x: "Police"},
    {y: 25, x: "No information"},
    {y: 30, x: "Undefined"},
    {y: 35, },
    {y: 40, },
    {y: 45, },
    {y: 50, },
    {y: 55, },
    {y: 60, },
    {y: 65, },
    {y: 70, },
    {y: 75, },
    {y: 80},
    {y: 85},
    {y: 90},
    {y: 95},
    {y: 100},
  ];
  
const API_DATA = [
    {x: "Car", y: 50},
    {x: "NCR", y: 50}
]

class ChartsAndTable extends Component {
    constructor(props){
        super(props);

        this.columns = [
            {
                title: "Type of Perpetrator",
            },
            {
                title: "Total Number",
            },
            {
                title: "Percentage",
            }
           
        ]
    }
  render() {
      const perpetrator = this.props.perpetrator === undefined ? [] : this.props.perpetrator;
      console.log(perpetrator)
    return (
      <div style={{margin: '20px 60px'}}>

        <div>
            <h4>Overview Results</h4>
            <h2>Type of Perpetrator</h2>
            <h4>as of Date:</h4>
           
           <div style={{display: 'flex', flexDirection: 'columns'}}>
            <div style={{margin: '0px 20px', borderTop: '5px solid #ffa394', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: '#ffa394' , float: 'left'}}></div> 
                <h4>Drug List</h4>
                <center><h1>0</h1></center>
            </div>
            <div style={{ margin: '0px 20px', borderTop: '5px solid #ffc28d', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: '#ffc28d' , float: 'left'}}></div> 
                <h4>Surrenderee</h4>
                <center><h1>0</h1></center>
            </div>
            <div style={{margin: '0px 20px', borderTop: '5px solid #8fdbe2', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: '#8fdbe2' , float: 'left'}}></div> 
                <h4>Other Link</h4>
                <center><h1>0</h1></center>
            </div>
            <div style={{margin: '0px 20px', borderTop: '5px solid #b6e5b6', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 10px', width: '18px', height: '18px' , background: '#b6e5b6' , float: 'left'}}></div> 
                <h5>No Known/Apparent Link</h5>
                <center><h1>0</h1></center>
            </div>
            <div style={{margin: '0px 20px', borderTop: '5px solid #cbcbcb', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: '#cbcbcb' , float: 'left'}}></div> 
                <h4>No Information</h4>
                <center><h1>0</h1></center>
            </div>

            <div style={{margin: '0px 20px', borderTop: '5px solid black', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: '#cbcbcb' , float: 'left'}}></div> 
                <h4>Undefined</h4>
                <center><h1>0</h1></center>
            </div>
            </div>
        </div>

        <div style={{textAlign: 'right', margin: '30px 0px'}}>
            <Button icon="download"> Download Report</Button>
        </div>
        
        <div style={{margin: ' 30px 0px'}}>
            <XYPlot xType="ordinal" width={1000} height={500} xDistance={100}>
            <VerticalGridLines />
            <HorizontalGridLines />
            <VerticalBarSeries data={data} color= "transparent" />
            <XAxis />
            <YAxis />
            <VerticalBarSeries  color = '#ffa394' style={{width: '10px', }}/>
            <VerticalBarSeries  color = '#ffc28d' style={{width: '10px', }}/>
            <VerticalBarSeries  color = '#8fdbe2' style={{width: '10px', }}/>
            <VerticalBarSeries  color = '#b6e5b6' style={{width: '10px', }}/>
            <VerticalBarSeries  color = '#cbcbcb' style={{width: '10px', }}/>
            <VerticalBarSeries  color = '#cbcbcb' style={{width: '10px', }}/>
            </XYPlot>
        </div>

        <Table 
            columns={this.columns}
            style={{margin: '20px 0px'}}/>
      </div>
    )
  }
}

export default ChartsAndTable;