import React, { Component } from 'react'
import { connect } from 'react-redux';
import { emailList, emailUpdate } from '../actionCreator';
import { Input,  Form, Icon, Button, notification, message } from 'antd';

const WrapperForn = Form.create()(
    class extends Component {

        handleSubmit = e => {
            e.preventDefault();
            this.props.form.validateFields((err, values) => {
              if (!err) {
                this.props.emailUpdate(values)
              }
            });
          };


     render() {
       const {getFieldDecorator} = this.props.form;
    
        return (
            <Form layout="inline" onSubmit={this.handleSubmit} style={{marginTop: '40px'}}>
              <Form.Item >
                {getFieldDecorator('config_email', {
                  rules: [{ required: true, message: 'Please input your username!' }],
                  initialValue: this.props.lastData
                })(
                  <Input
                    style={{width: '500px'}}
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Email Address"
                    disabled = {this.props.update}
                  />
                )}
              </Form.Item>
             
            {
                this.props.update === false ?
              <Form.Item>
                <Button type="primary" htmlType="submit" >
                  Save
                </Button>
              </Form.Item>
              :
              <Form.Item>
                  <Button type="primary"  onClick = {()=>{ this.props.createNewEmail() }}>
                    Update
                  </Button>
              </Form.Item>
            }
            
            </Form>
        );
    }
 }
);



class TableType extends Component {

    state = {
      update: true
    }

    componentDidMount(){
      this.props.emailList();
    }

    componentDidUpdate(prevProps){
     if(prevProps.email !== this.props.email){
       this.props.emailList();
     }
    }

    lastIndex(){
      let data = this.props.email.data !== undefined ? this.props.email.data : [];
      let lastIndex = data.length - 1;
      let lastData = data[lastIndex] !== undefined ? data[lastIndex].config_email : [];

      return lastData;
    }

    createNewEmail = () => {
      this.setState({update: false})
    }

    callback = (err)=>{
      if(!err){
        notification.success({message: "Email is Updated!"})
      } 
      this.setState({ update: true})
    }

    emailUpdate = (values) => {
      this.props.emailUpdate(values, this.callback)
    }

    render() {
      const lastData = this.lastIndex();
        return (
          <div style={{padding: '0px 20px 20px 20px'}}>
              <div>
                <h1  style={{color: "#22568f", fontWeight: "bold", margin: '50px 0px'}}>Configuration: Email </h1>
                  <p>
                  Configure your Organization's email fast and easily! The email that will be provided below is the primary email of Commission on Human Rights (CHR)  Analysis Unit (AU). This will serve as the receiving end for all of the request to change the password of users. Only the admins will have the privilege to approve and change the email below.
                  </p>
                </div>

              <WrapperForn 
                lastData ={lastData}
                update = {this.state.update}
                createNewEmail={this.createNewEmail}
                emailUpdate={this.emailUpdate}
              />
         </div>
        )
    }
}

const mapStateToProps = (state) => {
  return {
    email: state.case.emailList
  }
}

const mapDispatchToProps = {
  emailList, emailUpdate
}

export default connect(mapStateToProps, mapDispatchToProps)(TableType);