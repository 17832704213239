import React, { Component } from 'react'
import { Table, Icon } from 'antd';
import Tags from '../../../shared/components/tags';
import {get} from 'lodash';

 class case_table extends Component {
   constructor(props){
    super(props);

     this.columns = [
        {
          title: 'Account Name',
          dataIndex: 'account_name',
          key: 'account_name',
          render: (record, text, index)=>{
            return <div><Icon className="case-icon-table" type="folder-open"  theme="filled"/> <span className="name_folder_icon">{record}</span></div>
          }
        },
        {
          title: 'Case Name',
          dataIndex: 'case_identifier',
          key: 'case_identifier',
        },
        {
          title: 'Victim Name',
          key: '4',
          render: (record, text)=>{
              return <div>{text.victim_first_name} {text.victim_last_name}</div>
          }
        },
        {
          title: 'Account Source Name',
          key: '6',
          render: (record, text)=>{
            return <div>{text.account_source_first_name} {text.account_source_last_name}</div>
        }
        },
        {
          title: 'Tags',
          dataIndex: 'act_tags',
          key: 'act_tags',
          render: (record, text, index)=>{
            const arr2 = text.account_tags;
            const arr1 = arr2.length;
            const arr3 = arr2.slice(0, 2);
            const id = text.id;
            return (
                    <Tags 
                      arr2={arr2} 
                      arr1={arr1} 
                      arr3={arr3} 
                      id={id} 
                    />
                  )
          }
          
        },{
            title: 'Drug Trade',
            dataIndex: 'links_to_drug_trade',
            key: 'links_to_drug_trade',
            render: (record)=>{
                return (
                  record == 1 ?
                  <div className="tiles-cards-yes">
                    Yes
                  </div>
                  :
                  <div className="tiles-cards-no">
                    No
                  </div>
                )
              }
        },{
            title: 'Date Modified',
            dataIndex: 'updated_at',
            key: 'updated_at'
        },{
          title: 'Created By:',
          dataIndex: 'created_by',
          key: 'created_by:',
          render: (record, text)=>{
            const userList = this.props.users.data == undefined ? [] : this.props.users.data;
            const findData = userList.find(e=> e.id == record);
        
            return get(findData, 'first_name') + " " + get(findData, 'last_name')
            
        }
          }
     ] 
  
  }

  searchingList(value) {
    const caseList = this.props.caseList.data;
    
    let search = caseList.forEach((item)=>{
      if(item.id === value.case_id){
        sessionStorage.setItem('case_title', item.case_identifier)
      }
    })

    return search;
  }

  render() {
    return (
      <div>
        <Table 
          style={{marginTop: '30px'}}
          columns ={this.columns}
          dataSource={this.props.data.data}
          onRowClick={ (value)=>{

            this.searchingList(value);

            this.props.history.push('/chr/case/victim/account/view'); 
            sessionStorage.setItem('victim_id', value.victim_id);
            sessionStorage.setItem('victim_title', value.victim_first_name +" "+ value.victim_last_name);
            sessionStorage.setItem('account_id', value.id);
            sessionStorage.setItem('account_title', value.account_name);
            } 
          }
        />
      </div>
    )
  }
}
 
export default case_table;