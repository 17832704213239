import React, { Component } from 'react'
import { Table, Icon, Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { AccountArchived } from '../../../../cases/actionCreator';

 class victimAccount extends Component {
     constructor(props){
         super(props);

        
         this.columns = [
            {
               title: 'Account Name',
               dataIndex: 'account_name',
               sorter: true,
               key: 'account_name',
               render: (record) =>{
                  return <div><Icon className="case-icon-table" type="folder-open"  theme="filled"/> <span className="name_folder_icon">{record}</span></div>
               }
            },
            {
               title: 'Account Source Name',
               sorter: true,
               render: (record, text) =>{
                  return <div>{get(text, 'account_source_first_name')+" "+ get(text, 'account_source_last_name')}</div>
               }
            },
            {
               title: 'Account Source Type',
               filters : [{'value': '', 'text': ''}],
               dataIndex: 'account_source_type',
               key: 'account_source_type'
            },
            {
               title: 'Date Created',
               sorter: true,
               dataIndex: 'created_at',
               key: 'created_at'
            },
            {
               title: 'Created by',
               sorter: true,
               dataIndex: 'created_by',
               key: 'created_by',
               render: (record , text, index)=> {
                  const users = this.props.users.data;
                  const findData = users == undefined ? "" : users.find(e => e.id == record);
 
                   var user = findData.first_name +" "+ findData.last_name;
                   return <span>{user}</span>
                 }
            },
            {
               title: '',
               dataIndex: 'action',
               key: 'action',
               render: (record, text, index)=>{
                  return (
                  <Checkbox 
                      onClick={(e)=> { e.stopPropagation();}}
                      onChange={(e)=>{
                          let status = e.target.checked;
                          this.AccountArchivedList(text.id, status, index) }}
                      />)
                        }
                     }
                  ];
      
            this.state = {
               data: []
            }   
         }
   
   AccountArchivedList = ( id, status, index)=>{
      let data = this.state.data;

      if (status === false) {
            data.splice(index, 1);
            this.setState({ data: data })
      } else {
            data.push({id})
      }
   }

   AccountArchived = () => {
      let dataFiles = this.state.data; 

      for (let a = 0; a < dataFiles.length; a++) {
         setTimeout(()=>{
            this.props.AccountArchived(dataFiles[a].id)
         }, 500)
      }
   }
    
   userPermission = () => { 
      const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
  
        if (userRole === "viewer"){
            return ( 
               <div style={{ textAlign: 'right'}}>
                  <Button 
                     className="chr-font-light"
                     style={{ float: 'right', height: '40px'}} 
                     disabled={true}
                     type="primary" >
                        + Add Account
                  </Button>

                  <a disabled={true} style={{float: "right", fontSize: '23px' , margin: "0px 20px"}}>
                    <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
                  </a>
           </div>
            ) 
        } else if (userRole === "contributor") { 
         return ( 
            <div style={{ textAlign: 'right'}}>
               <Link to={'/chr/case/victim/add/source'}>
                  <Button 
                     className="chr-font-light"
                     style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                     onClick={()=> {
                        sessionStorage.setItem('account_edit', false); 
                        sessionStorage.setItem('victim_module_path_act_account', this.props.history.location.pathname)}}
                     type="primary">
                     + Add Account
                  </Button>
               </Link>

               <a disabled={true} style={{ float: "right", fontSize: '23px' , margin: "0px 20px"}}>
                  <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
               </a>
        </div>
         ) 
        } else {
            return (
              <div style={{ textAlign: 'right'}}>
                  <Link to={'/chr/case/victim/add/source'}>
                     <Button 
                        className="chr-font-light"
                        style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                        onClick={()=> {
                           sessionStorage.setItem('account_edit', false); 
                           sessionStorage.setItem('victim_module_path_act_account', this.props.history.location.pathname)}}
                        type="primary">
                        + Add Account
                     </Button>
                  </Link>

                  <a onClick={()=>{ this.AccountArchived() }} style={{fontSize: '23px' , margin: "0px 20px"}}>
                     <Icon type="delete" theme="filled" style={{color: '#77B9FF', fontSize: '30px'}}/>
                  </a>  
              </div>
            )
        }
   }

   headerFiles = () =>{
      return<div style={{margin: "20px"}}>
               <div style={{margin: "40px 0px", fontSize: "20px", fontWeight: "bold" }}> 
                  <a style={{color: "#22568f"}}>
                     <Icon type="solution"/>&nbsp;&nbsp;&nbsp;
                     <span className="chr-font-sm">Victim's Account</span>
                  </a>
                     
                  {this.userPermission()}
               </div>
            </div>
   }

RowClick = (data)=>{
   this.props.history.push(`/chr/victim/view/module/account/view`);
   sessionStorage.setItem('account_id', data.id);
   sessionStorage.setItem('account_title', data.account_name);
   
}

  render() {
     const data = !this.props.account.data ? [] : this.props.account.data;
    return (
      <div>
          <div >{ this.headerFiles() } </div>
          <Table 
          columns = {this.columns}
          dataSource = {data}
          onRowClick={(data)=>{this.RowClick(data)}}
          />
      </div>
    )
  }
}

const mapDispatchToProps = {
   AccountArchived
}

export default connect(null, mapDispatchToProps)(victimAccount);