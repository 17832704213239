import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, Select, notification, Radio, DatePicker, TimePicker, AutoComplete } from 'antd';
import { connect } from 'react-redux';
import SourceTabs from "../../../../../shared/components/addSourceTab";
import { victimActAdd, victimAccountList, victimActGet, actEdit, ActTypeList } from '../../../../cases/actionCreator';
import moment from 'moment';
import { regions, provinces } from '../../../data';

const REGIONS = [
  { title: 'NCR', key:'NCR'},
  { title: 'CAR', key:'CAR'},
  { title: 'R01', key:'R01'},
  { title: 'R02', key:'R02'},
  { title: 'R03', key:'R03'},
  { title: 'R04', key:'R04'},
  { title: 'R05', key:'R05'},
  { title: 'R06', key:'R06'},
  { title: 'R07', key:'R07'},
  { title: 'R08', key:'R08'},
  { title: 'R09', key:'R09'},
  { title: 'R10', key:'R10'},
  { title: 'R11', key:'R11'},
  { title: 'R12', key:'R12'},
  { title: 'R13', key:'R13'},

]

const RadioGroup = Radio.Group;
const CaseForm =  Form.create()(

  class extends Component {

    state = {
      regionsList: regions,
      provincesHolder: [],
      provinceList: []
    }

    handleSubmit = (e) => {
      e.preventDefault();

      this.props.form.validateFields((err, values) => {
      
        // values.time_of_act =  values.time_of_act.format('hh:mm:ss A');
        values.date_of_act = moment(values.date_of_act).format('DD-MM-YYYY');
        values.time_of_act =  moment(values.time_of_act).format('hh:mm:ss A');

        // values.police_operation =  JSON.parse(values.police_operation);
        if (!err) {
          this.props.edit == "true" ? this.props.onSaveEdit(values) : this.props.onSave(values) 
        } 
      });
    }

    onWithDisabilityChange = (e) => {
        if (e.target.value == 1) {
            this.props.form.setFieldsValue({type_of_disability: ''});
        }
    }

    returnMatches = (value) => {
      let regionsList = [];
      regions.forEach((item) => {
        let equalizer = value.toLowerCase();
        let temp = item.title.toLowerCase().search(equalizer);
        
        if(temp > -1) {
          regionsList.push(item)
        }
      })

      this.setState({ regionsList })
    }

    returnMatches_province = (value) => {
      let provinceList = [];
      this.state.provincesHolder.forEach((item) => {
        let equalizer = value.toLowerCase();
        let temp = item.toLowerCase().search(equalizer);
        
        if(temp > -1) {
          provinceList.push(item)
        }
      })

      this.setState({ provinceList })
    }

    setProvinceList = (itemKey) => {
      let province = provinces.find(item => item.key === parseInt(itemKey));
      this.setState({ 
        provinceList : province.list,
        provincesHolder : province.list
      })
    }

    render() {
      const actType = this.props.actType !== undefined ? this.props.actType : [];
      const finalList = [];
      const active_data = actType.forEach(item=>{
        if(item.is_act_type_active === 1 ){
            finalList.push(item);
        }
      });
      const edit = this.props.edit;
      const { getFieldDecorator } = this.props.form;
      const act = this.props.act;
      const { regionsList, provinceList } = this.state;

      return (
        <Form onSubmit={this.handleSubmit} >
         <Row style={{padding: '20px 20px 0px 20px'}}>
             <Col span={14}>
               <h1 className="chr-font">{edit == "true" ? "Modify Act" : "Adding Act"}</h1>
             </Col>

             <Col span={10}>
                 <Form.Item className="form-case-add">
                    <Button className="chr-btn" type="primary" htmlType="submit" style={{margin: '0px 20px'}} >
                        Continue
                    </Button>
                    <Button className="chr-close-btn" icon="cross" title="close" onClick={this.props.onCancel} />
                 </Form.Item>
             </Col>
         </Row>
         <Row>
          <Col className="form-case-add-row"></Col>
         </Row>
      <div>

   

    <div style={{margin: '50px 20px', }}>
       { edit == "true" ? "" : <SourceTabs history={this.props.history}/> }
        <Row  style={{margin: '50px 0px 0px 0px '}}> </Row>
     
      {  
      sessionStorage.getItem('act_module_table') == "false"  ?
      <div></div>
      : 
      <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Account Name:">
              {getFieldDecorator('account_id', {
                rules: [{ required: true, message: 'Please input your fields' }],
                initialValue: edit == "true" ? act ? act.account_id : "" : <span style={{color: "#c1c3c3"}}>Enter Account Name</span>
                })(
                 
                 <Select >
                    {
                      this.props.accounts.data != undefined ? this.props.accounts.data.map(e=>{
                        return <Select.Option key={e.id} value={e.id}>{e.account_name} </Select.Option>
                      })
                      :
                      []
                    }
                  </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        }

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Act Name:">
                {getFieldDecorator('act_name', {
                  rules: [{ required: true, message: 'Please input your fields' }],
                  initialValue: edit == "true" ? act ? act.act_name : "" : ""
                })(

                  <Input placeholder="Type of Act "></Input>

                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Type of Act:">
                {getFieldDecorator('type_of_act', {
                  rules: [{ required: true, message: 'Please input your fields' }],
                  initialValue: edit == "true" ? act ? act.type_of_act : "" :  <span style={{color: '#c7c7cd'}}> Select Act Type</span>,
                })(

                  // <Input placeholder="Act Name"></Input>
                  <Select>
                  {
                    finalList.map(item=>{
                      return <Select.Option key={item.id} value={item.act_type}> {item.act_type}</Select.Option>
                    })
                  }
                </Select>


                )}
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Police Operation:">
                {getFieldDecorator('police_operation', {
                  rules: [{ required: true, message: 'Please input your fields' }],
                  initialValue: edit == "true" ? act ? act.police_operation : "NO" : ""
                })(
                    <RadioGroup >
                      <Radio value="NO" key="2">No</Radio>
                      <Radio value="YES" key="1">Yes</Radio>
                      <Radio value="No Information" key="3">No Information</Radio>
                    </RadioGroup>
                )}
              </Form.Item>
            </Col>
          </Row>

   
        <Row  gutter={16}>
          <Col span={5}>
            <Form.Item label="Location">
              {getFieldDecorator('region', {
                rules: [{ required: true, message: 'Please input your fields' }],
                initialValue: edit == "true" ? act ? act.region  : "" : ""
              })(

                // <Select placeholder="Select Region" >
                //     {
                //       REGIONS.map((item)=>{
                //         return <Select.Option  key={item.key} value={item.title}>{item.title}</Select.Option>
                //       })
                //     }
                // </Select>
                <AutoComplete                   
                    placeholder="Select Region"
                    onSearch={ this.returnMatches }
                    onSelect={ (a, b) => this.setProvinceList(b.key) }>

                    {
                      regionsList.map((item)=>{
                        return <AutoComplete.Option  key={item.key} value={item.value}>{item.title}</AutoComplete.Option>
                      })
                    }
               </AutoComplete>

              )}
            </Form.Item>
          </Col>
          
         <div style={{margin: "40px 0px"}}>       
            <Col span={5} >
                <Form.Item  >
                {getFieldDecorator('province', {
                    rules: [{ required: false, message: 'Please input your fields' }],
                    initialValue: edit == "true" ? act ? act.province  : "" : ""
                })(
                    // <Input placeholder="Province" />
                    <AutoComplete 
                        placeholder="Province"
                        onSearch={ this.returnMatches_province }>
                        {
                          provinceList.map((item)=>{
                            return <AutoComplete.Option  key={item} value={item}>{item}</AutoComplete.Option>
                          })
                        }
                    </AutoComplete>
                )}
                </Form.Item>
            </Col>

            <Col span={5}>
                <Form.Item >
                {getFieldDecorator('municipality', {
                    rules: [{ required: false, message: 'Please input your fields' }],
                    initialValue: edit == "true" ? act ? act.municipality  : "" : ""
                })(
                    <Input placeholder="Municipality/City" />
                )}
                </Form.Item>
            </Col>

            <Col span={5}>
                <Form.Item >
                {getFieldDecorator('barangay', {
                    rules: [{ required: false, message: 'Please input your fields' }],
                    initialValue: edit == "true" ? act ? act.barangay  : "" : ""
                })(
                    <Input placeholder="Barangay" />
                )}
                </Form.Item>
            </Col>

            <Col span={5}>
                <Form.Item >
                {getFieldDecorator('district', {
                    rules: [{ required: false, message: 'Please input your fields' }],
                    initialValue: edit == "true" ? act ? act.district  : "" : ""
                })(
                    <Input placeholder="Area/District" />
                )}
                </Form.Item>
            </Col>
         </div>
        </Row>
        
        
         <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Relative Location Act:">
                {getFieldDecorator('relative_location_act', {
                  rules: [{ required: false, message: 'Please input your fields' }],
                  intialvalue: edit == "true" ? act ? act.relative_location_act : "" : ""
                })(

                  <Input placeholder="Relative Location Act "></Input>

                )}
              </Form.Item>
            </Col>
          </Row>

        <Row  gutter={16}>
          <Col span={5}>
            <Form.Item label="Date of Act">
              {getFieldDecorator('date_of_act', {
                rules: [{ required: true, message: 'Please input your fields' }],
                initialValue: edit == "true" ? act ? moment(act.date_of_act, "DD-MM-YYYY") : "" : "",
              })(
                <DatePicker format={"DD-MM-YYYY"}/>
              )}
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item label="Time of Act">
              {getFieldDecorator('time_of_act', {
                rules: [{ required: true, message: 'Please input your fields' }],
                initialValue: edit == "true" ?act ? moment(act.time_of_act, "h:mm:ss A") : "" :  "",
              })(
                <TimePicker use12Hours format="h:mm:ss A"/>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row  gutter={16}>
          <Col span={13}>
            <Form.Item label="Act Tags">
              {getFieldDecorator('act_tags', {
                rules: [{ required: true, message: 'Please input your fields' }],
                initialValue: edit == "true" ? act ? act.act_tags : [] : [],
              })(
                <Select mode="tags" placeholder="Tags"/>
              )}
            </Form.Item>
          </Col>
        </Row>

      </div>
    </div>
  </Form>
      );
    }
  }
)

class addVictimSource extends Component {
 
  componentDidMount(){
    this.props.victimAccountList();
    this.props.victimActGet();
    this.props.ActTypeList();
  }

  onCancel = () => {
    this.props.history.goBack();
  }

  onSave = (params)=> {
    this.props.victimActAdd(params, this.callback);
  }

  callback = (response, error) => {
    if(!error){
         this.props.history.push('/chr/victim/add/act/2');
         sessionStorage.setItem('act_add_id_upload', response.data.act.id)
    }else {
      notification.warning({ message: `Please Input Account Name`})
    }
  }

  editCallback = (response)=>{
    if(response.status =='200'){
      this.props.history.push('/chr/victim/add/act/2');
    }
  }

  onSaveEdit =(values)=>{
    this.props.actEdit(values, this.editCallback)
  }

  render(){
    const edit = sessionStorage.getItem('act_edit');
    const history = this.props.history;
    return(
        <div>
            <CaseForm
              onCancel = {this.onCancel}
              onSave = {this.onSave}
              history ={history}
              accounts= {this.props.accounts}
              edit={edit}
              act={this.props.act}
              onSaveEdit ={this.onSaveEdit}
              actType = {this.props.actType}
            />
        </div>
    );
  }
}
const mapStateToProps = (state) =>{

  return {
      accounts: state.case.victimAccount,
      act: state.case.act,
      actType: state.case.actType.data
  } 
}

const mapDispatchToProps = {
  victimActAdd, victimAccountList, victimActGet, actEdit, ActTypeList
}

export default connect(mapStateToProps, mapDispatchToProps)(addVictimSource); 
