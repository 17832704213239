import React, { Component } from 'react'
import { Table, Icon, Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import {connect} from 'react-redux';
import { ActArchived, ActTypeList } from '../../../actionCreator';

 class victimAccount extends Component {
     constructor(props){
         super(props);

     this.columns = [
      {
         title: 'Act Name',
         dataIndex: 'act_name',
         sorter: true,
         key: 'act_name',
         render: (record) =>{
            return <div><Icon className="case-icon-table" type="folder-open"  theme="filled"/>  <span className="name_folder_icon">{record}</span></div>
         }
      },
      {
         title: 'Type of Act',
         dataIndex: 'type_of_act',
         filters : [{'value': '', 'text': ''}],
         filterMultiple: false,
         key: 'type_of_act'
      },
      {
         title: 'Account Name',
         dataIndex: 'account_name',
         sorter: true,
         key: 'account_name'
      },
      {
         title: 'Date Created',
         dataIndex: 'created_at',
         sorter: true,
         key: 'created_at'
      },
      {
         title: 'Created by',
         dataIndex: 'created_by',
         sorter: true,
         key: 'created_by',
         render: (record)=>{

            const findData = this.props.users.data != undefined ? this.props.users.data.find(e => e.id == record) : [];
            return <div> {get(findData, 'first_name') +" "+ get(findData, "last_name")}</div>
         }
      },
      {
         title: '', 
         dataIndex: 'action',
         key: 'action',
         render: (record, text, index)=>{
            return (
            <Checkbox 
                onClick={(e)=> { e.stopPropagation();}}
                onChange={(e)=>{
                    let status = e.target.checked;
                    this.ActArhivedList(text.id, status, index) }}
                />)
                  }
               }
            ];

      this.state = {
         data: []
      }   
   }
   


   ActArhivedList = ( id, status, index)=>{
      let data = this.state.data;

      if (status === false) {
            data.splice(index, 1);
            this.setState({ data: data })
      } else {
            data.push({id})
      }
   }

  ActArchived = () => {
      let dataFiles = this.state.data; 

      for (let a = 0; a < dataFiles.length; a++) {
         setTimeout(()=>{
            this.props.ActArchived(dataFiles[a].id)
         }, 500)
      }
   }

   headerFiles = () =>{
      const path = {
         url: this.props.history.location.pathname,
         from: "add_act"
      }
      
        return <div style={{margin: "20px"}}>
                  <div style={{margin: "40px 0px"}}> 
                    <a style={{color: "#22568f", fontSize:'20px'}}>
                        <Icon type="solution"/>&nbsp;&nbsp;&nbsp;
                        <span className="chr-font-sm">List of Acts</span>
                     </a>
                        
                        { this.userPermission() }
                       
                    </div>
                </div>
   }

      userPermission = () => { 
        const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
    
          if (userRole === "viewer"){
              return ( 
                  <div style={{ textAlign: 'right'}}>
                        <Button 
                           disabled={true}
                           className="chr-font-light"
                           style={{ float: 'right', height: '40px'}} 
                           type="primary">
                            + Add Act
                         </Button>
                     
                     <a disabled={true} style={{zIndex: 10, float: "right", fontSize: '23px' , margin: "0px 20px"}}>
                        <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
                     </a>
                  </div>
              ) 
          } else if (userRole === "contributor") {  
            return (
               <div style={{ textAlign: 'right'}}>
                      <Link to={{pathname: '/chr/case/victim/act/add'}}>
                        <Button 
                         className="chr-font-light"
                         style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                         type="primary" onClick={()=> {
                           sessionStorage.setItem('act_edit', false);
                           sessionStorage.setItem('path_add_act', this.props.history.location.pathname);
                         }}>
                            + Add Act
                         </Button>
                     </Link>

                     <a disabled={true} style={{zIndex: 10, float: "right", fontSize: '23px' , margin: "0px 20px"}}>
                        <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
                     </a>
               </div>
             )
          } else {
              return (
                <div style={{ textAlign: 'right'}}>
                      <Link to={{pathname: '/chr/case/victim/act/add'}}>
                        <Button 
                         className="chr-font-light"
                         style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                         type="primary" onClick={()=> {
                           sessionStorage.setItem('act_edit', false);
                           sessionStorage.setItem('path_add_act', this.props.history.location.pathname);
                         }}>
                            + Add Act
                         </Button>
                     </Link>

                     <a onClick={()=>{this.ActArchived()}} style={{zIndex: 10, float: "right", fontSize: '23px' , margin: "0px 20px"}}>
                        <Icon type="delete" theme="filled" style={{color: '#77B9FF', fontSize: '30px'}}/>
                     </a>
                </div>
              )
          }
      }


   RowClick = (data)=>{
      this.props.history.push('/chr/case/victim/act/view');
      sessionStorage.setItem('act_id', data.id);
      sessionStorage.setItem('act_title', data.act_name);
      sessionStorage.setItem('account_module_act', 0)
   }

   componentDidMount(){
      this.props.ActTypeList();
   }

  render() {
     const data = !this.props.victimAct ? [] : this.props.victimAct.data;
     return (
        <div>
          <div >{ this.headerFiles() } </div>
          <Table 
            columns = {this.columns}
            dataSource= {data}
            rowKey="id"
            onRowClick={this.RowClick}
          />
      </div>
    )
  }
}

const mapStateToProps = (state)=> {
   return {
      actTypeListData: state.case.actType
   }
}

const mapDispatchToProps = {
   ActArchived,
   ActTypeList,
}

export default connect(mapStateToProps, mapDispatchToProps)(victimAccount);