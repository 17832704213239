import React, { Component } from 'react'
import { Table, Icon, Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { victimAccountAct, ActArchived } from '../../../../cases/actionCreator';
import { connect } from 'react-redux';

class victimAccount extends Component {

   componentDidMount(){
     this.props.victimAccountAct()
   }

   componentDidUpdate(props, states){
      if(props.list !== this.props.list){
        setTimeout(()=>{
          this.props.victimAccountAct()
        }, 500)
      }
    }

constructor(props){
   super(props);
    
     this.columns = [
      {
         title: 'Act Name',
         dataIndex: 'act_name',
         key: 'act_name',
         sorter: true,
         render: (record) =>{
            return <div><Icon className="case-icon-table" type="folder-open"  theme="filled"/><span className="name_folder_icon">{record}</span></div>
         }
      },
      {
         title: 'Type of Act',
         dataIndex: 'type_of_act',
         filters : [{'value': '', 'text': ''}],
         key: 'type_of_act'
      },
      {
         title: 'Account Name',
         dataIndex: 'account_name',
         sorter: true,
         key: 'account_name'
      },
      {
         title: 'Date Created',
         dataIndex: 'created_at',
         sorter: true,
         key: 'created_at'
      },
      {
         title: 'Created by',
         dataIndex: 'created_by',
         sorter: true,
         key: 'created_by',
         render: (record)=>{
            const findData = this.props.users.data != undefined ? this.props.users.data.find(e => e.id == record): [];
            return <div> {get(findData, 'first_name') +" "+ get(findData, "last_name")}</div>
         }
      },
      {
         title: '',
         dataIndex: 'action',
         key: 'action',
         render: (record, text, index)=>{
            return (
            <Checkbox 
                onClick={(e)=> { e.stopPropagation();}}
                onChange={(e)=>{
                    let status = e.target.checked;
                    this.ActArhivedList(text.id, status, index) }}
                />)
                  }
               }
            ];

      this.state = {
         data: []
      }   
   }
   
   ActArhivedList = ( id, status, index)=>{
      let data = this.state.data;

      if (status === false) {
            data.splice(index, 1);
            this.setState({ data: data })
      } else {
            data.push({id})
      }
   }

  ActArchived = () => {
      let dataFiles = this.state.data; 

      for (let a = 0; a < dataFiles.length; a++) {
         setTimeout(()=>{
            this.props.ActArchived(dataFiles[a].id)
         }, 500)
      }
   }
   
      
   userPermission = () => { 
      const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;

      if (userRole === "viewer"){
            return(
            <div>
               <Button 
                  disabled={true}
                  className="chr-font-light"
                  style={{ float: 'right', height: '40px'}} 
                  type="primary">
                  + Add Act
                  </Button>
                  <a disabled={true} style={{float: "right", fontSize: '25px' , margin: "0px 20px"}}>
                     <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
                  </a>
            </div>
            ) 
      } else if (userRole === "contributor") {  
         return(
             <div>
               <Link to={'/chr/victim/add/act/1'}>
                     <Button 
                        className="chr-font-light"
                        style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                        type="primary" 
                        onClick={()=>{
                           sessionStorage.setItem('path_add_act', this.props.history.location.pathname);
                           sessionStorage.setItem('act_module_table', false);
                           sessionStorage.setItem('act_edit', false)}}>
                           + Add Act
                     </Button>
               </Link>
               <a disabled={true} style={{float: "right", fontSize: '25px' , margin: "0px 20px"}}>
                  <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
               </a>
         </div>
         )
      } else {
            return (
               <div style={{textAlign: 'right'}}>
                    <Link to={'/chr/victim/add/act/1'}>
                           <Button 
                              className="chr-font-light"
                              style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                              type="primary" 
                              onClick={()=>{
                                 sessionStorage.setItem('path_add_act', this.props.history.location.pathname);
                                 sessionStorage.setItem('act_module_table', false);
                                 sessionStorage.setItem('act_edit', false)}}>
                                 + Add Act
                           </Button>
                     </Link>
                     <a  onClick={()=>{ this.ActArchived()}} style={{float: "right", fontSize: '25px' , margin: "0px 20px"}}>
                        <Icon type="delete" theme="filled" style={{color: '#77B9FF', fontSize: '30px'}}/>
                     </a>
               </div>
            )
      }
   }


   headerFiles = () =>{
        return <div style={{margin: "20px"}}>
                  <div style={{margin: "40px 0px", fontSize: "20px", fontWeight: "bold" }}> 
                     <a style={{color: "#22568f"}}>
                        <Icon type="solution"/>&nbsp;&nbsp;&nbsp;
                        <span className="chr-font-sm">List of Acts</span>
                     </a>
                        {this.userPermission()}
                    </div>
                </div>
              }

   RowClick = (data) =>{
      sessionStorage.setItem('act_id', data.id);
      sessionStorage.setItem('act_title', data.act_name);
      sessionStorage.setItem('account_module_act', true);
      this.props.history.push('/chr/victim/view/module/act/view');
   }

  render() { 
     const data = this.props.list;
      return (
        <div>
          <div >{ this.headerFiles() } </div>
          <Table 
            columns = {this.columns}
            dataSource= {data}
            onRowClick={this.RowClick}
          />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
   return {
      list: state.case.victimActAccount.data
   }
 }
 
  const mapDispatchToProps = {
   victimAccountAct,
   ActArchived
  }
 
 export default connect(mapStateToProps, mapDispatchToProps)(victimAccount);
 
 