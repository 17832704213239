import { BASE_URL } from '../../shared/constant/url';
import { axios, getAuthHeader,  getError } from '../../shared/utils/axios';

export const TRASH = {  
    START: "TRASH_LIST_START",
    SUCCESS: "TRASH_LIST_SUCCESS",
    FAILED: "TRASH_LIST_FAILED",

} 

export const trashList = (sorters) => {
    const data =  getAuthHeader();
    data.params = {
        sorters
    }

    return (dispatch)=> {   
        dispatch({ type: TRASH.START });
        
        axios.get(`${BASE_URL}/api/archives`, data)
        .then(response => {  dispatch({ type: TRASH.SUCCESS, payload: response.data}) })
        .catch(error=> { dispatch({type: TRASH.FAILED, payload: getError(error) }) })
    }
}

export const DocumentRestored= (docs_id, callback = null)=>{
    
    console.log(docs_id)
    return()=> {
        axios.put(`${BASE_URL}/api/document/${docs_id}/restore_document`, docs_id, getAuthHeader())
        .catch(error=> { if(error) {console.log(getError(error)) } })
    }
}   
