import React, { Component } from 'react'
import { Table, Button } from 'antd';
import {
        XYPlot,
        XAxis,
        YAxis,
        VerticalGridLines,
        HorizontalGridLines,
        VerticalBarSeries } from 'react-vis';
import moment from 'moment';
import { CSVLink, CSVDownload } from "react-csv";
import { assign } from 'lodash'

const data = [
    {y: 5,  x: "CAR"},
    {y: 10, x: "NCR"},
    {y: 15, x: "R01"},
    {y: 20, x: "R02"},
    {y: 25, x: "R03"},
    {y: 30, x: "R04"},
    {y: 35, x: "R05"},
    {y: 40, x: "R06"},
    {y: 45, x: "R07"},
    {y: 50, x: "R08"},
    {y: 55, x: "R09"},
    {y: 60, x: "R010"},
    {y: 65, x: "R011"},
    {y: 70, x: "R012"},
    {y: 75, x: "R013"},
    {y: 80 },
    {y: 85 },
    {y: 90 },
    {y: 95 },
    {y: 100 },
  ];

class ChartsAndTable extends Component {
    state = {
        x: [],
        y: [],
    }

    constructor(props){
        super(props);

        this.columns = [
            {
                title: "Region",
                dataIndex: "region"
            },
            {
                title: "Total number of Victim",
                dataIndex:"victim_total"
            },
            {
                title: "Percentage",
                dataIndex:"victim_total",
                render: (record, text)=>{
                    const data = this.props.killings;
                    let total = 0;
                
                    data.forEach(function(a, b){
                        total += a.victim_total ;
                    })
                    
                    const percent = record/total;
                    const final = percent * 100;
                    const finalInt = parseInt(final);
                    return <div> { finalInt } % </div>
                }
            }
        ]
    }

  

dateNow = ()=>{
    const Date = moment().format('YYYY-MM-DD');
    return <span> {Date}</span>
}

donwloadClick = () => {
    return <CSVDownload data={this.reportMapping()} target="_blank" />
}

totalData = () => {
    let data = this.props.killings === undefined ? []: this.props.killings;
    let total = 0;

    for (let i=0; i < data.length; i++) {
        total += data[i].victim_total;
    }

    return total;
}

totalPercent = ()=>{
    let data = this.props.killings === undefined ? []: this.props.killings;
    let percent = 0;
    let total = 0;  
    let arr = 0;

    for(let i=0; i < data.length; i++){
        percent += data[i].victim_total;
        arr = percent - total;
    }
    return percent;
}


reportMapping = () => {
    let arr = this.props.killings === undefined ? [] : this.props.killings;
    let datus = [];
    
        arr.forEach((data, index)=>{
            arr.map((item, item_index)=>{
                if(index === item_index){
                    let subDivide = arr[item_index].victim_total / this.totalData();
                    let total = subDivide * 100 + '%';
                    let lastData = assign({Region: arr[item_index].region, Total: arr[item_index].victim_total, Percentage: total });
                    datus.push(lastData)
                }
            })
        })
        return datus;
    }

  render() {
    var arr = this.props.killings === undefined ? []: this.props.killings;
    var API  = arr.map(e=>{
        return { x: e.region, y: e.victim_total }
    })
   
    return (
      <div style={{margin: '20px 60px'}}>   
        <div>
            <h4>Overview Results </h4> 
            <h2>Number of Investigated Killings per Region</h2>
            <h4>as of Date: { this.dateNow() }</h4>
            <div style={{width: '18px', height: '18px' , background: '#6998dc', float: "left" }}></div> 
            <h4 style={{ margin: '0px 30px'}}> Total number of killings:  { this.totalData() }</h4>
        </div>

        <div style={{textAlign: 'right'}}>
            <Button icon="download"> 
                &nbsp;  
                <CSVLink data={this.reportMapping()} filename="Number-of-Investigatoin-killing-per-Regions.csv" onClick={() => {  this.donwloadClick(); }} > 
                    <span >Download Report</span>
                </CSVLink>
            </Button>
        </div>
        
        <div style={{margin: '30px 0px'}}>
            <XYPlot xType="ordinal" width={900} height={500} xDistance={100}>
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis />
                <YAxis />
                <VerticalBarSeries data={data} color= "transparent" />
                <VerticalBarSeries data={API} color = '#6998dc' style={{width: '20px', }}/>
            </XYPlot>
        </div>

        <Table 
            columns={this.columns}
            style={{margin: '20px 0px'}}
            dataSource={arr}
            />
      </div>
    )
  }
}

export default ChartsAndTable;