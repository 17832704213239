import { Upload, Icon, Modal, Button } from "antd";
import React from "react";
import { API_FILE_UPLOAD } from '../constant/url';

const token = localStorage.getItem('token');
const auth = { Authorization: `Bearer ${token}`};

class VersionDoucments extends React.Component {
  state = {
    previewVisible: false,
    previewImage: "",
    fileList: []
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  handleChange = ({ fileList }) => {
    this.props.onChange(fileList);
    this.setState({ fileList });
  };

  render() {
    const { fileList } = this.state;
    const uploadButton = (
        <Button>
           <Icon type="upload" /> Select File
        </Button>
    );

    if(sessionStorage.getItem('hey') === "true") {
      setTimeout(() => {
        this.setState({ fileList:  []})
      }, 3000);
    }

    return (
      <div className="clearfix">
        <Upload
          action={API_FILE_UPLOAD}
          accept= '*'     
          headers= { auth }   
          listType="picture"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}>

          { fileList.length >= 1 ? null : uploadButton }
         
        </Upload>
       
   
      </div>
    );
  }
}

export default VersionDoucments;