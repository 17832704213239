import { API_USER_LIST, API_USER_ADD, API_USER_EDIT, BASE_URL } from '../../shared/constant/url';
import { axios, getAuthHeader,  getError } from '../../shared/utils/axios';

export const USER = {  
    START: "USER_LIST_START",
    SUCCESS: "USER_LIST_SUCCESS",
    FAILED: "USER_LIST_FAILED",

    USER_ADD_START: "USER_ADD_START",
    USER_ADD_SUCCESS: "USER_ADD_SUCCESS",
    USER_ADD_FAILED: "USER_ADD_FAILED",

    USER_EDIT_START: "USER_EDIT_START",
    USER_EDIT_SUCCESS: "USER_EDIT_SUCCESS",
    USER_EDIT_FAILED: "USER_EDIT_FAILED",

    USER_LOG_START: "USER_LOG_START", 
    USER_LOG_SUCCESS: "USER_LOG_SUCESS",
    USER_LOG_FAILED: "USER_LOG_FAILED",
    
    LIST_USER_LOG_START: "LIST_USER_LOG_START", 
    LIST_USER_LOG_SUCCESS: "LIST_USER_LOG_SUCESS",
    LIST_USER_LOG_FAILED: "LIST_USER_LOG_FAILED",

    GET_LIST_USER_LOG_START: "GET_LIST_USER_LOG_START", 
    GET_LIST_USER_LOG_SUCCESS: "GET_LIST_USER_LOG_SUCESS",
    GET_LIST_USER_LOG_FAILED: "GET_LIST_USER_LOG_FAILED", 

    GET_LIST_USER_REQUEST_START: "GET_LIST_USER_REQUEST_START", 
    GET_LIST_USER_REQUEST_SUCCESS: "GET_LIST_USER_REQUEST_SUCCESS", 
    GET_LIST_USER_REQUEST_FAILED: "GET_LIST_USER_REQUEST_FAILED", 

    POSITION_LIST_START: "POSITION_LIST_START", 
    POSITION_LIST_SUCCESS: "POSITION_LIST_SUCESS",
    POSITION_LIST_FAILED: "POSITION_LIST_FAILED",

} 

export const userList= (filters, sorter) => {
    const data = getAuthHeader();
    data.params = {
        ...filters,
        ...sorter
    }

    return (dispatch)=> {   
        dispatch({ type: USER.START });
        
        axios.get(API_USER_LIST, data)
        .then(response => {  dispatch({ type: USER.SUCCESS, payload: response.data}) })
        .catch(error=> { dispatch({type: USER.FAILED, payload: getError(error) }) })
    }
}

export const userAdd = (values, callback=null) => {
    return (dispatch)=> {   
        dispatch({ type: USER.USER_ADD_START }); 
        
        axios.post(API_USER_ADD, values, getAuthHeader())
        .then(response => { callback(response, null); dispatch({ type: USER.USER_ADD_SUCCESS, payload: response.data}) })
        .catch(error=> { callback(null, getError(error)); dispatch({type: USER.USER_ADD_FAILED, payload: getError(error) }) })
    }
}

export const userEdit = (values, id, editCallback = null) => {
    return (dispatch)=> {   
        dispatch({ type: USER.USER_EDIT_START });
        
        axios.put(`${API_USER_EDIT}/${id}`, values, getAuthHeader())
        .then(response => { editCallback(response);  dispatch({ type: USER.USER_EDIT_SUCCESS, payload: response.data }) })
        .catch(error=> { dispatch({type: USER.USER_EDIT_FAILED, payload: getError(error) }) })
    }
}

export const userLog= (params) => {
    return (dispatch)=> {   
        dispatch({ type: USER.USER_LOG_START });
        
        axios.get(API_USER_LIST, {params, ...getAuthHeader() })
        .then(response => {  dispatch({ type: USER.USER_LOG_SUCESS, payload: response.data}) })
        .catch(error=> { dispatch({type: USER.USER_LOG_FAILED, payload: getError(error) }) })
    }
}

export const userLogList= (params) => {
    let user_id = sessionStorage.getItem('user_id');
    let data = getAuthHeader();
    data.params = {
        ...params
    }
    
    return (dispatch)=> {   
        dispatch({ type: USER.LIST_USER_LOG_START });

        axios.get(`${BASE_URL}/api/history?user_id=${user_id}`, data)
        .then(response => {  dispatch({ type: USER.LIST_USER_LOG_SUCCESS, payload: response.data}) })
        .catch(error=> { dispatch({type: USER.LIST_USER_LOG_FAILED, payload: getError(error) }) })
    }
}

export const userGet= () => {
    let user_id = sessionStorage.getItem('user_id')
    return (dispatch)=> {   
        dispatch({ type: USER.GET_LIST_USER_LOG_START });

        axios.get(`${BASE_URL}/api/user/${user_id}`, getAuthHeader() )
        .then(response => {  dispatch({ type: USER.GET_LIST_USER_LOG_SUCCESS, payload: response.data}) })
        .catch(error=> { dispatch({type: USER.GET_LIST_USER_LOG_FAILED, payload: getError(error) }) })
    }
}

export const userRequestList = () => {
    return(dispatch)=> {
        dispatch({ type: USER.GET_LIST_USER_REQUEST_START });

        axios.get(`${BASE_URL}/api/change_password_requests`, getAuthHeader() )
        .then(response => { dispatch({ type: USER.GET_LIST_USER_REQUEST_SUCCESS, payload: response.data }) })
        .catch(error => { dispatch({ type: USER.GET_LIST_USER_REQUEST_FAILED, payload: getError(error) }) })
    }
}

export const approveRequest = (values, successCallback = null) => {
    return() => {

        axios.post(`${BASE_URL}/api/change_password_request_approve`, values, getAuthHeader() )
        .then(response => {successCallback(response)})
        .catch(error => { if(error){ console.log(getError(error))} })
    }
}

export const declineRequest = (values) => {
    return() => {
        axios.post(`${BASE_URL}/api/change_password_request_refuse`, values, getAuthHeader() )
        .catch(error => { if(error) {console.log(getError(error))} })
    }
}


export const generateNewpassword = (values) => {
    return() => {
        axios.post(`${BASE_URL}/api/change_password`, values, getAuthHeader() )
        .catch(error => { if(error) {console.log(getError(error))} })
    }
}

export const positionList = () => {
    return(dispatch)=> {
        dispatch({ type: USER.POSITION_LIST_START });

        axios.get(`${BASE_URL}/api/positions`, getAuthHeader() )
        .then(response => { dispatch({ type: USER.POSITION_LIST_SUCCESS, payload: response.data }) })
        .catch(error => { dispatch({ type: USER.POSITION_LIST_FAILED, payload: getError(error) }) })
    }
}

