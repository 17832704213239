import React, { Component } from 'react'
import StatusTables from './components/statusTable';
import { connect } from 'react-redux';
import { caseStatusList, caseStatusAdd, configStatusEdit } from '../actionCreator';
import { userList } from '../../users/actionCreators';

class StatusTable extends Component {

componentDidMount(){
    this.props.caseStatusList();
    this.props.userList();
}

componentDidUpdate(prevProps){
    if(prevProps != this.props.status)
    this.props.caseStatusList();
}

    render() {
        return (
            <div>
                <StatusTables 
                    status = { this.props.status } 
                    caseStatusAdd = { this.props.caseStatusAdd }
                    configStatusEdit = {this.props.configStatusEdit}
                />
            </div>
        )
    }
}
 const mapStateToProps = (state) => {
    return {
        status: state.case.status.data,
    }
}

const mapDispatchToProps = {
    caseStatusList , 
    caseStatusAdd , 
    userList,
    configStatusEdit
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusTable);