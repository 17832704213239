import React, { Component } from 'react';
import {HashRouter, Switch, Route, Redirect} from 'react-router-dom';
import 'antd/dist/antd.css'; 
import '../node_modules/react-vis/dist/style.css';
import {connect} from 'react-redux';
import MainContainer from './modules/login/screens/main/main';
import DashboardPage from './modules/login/screens/landing/landingLogin';
import Password from './modules/login/screens/forgot_password/password';
import ChangePassword from  './modules/login/screens/change_password/change_passowrd';

class App extends Component {
  render() {
    return (
      <div className="App">
          <HashRouter> 
            <Switch>
                <Route path="/" exact component={MainContainer} />
                <Route path="/chr" component={DashboardPage} />
                <Route path="/password" component={Password} />
                <Route path="/change/password" component={ChangePassword} />
            </Switch>
          </HashRouter>
      </div>
    );
  }
}

const mapStateToProps=({routes})=>{
    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
