import React, { Component } from 'react'
import {Table, Button} from 'antd';
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    } from 'react-vis';
import { CSVLink, CSVDownload } from "react-csv";
import { assign } from 'lodash';
const data = [
    {y: 5, x: "CAR"},
    {y: 10, x: "NCR"},
    {y: 15, x: "R01"},
    {y: 20, x: "R02"},
    {y: 25, x: "R03"},
    {y: 30, x: "R04"},
    {y: 35, x: "R05"},
    {y: 40, x: "R06"},
    {y: 45, x: "R07"},
    {y: 50, x: "R08"},
    {y: 55, x: "R09"},
    {y: 60, x: "R010"},
    {y: 65, x: "R011"},
    {y: 70, x: "R012"},
    {y: 75, x: "R013"},
    {y: 80},
    {y: 85},
    {y: 90},
    {y: 95},
    {y: 100},
  ];
  
const API_DATA = [
    {x: "Car", y: 50},
    {x: "NCR", y: 50}
]

class ChartsAndTable extends Component {
    constructor(props){
        super(props);

        this.columns = [
            {
                title: "Region",
                dataIndex: 'region'
            },
            {
                title: "Leo",
                dataIndex: 'leo',
                key: '1'
            },
            {
                title: "Leo (%)",
                dataIndex: 'leo',
                key: '2',
                render: (record, text)=>{
                    const data = this.props.enforcement !== undefined ? this.props.enforcement : [];
                    let total = 0;
                   
                    data.forEach(function(a, b){
                        total += a.leo ;
                    })

                    const percent = record/total;
                    const final = percent * 100;
                    const finalInt = parseInt(final);
                    return <div> { finalInt } % </div>
                }
                
            },
            {
                title: "Non-Leo",
                dataIndex: 'non_leo'
            },
            {
                title: "Non-Leo (%)",
                dataIndex: 'non_leo',
                render: (record, text)=>{
                    const data = this.props.enforcement !== undefined ? this.props.enforcement : [];
                    let total = 0;
                
                    data.forEach(function(a, b){
                        total += a.non_leo ;
                    })
                    
                    const percent = record/total;
                    const final = percent * 100;
                    const finalInt = parseInt(final);
                    return <div> { finalInt } % </div>
                }
            },
            {
                title: "No Info",
                dataIndex: 'no_info'
            },
            {
                title: "No Info (%)",
                dataIndex: 'no_info',
                render: (record, text)=>{
                    const data = this.props.enforcement !== undefined ? this.props.enforcement : [];
                    let total = 0;
                
                    data.forEach(function(a, b){
                        total += a.no_info ;
                    })
                    
                    const percent = record/total;
                    const final = percent * 100;
                    const finalInt = parseInt(final);
                    return <div> { finalInt } % </div>
                }

            },
        ]
    }
componentDidMount(){
    this.reportData();
}

reportData = ()=>{
    const data = this.props.enforcement;
    let total = 0;
    let newData = [];      
    

    data.forEach(function(a,b){
        total += a.leo ;
        let percent = parseInt(data[b].leo/total * 100);
        // let pushPercent = newData.push(...data[b], {percent: percent})
        console.log(percent)

        })


    }

donwloadClick = () => {
    return <CSVDownload data={this.reportMapping()} target="_blank" />
}


LeoTotal =()=>{
    const data = this.props.enforcement !== undefined ? this.props.enforcement : [];
    let sum = 0;
   
    data.forEach(function(a, b){
        sum += a.leo ;
    })
    return sum;
}


NonLeoTotal =()=>{
    const data = this.props.enforcement !== undefined ? this.props.enforcement : [];
    let sum = 0;
   
    data.forEach(function(a, b){
        sum += a.non_leo ;
    })
    return sum;
}


NoInfoTotal =()=>{
    const data = this.props.enforcement !== undefined ? this.props.enforcement : [];
    let sum = 0;
   
    data.forEach(function(a, b){
        sum += a.no_info ;
    })

    return sum;
}

reportMapping = ()=>{
    let arr = this.props.enforcement === undefined ? [] : this.props.enforcement;
    let datus = [];
    
        arr.forEach((data, index)=>{
            arr.map((item, item_index)=>{
                if(index === item_index){
                    let Leo = arr[item_index].leo / this.LeoTotal();
                    let LeoTotal = Leo * 100 + '%';

                    let Non_leo = arr[item_index].non_leo / this.NonLeoTotal();
                    let NonLeoTotal = Non_leo * 100 + '%';

                    let No_info = arr[item_index].no_info / this.NoInfoTotal();
                    let NoInfoTotal = No_info * 100 + '%';

                    let lastData = assign({
                        Region: arr[item_index].region, 
                        Leo: arr[item_index].leo, 
                        Percentage_of_Leo: LeoTotal, 
                        Non_Leo: arr[item_index].non_leo, 
                        Percentage_of_Non_Leo: NonLeoTotal,
                        No_Information: arr[item_index].no_info, 
                        Percentage_of_No_Information: NoInfoTotal,
                    });

                    datus.push(lastData)
                }
            })
        })
    
        return datus;
    }
    
  render() {

      var API  = this.props.enforcement.map(e=>{
        return { x: e.region, y: e.non_leo || e.leo || e.non_leo }
    })

    return (
      <div style={{margin: '20px 60px'}}>

        <div>
            <h4>Overview Results</h4>
            <h2>Law Enforcement Operations vs Non-Law Enforcement Operations</h2>
            <h4>as of Date:</h4>
           
           <div style={{display: 'flex', flexDirection: 'columns'}}>
            <div style={{margin: '0px 20px', borderTop: '5px solid #ffa394', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: '#ffa394' , float: 'left'}}></div> 
                <h4>Leo</h4>
                <center><h1>{this.LeoTotal()}</h1></center>
            </div>
            <div style={{ margin: '0px 20px', borderTop: '5px solid #ffc28d', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: '#ffc28d' , float: 'left'}}></div> 
                <h4>Non-Leo</h4>
                <center><h1>{this.NonLeoTotal()}</h1></center>
            </div>
            <div style={{margin: '0px 20px', borderTop: '5px solid #cbcbcb', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: '#cbcbcb' , float: 'left'}}></div> 
                <h4>No Information</h4>
                <center><h1>{this.NoInfoTotal()}</h1></center>
            </div>
            </div>
        </div>

        <div style={{textAlign: 'right', margin: '20px 0px'}}>
            <Button icon="download"> 
                &nbsp;  
                 <CSVLink data={this.reportMapping()} filename="Law Enforcement Operations vs Non-Law Enforcement Operations.csv" onClick={() => {  this.donwloadClick(); }} > 
                     Download Report
                  </CSVLink>
            </Button>
        </div>
        
        <div style={{margin: ' 30px 0px'}}>
            <XYPlot xType="ordinal" width={900} height={500} xDistance={100}>
            <VerticalGridLines />
            <HorizontalGridLines />
            <VerticalBarSeries data={data} color= "transparent" />
            <XAxis />
            <YAxis />
            <VerticalBarSeries data={API} color = '#ffa394' style={{width: '15px', }}/>
            <VerticalBarSeries data={API} color = '#ffc28d' style={{width: '15px', }}/>
            <VerticalBarSeries data={API} color = '#cbcbcb' style={{width: '15px', }}/>
            </XYPlot>
        </div>

        <Table 
            columns={this.columns}
            dataSource={this.props.enforcement}
            style={{margin: '20px 0px'}}/>
      </div>
    )
  }
}

export default ChartsAndTable;