import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, Select, notification, Checkbox, Switch } from 'antd';
import { connect } from 'react-redux';
import SourceTabs from "../../../../shared/components/addSourceTab";
import { victimAccountAdd,  victimAccountGet, source_edit, link_edit } from '../../../cases/actionCreator';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';

const CaseForm =  Form.create()(

  class extends Component {
    state = {
      drugStatus: false,
      other: false,
      values: []
    }

    handleSubmit = (e) => {
      e.preventDefault();

       this.props.form.validateFields((err, values) => {

        var user = values.user == true ? 1 : 0;
        var pusher = values.pusher == true ? 1 : 0;
        var asset = values.asset == true ? 1 : 0;
        var drug_test = values.drug_test == true ? 1 : 0;
        var surrenderee = values.surrenderee == true ? 1 : 0;
        var ra9165 = values.ra9165 == true ? 1 : 0;
        var affiliated = values.affiliated == true ? 1 : 0;
        var affiliation = values.affiliation;
        var drug_found = values.drug_found == true ? 1 : 0;
        var others_link = values.others_link == true ? 1 : 0;
        var messenger = values.messenger == true ? 1 : 0;
        var courier = values.courier == true ? 1 : 0;
        var others = values.other_affiliation != null ? values.other_affiliation: [];
        var runner = values.runner == true ? 1 : 0;
        

        var links = _.assign({
          user: user, 
          pusher: pusher,
          asset: asset,
          drug_test: drug_test,
          surrenderee: surrenderee,
          ra9165: ra9165,
          affiliated: affiliated,
          affiliation: affiliation,
          drug_found: drug_found,
          others_link: others_link,
          others: others,
          messenger: messenger,
          courier: courier,
          runner: runner,
          // account_source_id : parseInt(this.props.source_id)
        })

        var links_edit = _.assign({
          user: user, 
          pusher: pusher,
          asset: asset,
          drug_test: drug_test,
          surrenderee: surrenderee,
          ra9165: ra9165,
          affiliated: affiliated,
          affiliation: affiliation,
          drug_found: drug_found,
          others_link: others_link,
          others: others,
          messenger: messenger,
          courier: courier,
          runner: runner,
        })

        var account_source_id =  parseInt(this.props.source_id);
        
        var edit_values = {
          ...values, account_source_id
        }
        
        if (!err) {
          if( this.props.edit == "true" ) 
          {
            this.props.editSave(edit_values);
            this.props.link_edit(links_edit);
          } else {
            this.props.onSave(values, links) ;
          }  
        } 
      });
    }

    status = (e)=>{
     const data = e.target.checked;
     this.setState({ drugStatus: data })
    }

    others = (e)=>{
      const data = e.target.checked;
      this.setState({ other: data })
     }
    
     values =(data)=>{
       this.setState({ values: data })
     }

    render() {
      const { getFieldDecorator } = this.props.form;
      const edit = this.props.edit;
      const data = this.props.account;
      const source_id = this.props.source_id;
      const links = this.props.account.link_to_drug_trade != undefined ? this.props.account.link_to_drug_trade : ""
      
      return (
        <Form onSubmit={this.handleSubmit}>

          <Row style={{padding: '20px 20px 0px 20px'}}>
             <Col span={14}>
             <h1 className="chr-font">{edit == "true" ? "Modify Account" : "Adding Account"}</h1>
             </Col>

            {
              edit == "true" ?
              <Col span={10}>
                 <Form.Item className="form-case-add">
                    <Button className="chr-btn" type="primary" htmlType="submit" style={{margin: '0px 0px'}} >
                         Save
                    </Button>
                    <Button className="chr-close-btn"  title="close" style={{margin: '0px 10px'}} onClick={()=>{this.props.history.goBack()}} > Back </Button>
                    <Button className="chr-close-btn" icon="cross" title="close" onClick={this.props.onCancel} />
                 </Form.Item>
             </Col>
             :
             <Col span={10}>
               <Form.Item className="form-case-add">
                 <Button className="chr-btn" type="primary" htmlType="submit" style={{margin: '0px 20px'}} >
                    Continue
                 </Button>
              </Form.Item>
            </Col>
          }
         </Row>

         <Row>
          <Col className="form-case-add-row"></Col>
         </Row>

         <div style={{margin: "50px 10px 0px 10px"}}> 
          { edit=="true" ? <div></div> : <SourceTabs history={this.props.history}/> }
         </div>

         <div style={{margin: '0px 20px'}}>
              {/* <Row gutter={16}>
                <Col span={8}>
                  <Form.Item >
                    {getFieldDecorator('account_source_id', {
                      rules: [{ required: false }],
                      initialValue: source_id ? source_id : null
                    })(
                    <Input hidden />
                    )}
                  </Form.Item>
                  </Col>
              </Row> */}

              <Row  style={{margin: '50px 0px 0px 0px '}} />
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Account Name ">
                      {getFieldDecorator('account_name', {
                        rules: [{ required: true, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? data ? data.account_name : "" : ""
                      })(

                        <Input placeholder="Account Source Type"></Input>

                      )}
                    </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item >
                      {getFieldDecorator('links_to_drug_trade', {
                        rules: [{ required: false, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? data ? data.links_to_drug_trade : 1 : 0,
                        valuePropName: 'checked'
                      })(
                      <Checkbox  onChange={this.status} style={{fontWeight: "bolder"}}> Link to Drugs Trade</Checkbox>
                      )}
                    </Form.Item>
                    </Col>
                </Row>

      {/* Link to drug trade */}
                {
                  this.state.drugStatus == true || edit == "true"  ? 
                <div>
                  <h1 style={{width: '100%', borderTop: '3px solid #D1D1D1'}}></h1>
                  
                <Row gutter={16}>

                  <Col span={8}>
                    <Form.Item >
                      <label>User: </label>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item >
                      {getFieldDecorator('user', {
                        rules: [{ required: false, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? links.user == 1 ? true : false : false,
                        valuePropName: edit == "true" ?  links.user ? 'checked' : 'unchecked' : 'unchecked',
                      })(

                      <Switch />

                      )}
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item >
                      <label>Surrenderee: </label>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item >
                      {getFieldDecorator('surrenderee', {
                        rules: [{ required: false, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? links.surrenderee == 1 ? true : false : false,
                        valuePropName: edit == "true" ?  links.surrenderee ? 'checked' : 'unchecked' : 'unchecked',
                      })(

                      <Switch />

                      )}
                    </Form.Item>
                  </Col>

                </Row>

                <Row gutter={16}>

                  <Col span={8}>
                    <Form.Item >
                      <label>Pusher: </label>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item >
                      {getFieldDecorator('pusher', {
                        rules: [{ required: false, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? links.pusher == 1 ? true : false : false,
                        valuePropName: edit == "true" ?  links.pusher ? 'checked' : 'unchecked' : 'unchecked',
                      })(

                      <Switch />

                      )}
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item >
                      <label>R.A. 9165: </label>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item >
                      {getFieldDecorator('ra9165', {
                        rules: [{ required: false, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? links.ra9165 == 1 ? true : false : false,
                        valuePropName: edit == "true" ?  links.ra9165 ? 'checked' : 'unchecked' : 'unchecked',
                      })(

                      <Switch />

                      )}
                    </Form.Item>
                  </Col>

                </Row>

                <Row gutter={16}>

                  <Col span={8}>
                    <Form.Item >
                      <label>Runner/Courier/Messenger: </label>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item >
                      {getFieldDecorator('runner', {
                        rules: [{ required: false, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? links.runner == 1 ? true : false : false,
                        valuePropName: edit == "true" ?  links.runner ? 'checked' : 'unchecked' : 'unchecked',
                      })(

                      <Switch />

                      )}
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item >
                      <label>Affilliated with someone who has link to drug trade: </label>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item >
                      {getFieldDecorator('affiliated', {
                        rules: [{ required: false, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? links.affiliated == 1 ? true : false : false,
                        valuePropName: edit == "true" ?  links.affiliated ? 'checked' : 'unchecked' : 'unchecked',
                      })(

                      <Switch />

                      )}
                    </Form.Item>
                  </Col>

                </Row>

                <Row gutter={16}>

                  <Col span={8}>
                    <Form.Item >
                      <label>Asset: </label>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item >
                      {getFieldDecorator('asset', {
                        rules: [{ required: false, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? links.asset == 1 ? true : false : false,
                        valuePropName: edit == "true" ?  links.asset ? 'checked' : 'unchecked' : 'unchecked',
                      })(

                      <Switch />

                      )}
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item >
                      <label>Affiliation: </label>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item >
                      {getFieldDecorator('affiliation', {
                        rules: [{ required: false, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? links.affiliation  : "",
                      })(

                      <Input placeholder="Affiliation" />

                      )}
                    </Form.Item>
                  </Col>

                </Row>

                <Row gutter={16}>

                  <Col span={8}>
                    <Form.Item >
                      <label>Inclusion in Drug Test: </label>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item >
                      {getFieldDecorator('drug_test', {
                        rules: [{ required: false, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? links.drug_test == 1 ? true : false : false,
                        valuePropName: edit == "true" ?  links.drug_test ? 'checked' : 'unchecked' : 'unchecked',
                      })(

                      <Switch />

                      )}
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item >
                      <label>Drug Found at the Scene: </label>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item >
                      {getFieldDecorator('drug_found', {
                        rules: [{ required: false, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? links.drug_found == 1 ? true : false : false,
                        valuePropName: edit == "true" ?  links.drug_found ? 'checked' : 'unchecked' : 'unchecked',
                      })(

                        <Switch />

                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item >
                      <label>Runner/Courier/Messenger: </label>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item >
                      {getFieldDecorator('courier', {
                        rules: [{ required: false, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? links.courier == 1 ? true : false : false,
                        valuePropName: edit == "true" ?  links.courier ? 'checked' : 'unchecked' : 'unchecked',       
                      })(
                        <Switch />
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item >
                      <label>Runner/Courier/Messenger: </label>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item >
                      {getFieldDecorator('messenger', {
                        rules: [{ required: false, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? links.messenger == 1 ? true : false : false,
                        valuePropName: edit == "true" ?  links.messenger ? 'checked' : 'unchecked' : 'unchecked',
                      })(
                        <Switch />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item >
                      {getFieldDecorator('others_link', {
                        rules: [{ required: false, message: 'Please input your fields' }],
                      })(

                        <Checkbox onChange={this.others} style={{fontWeight: 'bolder'}}> Others Link to Drugs Trade</Checkbox>

                      )}
                    </Form.Item>
                    </Col>
                </Row>

                        {
                          this.state.other == true &&
                          <div>
                                        
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item >
                                  {getFieldDecorator('other_affiliation', {
                                    rules: [{ required: false, message: 'Please input your fields' }],
                                    initialValue: edit == "true" ? JSON.parse(links.others) : [],
                                  })(

                                  <Select mode="tags" placeholder="Other Affilliation" onChange={this.values}/>

                                  )}
                                </Form.Item>
                                </Col>
                            </Row>
                        
                            <Row gutter={16}>
                              <Col span={8}>
                                <Form.Item >
                                  {getFieldDecorator('test', {
                                    rules: [{ required: false, message: 'Please input your fields' }],
                                  })(
                                    <ul>
                                      {
                                        this.state.values.map((e)=>{
                                          return <li key={e} > {e} </li>
                                        })
                                        }
                                    </ul>
                                  )}
                                </Form.Item>
                              </Col>
                            </Row>

                          </div>
                        }
                </div> 
                :
                <div></div>
                }

      {/* End  */}

                <Row gutter={16}>
                  <Col span={15}>
                    <Form.Item label="Basis:">
                      {getFieldDecorator('basis', {
                        rules: [{ required: true, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? data ? data.basis : "" : ""
                      })(
                        <TextArea placeholder="Enter Basis" rows={4}></TextArea>
                      )}
                    </Form.Item>
                    </Col>
                </Row>
              
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Account Tags:">
                      {getFieldDecorator('account_tags', {
                        rules: [{ required: true, message: 'Please input your fields' }],
                        initialValue: edit == "true" ? data ? data.account_tags : [] : []
                      })(
                        <Select mode="tags" placeholder="Account Tags " />
                      )}
                    </Form.Item>
                    </Col>
                </Row>
            </div>
        </Form>
      );
    }
  }
)

class addVictimSource extends Component {

  componentDidMount(){
    this.props.victimAccountGet();
  }

  onCancel = () => {
    const victim_id = sessionStorage.getItem('victim_id')
    this.props.history.push(`/chr/case/victim/view/${victim_id}`);
  }

  onSave = (params, links )=> {
    this.props.victimAccountAdd(params, links, this.callback);
  }

  callback = (response) => {
      if(response.status =='200'){
        this.props.history.push('/chr/quick/add/source/upload');
        sessionStorage.setItem('account_id', response.data.account.id)
      return;
    }
  }

  editCallback = (response)=>{
    const path = sessionStorage.getItem('path_add_act');
      
    if(response.status =='200') {
        notification.success({message: `Successfully Added` })
        this.props.history.push(path)
    } else {
      notification.warning({ message: "Failed Modification!"})
    }
  }
  
  editSave = (values)=>{
     this.props.source_edit(values, this.editCallback)
  }
  
  
  render(){
    const history = this.props.history;
    const edit = sessionStorage.getItem('account_edit');
    const account = this.props.account ;
    const source_id = sessionStorage.getItem('account_source_id');
    
    return(
        <div>
          <CaseForm
            onCancel = {this.onCancel}
            onSave = {this.onSave}
            history = {history}
            edit = {edit}
            editSave = {this.editSave}
            account = {account}
            source_id={source_id}
            // linkToDrugTradeAdd = {this.props.linkToDrugTradeAdd}
            callback = {this.callback}
            link_edit= {this.props.link_edit}
          />
        </div>
    );
  }
}


const mapStateToProps = (state)=> {
  return {
    account: state.case.accountGet
  }
}

const mapDispatchToProps = {
  victimAccountAdd, victimAccountGet, source_edit, link_edit
}

export default connect(mapStateToProps, mapDispatchToProps)(addVictimSource); 