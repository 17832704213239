import React, { Component } from 'react'
import { connect } from 'react-redux';
import { victims, caseListVictim } from './actionCreators';
import TableVictims from './components/tables';
import {userList} from '../users/actionCreators';

class VictimMain extends Component {

    componentDidMount(){
      // this.getVictimsList();
      this.props.userList();
      this.props.victims();
    }

    componentDidUpdate(prevProps){
        if(this.props.victimList === {}) {
          this.getVictimsList()
        }

        if(prevProps.victimList !== this.props.victimList){
          setTimeout(()=>{
            this.props.victims();
          }, 500)
        }
    }

    // getVictimsList = (params) => {
    //   this.props.victims(params);
    // }
    
    render() {
        return (
            <div>
                <TableVictims
                    victims = {this.props.victimList} 
                    history = {this.props.history} 
                    users={this.props.users} 
                    caseList={this.props.caseListVictim}
                    getVictimsList={this.getVictimsList}  
                    login = {this.props.login}
                  />
            </div>
        )
    }
}

const mapStateToProps = (state)=> {
    return {
      users: state.user.users,
      victimList: state.victim.victims,
      login: state.login,
    }
}

const mapDispatchToProps = {
   victims, userList, caseListVictim, 
}

export default connect(mapStateToProps, mapDispatchToProps)(VictimMain); 