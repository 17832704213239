import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, notification, message, Select  } from 'antd';
import { connect } from 'react-redux';
import { 
  caseAddDocument,
  victimAddDocument,
  AddAccountDocument,
  victimActAddDocument,
  caseList,
  victimList,
  victimActList,
  victimAccountList,
} from '../../actionCreator';
import FileUpload from '../../../../shared/components/fileUploadCase';


const CaseForm =  Form.create()(

  class extends Component {

    handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {

        let data = {
          dev_notes: { ...values.dev_notes },
          documents: [...values.documents]
        }

        if (!err) {
          this.props.onSave(data) 
          // console.log(data)
        }
      });
    }


    render() {
      let { getFieldDecorator } = this.props.form;
      let caseValidator = sessionStorage.getItem('path');
      let caseAll = this.props.caseAll.data === undefined ? [] : this.props.caseAll.data;
      let victimAll = this.props.victimAll.data === undefined ? [] : this.props.victimAll.data;
      let accountAll = this.props.accountAll.data === undefined ? [] : this.props.accountAll.data;
      let actAll = this.props.actAll.data === undefined ? [] : this.props.actAll.data;
      let states = this.props.states;

      return (
        <Form onSubmit={this.handleSubmit}>

          <Row style={{padding: '20px 20px 0px 20px'}}>
             <Col span={12}>
             <h1 className="chr-font">Upload Attachments  </h1>
             </Col> 

             <Col span={12}>
                 <Form.Item className="form-case-add">
                    <Button className="chr-btn" type="primary" htmlType="submit" style={{margin: '0px 20px'}} >
                         Save
                    </Button>
                    <Button className="chr-close-btn" icon="cross" title="close" onClick={this.props.onCancel}/>
                 </Form.Item>
             </Col>
         </Row>
         <Row>
          <Col className="form-case-add-row"></Col>
         </Row>
         <Row>
           <Col>
          <Row  style={{margin: '50px 10px'}}>
              <Col span={24}>
    
      <div style={{  display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
        {/* Form Start */}

            { caseValidator === "document" && <Row>
              <Col span={24}>
                <Form.Item label="Case:">
                  {getFieldDecorator('1', {
                    rules: [{ required: true }],
                    initialValue: <span style={{color: '#9AA0A5'}}>Please Enter Case</span>
                  })(

                    <Select 
                      style={{ width: '600px'}} 
                      onChange={(value)=>{ 
                        let type= "case";
                        sessionStorage.setItem('case_id', value)
                        this.props.onChangeStatesId(value, type) 
                        }}>
                        {
                          caseAll.map((item, index)=>{
                            return  <Select.Option value={item.id} key={item.case_identifier+"_"+index}>
                                      {item.case_identifier}
                                    </Select.Option>
                          })
                        }
                    </Select>

                  )}
                </Form.Item>
              </Col>
            </Row>    
          }

         {   states.case_id === null ? 
         <div></div> 
         :  
         <Row  >
              <Col span={24}> 
                  <Form.Item label="Victim:">
                    {getFieldDecorator('2', {
                      rules: [{ required: true }],
                      initialValue: <span style={{color: '#9AA0A5'}}>Please Select Victim</span>
                    })(

                      <Select 
                        style={{ width: '600px'}} 
                        onChange={(value)=>{ 
                          let type= "victim";
                          sessionStorage.setItem('victim_id', value)
                          this.props.onChangeStatesId(value, type) 
                          }}>
                          {
                            victimAll.map((item, index)=>{
                              return <Select.Option  value={item.id} key={item.first_name+"_"+index}>
                                           {item.first_name} &nbsp; {item.last_name}
                                      </Select.Option>
                            })
                          }
                      </Select>

                    )}
                  </Form.Item>
              </Col>
            </Row>   
          }

        {  
        states.victim_id === null ? 
         <div></div> 
         :   
         <Row  gutter={16}>
                <Col span={12}>
                  <Form.Item label="Account:">
                    {getFieldDecorator('3', {
                      rules: [{ required: true }],
                      initialValue: <span style={{color: '#9AA0A5'}}>Please Select Accout</span>
                    })(

                      <Select 
                        style={{ width: '300px'}} 
                        onChange={(value)=>{ 
                          let type= "account";
                          sessionStorage.setItem('account_id', value)
                          this.props.onChangeStatesId(value, type) 
                          }}>
                          {
                            accountAll.map((item, index)=>{
                              return <Select.Option  value={item.id} key={item.account_name+"_"+index}>
                                          {item.account_name} 
                                      </Select.Option>
                            })
                          }
                      </Select>

                    )}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Act:">
                    {getFieldDecorator('4', {
                      rules: [{ required: true }],
                      initialValue: <span style={{color: '#9AA0A5'}}>Please Select Act</span>
                    })(

                      <Select 
                        style={{ width: '300px'}} 
                        onChange={(value)=>{ 
                          let type= "act";
                          sessionStorage.setItem('act_id', value)
                          this.props.onChangeStatesId(value, type) 
                          }}>
                          {
                            actAll.map((item, index)=>{
                              return <Select.Option  value={item.id} key={item.act_name+"_"+index}>
                                        {item.act_name} 
                                    </Select.Option>
                            })
                          }
                      </Select>

                    )}
                  </Form.Item>
                </Col>
              </Row>         
             }
            

              <Row  >
                <Col span={20}>
                  <Form.Item>
                    {getFieldDecorator('dev_notes', {
                      rules: [{ required: false }],
                      initialValue: { file_metadata: null , 1: null}
                    })(
                        <Input  hidden                      
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>          
            
              <Row  >
                <Col span={20}>
                  <Form.Item >
                    {getFieldDecorator('documents', {
                      rules: [{ required: false }],
                    })(
                      <FileUpload dataID={this.props.dataID} 
                      
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              </div>
              </Col>
          </Row>
        </Col>
      </Row>
    </Form>
      );
    }
  }
)

class CaseAdd extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      case_id: null,
      victim_id: null,
      act_id: null,
      account_id: null
    }
  }
 
  onChangeStatesId = (value, type)=>{
    if(type === "case"){
      return this.setState({case_id: value})
    } else if( type === "victim"){
      return this.setState({ victim_id: value})
    } else if( type === "act") {
      return this.setState({ act_id: value})
    } else if(type === "account"){
      return this.setState({ account_id : value})
    } else {
      return ""
    }
  }

  componentDidMount(){
    this.props.caseList();
  }

  componentDidUpdate(prevProps, prevState){
    let case_id = sessionStorage.getItem('case_id');
    let victim_id = sessionStorage.getItem('victim_id');

    if(prevState.case_id !== this.state.case_id)
      setTimeout(()=>{
        this.props.victimList(case_id);
        this.props.victimAccountList(case_id, victim_id);
        this.props.victimActList(case_id, victim_id)
      }, 500)
  }

  onSave = (params)=> { 
    let case_id = this.state.case_id;
    let victim_id = this.state.victim_id;
    let account_id = this.state.account_id;
    let act_id = this.state.act_id;
    let caseValidator = sessionStorage.getItem('path');

   if(caseValidator === "case"){
    this.props.caseAddDocument(params, sessionStorage.getItem('case_id'), this.callback);
   } else {
      if(case_id !== null && victim_id !== null && act_id !== null && account_id !== null ){
        this.props.victimActAddDocument(params, this.callback)
      } else if(case_id !== null && victim_id !== null && act_id === null && account_id === null ){
        this.props.victimAddDocument(params, victim_id, this.callback);
      } else if (case_id !== null && victim_id !== null && act_id === null && account_id !== null){
        this.props.AddAccountDocument(params, account_id, this.callback);
      } else {
        this.props.caseAddDocument(params, case_id, this.callback)
      }
    }
  }

  callback = (response, error) => {
    if(!error){
      notification.success({message: `Files successfully added` })
      this.props.history.goBack();
      return;
    } else {
        notification.warning({message: `Error on file uploading`})
    }
  }

  onCancel = ()=>{
    this.props.history.goBack();
  }

  render(){
    let history = this.props.history.location;
    console.log(this.props.accou)
    return(
        <div className="contentDiv">
          <CaseForm
            onCancel = {this.onCancel}
            onSave = {this.onSave}
            backButton= {this.backButton}
            history = {history}
            caseAll = {this.props.caseAll}
            victimAll = {this.props.victimAll}
            accountAll={this.props.accountAll}
            actAll={this.props.actAll}
            states = {this.state}
            onChangeStatesId = {this.onChangeStatesId}
            
          />
        </div>
    );
  }
}

const mapStateToProps = (state)=>{
  return {
    caseAll: state.case.case,
    victimAll: state.case.ListVictim,
    accountAll: state.case.victimAccount,
    actAll: state.case.acts
  }
}

const mapDispatchToProps = {
  caseAddDocument,
  victimAddDocument,
  AddAccountDocument,
  victimActAddDocument,
  caseList,
  victimList,
  victimActList,
  victimAccountList,

}

export default connect(mapStateToProps, mapDispatchToProps)(CaseAdd); 