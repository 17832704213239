import React, { Component } from 'react'
import {Row, Col, Icon} from 'antd'

class ContributorPrivileges extends Component {
    render() {
        return (
        <div style={{margin: '20px 20px'}}>
            <Row>
                <div style={{margin: "40px 0px"}}> 
                    <a style={{color: "#22568f"}}>
                        <Icon type="solution"/>&nbsp;&nbsp;&nbsp;
                        <span className="chr-font-sm">Editor Privileges</span>
                    </a>
                </div>

                <Row style={{padding: '20px'}}>
                    <Col span={6}>
                        <h1 className="chr-font-black">Case Management</h1><br />
                        <ul>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View List Cases</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Add Case</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Modify Case</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Case</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Upload File to Case</p></li>
                        </ul>
                    </Col>
                    <Col span={6}>
                        <h1 className="chr-font-black">Victim Management</h1><br />
                        <ul>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View List of Victim</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Add Victim</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Modify Victim</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Victim</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Upload File to Victim</p></li>
                        </ul>
                    </Col>
                    <Col span={6}>
                        <h1 className="chr-font-black">Account Management</h1><br />
                        <ul>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View List of Account</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Add Account</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Modify Account</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Account</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Upload File to Acount</p></li>
                        </ul>
                    </Col>
                </Row>
            </Row>

            <Row>
                <Col className="chr-row"></Col>
            </Row><br />

            <Row>
                <Row style={{padding: '20px'}}>
                    <Col span={6}>
                        <h1 className="chr-font-black">Act Management</h1><br />
                        <ul>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View List of Acts</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Add Act</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Modify Act</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Act</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Upload File to Act</p></li>
                        </ul>
                    </Col>
                    <Col span={6}>
                        <h1 className="chr-font-black">User Management</h1><br />
                        <ul>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View List of Users</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Add User</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Modify User</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View User Logs</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Generate New Password</p></li>
                        </ul>
                    </Col>
          
                    <Col span={6}>
                        <h1 className="chr-font-black">Document Management</h1><br />
                        <ul>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View List of Documents</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Document</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Upload Document</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Download Document</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Search Document</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Archived Document</p></li>
                        </ul>
                    </Col>
                </Row>
            </Row>

            <Row>
                <Col className="chr-row"></Col>
            </Row><br />
            
            <Row style={{padding: '20px'}}>
                <Col span={6}>
                    <h1 className="chr-font-black">Archive Management</h1><br />
                    <ul>
                        <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View List of Documents</p></li>
                        <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Document</p></li>
                        <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Upload Document</p></li>
                        <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Download Document</p></li>
                        <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Search Document</p></li>
                        <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Archived Document</p></li>
                    </ul>
                </Col>
            </Row>

        </div>
        )
    }
}

export default ContributorPrivileges