import React, { Component } from 'react'
import TableUser from './components/table';
import { connect } from 'react-redux';
import { userList, userLog, positionList } from './actionCreators';
import {assign} from 'lodash';

class Users extends Component {

    componentDidMount(){
        this.props.userList();
        this.props.positionList();
    }

    userList = (params) => {
        this.props.userList(params);
    }

    sortingAndfilters = (params, sorter)=> {
        const sorters =  assign({ sort_dir: sorter.order, sort_key: sorter.columnKey });

        for(let [key, value] of Object.entries(params)) {
            if(value.length > 0) {
                params[`${key}`] = value;
            }
        }

        this.props.userList(params, sorters)
    }


    render() {
     const data = this.props.users.users.data;
        return (
            <div>
                <TableUser 
                    data = {data}
                    history ={this.props.history}
                    userList = {this.props.userList} 
                    sortingAndfilters={this.sortingAndfilters}
                    position={this.props.position}
                />
            </div>
        )
    }
}
 const mapStateToProps = (state) => {
    return {
        users: state.user,
        logs: state.logs,
        position: state.user.positionList
    }
}

const mapDispatchToProps = {
    userList, 
    userLog,
    positionList
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);