import React, { Component } from 'react'
import { Table, Icon, Button, Checkbox, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import { victimListCase, VicitimArchived } from '../actionCreators';
import moment from 'moment';
import Tags from '../../../shared/components/tags';
import Tiles from '../../../shared/components/tilesVictim';

class CaseView extends Component {
    constructor(props){
        super(props);
        
        this.state ={
            visible: false,
            ShowLocation: false,
            indexToShow: null 
        }

        this.columns = [
            {
                title: "Name",
                sorter: true,
                render: (record, text)=>{
                    const firstName = text.first_name ==null? "" : text.first_name;
                    const middleName = text.middle_name ==null ? "" : text.middle_name;
                    const lastName = text.last_name ==null? "" : text.last_name;

                    const List =  firstName + " "+ middleName+" "+ lastName;
                    return <div><Icon className="case-icon-table" type="folder-open"  theme="filled"/> <span className="name_folder_icon">{List}</span></div>
                }
            },
            {
                title: "Location", 
                dataIndex: "location",
                // sorter: true,
                filters : [{'value': '', 'text': ''}],
                width : '300px',
                render: (record, text, index)=>{
                    const region = text.region === null ? " " :  `${text.region}, `;
                    const province = text.province === null ? " " : `${text.province}, `;
                    const city = text.municipality === null ? " " :`${text.municipality}, `;
                    const barangay = text.barangay === null ? " " : `${text.barangay}, `;
                    const district = text.district === null ? " " :`${text.district} `;

                    const ListShow = region+ " " +`${province}`;
                    const ListHide = region+ " " +`${province}`+ " " +`${city}`+ " " +`${barangay}`+ " " +`${district}`;
                    const indexToShow = this.state.indexToShow;

                    return ( 
                            <div>
                             {
                                 this.state.ShowLocation == true && index == indexToShow  ?
                                 <div>
                                 { ListHide } 
                                 <Button  id="show-hide-tags" icon="small-dash" onClick={(e)=>{ e.stopPropagation(); this.setState({ShowLocation: false, indexToShow: index})}}/>
                                 </div> 
                                 :
                                 <div>
                                 { ListShow } 
                                 <Button  id="show-hide-tags" icon="small-dash" onClick={(e)=>{e.stopPropagation(); this.setState({ShowLocation: true, indexToShow: index})}}/>
                                 </div> 
                             }
                            </div>
                    
                        )
                    },

            },
            {
                title: "Tags",
                dataIndex: "victim_tags",
                width: "200px",
                filters : [{'value': '', 'text': ''}],
                render: (record, text, index)=>{
                    const arr2 = text.victim_tags;
                    const arr1 = arr2.length;
                    const arr3 = arr2.slice(0, 2);
                    const id = text.id;
                return (
                        <Tags 
                            arr2={arr2} 
                            arr1={arr1} 
                            arr3={arr3} 
                            id={id} 
                        />
                      )
               }
            },
            {
                title: "Cases",
                dataIndex: "Cases",
                // sorter: true,
                render: (text,record) => {
                    return <span>{record.cases.length}</span>
                }
            },
            {
                title: "Date Created",
                dataIndex: "created_at",
                render: (date) => moment(date).format('MMMM DD, YYYY HH:mm A'),
                sorter: true
            },
            {
                title: "Date Modified",
                dataIndex: "updated_at",
                render: (date) => moment(date).format('MMMM DD, YYYY HH:mm A'),
                sorter: true
            },
            {
                title: "Created By",
                dataIndex: "created_by",
                sorter: true,
                render: (record , text, index)=> {
                    const users = this.props.users.data;
                    const findData = users == undefined ? "" : users.find(e => e.id == record);
   
                     var user = findData.first_name +" "+ findData.last_name;
                     return <span>{user}</span>
                   }
            },
            {
                title: " ",
                dataIndex: "delete_checkbox",
                render: (record, text, index)=>{
                    return (
                    <Checkbox 
                        onClick={(e)=> { e.stopPropagation();}}
                        onChange={(e)=>{
                            let status = e.target.checked;
                            this.victimArchivedList(text.first_name, text.last_name, text.id, status, index) }}
                        />)
                }
            }
        ];

        this.state = {
            data: []
        }   
    }

    victimArchivedList = ( first, last, id, status, index)=>{
        let data = this.state.data;

        if (status === false) {
            data.splice(index, 1);
            this.setState({ data: data })
        } else {
            data.push({ first, last, id})
        }
    }
   
    VictimArchived = () => {
        let dataFiles = this.state.data; 

        for (let a = 0; a < dataFiles.length; a++) {
           setTimeout(()=>{
            this.props.VicitimArchived(dataFiles[a].id, dataFiles[a].first_name, dataFiles[a].last_name)
           }, 500)
        }
    }
    
    viewVictim = (state)=>{
        const id = state.id;
        const firstName = state.first_name ==null? "" : state.first_name;
        const middleName = state.middle_name ==null ? "" : state.middle_name;
        const lastName = state.last_name ==null? "" : state.last_name;
        const name = firstName +" "+ middleName +" "+ lastName;
        this.props.history.push({ pathname: `/chr/victim/view/module` });
        sessionStorage.setItem('victim_id', state.id);
        sessionStorage.setItem('victim_title', name);
    }

    chunkArray = (array, size)=>{
        var arrayLength = array.length;
        var tempArray = [];
        
        for (var index = 0; index < arrayLength; index += size) {
           var myChunk = array.slice(index, index+size);
           tempArray.push(myChunk);
        }
        return tempArray;
    }

    handleTableChange = (pagination, filters, sorter) => {
        let params = {
            "sort_key" : sorter.columnKey,
            "sort_dir" : sorter.order            
        }

        for(let [key, value] of Object.entries(filters)) {
            if(value.length > 0) {
                params[`${key}`] = value;
            }
        }
        
        this.props.getVictimsList(params)
    }

    userPermission = ()=>{
        const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
        const path = "/chr/victim";
        console.log(userRole)
        
        if (userRole === "viewer"){
            return(
               <div>
                <Button 
                    disabled={true}
                    icon="plus" 
                    type="default" 
                    className="chr-font-light"
                    style={{ float: 'right', margin: '20px 30px', height: '40px'}} 
                >
                    Add Victim 
                </Button>
                <a disabled={true} style={{zIndex: 10, float: "right", fontSize: '25px' , margin: "18px 5px"}}>
                    <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
                </a>
               </div>
            ) 
        } else if (userRole === "contributor") { 
            return (
                <div>
                <Link to='/chr/case/add/victim' >
                    <Button 
                        style={{zIndex: 100}}
                        onClick={()=>{ sessionStorage.setItem('victim_module_add_path', path)}}
                        icon="plus" 
                        type="primary"  
                        className="add-case" 
                        style={{ zIndex: '100', float: 'right', margin: '20px 10px', height: '40px'}}>
                        Add Victim 
                    </Button>
                </Link>

                 <a  disabled={true} style={{zIndex: 10, float: "right", fontSize: '25px' , margin: "23px 10px"}}>
                     <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
                 </a>
                </div>
             ) 
        } else {
            return (
                <div >
                    <Row>
                        <Col span={12}>
                            <h1 className="chr-font">Victims</h1>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right'}}>
                            <Link to='/chr/case/add/victim' >
                                <Button 
                                    style={{zIndex: 100}}
                                    onClick={()=>{ sessionStorage.setItem('victim_module_add_path', path)}}
                                    icon="plus" 
                                    type="primary" 
                                    className="add-case" >
                                    Add Victim 
                                </Button>
                            </Link>
                            <a onClick={()=>{this.VictimArchived()}} style={{fontSize: '25px'}}>
                                <Icon type="delete" theme="filled" style={{margin: "23px 5px", color: '#77B9FF', fontSize: '30px'}}/>
                            </a>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    render() {
        const data = this.props.victims.data;

        return (
          <div style={{margin: '30px 0px'}}>
           <h1 className="chr-font">Recent Victim</h1>
         
            <Tiles victim = {this.props.victims} history = {this.props.history}/>

              <div style={{margin: '60px 10px'}}>
                 {this.userPermission()}
                    <Table 
                        columns = {this.columns}    
                        dataSource={data}
                        rowKey="id"
                        expandedRowRender = {(value)=>{
                            const result = this.chunkArray(value.cases==undefined ? []: value.cases, 5);
                            return <div>
                                <div>
                                    {/* <hr style={{borderTop: '2px dashed #ced8e2', width: '95%'}} /> */}
                                </div>
                                {
                                    value.cases == undefined ? [] : value.cases.length > 0 
                                    ? 
                                    <Row gutter={16}>
                                        <Col span={6}>
                                        {
                                        value.cases == undefined ? []: 
                                            result[0]==undefined ? [] : result[0].map(e=>{
                                            return <div key={e.id} style={{fontSize: "15px", margin: '20px 30px'}}>
                                                    <Icon type="user-add" style={{padding: '5px', border: '2px solid #abc2d9', color: '#abc2d9', borderRadius: '20px',margin: '0px 20px'}}/>
                                                        { e.case_identifier== null ? " " : e.case_identifier } 
                                                    </div>
                                                })
                                        }
                                        </Col>

                                        <Col span={7}>
                                        {
                                        value.cases == undefined ? []: 
                                            result[1]==undefined ? [] : result[1].map(e=>{
                                            return <div key={e.id} style={{fontSize: "15px", margin: '20px 30px'}}>
                                                    <Icon type="user-add" style={{padding: '5px', border: '2px solid #abc2d9', color: '#abc2d9', borderRadius: '20px',margin: '0px 20px'}}/>
                                                        { e.case_identifier== null ? " " : e.case_identifier } 
                                                    </div>
                                                })
                                        }
                                        </Col>

                                        <Col span={7}>
                                        {
                                        value.cases == undefined ? []: 
                                            result[2] == undefined ? [] : result[2].map(e=>{
                                            return <div key={e.id} style={{fontSize: "15px", margin: '20px 30px'}}>
                                                    <Icon type="user-add" style={{padding: '5px', border: '2px solid #abc2d9', color: '#abc2d9', borderRadius: '20px',margin: '0px 20px'}}/>
                                                        { e.case_identifier== null ? " " : e.case_identifier } 
                                                    </div>
                                                })
                                        }
                                        </Col>
                                    
                                        <Col span={3}>
                                        <Button type="default" style={{margin: '20px ', color: 'blue'}} onClick ={(e)=>{e.stopPropagation(); this.viewVictim(value)}}> View All </Button >
                                        {/* (VIEW ALL) na btn lilitaw lang if more than 15 yung nakadisplay */}
                                        </Col>
                                    </Row>
                                    :
                                    <div style={{textAlign: "center"}}>
                                        <p>No Case added to the Victim.</p>
                                    </div>
                                }
                            </div>
                        }}
                        onRowClick = {this.viewVictim}
                    />    
              </div>
        </div>
    )
  }
}

const mapDispatchToProps = {
    victimListCase, 
    VicitimArchived
}

export default connect(null, mapDispatchToProps)(CaseView);
