import React, { Component } from 'react'

export default class CaseTabs extends Component {
    render() {
        var pathconsole = this.props.history;
        return (
        <div>
            {
              pathconsole == '/chr/case/add/victim/form' ? 
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <div style={backgroundTabs}> 
                    <center ><p style={{fontSize: '25px', fontWeight: 'bold 200px', margin: '5px 0px'}}>1</p></center>
                </div>
                
                <div style={lineDiv}></div>


                <div style={backgroundPlain}> 
                    <center >
                    <p style={{fontSize: '25px', fontWeight: 'bold 200px'}}>2</p></center>
                </div>
            </div>
            :
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <div style={backgroundTabs}> 
                    <center ><p style={{fontSize: '25px', fontWeight: 'bold 200px', margin: '5px 0px'}}>1</p></center>
                </div>
                
                <div style={lineDiv2}></div>


                <div style={backgroundTabs}> 
                    <center >
                        <p style={{fontSize: '25px', fontWeight: 'bold 200px', margin: '5px 0px'}}>2</p></center>
                </div>
            </div>
        
            }

            <div style={styleName}>
               <label style={ labelStyle1}> Victim Information</label>
               <label style={pathconsole == '/chr/case/add/victim/form' ? labelStyle : labelStyle1} >  Attachments</label>
            </div>

        </div>
        )
    }
}

const backgroundTabs = {
    background:' #77B9FF',
    borderRadius: '50%', 
    width: '50px', 
    height: '50px',
    color: 'white'
}

const backgroundPlain = {
    borderRadius: '50%', 
    width: '50px', 
    height: '50px', 
    border: '5px solid rgb(241 241 241)'
}

const lineDiv = {
    width: '200px',
    border: '2px solid rgb(241 241 241)',

}
const lineDiv2 = {
    width: '200px',
    border: '2px solid #bbdcff',
    
}
const styleName= {
  display: 'flex',
  justifyContent: 'center'
}

const labelStyle = {
    margin: '10px 70px'
}

const labelStyle1 = {
    margin: '10px 70px',
    color: '#77B9FF',
    fontWeight: '900'
}