import React, { Component } from 'react';
import { Table, Input, Popconfirm, Form, Icon, Button, Switch, Checkbox } from 'antd';
import Status from '../../../../shared/components/isActiveStatus';

const FormItem = Form.Item;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr  {...props}/>
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {

    getInput = () => {
        if (this.props.dataIndex === 'is_active') {
            return <Switch  />
        }
        else if (this.props.dataIndex === 'id' && this.props.record[this.props.dataIndex]) {
            return <Input disabled={true}/>
        }
        return <Input />;
    };

    getInitialValue = () => {
        if (this.props.dataIndex === 'is_active' && (this.props.record['id'])) {
            return this.props.record[this.props.dataIndex];
        }
        else if (this.props.dataIndex === 'is_active') {
            return true;
        }

        return this.props.record[this.props.dataIndex];
    }

    render() {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            text,
            record,
            index,
            valuePropName,
            ...restProps
        } = this.props;

        return (
            <EditableContext.Consumer>
                {(form) => {
                    const { getFieldDecorator } = form;
                    return (
                        <td {...restProps}>
                        { editing ? (
                            <FormItem style={{ margin: 0 }}>
                            {
                                getFieldDecorator(dataIndex, {
                                    rules: [{ required: false, message: `Please Input ${title}!` }],
                                    initialValue: this.props.dataIndex === 'is_active' ? true  :  this.getInitialValue(dataIndex),
                                    valuePropName: valuePropName,

                                })(
                                    this.getInput()
                                  )
                            }
                            </FormItem>
                        ) 
                        : restProps.children }
                        </td>
                    );
                }}
            </EditableContext.Consumer>
        );
    }
}

class ListTable extends Component {

    constructor(props) {
        super(props);

        this.state = { 
            toBeInsertedData: null, 
            editingKey: '', 
            edit_id: '',
            hideColumns: []
        };

        this.columns = [{
                title: 'Account Source Type',
                dataIndex: 'name',
                key: 'id',
                editable: true,
                width: '40%',
            },
            {
                title: 'Date Created',
                dataIndex: 'created_at',
                key: 'created_at',
                editable: false,
            },
            {
                title: 'Created By',
                dataIndex: 'created_by',
                editable: false,
                key: 'created_by',
            },
            {
                title: 'Status',
                dataIndex: 'is_active',
                key: 'is_active',
                editable: true,
                valuePropName: 'checked',
                render: (a, text) => {
                    let record = text.is_account_source_type_active;
                    let data = record === 1 ? true : false;
                   
                    return <Status   status = {data}/> ;
                }
            },
            {
                title: '',
                dataIndex: 'updated_at',
                key: 'updated_at',
                editable: false,
            },
            {
                title: ' ',
                dataIndex: 'action',
                render: (text, record) => {
                const editable = this.isEditing(record);
                return (
                    <div>
                    {editable ? (
                        <span>
                            <EditableContext.Consumer>
                                {form => (
                                <button className="link-button"
                                    onClick={() => this.save(form, record.index)}
                                    style={{ marginRight: 8 }}>
                                    <Icon type="check" style={{color: "green"}} />
                                </button>
                                )}
                            </EditableContext.Consumer>
                            <Popconfirm
                                title="Sure to cancel?"
                                onConfirm={() => this.cancel(record.index)} >
                                <button className="link-button">
                                <Icon type ="close" style={{color: "red"}}/>
                                </button>
                            </Popconfirm>
                        </span>
                    ) : (
                        <div>
                            <button className="link-button" onClick={() =>{ this.edit(record.index); 
                            this.setState({ edit_id: record.id})}}><Icon type ="edit" style={{}}
                            className="editDeleteButtons"/></button> &nbsp;&nbsp; 
                            {
                                // <a onClick={()=> this.delete(record)}><Icon type ="delete" style={{}}/></a>
                            }
                        </div>
                    )}
                    </div>
                );
                },
            },
        ];
    }

   
    isEditing = (record) => {
        return record.index === this.state.editingKey;
    };

    edit(key) {
        this.setState({ toBeInsertedData: null, editingKey: key });
    }

    save(form, key) {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            
            if (this.state.toBeInsertedData) {
                this.props.caseTypeAdd(row);
                this.setState({toBeInsertedData: null, editingKey: ''})
            } else {
                this.props.configAccountTypeEdit(row, this.state.edit_id);
                this.setState({toBeInsertedData: null, editingKey: ''})
            }
        });
    }

    reset() {
        this.setState({ toBeInsertedData: null, editingKey: '' });
    }

    cancel = (key) => {
        this.setState({ toBeInsertedData: null, editingKey: '' });
    };

    add = () => {
        const value = !this.props.status ? []: this.props.status;
        
        if (this.state.toBeInsertedData) {
            return; 
        }
        
        const keys = this.columns.map(column => column.dataIndex);
        const newObj = keys.reduce((o, key) => ({ ...o, [key]: ''}), {})
        newObj.index = value.length;
        this.setState({ toBeInsertedData: newObj, editingKey: value.length })
    }

    delete = (row) => {
        this.props.onDelete(row);
    }

    filteredColumns = () => {
        return this.columns.filter(column => !this.state.hideColumns.includes(column.key))
    }

    toggleColumn = (column) => {
        const columns = [...this.state.hideColumns];
        const index = columns.indexOf(column);
        if (index >= 0) {
            columns.splice(index, 1);
        } else {
            columns.push(column);
        }
        this.setState({ hideColumns: columns })
    }

    columnsPopOver = () => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {
              [{ title: 'CREATED', key: 'created_at' }, { title: 'MODIFIED', key: 'updated_at' }].map(column => {
                const checked = !this.state.hideColumns.includes(column.key);
                return (
                  <div key={column.key}>
                    <Checkbox
                      onChange={e => this.toggleColumn(column.key)}
                      checked={checked}
                    >
                      { column.title }
                    </Checkbox>
                  </div>
                );
              })
            }
          </div>
        );
    }

    render() {
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };

        const columns = this.filteredColumns().map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                    valuePropName: !col.valuePropName ? 'value' : col.valuePropName,
                }),
            };
        });

        var data 
        if (this.props.status) {
            data = this.props.status.slice();
            if (this.state.toBeInsertedData != null) {
                data.push(this.state.toBeInsertedData);
            }
            if (data) {
                data = data.map((item, index) => {
                    return {index: index, ...item};
                });    
            }
        }
        
        return (
            <div>
               <h1  style={{float: "left", color: "#22568f", fontWeight: "bold"}}>Configuration: Account Source Type </h1>
               <Button  style={{ margin: '25px 5px', zIndex: '2', float:'right'}} type="primary" onClick ={this.add}> + Add </Button>
             
                <Table
                    style ={{marginTop: '30px'}}
                    components={components}
                    dataSource={data}
                    columns={columns}
                    size="middle"
                    rowClassName="editable-row"
                    rowKey="index"
                />
            </div>
        );
    }
}

export default ListTable;
