import React, { Component } from 'react'
import { Table, Icon, Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import Tags from '../../../../../../shared/components/tags';
import IconType from '../../../../../../shared/components/icon_file_type';
import { DocumentArchived } from '../../../../actionCreator';
import { connect } from 'react-redux';

 class victimAccount extends Component {
     constructor(props){
         super(props);
       
         this.columns = [
            {
                title: "File Name",
                dataIndex: "file_name",
                sorter: true,
                width: '300px',
                render: (record, text, index)=>{
            
                    return <div>
                            {/* <a onClick={()=>{ this.donwload(text.file_location, text.file_type ) }}>  */}
                            <IconType file_type={text.file_type} record = {record}/>  
                            {/* </a> */}
                           </div>
                    }
            }, {
                title: "File Type",
                dataIndex: "file_type",
                filters : [{'value': '', 'text': ''}],
                sorter: true,
                render: (record)=>{
                    if (record === 'txt') {
                        return <span>Text</span>
                    } else if (record === 'xls' || record === 'xlsx') {
                        return <span>Excel</span>
                    } else if (record === "pdf") {
                        return <span>Pdf</span>
                    } else if (record === "doc" || record === "docx") {
                        return <span>Document</span>
                    } else if (record === "jpg" || record === "png" ) {
                        return <span>Image</span>
                    }else {
                        return <span>Other Document</span>
                    }
                }
            }, {
                title: "File Tags",
                dataIndex: "document_tags",
                filters : [{'value': '', 'text': ''}],
                render: (record, text, index)=>{
                    const arr2 = text.document_tags;
                    const arr1 = arr2.length;
                    const arr3 = arr2.slice(0, 2);
                    const id = text.id;
                return (
                        <Tags 
                            arr2={arr2} 
                            arr1={arr1} 
                            arr3={arr3} 
                            id={id} 
                        />
                )
            }
            }, {
                title: "Date Filed",
                dataIndex: "created_at",
                sorter: true,
            }, {
                title: "",
                dataIndex: "action",
                render: (record, text, index)=>{
                    return (
                    <Checkbox 
                        onChange={(e)=>{ 
                            let status = e.target.checked;
                            this.donwload( text.file_location, text.file_type, text.file_name, text.id, status, index) }}
                        />)
                } 
            }
        ];

        this.state = {
            data: []
        }   
    }

    donwload = ( location, type, name, id, status, index)=>{
        let data = this.state.data;

        if (status === false) {
            data.splice(index, 1);
            this.setState({ data: data })
        } else {
            data.push({location, type, name, id})
        }
    }
 
    onClickDownload = () => {
        let dataFiles = this.state.data;

        for (let a = 0; a < dataFiles.length; a++) {
            this.props.multipleDownloadFile(dataFiles[a])
        }
    }

    
    DocumentArchived = () => {
        let dataFiles = this.state.data; 

        for (let a = 0; a < dataFiles.length; a++) {
           setTimeout(()=>{
            this.props.DocumentArchived(dataFiles[a].id)
           }, 1000)
        }
    }

    headerFiles = () =>{
        return <div>
                    <a className="header-of-tabs">
                        <Icon type="link" style={{margin: "45px 0px 0px 0px", fontSize: "25px" }} />&nbsp;Attach Files
                    </a>
                    
                    { this.userPermission() }
                </div>
    }

    userPermission = () => { 
        const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
          if (userRole === "viewer"){
              return ( 
                <div style={{ textAlign: 'right'}}>
                        <Button 
                            className="chr-font-light"
                            style={{ float: 'right', height: '40px'}} 
                            disabled={true}
                            type="primary">
                            + Add Files
                        </Button>
                        
                        <a disabled={true} style={{zIndex: 10, float: "right", fontSize: '23px' , margin: "0px 20px"}}>
                            <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
                        </a>

                        <a disabled={true} >
                            <Icon type="download" style={{margin: "2px 10px",  float: 'right', color: '#C8C8C8', fontSize: '30px'}}/>
                        </a>
                </div>
              ) 
          } else if (userRole === "contributor") { 
            return ( 
                <div style={{ textAlign: 'right'}}>
                    
                        <Link to={'/chr/case/victim/act/add/assailant/upload'} >
                            <Button  
                                className="chr-font-light"
                                style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                                type="primary"
                                onClick={()=>{ 
                                    sessionStorage.setItem('path_add_act', this.props.history.location.pathname);
                                    sessionStorage.setItem('act_edit', false);
                                }}
                                >
                                + Add Files
                            </Button>
                        </Link>
                        
                        <a disabled={true} style={{zIndex: 10, float: "right", fontSize: '23px' , margin: "0px 20px"}}>
                            <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
                        </a>

                        <a disabled={true} >
                            <Icon type="download" style={{margin: "2px 10px",  float: 'right', color: '#C8C8C8', fontSize: '30px'}}/>
                        </a>
                </div>
              ) 
          } else {
              return (
                <div style={{ textAlign: 'right'}}>
                         <Link to={'/chr/case/victim/act/add/assailant/upload'} >
                            <Button  
                                className="chr-font-light"
                                style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                                type="primary"
                                onClick={()=>{ 
                                    sessionStorage.setItem('path_add_act', this.props.history.location.pathname);
                                    sessionStorage.setItem('act_edit', false);
                                }}
                                >
                                + Add Files
                            </Button>
                        </Link>
                        
                        <a onClick={()=>{ this.onClickDownload()}}>
                            <Icon  type="download"  style={{margin: "2px 10px", color: '#77B9FF', fontSize: '30px'}}/>
                        </a>

                        <a onClick={()=>{ this.DocumentArchived()}} style={{zIndex: 10, fontSize: '23px' , margin: "0px 20px"}}>
                            <Icon type="delete" theme="filled" style={{color: '#77B9FF', fontSize: '30px'}}/>
                        </a>

                </div>
              )
          }
      }

  render() {
    const data = this.props.act_files.data;
    
    return (
        <div style={{margin: '0px 20px'}}>
            <div style={{ fontSize: "20px", fontWeight: "bold" }}> 
                { this.headerFiles() }
            </div>
            <Table 
                columns = {this.columns}
                dataSource={data}
                rowKey="id"
            />
        </div>
    )
  }
}
const mapDispatchToProps = { 
    DocumentArchived
}
export default connect(null, mapDispatchToProps)(victimAccount);