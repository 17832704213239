import React, { Component, useReducer } from 'react'
import { Icon, Button, Row, Col, Tabs } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import Breadcrumbs from '../../../../../shared/components/breadcrumps';
import { 
        victimAcccountGet, 
        victimActList, 
        accountSourceList, 
        accountFileList, 
        accountHistoryList, 
        links_drug_list, 
        multipleDownloadFile } from '../../../actionCreator';
import { userList } from '../../../../users/actionCreators';
import Files from './tabs_account/files';
import History from './tabs_account/history';
import Act from '../tabs/victim_module';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

const TabPane = Tabs.TabPane;

 class viewForm extends Component {
   
  state = {
      showLinkDrug: false,
      account: true,
      status: true
  }

  componentDidMount(){
    this.props.victimAcccountGet();
    this.props.victimActList();
    this.props.accountSourceList();
    this.props.accountFileList(); 
    this.props.accountHistoryList();
    this.props.userList();
    this.props.links_drug_list();
  }

  componentDidUpdate(props, states) {
    if(props.account_files_list !== this.props.account_files_list){
      setTimeout(()=>{
        this.props.accountFileList()
      }, 500)
    }
  }

  userPermission = () => { 
    const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;

      if (userRole === "viewer" || userRole === "contributor" ){
          return(
            <Button 
              className="chr-font-light"
              disabled={true}
              type="primary"
              icon="edit" 
              style={{ float: 'right', margin: '20px 30px', height: '40px'}}    
            >
              Edit
          </Button>
          ) 
      } else {
          return (
              <Link to={'/chr/case/victim/add/source'}>
                  <Button 
                    className="chr-font-light"
                    type="primary" ghost
                    icon="edit" 
                    style={{ float: 'right', margin: '20px 30px', height: '40px'}} 
                    onClick={()=>{
                      sessionStorage.setItem('path_add_act', this.props.history.location.pathname)
                      sessionStorage.setItem('account_edit', true)}}>
                      Edit
                  </Button>
              </Link>
          )
      }
  }

  CaseDetails = () =>{
    const accountProps= this.props.accountGet;
    const account = accountProps != undefined ? accountProps : {};
    const tags = get(account, 'account_tags');
    const account_tags = tags == undefined ? [] : tags;
    const account_sourece_id = get(accountProps, 'account_source_id');
    { sessionStorage.setItem('account_source_id_edit', account_sourece_id) }
    const others = get(account.link_to_drug_trade, 'others') != undefined ? JSON.parse(get(account.link_to_drug_trade, 'others')): [];
    const link_to_drug_trade =  account.link_to_drug_trade != null ? account.link_to_drug_trade : 0 ;
    { sessionStorage.setItem('link_drug_id', link_to_drug_trade.id) }
    return (
          <div>
            <div className="main-header-account-card" style={{padding: "20px 10px"}}>  
              <div>
                <Icon type='user'  style={{margin: '20px 6px 20px 20px', fontSize: '25px', fontWeight: "900", color: "#22568f" }} />
              
                { this.userPermission() }

                <span className="chr-font-sm"> Account Information </span>  
              </div>

              <div style={{margin: "20px 20px"}}>
            
              <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                 Account Name:
                </Col>
                <Col span={5} className="row-details-name">
                 {account.account_name}
                </Col>
                <Col span={5} className="row-label-name">
                 Victim Name:
                </Col>
                <Col span={5} className="row-details-name">
                 {account.victim_first_name +" "+account.victim_last_name}
                </Col>
              </Row>

              <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                 Account Source Name:
                </Col>
                <Col span={5} className="row-details-name">
                {account.account_sources_firstname +" "+account.account_sources_lastname}
                </Col>
                <Col span={5} className="row-label-name">
                  Case Name:
                </Col>
                <Col span={5} className="row-details-name">
                 {sessionStorage.getItem('case_title')}
                </Col>
              </Row>

          
              <div style={{margin: "30px 20px"}}>
                  {
                    account_tags &&
                    account_tags.map(e=>{
                      return <span key={e} className="tags-for-card" >{e}</span>
                    })
                  }
              </div> 

            </div>

            </div> 

            <div style={this.state.showLinkDrug == true ? linkShow : linkHide}>
              <div style={{margin: '10px 0px', padding: '10px 10px'}}>
                <Row>
                  <Button 
                    icon ={this.state.showLinkDrug == false ? 'plus' : 'down'} 
                    onClick ={()=>{ this.state.showLinkDrug == true ? this.setState({ showLinkDrug: false  }) : this.setState({ showLinkDrug: true  }) }}
                    // className="button-account-card"
                    type="link" ghost
                    style={{color: '#21568E', fontWeight: '900'}}
                  >   
                    <span className="chr-font-sm"> Link to Drug Trade </span>  
                  </Button>
                </Row>
                    {
                      this.state.showLinkDrug == true && 
                    
                      <div style={{margin: "20px 20px"}}>
                        <Row style={{padding: "10px"}}>
                          <Col span={5} className="row-label-name">
                            User:
                          </Col>
                        
                          <Col span={5} >
                            {
                            link_to_drug_trade.user == 1 ?
                            <div className="tiles-cards-yes">
                              Yes
                            </div>
                            :
                            <div className="tiles-cards-no">
                              No
                            </div>
                            }
                          </Col>
          
                          <Col span={8} className="row-label-name">
                            Surrenderee:
                          </Col>
                          <Col span={5}>
                            {
                            link_to_drug_trade.surrenderee == 1 ?
                            <div className="tiles-cards-yes">
                              Yes
                            </div>
                            :
                            <div className="tiles-cards-no">
                              No
                            </div>
                            }
                          </Col>
                      </Row>

                      <Row style={{padding: "10px"}}>
                          <Col span={5} className="row-label-name">
                            Pusher:
                          </Col>
                        
                          <Col span={5}>
                            {
                              link_to_drug_trade.pusher == 1 ?
                            <div className="tiles-cards-yes">
                              Yes
                            </div>
                            :
                            <div className="tiles-cards-no">
                              No
                            </div>
                            }
                          </Col>
          
                          <Col span={8} className="row-label-name">
                            R.A 9165:
                          </Col>
                          <Col span={5}>
                            {
                              link_to_drug_trade.ra9165 == 1 ?
                            <div className="tiles-cards-yes">
                              Yes
                            </div>
                            :
                            <div className="tiles-cards-no">
                              No
                            </div>
                            }
                          </Col>
                      </Row>

                      <Row style={{padding: "10px"}} >
                          <Col span={5} className="row-label-name">
                            Runner/Courier/Messenger:
                          </Col>
                        
                          <Col span={5}>
                            {
                              link_to_drug_trade.runner == 1 ?
                            <div className="tiles-cards-yes">
                              Yes
                            </div>
                            :
                            <div className="tiles-cards-no">
                              No
                            </div>
                            }
                          </Col>
          
                          <Col span={8} className="row-label-name">
                            Affiliated with someone who has link to drug trade:
                          </Col>
                          <Col span={5}>
                            {
                              link_to_drug_trade.affiliated == 1 ?
                            <div className="tiles-cards-yes">
                              Yes
                            </div>
                            :
                            <div className="tiles-cards-no">
                              No
                            </div>
                            }
                          </Col>
                      </Row>

                      <Row style={{padding: "10px"}}>
                          <Col span={5} className="row-label-name">
                            Asset:
                          </Col>
                        
                          <Col span={5}>
                            {
                              link_to_drug_trade.asset == 1 ?
                            <div className="tiles-cards-yes">
                              Yes
                            </div>
                            :
                            <div className="tiles-cards-no">
                              No
                            </div>
                            }
                          </Col>
          
                          <Col span={8} className="row-label-name">
                            Affiliation:
                          </Col>
                          <Col span={5} className="row-details-name">
                          {
                            link_to_drug_trade.affiliation
                          }
                          </Col>
                      </Row>

                      <Row style={{padding: "10px"}}>
                          <Col span={5} className="row-label-name">
                            Inclusion in Drug Test:
                          </Col>
                        
                          <Col span={5}>
                            {
                              link_to_drug_trade.drug_test == 1?
                            <div className="tiles-cards-yes">
                              Yes
                            </div>
                            :
                            <div className="tiles-cards-no">
                              No
                            </div>
                            }
                          </Col>
          
                          <Col span={8} className="row-label-name">
                            Drugs Found at the Scene:
                          </Col>
                          <Col span={5}>
                            {
                            link_to_drug_trade.drug_found == 1 ?
                            <div className="tiles-cards-yes">
                              Yes
                            </div>
                            :
                            <div className="tiles-cards-no">
                              No
                            </div>
                            }
                          </Col>
                      </Row>

                      <Row style={{padding: "10px"}}>
                          <Col span={5} className="row-label-name">
                            Runner/Courier/Messenger:
                          </Col>
                        
                          <Col span={5}>
                            {
                              link_to_drug_trade.courier == true ?
                            <div className="tiles-cards-yes">
                              Yes
                            </div>
                            :
                            <div className="tiles-cards-no">
                              No
                            </div>
                            }
                          </Col>
          
                          <Col span={8} className="row-label-name">
                          Runner/Courier/Messenger:
                          </Col>
                          <Col span={5}>
                            {
                              link_to_drug_trade.messenger == true ?
                            <div className="tiles-cards-yes">
                              Yes
                            </div>
                            :
                            <div className="tiles-cards-no">
                              No
                            </div>
                            }
                          </Col>
                      </Row>

                      <Row>
                        <h3 style={{padding: "10px", margin: '10px 0px 0px 0px', color: "#22568f",  fontWeight: "bold"}}>Others</h3>
                      </Row> 

                      <Row style={{padding: "10px"}}>
                          <Col span={12} className="row-label-name">
                            {
                            others ? others.map(item=>{
                              return <span key={item}> {item} </span>
                            })
                            //  .reduce((prev, curr) => [prev, ', ', curr])
                            :
                            <div></div>
                            }
                          </Col>
                        
                      </Row>
                      <Row>
                        <h3 style={{padding: "10px", margin: '10px 0px 0px 0px', color: "#22568f",  fontWeight: "bold"}}>Basis</h3>
                      </Row> 


                      <Row style={{padding: "10px"}} >
                          <Col span={12} className="row-label-name">
                            {
                              account.basis
                            }
                          </Col>
                      </Row>
                    </div>
                    }
              </div>
            </div>

            <div className="main-card-holder-account">
              <div style={{margin: "10px 10px", padding: '10px 10px'}}>
                <Row >
                <Icon type='user'  style={{margin: '20px 6px 20px 20px', fontSize: '25px', fontWeight: "900", color: "#22568f" }} />
                <span className="chr-font-sm"> Account Source Information </span> 
                </Row> 

              <div style={{padding: '10px 10px'}}>
                <Row style={{padding: "10px"}}>
                  <Col span={7} className="row-label-name">
                  Account Source Name
                  </Col>
                </Row>

                <Row style={{padding: "10px"}}>
                  <Col span={5} className="row-label-name">
                  Last Name:
                  </Col>
                  <Col span={5} className="row-details-name">
                  {
                    account.account_sources_lastname
                  }
                  </Col>

                  <Col span={5} className="row-label-name">
                  Account Source Type:
                  </Col>
                  <Col span={5} className="row-details-name">
                  {
                    account.account_source_type
                  }
                  </Col>
                </Row>
                <Row style={{padding: "10px"}}>
                  <Col span={5} className="row-label-name">
                  First Name:
                  </Col>
                  <Col span={5} className="row-details-name">
                  {
                    account.account_sources_firstname
                  }
                  </Col>

                  <Col span={5} className="row-label-name">
                  Account Source Age:
                  </Col>
                  <Col span={5} className="row-details-name">
                  {
                    account.account_source_age
                  }
                  </Col>
                </Row>
                
                <Row style={{padding: "10px"}}>
                  <Col span={5} className="row-label-name">
                  Middle Name:
                  </Col>
                  <Col span={5} className="row-details-name">
                  {
                    account.account_sources_middlename
                  }
                  </Col>
                </Row>

                <Row style={{padding: "10px"}}>
                  <Col span={5} className="row-label-name">
                  Alias:
                  </Col>
                  <Col span={5} className="row-details-name">
                  {
                    account.account_sources_alias
                  }
                  </Col>
                </Row>
              </div>
              </div>
            </div>
        </div> 
        )
  }

    TabsPanesList = ()=> {
      const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
      
      if( userRole === "viewer" || userRole === "contributor" || userRole === "editor") {
        return (
          <div className="card-container" style={{margin: "10px 20px"}}>
             <Tabs type="card">
               <TabPane tab="Act" key="1">
                 <Act login={this.props.login} victimAct ={this.props.victimAct}  history ={this.props.history} users={this.props.users}/>
               </TabPane>
               <TabPane tab="Files" key="3" >
                 <Files multipleDownloadFile = {this.props.multipleDownloadFile } login={this.props.login} account_files_list={this.props.account_files_list} history={this.props.history}/>
               </TabPane>
           </Tabs>
       </div>
       )
      } else {
        return (
          <div className="card-container" style={{margin: "10px 20px"}}>
             <Tabs type="card">
               <TabPane tab="Act" key="1">
                 <Act login={this.props.login} victimAct ={this.props.victimAct}  history ={this.props.history} users={this.props.users}/>
               </TabPane>
               <TabPane tab="Files" key="3" >
                 <Files multipleDownloadFile = {this.props.multipleDownloadFile } login={this.props.login} account_files_list={this.props.account_files_list} history={this.props.history}/>
               </TabPane>
               <TabPane tab="History" key="4" >
                 <History users={this.props.users} login={this.props.login} account_history_list = {this.props.account_history_list}/>
               </TabPane>
           </Tabs>
       </div>
       )
      }
    }
   

  render() {
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}> 
            <div><Breadcrumbs history = {this.props.history} /></div>
            <div>{ this.CaseDetails() } </div>
            <div>{ this.TabsPanesList() }</div>
      </div>
    )
  }
}
 
 const mapStateToProps = (state) => {
  return {
    accountGet: state.case.actGet,
    victimAct: state.case.acts,
    accountSource: state.case.account_source,
    account_files_list: state.case.account_files_list,
    account_history_list: state.case.account_history_list,
    users: state.user.users,
    links_drug: state.case.links_drug_list,
    login: state.login,
  }
}

 const mapDispatchToProps = {
      victimAcccountGet,  
      victimActList, 
      accountSourceList, 
      accountFileList, 
      accountHistoryList, 
      userList, 
      links_drug_list, 
      multipleDownloadFile 
   }
 
 const linkShow = {
  width: '97%', 
  height: '100%', 
  boxShadow: '1px 1px 7px #D1D1D1',
  borderRadius: '5px',
  margin: '5px 20px', 
  background: 'white'
}

const linkHide = {
  width: '97%', 
  height: '50px', 
  boxShadow: '1px 1px 7px #D1D1D1', 
  borderRadius: '5px',
  margin: '5px 20px', 
  background: 'white'
}

export default connect(mapStateToProps, mapDispatchToProps)(viewForm);

