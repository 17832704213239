import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, notification, message  } from 'antd';
import { connect } from 'react-redux';
import { AddAccountDocument } from '../../../cases/actionCreator';
import FileUpload from '../../../../shared/components/fileUploadCase';
import SourceTabs from "../../../../shared/components/addSourceTab";

const CaseForm =  Form.create()(

  class extends Component {
    
    handleSubmit = (e) => {
      // e.preventDefault();
      let routeValue = e.routeValue;

      this.props.form.validateFields((err, values) => {

        if (!err) {
          this.props.onSave(values, routeValue) 
        }
      });
    }
    render() {
      const { getFieldDecorator } = this.props.form;
      
      return (
        <Form >

          <Row style={{padding: '20px 20px 0px 20px'}}>
             <Col span={12}>
                <h1 className="chr-font">Adding Account </h1>
             </Col> 

             <Col span={12}>
                 <Form.Item className="form-case-add">
           
                  <div>
                    <Button className="chr-plain-btn" type="default"   onClick={()=>{ this.handleSubmit({routeValue : "act"}) }}>
                         Save and Add Act
                         
                    </Button>
                    <Button className="chr-btn" type="primary" onClick={()=>{ this.handleSubmit({routeValue : "null"}) }} style={{margin: '0px 20px'}} >
                         Save
                    </Button>
                  </div>
                   
                   
                 </Form.Item>
             </Col>
         </Row>

         <Row>
          <Col className="form-case-add-row"></Col>
         </Row>

    <div style={{margin: '50px 10px' }}>
            { 
               this.props.files_quick_upload == true ? 
                <div></div>
                : 
                <SourceTabs history={this.props.history} />
            }

      <div style={{  display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
        {/* Form Start */}
              <Row  >
                <Col span={20}>
                  <Form.Item>
                    {getFieldDecorator('dev_notes', {
                      rules: [{ required: false }],
                      initialValue: { file_metadata: null , 1: null}
                    })(
                        <Input  hidden                      
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>          
            
              <Row  >
                <Col span={20}>
                  <Form.Item >
                    {getFieldDecorator('documents', {
                      rules: [{ required: false }],
                    })(
                      <FileUpload dataID={this.props.dataID} 
                      
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              
           </div>
        </div>
    </Form>
      );
    }
  }
)

class VictimAdd extends Component {


  onSave = (params, routeValue)=> {
    const id = sessionStorage.getItem('account_id')
  
    if(routeValue == "act"){
      this.props.AddAccountDocument(params, id, this.routeCallback);
      this.props.history.push('/chr/quick/add/mulitple/act');
      
    } else {
      this.props.AddAccountDocument(params, id, this.callback)
    }
  }

  routeCallback = ()=> { }

  onCancel =()=>{
    this.props.history.goBack();
  }

    callback = (response, error) => {
      if(!error) {
          notification.success({message: `Successfully Added` })
          this.props.history.push('/chr/case')
      } else {
        notification.success({message: `${error.message}` })
      }
    }

  render(){
    const history = this.props.history;
    const files_quick_upload = !this.props.history.location.state ? null : this.props.history.location.state.quick_add_account;
    return(
      
        <div className="contentDiv">
          <CaseForm
            onCancel = {this.onCancel}
            onSave = {this.onSave}
            backButton= {this.backButton}
            history = {history}
            files_quick_upload = {files_quick_upload}
          />
        </div>
    );
  }
}

const mapDispatchToProps = {
  AddAccountDocument
}

export default connect(null, mapDispatchToProps)(VictimAdd); 
