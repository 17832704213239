import React, {Component} from 'react';
import { Form, Icon, Button, Row, Col, Modal,  Select,  } from 'antd';
import { connect } from 'react-redux';
import { enrollCase } from '../actionCreators';

const CaseForm =  Form.create()(

  class extends Component {
   
    handleSubmit = (e) => {
      e.preventDefault();

      this.props.form.validateFields((err, values) => {
        if (!err) {
              this.props.enrollCase(values) 
        }
      });
    }
 
    render() {
        const {getFieldDecorator} = this.props.form
      return (
        <Form onSubmit={this.handleSubmit}   >
            <Row >
                <Col>
                <Form.Item>
                    { getFieldDecorator('case_id', {
                        rules: [{ required: false }],
                        initialValue: ""
                        })(
                            <Select placeholder="Enroll Case">
                                {
                                    this.props.caseList.map((e, index)=>{
                                        return <Select.Option key={e.id} value={e.id}>{e.case_identifier}</Select.Option>
                                    })
                                }
                            </Select>
                        )}
                    </Form.Item>
                
                </Col>
            </Row>
                
            <Row style={{display:"flex", justifyContent: 'flex-end'}}>
                <Col >
                    <Form.Item >
                        <Button className="chr-font-light"  type="default" onClick ={ ()=>{ this.props.onCancel() }} style={{height: "40px", margin: '0px 10px'}} >
                            Cancel 
                        </Button>
                        <Button className="chr-font-light" type="primary" htmlType="submit" style={{height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}} >
                            Save
                        </Button>
                    
                    </Form.Item>
                </Col>
            </Row>
          </Form>
      );
    }
  }
)

class UserAdd extends Component {

constructor(props){
    super(props);
    this.state = { visible: false }
}


onCancel = ()=>{
    this.setState({
        visible: false,
    });
}

onSave = (values)=>{
    this.setState({
        visible: false,
    });
}

callback = (response)=>{
  if(response.status == 200) {
    this.setState({ visible: false });
  }
}

saveEnroll = (value)=>{
  this.props.enrollCase(value, this.callback)
}

showModal = () => {
    this.setState({
      visible: true,
    });
  }

  userPermission = ()=>{
      const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;

      if (userRole === "viewer"){
          return(
            <div>
                <Button  
                  disabled={true}
                  className="chr-font-light"
                  style={{ float: 'right', height: '40px'}} 
                  type="primary" >
                  + Enroll Case
                </Button>
                <a disabled={true} style={{float: "right", fontSize: '25px' , margin: "0px 20px"}}>
                  <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
                </a>
            </div>
          ) 
      }  else if (userRole === "contributor") { 
        return(
          <div>
              <Button 
                  className="chr-font-light"
                  style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                  type="primary" 
                  onClick={this.showModal}>
                  + Enroll Case
              </Button>
              <a disabled={true} style={{float: "right", fontSize: '25px' , margin: "0px 20px"}}>
                <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
              </a>
          </div>
        ) 
      } else {
          return (
              <div style={{ textAlign: 'right'}}>
                <Button 
                    className="chr-font-light"
                    style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                    type="primary" 
                    onClick={this.showModal}>
                  + Enroll Case
                </Button>
                <a onClick={()=>{ this.props.CaseArchived() }} style={{ fontSize: '25px' , margin: "0px 20px"}}>
                  <Icon type="delete" theme="filled" style={{color: '#77B9FF', fontSize: '30px'}}/>
                </a>
              </div>
          )
      }
  }

  render(){
    const caseList = this.props.case.data;
    return(
      
    <div className="contentDiv">
        { this.userPermission() }
        

        <Modal
          title="Enroll Case"
          visible={this.state.visible}
          footer={null}
        >

          <CaseForm
            onSave = {this.onSave}
            onCancel= {this.onCancel}
            caseList = {caseList}
            enrollCase = {this.saveEnroll}
          />

        </Modal>
    </div>
    ); 
  }
}

const mapStateToProps = (state) => {
    return {
      login: state.login
    }
}

const mapDispatchToProps = {
  enrollCase
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdd);
