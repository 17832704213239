import { combineReducers } from 'redux';
import loginReducer from '../../modules/login/reducer';
import caseReducer from '../../modules/cases/reducer';
import userReducer from '../../modules/users/reducer';
import victimReducer from '../../modules/victims/reducer';
import searchReducer from '../../modules/search_tables/reducer';
import reportsReducer from '../../modules/reports/redux';
import trashReducer from '../../modules/trash/reducer';

const rootReducer = combineReducers({
    login: loginReducer,
    case: caseReducer,
    user: userReducer,
    victim: victimReducer,
    search: searchReducer,
    reports: reportsReducer,
    trash: trashReducer

});

export default rootReducer;
 