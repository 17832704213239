import React, { Component } from 'react'
import { Table, Button, Switch, Icon, Tabs, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import Status from '../../../shared/components/status';
import UserRequest from '../components/userRequest';
import '../../css/style.css';

const TabPane = Tabs.TabPane;

class TableUser extends Component {
    constructor(props){
        super(props);

        this.columns = [
            {
                title: "Email",
                dataIndex: "email",
                key: 'email',
                filterMultiple: false,
                sorter: true,
                // render:(record, text)=>{
                //     return <div>{text.first_name +" "+ text.middle_name +" "+ text.last_name}</div>
                // }
            },
            {
                title: "Gender",
                dataIndex: "gender",
                key: 'gender',
                filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
            },
            {
                title: "Position",
                dataIndex: "position",
                key: 'position',
                filters: this.positionList(),
            },
            {
                title: "User Role",
                dataIndex: "role",
                key: "role",
                filters: [{ text: 'Admin', value: 'Admin' }, { text: 'Viewer', value: 'viewer' }, { text: 'Editor', value: 'editor' }, { text: 'Contributor', value: 'contributor' }],
            },
            {
                title: "Date Created",
                dataIndex: "created_at",
                sorter: true,
                key: "created_at",
                sorter: true
            },
            {
                title: "Last Date Modified",
                dataIndex: "updated_at", 
                sorter: true,
                key: "updated_at",
            },
            {
                title: "Status",
                dataIndex: "is_active",
                filters: [
                    {
                        text: 'Active',
                        value: 'active'
                    },
                    {
                        text: 'Inactive',
                        value: 'inactive'
                    }
                ],
                render: (record)=>{
                    return <Status value = {record} />
                }
            },
            {
                title: "",
                dataIndex: "action",
                render: (record, text, index)=>{
                    return <Link onClick={(e)=> e.stopPropagation() } to={`/chr/user/${text.id}`} dataLink ={text}><Icon type="edit" /></Link>
                }
            }
        ]
    }

    rowClick = (value, index)=>{
        const title = value.email;
        sessionStorage.setItem('user_id', value.id);
        sessionStorage.setItem("case_log", title);
        this.props.history.push(`/chr/user/view/details`) ;
    }

    onChangeTable = (pagination, filters, sorter) => {
        this.props.sortingAndfilters(filters, sorter)
    }

    positionList= () => { 
        const data = this.props.position.data !== undefined ? this.props.position.data : [];
        return data.map(item => {
            return { text: item.position, value: item.position}
        })
    }

    UserView = () => {
        return(
            <div style={{margin: "20px"}}>
                <div style={{marginTop: '40px', fontSize: "20px", fontWeight: "bold" }}> 
                    <Row>
                        <Col span={20}>
                            <a style={{color: "#22568f"}}>
                                <Icon type="solution"/>&nbsp;&nbsp;&nbsp;
                                <span className="chr-font-sm">User List</span>
                            </a>
                        </Col>
                        <Col span={4}>
                            <Link to='/chr/user/add' >
                                <Button 
                                    className="chr-btn" 
                                    style={{ margin: '25px 5px', zIndex: '2', float:'right'}} 
                                    type="primary"
                                > 
                                    <span className="chr-font-light">+ Add</span>
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </div>

                <Table 
                    rowKey="id"
                    columns={this.columns}
                    dataSource={this.props.data}
                    onRowClick = {(value,  index)=>{ this.rowClick(value, index)  }}
                    onChange = {this.onChangeTable}
                />
            </div>
        )
    }

  render() {
    return (
        <div style={{margin: '30px 10px'}}>
            <h1 className="chr-font" style={{marginBottom: "50px"}}>Users Account List</h1>
            <div>
                <Tabs type="card">
                    <TabPane tab="Users" key="1">
                        {this.UserView()}
                    </TabPane>
                    <TabPane tab="User Request" key="2">
                        <UserRequest userRequestList = {this.props.userRequestList}/> 
                    </TabPane>
                </Tabs>
            </div>
      </div>
    )
  }
}

export default TableUser;