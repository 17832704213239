import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, notification, message  } from 'antd';
import { connect } from 'react-redux';
import { victimActAddDocument } from '../../../../cases/actionCreator';
import FileUpload from '../../../../../shared/components/fileUploadCase';
import SourceTabs from "../../../../../shared/components/addSourceTab";
import IsActive from './../../../../../shared/components/status';

const CaseForm =  Form.create()(

  class extends Component {
    
    handleSubmit = (e) => { 
      // e.preventDefault();
      let routeValue = e.routeValue;

      this.props.form.validateFields((err, values) => {

        if (!err) {
          this.props.onSave(values, routeValue) 
        }
      });
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      const path = sessionStorage.getItem('path_add_act');
      return (
        <Form >

          <Row style={{padding: '20px 20px 0px 20px'}}>
             <Col span={12}>
             {
              this.props.act_quick_upload == true || path == `/chr/victim/view/module/act/view`?  
              <h1 className="chr-font">Upload Attachment</h1>
              :
              <h1 className="chr-font">Adding Act </h1>
            }
             </Col> 

             <Col span={12}>
                 <Form.Item className="form-case-add">
                   {
                     this.props.act_quick_upload == true || path == `/chr/victim/view/module/act/view`? 
                     <div>
                   
                     <Button className="chr-btn" type="primary" onClick={()=>{ this.handleSubmit({routeValue : "null"}) }}  style={{margin: '0px 20px'}} >
                         Save
                     </Button>
                     <Button className="chr-close-btn" icon="cross" title="close" onClick={this.props.onCancel} />
                   </div>
                     :
                    <div>
                      <Button className="chr-btn-gen" onClick={()=>{ this.handleSubmit({routeValue : "act"}) }}  type="primary" ghost >
                          Save and Add more Act
                      </Button>
                    
                      <Button className="chr-btn" type="primary" onClick={()=>{ this.handleSubmit({routeValue : "null"}) }}  style={{margin: '0px 20px'}} >
                          Save
                      </Button>
                      {/* <Button className="chr-close-btn" icon="cross" title="close" onClick={this.props.onCancel} /> */}
                    </div>
                   } 
                 </Form.Item>
             </Col>
         </Row>
         <Row>
          <Col className="form-case-add-row"></Col>
         </Row>

         <Row>
           <Col>
  
                
      <div style={{margin: '50px 10px' }}>
      {
        this.props.act_quick_upload == true || path == `/chr/victim/view/module/act/view`? <div></div> : <SourceTabs history={this.props.history}/> 
      }

      <div style={{  display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
        {/* Form Start */}
              <Row  >
                <Col span={20}>
                  <Form.Item>
                    {getFieldDecorator('dev_notes', {
                      rules: [{ required: false }],
                      initialValue: { file_metadata: null , 1: null}
                    })(
                        <Input  hidden                      
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>          
            
              <Row  >
                <Col span={20}>
                  <Form.Item >
                    {getFieldDecorator('documents', {
                      rules: [{ required: false }],
                      initialValue: []
                    })(
                      <FileUpload dataID={this.props.dataID} 
                      
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

            </div>
          </div>
        </Col>
      </Row>
    </Form>
      );
    }
  }
)

class VictimAdd extends Component {

  onCancel = ()=>{
    this.props.history.goBack();
  }

  onSave = (params, routeValue)=> {

    if(routeValue == "act" ){
      this.props.victimActAddDocument(params, this.routeCallback)
    } else {
      this.props.victimActAddDocument(params, this.callback)
    }
    
  }

  routeCallback = (response, error)=>{ 
    if(!error){
      this.props.history.push(`/chr/quick/add/mulitple/act`);
    }
  }

 callback = (response, error) => {
    const path = sessionStorage.getItem('path_add_act');
    if(!error) {
        notification.success({message: `Successfully Added` })
        this.props.history.push(path)
    } else {
      notification.success({message: `${error.message}` })
    }
  }

  render(){
    var history = this.props.history;
    var act_quick_upload = !this.props.history.location.state ? false : this.props.history.location.state.quick_add_upload_act;

    return(

        <div className="contentDiv">
          <CaseForm
            onCancel = {this.onCancel}
            onSave = {this.onSave}
            backButton= {this.backButton}
            history = {history}
            act_quick_upload = {act_quick_upload}
          />
        </div>
    );
  }
}

const mapDispatchToProps = {
  victimActAddDocument
}

export default connect(null, mapDispatchToProps)(VictimAdd); 
