import React, { Component } from 'react'
import {BASE_URL} from '../../../shared/constant/url';
import FileViewer from 'react-file-viewer';
import {Row, Col, Icon, Button} from 'antd';
import { connect } from 'react-redux';
import { 
  DocumentsList,
  downloadFile,
  DocumentsVersionList, 
  downloadFileVersion,
   } from '../../cases/actionCreator';
import { get } from 'lodash';
import AddVersion from '../components/formUpload';
import {userList} from '../../users/actionCreators';
import moment from 'moment';

class viewDocuments extends Component {
  state = {
    changeViewDocs: sessionStorage.getItem('file_location'),
    version: false,
    file_name: "",
    file_type: "",
    file_location: "",
    id:""
  }

  componentDidMount(){
    this.props.DocumentsList();
    this.props.DocumentsVersionList();
    this.props.userList();
  }

  componentDidUpdate(a,b){
    if(a.DocumentsList !== this.props.docVersionList){
     setTimeout(()=>{
         this.props.DocumentsVersionList();
     }, 5000)
    }
    
  }
 
  icon (){
    let record = sessionStorage.getItem('file_type');
    let icon_type = "file-unknown";

    if (record === 'txt') {
        icon_type = "file-text"
    } else if (record === 'xls' || record === 'xlsx' || record === "csv") {
        icon_type = "file-excel"
    } else if (record === "pdf") {
        icon_type = "file-pdf"
    } else if (record === "doc" || record === "docx") {
        icon_type = "file-word"
    } else if (record === "jpg" || record === "png" ) {
        icon_type = "file-jpg"
    }

    return icon_type;
  }


  donwload = () => {
    const data = this.props.documents;
    this.props.downloadFile(data.file_location, data.file_type, data.file_name, data.id)
  }

  downloadFileVersion = (data)=>{
    // 
    let file_location =  data.file_location;
    let file_type = data.file_type; 
    let file_name =  data.file_name;
    let id = data.id;

    this.setState({ file_location, file_type, file_name, id})
  }

  downloadFileVersionGo = ()=>{
    let file_location =  this.state.file_location;
    let file_type = this.state.file_type; 
    let file_name =  this.state.file_name;
    let id = this.state.id;

    this.props.downloadFileVersion(file_location, file_type, file_name, id)
  }

  render() {
      const data = this.props.documents;
      const type = sessionStorage.getItem('file_type');
      const path = sessionStorage.getItem('file_location');
      const record = sessionStorage.getItem('file_name');
      const date = sessionStorage.getItem('created');
      const tags = get(data, 'document_tags') !== undefined ? get(data, 'document_tags') : [];
      const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
      const docVersionList = this.props.docVersionList.data === undefined ? [] : this.props.docVersionList.data;
      const userList = this.props.user.data === undefined ? [] : this.props.user.data;
      const url  = `${BASE_URL}/api/file?location=${path}`

   

      return (
      <div style={{ minHeight: '80vh'}}>

          <Row gutter={20}>
             <Col span={6}>
                <div style={{ overflowX: 'hidden', overflowY:'scroll', height:'100px', boxShadow: '1px 1px 5px #D4D2D2', background: 'white', maxHeight: "100%", minHeight: '80vh', padding: '10px'}}>

                 <Row gutter={15} style={{borderBottom: '1px solid #D4D2D2', padding: '50px 20px 0px 20px'}}>
                   <Col span={6}>
                     <Icon type={this.icon()} style={{fontSize: '50px', color: '#65BBFB'}} />
                   </Col>
                 
                   <Col span={12}>
                     <center><h3 style={{color: '#21568E', fontWeight: 'bold'}}>{record}</h3><h5>{date}</h5></center><br/><br/>
                   </Col>
                </Row>

                  <Row gutter={15} style={{borderBottom: '1px solid #D4D2D2', padding: '20px 20px'}} >
                   <Col span={6}>
                    <h3 className="chr-font-plain">Tags</h3>
                   </Col>

                   <Col span={12}>
                    <ul  style={{display: 'inline'}}>
                          { 
                            tags.map((e)=>{
                                return <li style={{borderRadius: '10px', color: 'white', background:'orange', border: '1px solid orange', padding: '2px', margin: '5px 0px'}} key={e} >
                                   <center>{e}</center> 
                                </li>
                              })
                            }
                      </ul>
                   </Col>
                  </Row>
                  
                {
                  userRole === "viewer" || userRole === "contributor" ? 
                  <Row gutter={15} style={{ margin: '40px 0px'}}>
                    <Col span={6}>
                      <h3 style={{margin: '4px 0px'}}>Version:</h3>
                    </Col>
                    <Col span={12}>
                       <Button disabled={true} style={{margin: '0px 20px', }}>+ add version</Button>
                    </Col>
                  </Row>
                  :
                  <Row gutter={15} style={{ margin: '40px 0px'}}>
                    <Col span={6}>
                      <h3 className="chr-font-plain" style={{margin: '4px 50px 4px 0px'}}>Versions</h3>
                    </Col>
                    <Col span={1} />
                    <Col span={12}>
                     <AddVersion />
                    </Col>
                  </Row>
                } 

                  <Row  style={{ margin: '40px 0px'}}>
                    <Col span={8}>
                       <ul style={{margin: '4px 0px'}}>
                        {
                           docVersionList.map( (item, index) => {
                             return (
                              <li style={{fontWeight: 'bolder', fontSize: '16px', listStyle: "none", margin: "23px 0px"}} key={item.id+"_"+index}>
                                 {item.version_number}.0
                              </li> 
                             )
                           })
                         }
                       </ul>
                    </Col>
                    <Col span={14}>
                      <ul>
                        {
                          docVersionList.map((item, index) => {
                            let arr = [];
                            let user = userList.forEach(function(data){
                              if(data.id == item.created_by){
                                arr.push(data.first_name)
                              }
                            })
                        return (
                           <a 
                           onClick={()=>{  
                             sessionStorage.setItem('file_location', item.file_location);
                             sessionStorage.setItem('file_name', item.file_name);
                             sessionStorage.setItem('file_type', item.file_type);
                             console.log(item)
                             this.setState({version: true})
                            //  sessionStorage.setItem('docs_id', item.id);
                             this.downloadFileVersion(item);
                              }
                             }> 
                             <li style={{listStyle: "none", margin: '10px 0px'}} key={item.created_at +"_"+ index}> 
                              { moment( item.created_at).format("YYYY/MM/DD") } <br/> 
                              { arr.map((things, index )=> { return <div key={things+"_"+index}>by {things}</div>}) }
                            </li>
                            </a>
                            )
                          })
                        }
                      </ul>
                     
                    </Col>
                  </Row>
                {
                   userRole === "viewer" || userRole === "contributor"? 
                  <Row  style={{ marginTop: '300px'}}>
                    <Col span={24}>
                        <center><Button disabled={true} style={{width:'200px'}} icon="download" onClick={()=>{this.donwload()}}>Download</Button></center>  
                    </Col>
                  </Row>
                  :
                  <Row  style={{ marginTop: '300px'}}>
                    <Col span={24}>
                      <center>
                         { this.state.version === true ? 
                          <Button style={{width:'200px'}} icon="download" 
                                  onClick={()=>{
                                  
                                    this.downloadFileVersionGo() }}>
                                      Download
                          </Button>
                          :
                          <Button style={{width:'200px'}} icon="download" 
                                  onClick={()=>{
                                  
                                    this.donwload() }}>
                                      Download
                          </Button>
                          }
                        </center>  
                    </Col>
                 </Row>
                }
                 
                </div>
              </Col>

              {/* <Col span={1} /> */}

              <Col span={18} style={{minHeight: '80vh', background: '#dcdcdc', }}>
            {
              type === "pdf" ?
              <div></div> 
              :
              <FileViewer
                style={{ margin: "20px"}}
                fileType= {type}
                filePath={url} 
              />
            }

              </Col>
          </Row>

       
          
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      documents:  state.case.documents.data === undefined ? [] :  state.case.documents.data.find(item => item.id == sessionStorage.getItem('docs_id')),
      login: state.login,
      docVersionList: state.case.docVersionList,
      user: state.user.users
  }
}

const mapDispatchToProps = {
  DocumentsList, 
  downloadFile, 
  DocumentsVersionList, 
  userList, 
  downloadFileVersion,
}

export default connect(mapStateToProps, mapDispatchToProps)(viewDocuments);