import React, { Component } from 'react'
import {Table, Button} from 'antd';
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    } from 'react-vis';
import moment from 'moment';
import { CSVLink, CSVDownload } from "react-csv";
import {assign} from 'lodash';

const data = [
    {y: 5, x: "Car"},
    {y: 10, x: "NCR"},
    {y: 15, x: "R01"},
    {y: 20, x: "R02"},
    {y: 25, x: "R03"},
    {y: 30, x: "R04"},
    {y: 35, x: "R05"},
    {y: 40, x: "R06"},
    {y: 45, x: "R07"},
    {y: 50, x: "R08"},
    {y: 55, x: "R09"},
    {y: 60, x: "R010"},
    {y: 65, x: "R011"},
    {y: 70, x: "R012"},
    {y: 75, x: "R013"},
    {y: 80},
    {y: 85},
    {y: 90},
    {y: 95},
    {y: 100},
  ];
  

class ChartsAndTable extends Component {
    constructor(props){
        super(props);

        this.columns = [
            {
                title: "Region",
                dataIndex: "region"
            },
            {
                title: "Female",
                key:"female_1",
                dataIndex: "female"
            },
            {
                title: "Female (%)",
                dataIndex: "female",
                key:"female_2",
                render: (record, text)=>{
                    const data = this.props.profile;
                    let total = 0;
                
                    data.forEach(function(a, b){
                        total += a.female ;
                    })
                    
                    if ( total === 0 ) {
                        return <div>0%</div>
                      } else {
                        const percent = record/total;
                        const final = percent * 100;
                        const finalInt = parseInt(final);
                        return <div> { finalInt } % </div>
                      }
                }

            },
            {
                title: "Male",
                key:"male_1",
                dataIndex: "male"
            },
            {
                title: "Male (%)",
                key:"male_2",
                dataIndex: "male",
                render: (record, text)=>{
                    const data = this.props.profile;
                    let total = 0;
                
                    data.forEach(function(a, b){
                        total += a.male ;
                    })
                    
                  if ( total === 0 ) {
                    return <div>0%</div>
                  } else {
                    const percent = record/total;
                    const final = percent * 100;
                    const finalInt = parseInt(final);
                    return <div> { finalInt } % </div>
                  }
                }
            },
            {
                title: "No Info",
                key: "no_info_1",
                dataIndex: "no_info",
                key: '2'
            },
            {
                title: "No Info (%)",
                key: "no_info_2",
                dataIndex: "no_info",
                key: '3',
                render: (record, text)=>{
                    const data = this.props.profile;
                    let total = 0;
                
                    data.forEach(function(a, b){
                        total += a.no_info ;
                    })
                    
                    if ( total === 0 ) {
                        return <div>0%</div>
                      } else {
                        const percent = record/total;
                        const final = percent * 100;
                        const finalInt = parseInt(final);
                        return <div> { finalInt } % </div>
                      }
                }
            },
        ]
    }

    dateNow = ()=>{
        let Date = moment().format('YYYY-MM-DD');
        return <span> {Date}</span>
    }
  
    donwloadClick = () => {
        return <CSVDownload data={this.reportMapping()} target="_blank" />
    }

    female = ()=> {
        let data = this.props.profile;
        let total = 0;
            for(let i=0; i < data.length; i++){
                total += data[i].female;
            }
        return total;
    }

    
    male = ()=> {
        let data = this.props.profile;
        let total = 0;
            for(let i=0; i < data.length; i++){
                total += data[i].male;
            }
        return total;
    }

    
    no_info = ()=> {
        let data = this.props.profile;
        let total = 0;
            for(let i=0; i < data.length; i++){
                total += data[i].no_info;
            }
        return total;
    }

    
 reportMapping = ()=>{
    let arr = this.props.profile === undefined ? [] : this.props.profile;
    let datus = [];
    
        arr.forEach((data, index)=>{
            arr.map((item, item_index)=>{
                if(index === item_index){
                    let Women = arr[item_index].female / this.female();
                    let femaleTotal = Women * 100 + '%';

                    let Men = arr[item_index].male / this.male();
                    let MenTotal = Men * 100 + '%';

                    let No_info = arr[item_index].no_info / this.no_info();
                    let No_infoTotal = No_info * 100 + '%';

                    let lastData = assign({
                        Region: arr[item_index].region, 
                        Female: arr[item_index].female, 
                        Percentage_of_Female: femaleTotal === "NaN%" ? "0%" : femaleTotal ,
                        Male: arr[item_index].male, 
                        Percentage_of_Men: MenTotal === "NaN%" ? "0%" : MenTotal ,
                        No_Information: arr[item_index].no_info, 
                        Percentage_of_No_Information: No_infoTotal === "NaN%" ? "0%" : No_infoTotal ,
                    });

                    datus.push(lastData)
                }
            })
        })
    
        return datus;
    }
    
  render() {
    let arr = this.props.profile;
    let API1  = arr.map(e=>{
        return { x: e.region, y: e.male }
    })
   
    let API2  = arr.map(e=>{
        return { x: e.region, y: e.female }
    })

    let API3  = arr.map(e=>{
        return { x: e.region, y: e.no_info }
    })

    return (
      <div style={{margin: '20px 60px'}}>

        <div>
            <h4>Overview Results</h4>
            <h2>Demographic Profile of Victims Report</h2>
            <h4>as of Date:</h4>
           
           <div style={{display: 'flex', flexDirection: 'columns'}}>
            <div style={{margin: '0px 20px', borderTop: '5px solid pink', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: 'pink' , float: 'left'}}></div> 
                <h4>Female</h4>
                <center><h1>{this.female()}</h1></center>
            </div>
            <div style={{ margin: '0px 20px', borderTop: '5px solid #8fdbe2', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: '#8fdbe2' , float: 'left'}}></div> 
                <h4>Male</h4>
                <center><h1>{this.male()}</h1></center>
            </div>
            <div style={{margin: '0px 20px', borderTop: '5px solid #cbcbcb', borderRadius: '5px', boxShadow: '1px 1px 2px gray', width: '250px', height: '120px', padding: '20px'}}>
                <div style={{margin: '0px 20px', width: '18px', height: '18px' , background: '#cbcbcb' , float: 'left'}}></div> 
                <h4>No Information</h4>
                <center><h1>{this.no_info()}</h1></center>
            </div>
            </div>
        </div>

        <div style={{textAlign: 'right', margin: '20px 0px '}}>
            <Button icon="download"> 
                &nbsp;  
                 <CSVLink data={this.reportMapping()} filename="Demographic Profile of Victim.csv" onClick={() => {  this.donwloadClick(); }} > 
                     Download Report
                  </CSVLink>
            </Button>
        </div>
        
        <div style={{margin: ' 30px 0px'}}>
            <XYPlot xType="ordinal" width={900} height={500} xDistance={100}>
            <VerticalGridLines />
            <HorizontalGridLines />
            <VerticalBarSeries data={data} color= "transparent" />
            <XAxis />
            <YAxis />
            <VerticalBarSeries data={API2} color = 'pink' style={{width: '15px', }}/>
            <VerticalBarSeries data={API1} color = '#8fdbe2' style={{width: '15px', }}/>
            <VerticalBarSeries data={API3} color = '#cbcbcb' style={{width: '15px', }}/>
            </XYPlot>
        </div>

        <Table 
            columns={this.columns}
            dataSource={arr}
            rowKey="id"
            style={{margin: '20px 0px'}}/>
      </div>
    )
  }
}

export default ChartsAndTable;