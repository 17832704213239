import React, { Component } from 'react'
import TableTypes from './components/actTable';
import { connect } from 'react-redux';
import { ActTypeList, ActType, configActTypeEdit } from '../actionCreator';

class TableType extends Component {

componentDidMount(){
    this.props.ActTypeList();
}

componentDidUpdate(prevProps){
    if(prevProps != this.props.status) {
        this.props.ActTypeList();
    }
}

    render() {
        return (
            <div>
                <TableTypes 
                    status = { this.props.status }
                    caseTypeAdd = { this.props.ActType }
                    configActTypeEdit={this.props.configActTypeEdit}
                />
            </div>
        )
    }
}
 const mapStateToProps = (state) => {
     console.log(state)
    return {
        status: state.case.actType.data
    }
}

const mapDispatchToProps = {
    ActTypeList , ActType, configActTypeEdit
}

export default connect(mapStateToProps, mapDispatchToProps)(TableType);