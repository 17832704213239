import React, { Component } from 'react'
import  { Table } from 'antd';
import moment from 'moment';

class UserLog extends Component {
    constructor(props){
        super(props);

        this.columns = [
            {
                title: "Actions",
                dataIndex: "action",
                sorter: true,
            }, 
            {
                title: "Date",
                dataIndex: "updated_at",
                sorter: true,
                render: (record) =>{
                    let date = moment(record).format("YYYY-MM-DD")
                    return date;
                }
            },
            {
                title: "Time",
                dataIndex: "updated_at",
                key:'updated_at-2',
                render: (record) =>{
                    let date = moment(record).format("hh:mm:ss A ")
                    return date;
                },
             
            }
        ]
    }

    onChangeTable = (pagination, filter, sorter)=>{
        this.props.filterSorters(sorter)
    }

    render() {
        const data = this.props.data.data === undefined ? [] : this.props.data.data;
        return (
            <div>
                <div style={{margin: '20px 20px'}}>
                    <h1 className="chr-font">User Log</h1>
                        <Table 
                            dataSource={data}
                            columns={this.columns}
                            rowKey= "id"
                            onChange = {this.onChangeTable}
                        />
                </div>
            </div>
        )
    }
}

export default UserLog