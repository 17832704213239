import React, { Component } from 'react';
import { Input, Icon } from 'antd';

class AddVersion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            iconVisible: false,
            label: '+ Add Version'
        }
    }

    toggle_add_version = (e) => {
        e.stopPropagation();
        const visible = this.state.isVisible;

        this.setState({
            isVisible: !visible
        })
    }

    hide_add_version = (e) => {
        e.stopPropagation();

        this.setState({
            isVisible: false
        })
    }

    record_version = (e) => {
        e.stopPropagation();        

        const text = e.target.value;
        let tempText;
        var showIcon = false;

        this.props.add_file_version(this.props.file, text)

        if(text.trim().length > 0) {
            tempText = text;
            showIcon = true;
        } else {
            tempText = '+ Add Version'
            showIcon = false;
        }

        this.setState({
            label: tempText,
            iconVisible: showIcon
        })
    }

    // stop_propagation = (e) => {
    //     e.stopPropagation();
    // }

    render() {
        return(
            <div>
                <a 
                    style={{ display: this.state.isVisible ? 'none' : 'inline-block' }}
                    onClick={ this.toggle_add_version }
                    className="file-add-version">
                    { this.state.label }
                    <Icon
                        type="edit" 
                        style={{ display: this.state.iconVisible ? 'inline-block' : 'none', paddingLeft: '5px' }}/>
                </a>
                <Input 
                    style={{ display: this.state.isVisible ? 'block' : 'none' }}
                    className="version-input"
                    onChange={ this.record_version }
                    // onClick={ this.stop_propagation }
                    // onPressEnter={ this.stop_propagation }
                    onBlur={ this.hide_add_version }
                    onBlurCapture={ this.hide_add_version }/>
            </div>
        )
    }
}

export default AddVersion;