import React, { Component } from 'react'
import { connect } from 'react-redux';
import TableList from './components/table';
import { Button, Row, Col, Switch, Tabs } from 'antd';
import { caseAdd, caseList, victimTableList, caseTypeList, caseStatusList, victimListAll } from './actionCreator';
import { userList } from '../users/actionCreators';
import { Link } from 'react-router-dom';
import Tiles from '../../shared/components/tiles';


class CaseMain extends Component {

    componentDidMount(){
        // this.getCaseList({"sort_key":"id"});
        this.props.caseTypeList(); 
        this.props.caseStatusList();
        this.props.userList();
        this.props.victimListAll();
    }

    getCaseList = (params) => {
        this.props.caseList(params);
    }

    userPermission = ()=>{
        const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;

        if (userRole === "viewer"){
            return(
            <Button 
                disabled={true}
                icon="plus" 
                type="default" 
                className="chr-font-light"
                style={{ float: 'right', margin: '20px 30px', height: '40px'}} 
            >
                Add Case
            </Button> 
            ) 
        } else {
            return (
                <Link to='/chr/case/add' >
                    <Button 
                        style={{zIndex: 10}}
                        onClick = { ()=>{ sessionStorage.setItem('case_edit', false) }}
                        icon="plus" 
                        type="primary" 
                        className="add-case">
                        Add Case
                    </Button>
                </Link>
            )
        }
    }

    render() {
        const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
        return (
            <div>
                <div style={{margin: '30px 0px'}}>
                <h1 className="chr-font">Recent Cases</h1>
                <Tiles List = {this.props.case} history = {this.props.history} />
                </div>

                <div style={{margin: '60px 10px'}}>
                    <Row>
                        <Col span={20}>
                            <h1 className="chr-font">Cases</h1>
                        </Col>
                        <Col span={4}>
                            {
                                this.userPermission()
                            }
                        </Col>
                    </Row>
                    <TableList 
                        caseList = {this.props.case} 
                        login = {this.props.login}
                        history ={this.props.history}
                        victimAction = {this.props.victimTableList}
                        list = {this.props.ListVictim}
                        status = {this.props.status}
                        type = {this.props.type}
                        users = {this.props.users}
                        victims = {this.props.victims}
                        getCaseList={this.getCaseList}
                        />    
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state)=> {
    return {
        users: state.user.users,
        case: state.case,
        login: state.login,
        ListVictim: state.case.table_victim,
        status: state.case.status.data,
        type: state.case.type.data,
        victims: state.case.victimAll,
    }
}

const mapDispatchToProps = {
    caseAdd, caseList, victimTableList, caseTypeList, caseStatusList, userList, victimListAll
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseMain); 