import React, { Component } from 'react'
import { Select, Icon, Input, Popover, Button } from 'antd';
const Search = Input.Search;

export default class searchSelect extends Component {
   
    state = {
        filter: null,
        value: undefined,
      }
    
      handleSearch = (value) => {
        const filter = this.state.filter;
         this.props.history.push({  pathname: `/chr/search?q=${value}`, hash: filter });
        }
    
      handleChange = (value) => {
        this.setState({ filter: value });
      }

    
      content = ()=>{
        return <div>
            <h4>Filter By:</h4>
                <Select style={{width: '250px'}} value = {this.state.filter} placeholder="Select Filter" onChange={value => this.handleChange(value)}>
                  <Select.Option key="case"> Case </Select.Option>
                  <Select.Option key="victim"> Victim </Select.Option>
                  <Select.Option key="act"> Act </Select.Option>
                  <Select.Option key="account"> Account </Select.Option>
                  <Select.Option key="file"> File </Select.Option>
                </Select>

                <div style={{textAlign: 'right', margin: '10px 0px'}}>
                  <Button type="primary" style={{margin: '0px 0px'}} onClick ={()=>{ this.setState({ filter: null})}}>Reset</Button>
                </div>
               </div>
      }
  render() {
    return (
          <span>
            <Search
              placeholder=" Search File "
              onSearch={value=>this.handleSearch(value)}
              style={{width: "400px", margin: "15px 5px"}} 
            /> 

            <Popover  placement="bottomRight" content={this.content()} >
             <Button type="success" icon="filter"></Button>
            </Popover>
          </span>
    
    )
  }
}
