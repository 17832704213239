import React, { Component } from 'react'
import TableTypes from './components/assaillantTable';
import { connect } from 'react-redux';
import { AssaillantTypeList, AssaillantType, configassailantTypeEdit} from '../actionCreator';

class TableType extends Component {

componentDidMount(){
    this.props.AssaillantTypeList();
}

componentDidUpdate(prevProps){
    if(prevProps != this.props.status) {
        this.props.AssaillantTypeList();
    }
}

    render() {
        return (
            <div>
                <TableTypes 
                    status = { this.props.status }
                    AssaillantType = { this.props.AssaillantType }
                    configassailantTypeEdit={this.props.configassailantTypeEdit}
                />
            </div>
        )
    }
}
 const mapStateToProps = (state) => {
    return {
        status: state.case.assaillantType.data
    }
}

const mapDispatchToProps = {
    AssaillantTypeList , AssaillantType, configassailantTypeEdit
}

export default connect(mapStateToProps, mapDispatchToProps)(TableType);