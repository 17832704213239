import React, { Component } from 'react'
import { Table, Icon } from 'antd';
import {get} from 'lodash';
import Tags from '../../../shared/components/tags';

 class case_table extends Component {
   constructor(props){
    super(props);

     this.columns = [
        {
          title: 'Case Name',
          dataIndex: 'case_identifier',
          key: 'case_identifier',
          render: (record, text, index)=>{
            return <div><Icon className="case-icon-table" type="folder-open"  theme="filled"/> <span className="name_folder_icon">{record}</span></div>
          }
        },
        {
          title: 'Type',
          dataIndex: 'case_type',
          key: 'case_type',
        },
        {
          title: 'Status',
          dataIndex: 'case_status',
          key: 'case_status',
       
        },
        {
          title: 'Tags',
          ddataIndex: 'tags',
          key: 'tags',
          render: (record, text, index)=>{
            const arr2 = text.case_tags;
            const arr1 = arr2.length;
            const arr3 = arr2.slice(0, 2);
            const id = text.id;
            return (
                    <Tags 
                      arr2={arr2} 
                      arr1={arr1} 
                      arr3={arr3} 
                      id={id} 
                    />
                  )
          }
       
        },
        {
            title: 'Date Created',
            dataIndex: 'created_at',
            key: 'created_at'
        },{
            title: 'Date Modified',
            dataIndex: 'updated_at',
            key: 'updated_at'
        },{
          title: 'Created By:',
          dataIndex: 'created_by',
          render: (record, text)=>{
            const userList = this.props.users.data == undefined ? [] : this.props.users.data;
            const findData = userList.find(e=> e.id == record);
        
            return get(findData, 'first_name') + " " + get(findData, 'last_name')
            
        }
          }
     ] 
  
  }

  render() {
    const data = this.props.data.cases.data;
    return (
      <div>
        <Table 
          style={{marginTop: '30px'}}
          columns ={this.columns}
          dataSource={data}
          onRowClick = { (value)=>{
                      this.props.history.push('/chr/case/view'); 
                      sessionStorage.setItem('case_id', value.id )
                      sessionStorage.setItem('case_title', value.case_identifier )
                    }}
        />
      </div>
    )
  }
}
 
export default case_table;