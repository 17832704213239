import React, { Component } from 'react'
import {connect} from 'react-redux';
import PasswordForm from '../../components/PasswordForm';
// import { PasswordRequest } from '../../ActionCreators';

class Main extends Component {

  render() {
    return (
        <div className=" background-login">
            <PasswordForm history={this.props.history} PasswordRequest={this.props.PasswordRequest}/>
        </div>
    )
  }
}

const mapStateToProps = (state)=>{
    return { 
    }
}

const mapDispatchToProps = {
  // PasswordRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);