import React, { Component } from 'react'
import { Table, Icon,  Button, Row, Col } from 'antd';
import Tags from '../../../shared/components/tags';
import  moment from 'moment';

class TableList extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            expanded: 0
        }

        this.columns = () => {
            return [
            {
                title: 'Case Name',
                dataIndex: 'case_identifier',
                key: 'case_identifier',
                render: (record, text, index)=>{
                return <div><Icon className="case-icon-table" type="folder-open"  theme="filled"/> <span className="name_folder_icon">{record}</span></div>
                },
                sorter: true
            },{
                title: 'Type',
                dataIndex: 'case_type',
                key: 'case_type',
                filters: this.returnTypeFilters(this.props.type),
                filterMultiple: false,
                // sorter: true
            },
            {
                title: 'Tags',
                dataIndex: 'case_tags',
                key: 'case_tags_list',
                width: '200px',
                filters : [{'value': '', 'text': ''}],
                // sorter: true,
                render: (record, text, index)=>{
                    const arr2 = text.case_tags;
                    const arr1 = arr2.length;
                    const arr3 = arr2.slice(0, 2);
                    const id = text.id;
                return (
                    <Tags 
                        arr2={arr2} 
                        arr1={arr1} 
                        arr3={arr3} 
                        id={id} />
                      )
              },
              
            },
            {
                title: 'Victims',
                dataIndex: 'id',
                key: 'id',
                // sorter: true,
                render: (text, record)=>{
                    let case_victims = record.case_victims == undefined ? []: record.case_victims;
                    let count = 0;
                    let countPlusone = case_victims.filter((item)=>{
                        if(item.is_archived === 0){
                            count = count + 1 ;
                        }
                    })

                    return <div>{count}</div>
                },
            },{
                title: 'Status',
                dataIndex: 'case_status',
                key: 'case_status',
                filters: this.returnStatusFilters(this.props.status),
                filterMultiple: false,
                // sorter: true
            },{
                title: 'Date Created',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (date) => moment(date).format('MMMM DD, YYYY HH:mm A'),
                sorter: true
            },{
                title: 'Date Modified',
                dataIndex: 'updated_at',
                key: 'updated_at',
                render: (date) => moment(date).format('MMMM DD, YYYY HH:mm A'),
                sorter: true
            },{
                title: 'Created by',
                dataIndex: 'created_by',
                key: 'created_by',
                render: (record , text, index)=> {
                 const users = this.props.users.data;
                 const findData = users == undefined ? "" : users.find(e => e.id == record);

                  var user = findData.first_name +" "+ findData.last_name;
                  return <span>{user}</span>
                },
                //filters: this.returnCreatorFilters(),
                filterMultiple: false,
                sorter: true
            },
        ]}
    }

    rowClick = (value, index)=>{
        const title = value.case_identifier;
        sessionStorage.setItem('case_id', value.id);
        sessionStorage.setItem("case_title", title);
        this.props.history.push(`/chr/case/view`) ;
    }
    
    chunkArray = (array, size)=>{
        var arrayLength = array.length;
        var tempArray = [];
        
        for (var index = 0; index < arrayLength; index += size) {
           var myChunk = array.slice(index, index+size);
           tempArray.push(myChunk);
        }
        return tempArray;
    }

    returnStatusFilters = (status) => {
        if (status !== undefined) {
            return status.map((item) => {
                return { 'value' : item.case_status, 'text' : item.case_status }
            })
        }
    }

    returnTypeFilters = (type) => {
        if(type !== undefined) {
            return type.map((item) => {
                return { 'value' : item.case_type, 'text' : item.case_type }
            })
        }
    }

    handleTableChange = (pagination, filters, sorter) => {
        let params = {
            "sort_key" : sorter.columnKey,
            "sort_dir" : sorter.order            
        }

        for(let [key, value] of Object.entries(filters)) {
            if(value.length > 0) {
                params[`${key}`] = value;
            }
        }

        this.props.getCaseList(params)
    }
    
    render() {
        const data= this.props.caseList.case.data;
        const loading = this.props.caseList.loading;
        return (
            <div>
                <Table 
                    columns = {this.columns()}
                    dataSource={data}
                    rowKey="id"
                    // onChange={ this.handleTableChange }
                    // loading={ loading }
                    onRowClick = {(value,  index)=>{ this.rowClick(value, index)  }}
                    onExpand= { (expanded, record) => {  if (expanded) this.props.victimAction(record.id); }}
                    expandedRowRender={(value)=> { 

                        const result = this.chunkArray(value.victims==undefined ? []: value.victims.data, 5);
                        return <div>
                                <div>
                                    {/* <hr style={{borderTop: '2px dashed #ced8e2', width: '95%'}} /> */}
                                </div>
                            {
                              value.victims == undefined ? [] : value.victims.data.length > 0 
                              ? 
                              <Row gutter={16}>
                                  <Col span={6}>
                                   {
                                   value.victims == undefined ? []: 
                                     result[0]==undefined ? [] : result[0].map(e=>{
                                        return <div key={e.id} style={{fontSize: "15px", margin: '20px 30px'}}>
                                               <Icon type="user-add" style={{padding: '5px', border: '2px solid #abc2d9', color: '#abc2d9', borderRadius: '20px',margin: '0px 20px'}}/>
                                                 { e.first_name== null ? " " : e.first_name } 
                                                 {e.middle_name == null ? " " : e.middle_name} 
                                                 { e.last_name  == null ? " " : e.last_name}
                                                </div>
                                            })
                                  }
                                 </Col>

                                 <Col span={7}>
                                   {
                                   value.victims == undefined ? []: 
                                     result[1]==undefined ? [] : result[1].map(e=>{
                                        return <div key={e.id} style={{fontSize: "15px", margin: '20px 30px'}}>
                                               <Icon type="user-add" style={{padding: '5px', border: '2px solid #abc2d9', color: '#abc2d9', borderRadius: '20px',margin: '0px 20px'}}/>
                                                 { e.first_name== null ? " " : e.first_name } 
                                                 {e.middle_name == null ? " " : e.middle_name} 
                                                 { e.last_name  == null ? " " : e.last_name}
                                                </div>
                                            })
                                  }
                                 </Col>

                                 <Col span={7}>
                                   {
                                   value.victims == undefined ? []: 
                                     result[2] == undefined ? [] : result[2].map(e=>{
                                        return <div key={e.id} style={{fontSize: "15px", margin: '20px 30px'}}>
                                               <Icon type="user-add" style={{padding: '5px', border: '2px solid #abc2d9', color: '#abc2d9', borderRadius: '20px',margin: '0px 20px'}}/>
                                                 { e.first_name== null ? " " : e.first_name } 
                                                 {e.middle_name == null ? " " : e.middle_name} 
                                                 { e.last_name  == null ? " " : e.last_name}
                                                </div>
                                            })
                                  }
                                 </Col>
                                
                                 <Col span={3}>
                                    <Button type="default" style={{margin: '20px'}} onClick ={(e)=>{e.stopPropagation(); this.rowClick(value)}}> View All </Button >
                                    {/* (VIEW ALL) na btn lilitaw lang if more than 15 yung nakadisplay */}
                                 </Col>
                             </Row>
                             :
                             <div style={{textAlign: "center"}}>
                                 <p>No Victim added to the Case.</p>
                             </div>
                            }
                        </div>
                    }  
                }
                />
            </div>
        )
    }
}

export default TableList;