import React, { Component } from 'react'
import {Table, Button} from 'antd';
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    } from 'react-vis';
import { CSVLink, CSVDownload } from "react-csv";

const data = [
    {y: 5,  x: "0_12" },
    {y: 10, x: "13_17"},
    {y: 15, x: "18_24"},
    {y: 20, x: "25_34"},
    {y: 25, x: "35_44"},
    {y: 30, x: "45_54"},
    {y: 35, x: "55_64"},
    {y: 40, x: "65_74"},
    {y: 45, x: "45_older"},
    {y: 50, x: "no_info"},
    {y: 55 },
    {y: 60 },
    {y: 65 },
    {y: 70 },
    {y: 75 },
    {y: 80 },
    {y: 85 },
    {y: 90 },
    {y: 95 },
    {y: 100},
  ];
  
const API_DATA = [
    {x: "Age", y: 50}
]

class ChartsAndTable extends Component {
    constructor(props){
        super(props);

        this.columns = [
            // {
            //     title: "Age",
            // },
            {
                title: "0-12",
                dataIndex: "0_12",
            },
            {
                title: "12-17",
                dataIndex: "13_17",
            },
            {
                title: "18-24",
                dataIndex: "18_24",
            },
            {
                title: "25-34",
                dataIndex: "25_34",
            },
            {
                title: "35-44",
                dataIndex: "35_44",
            },
            {
                title: "45-54",
                dataIndex: "45_54",
            },
            {
                title: "55-64",
                dataIndex: "55_64",
            },
            {
                title: "65-74",
                dataIndex: "65_74",
            },
            {
                title: "45-older",
                dataIndex: "45_older",
            },
            {
                title: "No-info",
                dataIndex: "no_info",
            }
        ]
    }

    donwloadClick = () => {
        return <CSVDownload data={this.props.age} target="_blank" />
    }



  render() {
    // "region": "NCR",
    // "victim_total": 1
    var arr = this.props.age;

    var API  = arr.map(item=>{
        return  { y: item['0_12'], x: "0_12" }
    })

    var API2  = arr.map(item=>{
        return  { y: item['13_17'], x: "13_17" }
    })

    var API3  = arr.map(item=>{
        return  { y: item['18_24'], x: "18_24" }
    })

    var API4  = arr.map(item=>{
        return  { y: item['25_34'], x: "25_34" }
    })

    var API5  = arr.map(item=>{
        return  { y: item['35_44'], x: "35_44" }
    })

    var API6  = arr.map(item=>{
        return  { y: item['45_54'], x: "45_54" }
    })

    var API7  = arr.map(item=>{
        return  { y: item['55_64'], x: "55_64" }
    })

    var API8  = arr.map(item=>{
        return  { y: item['65_74'], x: "65_74" }
    })

    var API9 = arr.map(item=>{
        return  { y: item['45_older'], x: "45_older" }
    })

    var API10  = arr.map(item=>{
        return  { y: item['no_info'], x: "no_info" }
    })
    return (
      <div style={{margin: '20px 60px'}}>

        <div>
            <h4>Overview Results</h4>
            <h2>Distribution of Victims per Age Group Report</h2>
            <h4>as of Date:</h4>
        </div>

        <div style={{textAlign: 'right'}}>
            <Button icon="download"> 
                &nbsp;  
                 <CSVLink data={arr} filename="Distribution of Victims per Age Group Report.csv" onClick={() => {  this.donwloadClick(); }} > 
                     Download Report
                  </CSVLink>
            </Button>
        </div>
        
        <div style={{margin: ' 30px 0px'}}>
            <XYPlot xType="ordinal" width={900} height={500} xDistance={100}>
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <VerticalBarSeries data={data} color= "transparent" />
            <VerticalBarSeries data={API} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API2} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API3} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API4} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API5} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API6} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API7} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API8} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API9} color = '#6998dc' style={{width: '20px', }}/>
            <VerticalBarSeries data={API10} color = '#6998dc' style={{width: '20px', }}/>
        
            </XYPlot>
        </div>

        <Table 
            columns={this.columns}
            dataSource={arr}
            style={{margin: '20px 0px'}}/>

      </div>
    )
  }
}

export default ChartsAndTable;