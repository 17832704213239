import React, { Component } from 'react'
import {Table, Button} from 'antd';
import {
    RadialChart
    } from 'react-vis';

const myData = [{angle: 1}, {angle: 5}, {angle: 2}]

class ChartsAndTable extends Component {

  totalData(){
    let data = this.props.leoReports.data !==undefined ? this.props.leoReports.data : [];
    let leoTotal = 0;
    let nonLeoTotal = 0;
    let noInfoTotal = 0;

    data.forEach((a)=>{
         leoTotal += a.leo;
         nonLeoTotal += a.non_leo;
         noInfoTotal += a.no_info;
    })
    
   return [{angle: nonLeoTotal},{angle: leoTotal},{angle: noInfoTotal}];
  }

  render() {
    return (
      <div> 
       <center>
         <h4 style={{margin:'28px 20px', color: "#22568f", fontWeight: "bold"}}>Law Enforcement Operations  </h4>
       </center> 
   
       <hr />
       <center>
          <RadialChart
            labelsRadiusMultiplier = "2"
            labelsAboveChildren ={true}
            showLabels = {true}
            radius={100}
            innerRadius = {50}
            data={this.totalData()}
            width={200}
            height={310} />
        </center>
      </div>
    )
  }
}

export default ChartsAndTable;