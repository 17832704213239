import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, Modal } from 'antd';
import { connect } from 'react-redux';
import FileUpload from '../../../shared/components/uploadVersion';
import {DocumentsVersionAdd} from '../../cases/actionCreator';
import {assign} from 'lodash';

const UploadVersion =  Form.create()(

  class extends Component {
    
    handleSubmit = (e) => {
      e.preventDefault();

      this.props.form.validateFields((err, values) => {
        
        let file_location = values.documents[0] === undefined ? "" : values.documents[0].response.file_location;
        let file_name = values.documents[0] === undefined ? "" : values.documents[0].response.file_name;
        let file_type = values.documents[0] === undefined ? "" : values.documents[0].response.file_type;
        let docs_id = parseInt(sessionStorage.getItem('docs_id'));
        let params = assign({ file_location, file_name, file_type, document_id: docs_id});

        if (!err) {
            this.props.DocumentsVersionAdd(params);
            this.props.onCancel();
            // console.log(params)
        }
      });
    }



    render() {
      const { getFieldDecorator } = this.props.form;
      return (
        <Form onSubmit={this.handleSubmit}  style={{margin: '20px 20px'}} >
       
         <Row style={{borderBottom: "2px solid black"}}>
             <Col span={24}>
                 <h1 className="chr-font" style={{color: "#21568E", fontSize: '20px'}}>Upload Version</h1>
             </Col> 
         </Row> 

        <div style={{ margin: '20px 0px'}}>
            <Row  >
              <Col span={24}>
                  <Form.Item >
                  { getFieldDecorator('documents', {
                    rules: [{ required: false }],
                    initialValue: ""
                  })(
                    <FileUpload stateList = {this.props.stateList}/>
                  )}
                  </Form.Item>
              </Col>
            </Row>
        </div>

        <Row>
            <Col span={24} style={{textAlign: 'right'}}>
                <Form.Item className="form-case-add">
                 
                  <Button 
                        onClick={()=>{sessionStorage.setItem('hey', true)}}  
                        style={{ width: '100px' }} type="default" onClick={()=>{this.props.onCancel()}} >
                        Cancel
                  </Button>

                  <Button 
                      onClick={()=>{ 
                        {sessionStorage.setItem('hey', true)}}} 
                      style={{ width: '100px',margin: '0px 10px' }} 
                      type="primary" 
                      htmlType="submit">
                        Save
                  </Button>

                </Form.Item>
            </Col>
        </Row>
       
    </Form>
      );
    }
  }
)

class CaseAdd extends Component {
  
    state = { 
      visible: false,
     };

  showModal = () => {
      this.setState({
        visible: true,
      });
    };
  
  handleCancel = e => {
      this.setState({
        visible: false,
      });
    };

  onSave = (params)=> {
    this.setState({
        visible: false,
      });
  }

  render(){
    
    setTimeout(()=>{
      sessionStorage.setItem('hey',false)
    }, 2000);

    return ( 
        <div>
          <Button
            type="primary" 
            style={{margin: "0px 30px"}}
            onClick={()=>{ 
              this.showModal(); 
              }}>
            + add version
          </Button>
            
          <Modal
              visible={this.state.visible}
              width="400px"
              header={false}
              footer={false}>
            
              <UploadVersion
                onCancel = {this.handleCancel}
                onSave = {this.handleCancel}
                stateList = {this.state.visible}
                DocumentsVersionAdd={this.props.DocumentsVersionAdd}
              />

          </Modal>
        </div>
    );
  }
}

const mapDispatchToProps = {
  DocumentsVersionAdd
}

export default connect(null, mapDispatchToProps)(CaseAdd); 