import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, notification, message, DatePicker, Radio, Select, AutoComplete  } from 'antd';
import { connect } from 'react-redux';
import { victimAdd, victimGet, victimEdit } from '../../../cases/actionCreator';
import VictimTabs from '../../../../shared/components/addVictimTabs';
import moment from 'moment';
import { regions, provinces } from './data';

const RadioGroup = Radio.Group;
const REGIONS = [
  { title: 'NCR', key:'NCR'},
  { title: 'CAR', key:'CAR'},
  { title: 'R01', key:'R01'},
  { title: 'R02', key:'R02'},
  { title: 'R03', key:'R03'},
  { title: 'R04', key:'R04'},
  { title: 'R05', key:'R05'},
  { title: 'R06', key:'R06'},
  { title: 'R07', key:'R07'},
  { title: 'R08', key:'R08'},
  { title: 'R09', key:'R09'},
  { title: 'R10', key:'R10'},
  { title: 'R11', key:'R11'},
  { title: 'R12', key:'R12'},
  { title: 'R13', key:'R13'},

]

const CaseForm =  Form.create()(
  class extends Component {
    
    state = {
      regionsList: regions,
      provincesHolder: [],
      provinceList: []
    } 

    handleSubmit = (e) => {
      e.preventDefault();

      this.props.form.validateFields((err, values) => {

        values.date_of_birth =  values.date_of_birth.format('DD-MM-YYYY');
        values.type_of_disability = values.with_disability == 1 ?  " " : values.type_of_disability
      
        if (!err) {
         this.props.onSave(values);
        }

      });
    }

  onWithDisabilityChange = (e) => {
      if (e.target.value == 1) {
          this.props.form.setFieldsValue({type_of_disability: ''});
      }
  }

  returnMatches = (value) => {
    let regionsList = [];
    regions.forEach((item) => {
      let equalizer = value.toLowerCase();
      let temp = item.title.toLowerCase().search(equalizer);
      
      if(temp > -1) {
        regionsList.push(item)
      }
    })

    this.setState({ regionsList })
  }

  returnMatches_province = (value) => {
    let provinceList = [];
    this.state.provincesHolder.forEach((item) => {
      let equalizer = value.toLowerCase();
      let temp = item.toLowerCase().search(equalizer);
      
      if(temp > -1) {
        provinceList.push(item)
      }
    })

    this.setState({ provinceList })
  }

  setProvinceList = (itemKey) => {
    let province = provinces.find(item => item.key === parseInt(itemKey));
    this.setState({ 
      provinceList : province.list,
      provincesHolder : province.list
    })
  }

    onWithDisabilityChange = (e) => {
        if (e.target.value == 1) {
            this.props.form.setFieldsValue({type_of_disability: ''});
        }
    }

    render() {
      const { getFieldDecorator, getFieldValue } = this.props.form;
      const edit = sessionStorage.getItem('victim_edit');
      const victims = this.props.victims;
      const disabledInput = getFieldValue('with_disability');
      const { regionsList, provinceList } = this.state;
      // const search_victim = this.props.search_victim;
      // console.log('hey', disabledInput)
      return (
        <Form onSubmit={this.handleSubmit}>
         <Row style={{padding: '20px 20px 0px 20px'}}>
             <Col span={12}>
                 <h1 className="chr-font">{ edit == "true" ? "Modify Victim Information" : "Adding of Victim " }</h1>
             </Col> 

             <Col span={12}>
                 <Form.Item className="form-case-add">
                   <Button className="chr-btn" type="primary" htmlType="submit" style={{margin: '0px 20px'}}>{edit == "true" ? "Save" : "Continue"}  </Button>
                   <Button className="chr-close-btn" icon="cross"  onClick={this.props.onCancel}/>
                 </Form.Item>
             </Col>
         </Row>
         <Row>
          <Col className="form-case-add-row"></Col>
        </Row>

        <div style={{margin: "50px 10px 0px 10px"}}> 
         { edit == "true" ? <div></div> : <VictimTabs history = {this.props.history.location.pathname}/> }
        </div>
      
      {/* start form */}
      
      <div style={{margin: '0px 20px'}}>
      <Form.Item>
            {getFieldDecorator('victim_identifier', {
              rules: [{ required: false}],
              initialValue: "V12-256"
            })(
              <Input hidden />
            )}
        </Form.Item>

        <Row  gutter={16}>
          <Col span={5}>
             
            <Form.Item label="Name of Victim">
              { getFieldDecorator('last_name', {
                rules: [{ required: true, message: 'Please input your fields' }],
                initialValue: edit == "true"  ? victims ? victims.last_name : "" : ""
              })(
                <Input placeholder="Last Name" />
              )}
            </Form.Item>
          </Col>
         <div style={{margin: "40px 0px"}}>       
            <Col span={5} >
                <Form.Item  >
                {getFieldDecorator('first_name', {
                    rules: [{ required: true, message: 'Please input your fields' }],
                    initialValue: edit == "true" ? victims ? victims.first_name : "" : ""
                })(
                    <Input placeholder="First Name " />
                )}
                </Form.Item>
            </Col>

            <Col span={5}>
                <Form.Item >
                {getFieldDecorator('middle_name', {
                    rules: [{ required: false, message: 'Please input your fields' }],
                    initialValue: edit == "true" ? victims ? victims.middle_name : "" : ""
                })(
                    <Input placeholder="Middle Name " />
                )}
                </Form.Item>
            </Col>

            <Col span={5}>
                <Form.Item >
                {getFieldDecorator('alias', {
                    rules: [{ required: false, message: 'Please input your fields' }],
                    initialValue: edit == "true" ? victims ? victims.alias : "" : ""
                })(
                    <Input placeholder="Alias (Optional)" />
                )}
                </Form.Item>
            </Col>
         </div>
        </Row>

    {/* Date of birth  */}

        <Row  gutter={16}>
          <Col span={5}>
            <Form.Item label="Date of Birth">
              {getFieldDecorator('date_of_birth', {
                rules: [{ required: true, message: 'Please input your fields' }],
                initialValue:  edit == "true" ? victims ? moment(victims.date_of_birth, "DD-MM-YYYY") : moment() : ""  
              })(
                <DatePicker format={"DD-MM-YYYY"}/>
                
              )}
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item label="Assigned Sex">
              {getFieldDecorator('sex', {
                rules: [{ required: true, message: 'Please input your fields' }],
                initialValue: edit == "true" ? victims ? victims.sex : "" : <span style={{color: '#C8C8C8'}}>Select gender</span>
              })(
                <Select placeholder="Select gender" >
                    <Select.Option key="male" value="male">Male</Select.Option>
                    <Select.Option key="female" value="female">Female</Select.Option>
                    <Select.Option key="no_information " value="no_information ">No Information</Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

    {/* Disability */}

        <Row  gutter={16}>
          <Col span={5}>
            <Form.Item >
              {(
                <label> <b>Victim has Disability? </b></label>
              )}
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item>
              {getFieldDecorator('with_disability', {
                rules: [{ required: false, message: 'Please input your fields' }],
                initialValue: edit == "true" ? victims ? victims.with_disability : 0 : 1
              })(
                <RadioGroup name="radiogroup" onChange={(e) => this.onWithDisabilityChange(e)}>
                    <Radio value={0}>Yes</Radio>
                    <Radio value={1}>No</Radio>
                </RadioGroup>
              )}
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item >
                <label>Type of Disability:</label>
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item >
              {getFieldDecorator('type_of_disability', {
                rules: [{ required: false, message: 'Please input your fields' }],
                initialValue: edit == "true" ? victims ? victims.type_of_disability : "" : ""
              })(
                <Input placeholder="Disability" disabled={ disabledInput == 0 ? false : true}/>
              )}
            </Form.Item>
          </Col>
        </Row>
     
     {/* location */}

        <Row  gutter={16}>
          <Col span={5}>
            <Form.Item label="Location">
              {getFieldDecorator('region', {
                rules: [{ required: true, message: 'Please input your fields' }],
                initialValue: edit == "true" ? victims ? victims.region : "" : ""
              })(
                // <Input placeholder="Region" />
                // <Select placeholder="Select Region" >
                //     {
                //       REGIONS.map((item)=>{
                //         return <Select.Option  key={item.key} value={item.title}>{item.title}</Select.Option>
                //       })
                //     }
                // </Select>
                
                <AutoComplete                   
                    placeholder="Select Region"
                    onSearch={ this.returnMatches }
                    onSelect={ (a, b) => this.setProvinceList(b.key) }>

                    {
                      regionsList.map((item)=>{
                        return <AutoComplete.Option  key={item.key} value={item.value}>{item.title}</AutoComplete.Option>
                      })
                    }
                </AutoComplete>
              )}
            </Form.Item>
          </Col>
         <div style={{margin: "40px 0px"}}>       
            <Col span={5} >
                <Form.Item  >
                {getFieldDecorator('province', {
                    rules: [{ required: true, message: 'Please input your fields' }],
                    initialValue: edit == "true" ? victims ? victims.province : "" : ""
                })(
                    // <Input placeholder="Province" />
                    <AutoComplete 
                      placeholder="Province"
                      onSearch={ this.returnMatches_province }>
                      {
                        provinceList.map((item)=>{
                          return <AutoComplete.Option  key={item} value={item}>{item}</AutoComplete.Option>
                        })
                      }
                    </AutoComplete>
                )}
                </Form.Item>
            </Col>

            <Col span={5}>
                <Form.Item >
                {getFieldDecorator('municipality', {
                    rules: [{ required: false, message: 'Please input your fields' }],
                    initialValue: edit == "true" ? victims ? victims.municipality : "" : ""
                })(
                    <Input placeholder="Municipality/City" />
                )}
                </Form.Item>
            </Col>

            <Col span={5}>
                <Form.Item >
                {getFieldDecorator('barangay', {
                    rules: [{ required: false, message: 'Please input your fields' }],
                    initialValue: edit == "true" ? victims ? victims.barangay : "" : ""
                })(
                    <Input placeholder="Barangay" />
                )}
                </Form.Item>
            </Col>

            <Col span={5}>
                <Form.Item >
                {getFieldDecorator('district', {
                    rules: [{ required: false, message: 'Please input your fields' }],
                    initialValue: edit == "true" ? victims ? victims.district : "" : ""
                })(
                    <Input placeholder="Area/District" />
                )}
                </Form.Item>
            </Col>
         </div>
        </Row>

    {/* Eduactional Attainement */}
        
        <Row  gutter={16}>
          <Col span={13}>
            <Form.Item label="Educational Attainment">
              {getFieldDecorator('educational_attainment', {
                rules: [{ required: false, message: 'Please input your fields' }],
                initialValue: edit == "true" ? victims ? victims.educational_attainment : "" : ""
              })(
                <Input placeholder="Elementary, High School, College"/>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row  gutter={16}>
          <Col span={13}>
            <Form.Item label="Source on Income">
              {getFieldDecorator('source_of_income', {
                rules: [{ required: false, message: 'Please input your fields' }],
                initialValue: edit == "true" ? victims ? victims.source_of_income : "" : ""
              })(
                <Input placeholder="Work"/>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row  gutter={16}>
          <Col span={13}>
            <Form.Item label="Victim Tags">
              {getFieldDecorator('victim_tags', {
                rules: [{ required: true, message: 'Please input your fields' }],
                initialValue: edit == "true" ? victims ? victims.victim_tags : [] : []
              })(
                <Select mode="tags" placeholder="Tags"/>
              )}
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
      );
    }
  }
)

class CaseAdd extends Component {

  componentDidMount() {
    const victim_id = this.props.history.location.state === undefined ? null : this.props.history.location.state.victim_id;
    this.props.victimGet(victim_id);
  }

  callback = (response) => {
    const id = response.data.victim.id;
    if(response.status == 200){
      this.props.history.push(`/chr/quick/add/upload/${id}`); 
    }
  }
  
  onSave = (params)=>{
    const case_id = sessionStorage.getItem('quick_case_id');
    this.props.victimAdd(params, this.callback, case_id);
  } 

  onCancel=()=>{
    this.props.history.goBack();
  }

  editCallback = (response)=>{
    if(response.status == 200){
      notification.success({ message: "Modify success!"});
      this.props.history.goBack();
    }
  }
    
  onEdit = (params)=>{
    this.props.victimEdit(params, this.editCallback)
  }


render(){
  // const search_victim = this.props.history.location.state === undefined ? false : this.props.history.location.state.victim_search;
    return(
        <div className="contentDiv">
          <CaseForm
                history={this.props.history}
                onSave = {this.onSave} 
                onCancel={this.onCancel}
                victims={this.props.victims}
                onEdit ={this.onEdit}
                // search_victim ={search_victim}
                />
        </div>
    );
  }
}

 const mapStateToProps = (state) => {
  return {
    victims: state.case.victimDetails
  }
}
const mapDispatchToProps = {
  victimAdd, victimGet, victimEdit
} 

export default connect(mapStateToProps, mapDispatchToProps)(CaseAdd); 