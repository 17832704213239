import React, { Component } from 'react'
import { Row, Col, Layout, Tree, Button, Icon } from 'antd';
import {Switch, Route, HashRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {victimAccountList, victimActList, caseGet, victimList} from '../../../cases/actionCreator';
import {get} from 'lodash';
import AccountSource from './account/addVictimSource';
import AccoutAdding from './account/addVictimAccount';
import AccountUpload from './account/addVictimSourceUpload';

import Act from './act/addVictimAct';
import Assaillant from './act/addVictimAssailant';
import ActUpload from './act/addVictimActUpload';

import '../../../css/style.css';

const { Content } = Layout;
const { TreeNode } = Tree;
const DirectoryTree = Tree.DirectoryTree;

class viewDocuments extends Component {
    state = {
      expanded_node: null
    }

componentDidMount(){
    const case_id = sessionStorage.getItem('quick_case_id');
    const victim_id = sessionStorage.getItem('quick_victim_id');

    this.props.caseGet(case_id);
    this.props.victimList(case_id);
    this.props.victimAccountList(case_id, victim_id);
    this.props.victimActList(case_id, victim_id);
  }


componentDidUpdate (props, states) {
  const case_id = sessionStorage.getItem('quick_case_id');
  const victim_id = sessionStorage.getItem('quick_victim_id');
  
  if(props.victim !== this.props.victim){
    setTimeout(()=>{
      this.props.victimList(case_id);
    }, 3000)
  }

  if(props.actList !== this.props.actList){
    setTimeout(()=>{
      this.props.victimActList(case_id, victim_id);
    }, 3000)
  }

  if(props.accountList !== this.props.accountList){
    setTimeout(()=>{
      this.props.victimAccountList(case_id, victim_id);
    }, 3000)
  }

}

  renderTreeNodes = (data) => {
    const account = this.props.accountList === undefined ? [] : this.props.accountList;
    const acts = this.props.actList === undefined ? [] : this.props.actList;
    const victim = this.props.victim === undefined ? [] : this.props.victim;
    return (
  
      <Tree 
       showLine
       autoExpandParent={ true }
       onSelect={ this.handleTreeSelect }
       expandedKeys={ this.state.expanded_node === null ? [] : this.state.expanded_node } 
      >
        { victim.map( (parent, index)=>{
           return (
            <TreeNode  
                key = { parent.id }
                title = { parent.first_name+parent.last_name+index} >
                   
                   <TreeNode  
                      key ={ "account"+'_'+index }
                      title = {
                      <span>
                        <span>Accounts </span>
                         <a style={{   marginRight: 'auto', marginLeft: '113px'}}> 
                            <Button icon="plus"  className="tree-button" 
                              onClick={(argss, type = parent.id)=>{ 
                                sessionStorage.setItem('victim_id', type);
                                this.props.history.push('/chr/quick/add/mulitple')} }>  
                                add
                            </Button>
                         </a>
                      </span> } />

                      { account.filter(item=> item.victim_id === parent.id).map(
                            (level, index) => {
                                return (
                                        <TreeNode 
                                            icon={ <Icon type="folder" /> }    
                                            key={ level.account_name+'_'+index }
                                            title={  <span> {level.account_name} </span>}>
                                                <TreeNode  
                                                    title = {
                                                    <span>
                                                      <span>Acts </span>
                                                      <a style={{   marginRight: 'auto', marginLeft: '130px'}}> 
                                                        <Button icon="plus" className="tree-button" 
                                                            onClick={(argss, type = level.id)=>{ 
                                                              sessionStorage.setItem('account_id', type);
                                                              this.props.history.push('/chr/quick/add/mulitple/act')} }> 
                                                              add
                                                            </Button> 
                                                      </a>
                                                    </span> } />
                                                
                                                { this.loopThroughChildren(acts, level.id) }
                                                
                                        </TreeNode>
                                );            
                            })}

            </TreeNode  >
            )}) }
    </Tree>
    )
}

  loopThroughChildren (level, account_id) {  
    return(
        level ? level.filter(item=> item.account_id === account_id).map(
            (child, index) => {  
                return(
                    <TreeNode 
                        icon={<Icon type="folder" />}                                    
                        key = {child.act_name+'_'+index+child.id}
                        title = {child.act_name }>
                    </TreeNode>
                );
            }) : null
        );
      }

  handleTreeSelect = (selectedKeys, e) => {
    const victim_id = sessionStorage.getItem('quick_victim_id');
    const case_id = sessionStorage.getItem('quick_case_id');

    if(selectedKeys === this.state.expanded_node){
      return false;
    } else {
      this.setState({ expanded_node: selectedKeys});
      this.props.victimAccountList(case_id, victim_id);
      this.props.victimActList(case_id, victim_id);
      this.props.victimList(case_id);
    }
  }

  render() {
    const caseGet = this.props.caseList;
    return (
        <div style={{ minHeight: '85vh'}}>
         
          <Row gutter={12}>
                <Col span={8}>
                  <div style={{boxShadow: '1px 1px 5px #C8C8C8', background: 'white', minHeight: '80vh', padding: '30px', }}> 

                  <div className="chr-row">
                    <Row style={{ marginBottom: '20px'}}>  
                        <Col span={15}>
                          <h3 className="chr-font">{get(caseGet, 'case_identifier')}</h3>
                          <h5 style={{fontSize: '12px', color: '#21568E', fontWeight: 'bold'}}> Case Type: &nbsp; {get(caseGet, 'case_type')}</h5>
                        </Col>

                        <Col span={3}>
                        <Button type="primary" ghost onClick={()=> { this.props.history.push('/chr/quick/add/victim')}} style={{margin: '20px 0px 0px 30px'}}>Add Victim</Button>
                        </Col>
                    </Row>
                  </div>

                  <div style={{marginTop: '30px'}}>
                     { this.renderTreeNodes() }
                  </div>

                  </div>
                </Col>

              <Layout>
                <Content>
                  <Col span={24} style={{boxShadow: '1px 1px 5px #C8C8C8', background: 'white', minHeight: '80vh'}}>
                      <HashRouter>
                        <Switch>
                            <Route path="/chr/quick/add/mulitple" exact component={AccountSource} /> 
                            <Route path="/chr/quick/add/mulitple/account" exact component={AccoutAdding} /> 
                            <Route path="/chr/quick/add/mulitple/upload" exact component={AccountUpload} /> 

                            <Route path="/chr/quick/add/mulitple/act" exact component={Act} /> 
                            <Route path="/chr/quick/add/mulitple/act/assaillant" exact component={Assaillant} /> 
                            <Route path="/chr/quick/add/mulitple/act/upload" exact component={ActUpload} /> 
                        </Switch>
                      </HashRouter>
                  </Col>
                </Content>
              </Layout>
            
          </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountList: state.case.victimAccount.data,
    actList: state.case.acts.data,
    caseList: state.case.caseGet,
    victim: state.case.ListVictim.data
  }
}

const mapDispatchToProps = {
  victimAccountList, victimActList, caseGet, victimList
}

  export default connect(mapStateToProps, mapDispatchToProps)(viewDocuments);