import { VICTIM } from './actionCreators';
import { victimList } from './../search_tables/actionCreators';

const INITIAL_STATE = {
    loading: {},
    error: '',
    victims: {},
    caseEnrolled: {},
    caseVictimList: {}
}

 const VICTIM_REDUCER = (state = INITIAL_STATE, action)=>{
    switch (action.type) {

        case VICTIM.VICTIM_LIST_START:
            return {...state, loading: true, error: '' }
        case VICTIM.VICTIM_LIST_SUCCESS:
            return {...state, loading: false, victims: action.payload }
        case VICTIM.VICTIM_LIST_FAILED:
            return {...state, loading: false, error: action.payload }
        
        
        case VICTIM.CASE_ENROLLED_LIST_START:
            return {...state, loading: true, error: '' }
        case VICTIM.CASE_ENROLLED_LIST_SUCCESS:

            return {...state, loading: false, caseEnrolled: action.payload }
        case VICTIM.CASE_ENROLLED_LIST_FAILED:
            return {...state, loading: false, error: action.payload }


        case VICTIM.CASE_LIST_VICTIM_START:
            return {...state, loading: true, error: '' }
        case VICTIM.CASE_LIST_VICTIM_SUCCESS:
                const { data, id } = action.payload;
                const victimList = state.victims;
                // const specificVictim = victimList.data.find(e => e.case_id == id);

                for( var i=0; i < victimList.data.length; i++){
                    if(victimList.data[i].case_id == id ){
                    }
                }
                // specificVictim.cases = data;
                // console.log(specificVictim)
            // return {...state, loading: false, victims: victimList }

        case VICTIM.CASE_LIST_VICTIM_FAILED:
            return {...state, loading: false, error: action.payload }
        
        case VICTIM.VICTIM_CASE_LIST_START:
            return {...state, loading: true, error: '' }
        case VICTIM.VICTIM_CASE_LIST_SUCCESS:
            return {...state, loading: false, caseVictimList: action.payload }
        case VICTIM.VICTIM_CASE_LIST_FAILED:
            return {...state, loading: false, error: action.payload }

        default:
           return state;
    }
}

export default VICTIM_REDUCER;



