import React, { Component } from 'react'
import { Table, Icon } from 'antd';
import Tags from '../../../shared/components/tags';

 class case_table extends Component {
   constructor(props){
    super(props);

     this.columns = [
        {
          title: 'Act Name',
          dataIndex: 'act_name',
          key: 'name',
          render: (record, text, index)=>{
            return <div><Icon className="case-icon-table" type="folder-open"  theme="filled"/> <span className="name_folder_icon">{record}</span></div>
          }
        },
        {
          title: 'Case Name',
          dataIndex: 'case_identifier',
          key: 'case_identifier',
        },
        {
          title: 'Victim Name',
          key: 'victim_name',
          render: (record, text) => {
            return <div>{text.victim_first_name} {text.victim_last_name}</div>
          }
        },
        {
          title: 'Account Name',
          dataIndex: 'account_name',
          key: 'account_name',
        },
        {
          title: 'Account Source Name',
          dataIndex: 'account_source_name ',
          key: 'account_source_name ',
          render: (value, record) => {
              return <span>{record.first_name} {record.last_name}</span>
          }
        },
        {
          title: 'Tags',
          dataIndex: 'tags',
          key: 'tags',
          render: (record, text, index)=>{
            const arr2 = text.act_tags;
            const arr1 = arr2.length;
            const arr3 = arr2.slice(0, 2);
            const id = text.id;
            return (
                    <Tags 
                      arr2={arr2} 
                      arr1={arr1} 
                      arr3={arr3} 
                      id={id} 
                    />
                  )
          }
       
        }, 
        {
          title: 'Act type',
          dataIndex: 'type_of_act',
          key: 'type_of_act',
        }
     ] 
  
  }

  render() {
    return (
      <div>
        <Table 
          style={{marginTop: '30px'}}
          columns ={this.columns}
          dataSource={this.props.data.data}
          onRowClick={ (value)=>{
            this.props.history.push('/chr/case/victim/act/view'); 
            sessionStorage.setItem('case_id', value.case_id);
            sessionStorage.setItem('case_title', value.case_identifier);
            sessionStorage.setItem('victim_id', value.victim_id);
            sessionStorage.setItem('victim_title', value.victim_first_name +" "+ value.victim_last_name);
            sessionStorage.setItem('account_id', value.account_id);
            sessionStorage.setItem('account_title', value.account_name);
            sessionStorage.setItem('act_id', value.id);
            sessionStorage.setItem('act_title', value.act_name);
            } 
          }
        />
      </div>
    )
  }
}
 
export default case_table;