import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userRequestList, approveRequest, declineRequest } from '../actionCreators'
import {Table, Button, Icon, notification} from 'antd';

class UserRequest extends Component {

    componentDidMount() {
        this.props.userRequestList();
    }

    componentDidUpdate(props, state){
        if(props.requests !== this.props.requests){
           setTimeout(()=>{
            this.props.userRequestList();
           }, 500)
        }
    }

    constructor(props){
        super(props);

        this.columns = [ 
            {
                title: "Email",
                dataIndex: 'email'
            },
            {
                title: "New Email", 
                dataIndex: 'change_password_email'
            },
            {
                title: "Date Created",
                dataIndex: 'created_at'
            },
            {
                title: "Last Date Modified", 
                dataIndex: 'updated_at'
            },
            {
                title: "Approve/Decline",
                render: (record, text, index)=>{
                    return (
                        <div>
                            <Button 
                                className="chr-btn-gen" 
                                type="link" 
                                icon="check"
                                onClick={()=>{this.approvedRequest(record, text, index) }}
                                style={{backgroundColor: '#92E688', margin: '0px 10px', color: '#9AA0A5 '}}
                            >  
                            </Button>
                            <Button 
                                className="chr-btn-gen" 
                                type="link" 
                                icon="cross"
                                onClick={()=>{this.declinedRequest(record, text, index)}}
                                style={{backgroundColor: '#FFAEAE', color: '#9AA0A5'}}
                            > 
                            </Button>
                        </div>
                    )
                }
            }
        ]
    }
    
    approvedRequest = (record, text, index ) => {
        const approved = {
            email: record.email,
            change_password_email: record.change_password_email
        }
        this.props.approveRequest(approved, this.approveCallback)
    }

    declinedRequest = (record, text, index ) => {
        const declined = {
            email: record.email,
            change_password_email: record.change_password_email
        }
        this.props.declineRequest(declined)
    }

    approveCallback(response) {
        if(response.status === 200) {
            notification.success({ message: "User Request is Approved!" })
        }
    }


    render() {
        const data = this.props.requests.data === undefined ? [] : this.props.requests.data
        
        return (
            <div style={{margin: "20px"}}>
                <div style={{marginTop: '40px', fontSize: "20px", fontWeight: "bold" }}> 
                    <a style={{color: "#22568f"}}>
                        <Icon type="solution"/>&nbsp;&nbsp;&nbsp;
                        <span className="chr-font-sm">User Requests</span>
                    </a>
                     
                </div>
                <Table 
                    columns={this.columns}
                    dataSource={data}
                    rowKey="id"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        requests: state.user.userRequests
    }
}

const mapDispatchToProp = {
    userRequestList, approveRequest, declineRequest
}

export default connect(mapStateToProps, mapDispatchToProp)(UserRequest)