import React, { Component } from 'react'
import { Icon, Button, Row, Col, Tabs  } from 'antd';
import { connect } from 'react-redux';
import { 
          victimGet, 
          caseList, 
          caseTypeList, 
          caseStatusList, 
          victimHistoryList, 
          multipleDownloadFile } from '../../cases/actionCreator';
import { caseEnrolled } from '../actionCreators';
import { Link } from 'react-router-dom';
import { userList } from '../../users/actionCreators';
import Enrolled from './tabs/enrolled';
import Files from './tabs/files';
import History from './tabs/history';
import Breadcrumps from '../../../shared/components/breadcrumpsVictim';
import _ from 'lodash';


const TabPane = Tabs.TabPane;

 class viewForm extends Component {

  componentDidMount(){
    const id = this.props.match.params.id;
    this.props.victimGet(id)
    this.props.caseList();
    this.props.caseTypeList(); 
    this.props.caseStatusList();
    this.props.victimHistoryList(id);
    this.props.caseEnrolled();
    this.props.userList();
  }

  componentDidUpdate(prevProps){
    if(this.props.enrollCase.data !== prevProps.enrollCase){
      setTimeout(()=>{
        this.props.caseEnrolled();
      }, 1000)
    }
  }

  userPermission = () => { 
    const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;

    if (userRole === "viewer" || userRole === "contributor"){
          return(
                <Button 
                    className="chr-font-light"
                    disabled={true}
                    type="primary"
                    style={{ float: 'right', margin: '20px 30px', height: '40px'}} 
                    icon="edit">
                      Edit
                </Button>
          ) 
      } else {
          return (
                 <Link to ={'/chr/case/add/victim/form'} >
                    <Button 
                      type="primary" ghost
                      style={{ float: 'right', margin: '20px 30px', height: '40px'}}  
                      icon="edit" 
                      onClick= {()=>{sessionStorage.setItem('victim_edit', true)}}
                      >
                        Edit
                    </Button>
                </Link>
          )
      }
  }

  CaseDetails = () =>{
      const victim = this.props.victim;
      const firstName = victim.first_name == null ? " " :  victim.first_name;
      const lastName = victim.last_name == null ? " " :  victim.last_name;
      const middleName = victim.middle_name == null ? " " :  victim.middle_name;
      const name = firstName +" "+ middleName +" "+ lastName;

    return  <div style={{width: '97%', height: '100%', boxShadow: '1px 1px 7px #D1D1D1', borderRadius: '5px', margin: '40px 20px 0px 20px ', background: 'white'}}>
              <div style={{padding: "20px 10px"}}>
                <div>
                  <Icon type='folder-open' className="icon-folder" theme="filled" style={{margin: '20px 6px 20px 20px', fontSize: '25px', fontWeight: "bold", color: "#22568f" }} />
                  
                { 
                  this.userPermission()
                }

                  <span className="chr-font-sm"> {name} </span>  
                </div>

                <div style={{margin: "20px 20px"}}>
              
                  <Row style={{padding: "10px"}}>
                    <Col span={5} className="row-label-name">
                    Age of Victim:
                    </Col> 
                    <Col span={5} className="row-details-name">
                    {victim.age}
                    </Col>

                    <Col span={5} className="row-label-name">
                      Educational Attainment:
                    </Col>
                    <Col span={5} className="row-details-name">
                    {victim.educational_attainment}
                    </Col>
                  </Row>

                  <Row style={{padding: "10px"}}>
                    <Col span={5} className="row-label-name">
                    Assigned Sex:
                    </Col>
                    <Col span={5} className="row-details-name">
                    {victim.sex}
                    </Col>

                    <Col span={5} className="row-label-name"> 
                      Source of Income:
                    </Col>
                    <Col span={5} className="row-details-name">
                    {victim.source_of_income}
                    </Col>
                  </Row>

                  <Row style={{padding: "10px"}}>
                    <Col span={5} className="row-label-name">
                      Disability: 
                    </Col>
                    <Col span={5} className="row-details-name">
                      {/* {victim.type_of_disability } */}
                      {victim.type_of_disability ? victim.type_of_disability : "n/a"} 
                    </Col>
                  </Row>

                  <Row style={{paddingLeft: "10px"}}>
                    <h3 className="chr-font-ss"style={{paddingTop: '30px'}}>Location</h3>
                  </Row>

                  <Row style={{padding: "10px"}}>
                    <Col span={5} className="row-label-name">
                    Region:
                    </Col>
                    <Col span={5} className="row-details-name">
                    {victim.region}
                    </Col>

                    <Col span={5} className="row-label-name">
                    Municipality/City:
                    </Col>
                    <Col span={5} className="row-details-name">
                    {victim.municipality}
                    </Col>
                  </Row>

                  <Row style={{padding: "10px"}}>
                    <Col span={5} className="row-label-name">
                      Province:
                    </Col>
                    <Col span={5} className="row-details-name">
                    {victim.province}
                    </Col>

                    <Col span={5} className="row-label-name">
                    Barangay:
                    </Col>
                    <Col span={5} className="row-details-name">
                    {victim.barangay}
                    </Col>
                  </Row>

                  <Row style={{padding: "10px"}}>
                    <Col span={5} className="row-label-name">
                    Area/District:
                    </Col>
                    <Col span={5} className="row-details-name">
                      {victim.district}
                    </Col>
                  </Row>

                </div>
            
                <div style={{margin: "30px 20px"}}>
                  {
                    ! victim.victim_tags ? [] :
                      victim.victim_tags.map(e=>{
                      return <span key={e} className="tags-for-card">{e}</span>
                    })
                  }
                </div> 
              </div>
            </div>
  }

    
    TabsPanesList = () => { 
    const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;

    if (userRole === "viewer" || userRole=== "contributor" || userRole === "editor" ){
          return(
            <div className="card-container" style={{margin: "10px 20px"}}>
            <Tabs type="card">

              <TabPane tab="Enrolled Cases" key="2">
                <Enrolled 
                    victim ={this.props.victim} 
                    case={this.props.case} 
                    enrollCase={this.props.enrollCase} 
                    status= {this.props.status} 
                    type={this.props.type} 
                    history ={this.props.history} 
                    match = {this.props.match}
                    users={this.props.users}
                  />
              </TabPane>

              <TabPane tab="Files" key="3" >
                <Files multipleDownloadFile={this.props.multipleDownloadFile} login = {this.props.login } users={this.props.users} victim ={this.props.victim} history={this.props.history}/>
              </TabPane>

          </Tabs>
      </div>
          ) 
      } else {
          return (
            <div className="card-container" style={{margin: "10px 20px"}}>
            <Tabs type="card">

              <TabPane tab="Enrolled Cases" key="2">
                <Enrolled 
                    victim ={this.props.victim} 
                    case={this.props.case} 
                    enrollCase={this.props.enrollCase} 
                    status= {this.props.status} 
                    type={this.props.type} 
                    history ={this.props.history} 
                    match = {this.props.match}
                    users={this.props.users}
                  />
              </TabPane>

              <TabPane tab="Files" key="3" >
                 <Files multipleDownloadFile={this.props.multipleDownloadFile} login = {this.props.login } users={this.props.users} victim ={this.props.victim} history={this.props.history}/>
              </TabPane>

              <TabPane tab="History" key="4">
                  <History user={this.props.users} logs= {this.props.victim_history_list}/>
              </TabPane>
              
          </Tabs>
      </div>
          )
      }
  }

  render() {
    const victim = this.props.victim;
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
          <div><Breadcrumps history ={this.props.history} victim={victim}  match = {this.props.match}/></div>
          <div> { this.CaseDetails() } </div>
          <div> { this.TabsPanesList() } </div>
      </div>
    )
  }
}

 const mapStateToProps = (state) => {
  return {
    victim : state.case.victimDetails,
    case: state.case.case,
    status: state.case.status.data,
    type: state.case.type.data,
    victim_history_list: state.case.victim_history_list,
    enrollCase: state.victim.caseEnrolled,
    users: state.user.users,
    login: state.login
  }
}

 const mapDispatchToProps = {
    victimGet, 
    caseList, 
    caseTypeList, 
    caseStatusList, 
    victimHistoryList, 
    caseEnrolled, 
    userList, 
    multipleDownloadFile
 }
  
export default connect(mapStateToProps, mapDispatchToProps)(viewForm);