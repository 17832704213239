import React, { Component } from 'react'
import { Table, Icon,  Checkbox} from 'antd';
import { CaseArchived } from '../../actionCreators';
import { connect } from 'react-redux';
import Tags from '../../../../shared/components/tags';
import Modal from '../../components/modal';

class TableList extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            expanded: 0
        }

        this.columns = [
            {
                title: 'Case Name',
                dataIndex: 'case_identifier',
                key: '1',
                sorter: true,
                render: (record, text, index)=>{
                return <div><Icon className="case-icon-table" type="folder-open"  theme="filled"/>  <span className="name_folder_icon">{record}</span></div>
                }
            },{
                title: 'Type',
                dataIndex: 'case_type',
                filters : [{'value': '', 'text': ''}],
                key: '2',
                // sorter: true,
                // render: (record, text)=>{
                //     const type = this.props.type;
                //     const length = type ? type.length : 0;

                // if(type) {
                //     for(var i = 0 ; i < length; i++){
                //         if(type[i].id == record){
                //             var data =  type[i] ;
                //         }
                //     } 
                //    return <div>{data != undefined ? data.case_type : " "}</div>
                //   }
                // }
            },
            {
                title: 'Tags',
                dataIndex: 'case_tags',
                key: '3',
                filters : [{'value': '', 'text': ''}],
                width: '200px',
                render: (record, text, index)=>{
                    const arr2 = text.case_tags;
                    const arr1 = arr2.length;
                    const arr3 = arr2.slice(0, 2);
                    const id = text.id;
                return (
                    <Tags 
                        arr2={arr2} 
                        arr1={arr1}     
                        arr3={arr3} 
                        id={id} />
                      )
              }
            },
            {
             title: 'Victims',
             dataIndex: 'case_victims',
             sorter: true,
             render: (text, record)=>{
                let case_victims = record.case_victims == undefined ? []: record.case_victims;
                let count = 0;
                let countPlusone = case_victims.filter((item)=>{
                    if(item.is_archived === 0){
                        count = count + 1 ;
                    }
                })

                return <div>{count}</div>
             }
            },{
                title: 'Status',
                dataIndex: 'case_status',
                key: 'case_status',
                filters : [{'value': '', 'text': ''}],
                // sorter: true,
                // render: (record, text, index)=>{
                //     const status = this.props.status;
                //     const length = status ? status.length : 0;
                // if(status) {
                //     for(var i = 0; i<length; i++){
                //         if(status[i].id == record){
                //             var data = status[i];
                //         }
                //     }
                //     return <div>{data != undefined ? data.case_status : " "}</div>
                //     }
                // }
            },{
                title: 'Date Created',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
            },{
                title: 'Date Modified',
                dataIndex: 'updated_at',
                key: 'updated_at',
                sorter: true,
            },{
                title: 'Created by',
                dataIndex: 'created_by',
                key: 'created_by',
                sorter: true,
                render: (record , text, index)=> {
                    const users = this.props.users.data;
                    const findData = users == undefined ? "" : users.find(e => e.id == record);
   
                     var user = findData.first_name +" "+ findData.last_name;
                     return <span>{user}</span>
                   }
            },
            {
            title: " ",
            dataIndex: "delete_checkbox",
            render: (record, text, index)=>{
                return (
                <Checkbox 
                    onClick={(e)=> { e.stopPropagation();}}
                    onChange={(e)=>{
                        let status = e.target.checked;
                        this.CaseArchivedList(text.id, status, index) }}
                    />)
                            }
                        }
                    ];

            this.state = {
                data: []
            }   
        }

    CaseArchivedList = (id, status, index)=>{
        let data = this.state.data;

        if (status === false) {
            data.splice(index, 1);
            this.setState({ data: data })
        } else {
            data.push({id})
        }
    }

    CaseArchived = () => {
        let dataFiles = this.state.data; 

        for (let a = 0; a < dataFiles.length; a++) {
        setTimeout(()=>{
            this.props.CaseArchived(dataFiles[a].id)
        }, 500)
        }
    }
    
    rowClick = (value, index) => {
        this.props.history.push({pathname: `/chr/victim/view/module/case` }) ;
        sessionStorage.setItem('case_id', value.id);
        sessionStorage.setItem('case_title', value.case_identifier);
    }
    
    chunkArray = (array, size)=>{
        var arrayLength = array.length;
        var tempArray = [];
        
        for (var index = 0; index < arrayLength; index += size) {
           var myChunk = array.slice(index, index+size);
           tempArray.push(myChunk);
        }
        
        return tempArray;
    }
    
    render() {
        const data= this.props.enrollCase.data;
        const columns = this.columns;
        return (
            <div style={{margin: "20px"}}>
                <div style={{margin: "40px 0px", fontSize: "20px", fontWeight: "bold" }}> 

                <a style={{color: "#22568f"}}>
                    <Icon type="solution"/>&nbsp;&nbsp;&nbsp;
                    <span className="chr-font-sm">Enrolled Case</span>
                </a>

                   <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                        <Modal CaseArchived={this.CaseArchived} case = {this.props.case}/>
                    </div> 
                </div>
              
                <Table 
                    columns = {columns}
                    dataSource={data}
                    rowKey="id"
                    onRowClick = {(value,  index)=>{ this.rowClick(value, index)  }}
                    
                />
            </div>
        )
    }
}

const mapDispatchToProps = {
    CaseArchived
}

export default connect(null, mapDispatchToProps)(TableList);