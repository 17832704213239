import React, { Component } from 'react'
import {Breadcrumb} from 'antd';

class BreadCrupms extends Component {
    constructor(props){
        super(props);
            this.state = {
                items: [],
                array: []
            }
        }
    render() {
        const case_title = sessionStorage.getItem('case_title');
        const victim = sessionStorage.getItem('victim_title') ;
        const account = sessionStorage.getItem('account_title');
        const act  = sessionStorage.getItem('act_title');  
        const account_module = sessionStorage.getItem('account_module_act');
        const history = this.props.history.location.pathname;
        const id = sessionStorage.getItem('victim_id');
        return (
            <div>       
               <div style={{margin: '0px 0px', position : 'fixed'}}  className="breadcrumps-list"> 
                    <Breadcrumb separator="&#8250;" 
                     style={{fontSize: "18px", fontWeight: "bold"}}>

                       <Breadcrumb.Item >
                            <a 
                            style={{
                                color: "white", 
                                fontSize: "20px",
                                fontWeight: 'bold', margin: '0px 0px 0px 20px'}} 
                                onClick={()=>{this.props.history.push('/chr/case'); }}>
                            Cases
                            </a>
                        </Breadcrumb.Item>
                      
                    { 
                      history == '/chr/case/view' || 
                      history == `/chr/case/victim/view` ||
                      history == `/chr/case/victim/act/view` ||
                      history == `/chr/case/victim/account/view` ?
                         <Breadcrumb.Item >
                            <a style={{  color: history == '/chr/case/view' ?'#22568f': 'white' }}
                             onClick={()=>{this.props.history.push('/chr/case/view')}}>{case_title}</a>
                        </Breadcrumb.Item>
                        : 
                        ""
                    }
                    { 
                     history == `/chr/case/victim/view` ||
                     history == `/chr/case/victim/act/view` ||
                     history == `/chr/case/victim/account/view` ?
                       
                        <Breadcrumb.Item >
                            <a style={{color:  history == `/chr/case/victim/view` ? "#22568f" : 'white'}} 
                             onClick={()=>{this.props.history.push(`/chr/case/victim/view`); }}>{victim}</a>
                        </Breadcrumb.Item>
                        :
                        ""
                    }

                   {    
                      history == `/chr/case/victim/account/view` ||  
                      history == `/chr/case/victim/act/view` && account_module == "1" ?
                       <Breadcrumb.Item>
                            <a style={{color: history == '/chr/case/victim/account/view' ?'#22568f': 'white'}} 
                             onClick={()=>{this.props.history.push('/chr/case/victim/account/view'); }}>{account}</a>
                        </Breadcrumb.Item>
                          :
                        ""
                    }
                    
                    {   
                        history == `/chr/case/victim/act/view`  ?
                        <Breadcrumb.Item>
                            <a style={{color: "#22568f"}} 
                             onClick={()=>{this.props.history.push('/chr/case/victim/act/view'); }}>{act}</a>
                        </Breadcrumb.Item>
                        : 
                       ""
                    }

                 
                   </Breadcrumb>
                </div>
                
            </div>
        )
    }
}

export default BreadCrupms;