import { LOGIN } from './ActionCreators';

const INITIAL_STATE = {
    loading: false,
    error: {},
    user: {},
    token: '',
};

const login = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case LOGIN.START: 
                return { ...state, loading: true, error: '' }
        case LOGIN.SUCCESS:
                const { user, token } = action.payload;
                return { ...state, loading: false, user, token }
        case LOGIN.ERROR: 
                return { ...state, loading: false, error: action.payload }
        case LOGIN.LOGOUT:
                return { ...state,  token: '', user: {} }


        default:
            return state;
    }
}

export default login; 