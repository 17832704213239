import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Redirect, NavLink, Route, Switch, HashRouter,   } from 'react-router-dom';
import '../../../css/style.css';
import { Layout, Menu, Icon, Button, Popover, Row, Col, notification, message } from 'antd';
import { logout } from '../../ActionCreators';
import logo from '../../../assets/Logo.png';
import HeaderName from '../../../assets/header.JPG';
import Moment from 'moment'; 
import Clock from 'react-live-clock';
import IdleTimer from 'react-idle-timer';

import Dashboard from '../../../dashboard/index';
import Case from '../../../cases/index';
import AddCase from '../../../cases/forms/_documents/forms/addForm';
import AddCaseUpload from '../../../cases/forms/_documents/forms/addFormUpload';
import ViewCaseList from '../../../cases/forms/_documents/viewForm';
import AddVictim from '../../../cases/forms/_case/forms/addVictim';
import FormVictim from '../../../cases/forms/_case/forms/addVictimForm';
import FormVictimUpload from '../../../cases/forms/_case/forms/addVictimUpload';
import FilesUpload from '../../../cases/forms/_files/addFilesUpload';
import ViewVictim from '../../../cases/forms/_victim/viewVictim';

import ViewAccounts from '../../../cases/forms/_victim/forms/viewVictimAccount';
import ViewVictimAdd from '../../../cases/forms/_victim/forms/addVictimSource';
import ViewVictimAddAccount from '../../../cases/forms/_victim/forms/addVictimAccount';
import ViewVictimUploadFiles from '../../../cases/forms/_victim/forms/addVictimSourceUpload';

import ViewVictimAct from '../../../../modules/cases/forms/_victim/forms/viewVictimAct'; 
import AddVictimAct from '../../../cases/forms/_victim/forms/addVictimAct'; 
import AddVictimActAssailant from '../../../cases/forms/_victim/forms/addVictimAssailant';
import AddVictimActUpload from '../../../cases/forms/_victim/forms/addVictimActUpload';

import CaseStatus from '../../../cases/configuration/caseStatus';
import CaseType from '../../../cases/configuration/caseType';
import ActType from '../../../cases/configuration/actType';
import AssaillantType from '../../../cases/configuration/assaillantType';
import AccountSource from '../../../cases/configuration/accountSource';
import Email from '../../../cases/configuration/emai';

import Victims from '../../../victims/index';
import VictimView from '../../../victims/forms/viewVictim';
import VitctimViewAct from '../../../victims/forms/_victim/viewVictim';
import VictimViewActModule from '../../../victims/forms/_victim/forms/viewVictimAct';
import VictimViewAccoountModule from '../../../victims/forms/_victim/forms/viewVictimAccount';
import VictimAddActModule from '../../../victims/forms/_victim/forms/addVictimAct';
import VictimAddActModule2 from '../../../victims/forms/_victim/forms/addVictimAssailant'; 
import VictimAddActModule3 from '../../../victims/forms/_victim/forms/addVictimActUpload';

import Documents from '../../../documents/index';
import ViewDocuments from '../../../documents/forms/viewDocuments';

import Trash from '../../../trash/index';
import Reports from '../../../reports/index';

import Users from '../../../users/index';
import UsersAdd from '../../../users/forms/userAddEdit';
import ViewUserList from '../../../users/forms/users';
import SeacrSelect from '../../../../shared/components/searchSelect';

import Search from '../../../search_tables/index';

import QuickUpload from '../../../quick-add/forms/upload';
import QuickVictim from '../../../quick-add/forms/victim/addVictim';
import QuickVictimAdd from '../../../quick-add/forms/victim/addVictimForm';
import QuickVictimUpload from '../../../quick-add/forms/victim/addVictimUpload';

import QuickAct from '../../../quick-add/forms/act/addVictimAct';
import QuickAssaillant from '../../../quick-add/forms/act/addVictimAssailant';
import QuickActUpload from '../../../quick-add/forms/act/addVictimActUpload';

import QuickAccount from '../../../quick-add/forms/account/addVictimSource';
import QuickAccountSource from '../../../quick-add/forms/account/addVictimAccount';
import QuickAccountUpload from '../../../quick-add/forms/account/addVictimSourceUpload';
import TreeNode from '../../../quick-add/forms/TreeNode/index';

import ForgotPassword from '../forgot_password/password';
import ChangePassword from '../change_password/change_passowrd'

const { Header, Sider, Content, Footer } = Layout;



class LandingPage extends Component {
   constructor(props){
       super(props);
        this.state = {
            collapsed: false,
            caseManagement: false,
            accountManagement: false,
            actManagement: false,
            emailManagement: false,
        };
   }

   content = ()=>{
     let data = this.props.login.user === undefined ? [] :  this.props.login.user;
    //  let userLog = this.props.user.logs === undefined ? [] : this.props.user.logs;
    let data2 = "account"
    return (
        <div>
          <a onClick={()=>{ 
              sessionStorage.setItem('user_id', data.id);
              // this.props.history.push('/chr/user/view/details')
              this.props.history.push({
                pathname: `/chr/user/view/details?${data2}`, 
                state: { data: data }
              })
          }}
          >My Account</a>
              
          <br />
          <a onClick={this.logout}>Logout</a>

        </div>
     )
   }

confifuration =()=>{
     return (
     <div style={{padding: '10px'}}>
       <h3>Configuration:</h3>

          <Row gutter={2}>
            <Col span={24}>
              <a onClick = { ()=> { this.state.caseManagement === false ?  this.setState({ caseManagement: true }) :  this.setState({ caseManagement: false }) }}>
               <span style={{color: 'black'}}>Case Management</span> 
                &nbsp; &nbsp; &nbsp; 
                {
                  this.state.caseManagement === false ? <Icon type="caret-down" /> : <Icon type="caret-up" />
                }
              </a>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              {
                this.state.caseManagement === true &&
                <a style={{fontSize: '12px'}} onClick={()=>{ this.props.history.push('/chr/configuration/case/status')}}>Case Status</a>
              }
            </Col>
            <Col span={24}>
              {
                this.state.caseManagement === true &&
                <a  style={{fontSize: '12px'}} onClick={()=>{ this.props.history.push('/chr/configuration/case/type')}}>Case Type</a>
              }
            </Col>
          </Row>
           
          <Row gutter={2}>
            <Col span={24}>
              <a onClick = { ()=> { this.state.accountManagement === false ?  this.setState({ accountManagement: true }) :  this.setState({ accountManagement: false }) }}>
               <span style={{color: 'black'}}>Account Management</span> 
                &nbsp; &nbsp; &nbsp; 
                {
                  this.state.accountManagement === false ? <Icon type="caret-down" /> : <Icon type="caret-up" />
                }
              </a>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              {
                this.state.accountManagement === true &&
                <a style={{fontSize: '12px'}} onClick={()=>{ this.props.history.push('/chr/configuration/case/account_source')}}>Account Source Type</a>
              }
            </Col>
          </Row>

          <Row gutter={2}>
            <Col span={24}>
              <a onClick = { ()=> { this.state.actManagement === false ?  this.setState({ actManagement: true }) :  this.setState({ actManagement: false }) }}>
               <span style={{color: 'black'}}>Act Management</span> 
                &nbsp; &nbsp; &nbsp; 
                {
                  this.state.actManagement === false ? <Icon type="caret-down" /> : <Icon type="caret-up" />
                }
              </a>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              {
                this.state.actManagement === true &&
                <a  style={{fontSize: '12px'}} onClick={()=>{ this.props.history.push('/chr/configuration/case/act')}}>Act Type</a>
              }
            </Col>
            <Col span={24}>
              {
                this.state.actManagement === true &&
                <a  style={{fontSize: '12px'}} onClick={()=>{ this.props.history.push('/chr/configuration/case/assaillant')}}>Assailant Type</a>
              }
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}> 
                <a onClick={()=>{ this.props.history.push('/chr/configuration/case/email')}}><span style={{color: 'black'}}>Configure Email</span> </a>
            </Col>
          </Row>
      </div>
   )
}
 
quickAdd = () => {
    return (
        <div style={{padding: '5px 20px 5px 5px', width: '120px', fontSize: '15px'}}>
          <label>Create:</label>
            <br />
            <a onClick={()=>{ this.props.history.push('/chr/case/add'); sessionStorage.setItem('case_edit', false); }}>Case </a>
            <br />
            <a onClick={()=>{ this.props.history.push('/chr/quick/add/victim')}}>Victim</a>
            <br />
            <a onClick={()=>{ sessionStorage.setItem('from_quick_account_add', false); this.props.history.push('/chr/quick/add/act')}}>Act </a>
            <br />
            <a onClick={()=>{ this.props.history.push('/chr/quick/add/account')}}>Account</a>
            <br />
            <a onClick={()=>{ sessionStorage.setItem('path', "document"); this.props.history.push('/chr/case/add/victim/fileupload')}}>File</a>
        </div>
        )
      }

headerPermissionConfig =()=>{
      const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
     
      if( userRole === "viewer" || userRole === "contributor" || userRole === "editor"){
        return (
          <Icon 
            onClick={()=>{ notification.warning({ message:'You dont have permission to access this feature!'});}}
            style={{ fontSize: '18px', color: 'white', margin: '22px 30px', float: 'right'}} 
            type="setting" 
            theme="filled"/>
        )
      } else {
        return (
          <Popover placement="bottomRight"  content={this.confifuration()} trigger="click">
            <Icon 
              style={{ fontSize: '18px', color: 'white', margin: '22px 30px', float: 'right'}} 
              type="setting" 
              theme="filled"/>
        </Popover>
        )
      }
    }

headerDeatail =()=>{
    const nameUser = this.props.login.user.first_name +" "+this.props.login.user.last_name;
    const CurentDate = Moment().format('dddd MMMM Do YYYY');
  return (
    <div>
     {
     this.props.history.location.pathname == "/chr/document/view" ?
     
     <div style={{ marginRight: '10px'}}>      
       <Icon style={{fontSize: "30px", color: 'lightblue', background: '#f3f9ff', padding: '20px 20px 14px 20px'}} type="step-backward" onClick={()=>this.props.history.goBack()} />

      <Popover placement="bottomRight"  content={this.content()} trigger="click">
        <Icon
          className="logout-btn" 
          type="user" title="Logout"/>
      </Popover>

        <span className="logout-name">{nameUser}</span>

      {this.headerPermissionConfig()}

      <Clock 
        className="clock-real-time"
        format={'hh:mm:ss A'} 
        ticking={true} 
        timezone={'Singapore'} />

        <span  className="real-date" >{CurentDate}</span>
     </div>
     :
     <div style={this.state.collapsed == true ? headerIcon : headerIconCollapsed}>      
   
     <Icon
        type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
        onClick={this.toggle}
        style={{margin: '20px 20px', fontSize: '20px', color: 'white'}}
      />
     
    <Popover placement="bottomRight"  content={this.content()} >
      <Icon
        className="logout-btn" 
        type="user" title="Logout"
        />
    </Popover>

    <SeacrSelect history={this.props.history}/>

      <span className="logout-name">{nameUser}</span>
      
      {this.headerPermissionConfig()}

    <Clock 
      className="clock-real-time"
      format={'hh:mm:ss A'} 
      ticking={true} 
      timezone={'Singapore'} 
    />

      <span  className="real-date" >{CurentDate}</span>
   </div>
    }
  </div>
  )
}

toggle = () => {
    this.setState({
    collapsed: !this.state.collapsed,
        });
    }

logout = () => {
    this.props.logout();
}

addBUtton =() =>{
      const toggle = this.state.collapsed;
      const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
      
      if(userRole === 'viewer'){
        return (
          toggle == false ?
           <Button disabled={true} icon="plus" className="dashboard-add" > Add</Button>
          :
           <Button disabled={true} icon="plus" className="dashboard-add-collpased-button" />
          )
      } else {
        return (
          toggle == false ?
          <Popover placement="rightBottom"  content={this.quickAdd()} trigger="click">
           <Button icon="plus" className="dashboard-add" > Add</Button>
          </Popover>
          :
          <Popover placement="rightBottom"  content={this.quickAdd()} trigger="click">
           <Button icon="plus" className="dashboard-add-collpased-button" />
          </Popover>
          )
      }
  }

  userPermissionMenu = () => {
    const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;

    if(userRole === "viewer" || userRole ==="contributor" || userRole === "editor") {
      return (
        <Menu 
          mode="inline" defaultSelectedKeys={['dashboard']} 
          style={ this.state.collapsed == true ? style : style2}>
          
        <div className="header-landing">  
          <img src={logo} style={{width: '50px ', height: '50px ', marginTop: '5px' }} />
            { 
              this.state.collapsed == false && <img id="nameHeader" src={HeaderName} style={{margin: '3px 10px'}} />
            }
          </div>
          <a> { this.addBUtton() }  </a>

          <Menu.Item key="dashboard" style={{ margin: '30px 0px 10px 0px'}}>
            <NavLink to="/chr/dashboard" activeClassName="active" >
            <Icon type="dashboard" className="icons-menu" style={{fontSize: '28px', paddingTop: '5px', float: 'left'}}/>
            <span className="icons-menu-label">Dashboard</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="case" onClick={()=>{sessionStorage.clear();}} style={{ margin: '15px 0px'}}>
          <NavLink to="/chr/case">
            <Icon type="folder-open"  className="icons-menu" style={{fontSize: '28px', paddingTop: '5px', float: 'left'}}/>
            <span className="icons-menu-label">Case</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="victim" onClick={()=>{sessionStorage.clear();}} style={{ margin: '15px 0px'}}>
            <NavLink to="/chr/victim">
            <Icon type="solution"  className="icons-menu" style={{fontSize: '28px', paddingTop: '5px', float: 'left'}}/>
          
            <span className="icons-menu-label">Victims</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="document" onClick={()=>{sessionStorage.clear();}} style={{ margin: '15px 0px'}}>
            <NavLink to="/chr/document">
            <Icon type="file"  className="icons-menu" style={{fontSize: '28px', paddingTop: '5px', float: 'left'}}/>
            <span className="icons-menu-label">Documents</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="trash" onClick={()=>{ notification.warning({ message:'You dont have permission to access this feature!'}); sessionStorage.clear();}} style={{ margin: '15px 0px'}}>
            <NavLink to="/"  disabled={true}>
            <Icon type="delete"  className="icons-menu" style={{fontSize: '28px', paddingTop: '5px', float: 'left'}}/>
            <span className="icons-menu-label">Trash</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item   key="report" onClick={()=>{ notification.warning({ message:'You dont have permission to access this feature!'}); sessionStorage.clear();}} style={{ margin: '15px 0px'}}>
            <NavLink to="/" >
            <Icon type="line-chart" className="icons-menu"  style={{fontSize: '28px', paddingTop: '5px', float: 'left'}}/>
            <span className="icons-menu-label">Reports</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item  key="user" onClick={()=>{notification.warning({ message:'You dont have permission to access this feature!'}); sessionStorage.clear();}} style={{ margin: '15px 0px'}}>
            <NavLink to="/" disabled={true}> 
            <Icon type="team"  className="icons-menu" style={{fontSize: '28px', paddingTop: '5px', float: 'left'}}/>
            <span className="icons-menu-label">Users</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item className="side-footer" key="footer" style={{ opacity: 0.5, position: 'fixed', width: 'inherit', bottom: 0, marginBottom: '0', backgroundColor: 'white', color:'rgb(34, 86, 143)' }}>
            &copy; 2019 - ACC TECHNOLOGY
          </Menu.Item>
        </Menu>
      
      )
    } else {
      return (
        <Menu 
          mode="inline" defaultSelectedKeys={['dashboard']} 
          style={ this.state.collapsed == true ? style : style2}>
          
        <div className="header-landing">  
          <img src={logo} style={{width: '50px ', height: '50px ', marginTop: '5px' }} />
            { 
              this.state.collapsed == false && <img id="nameHeader" src={HeaderName} style={{margin: '3px 10px'}} />
            }
          </div>
          <a> { this.addBUtton() }  </a>

          <Menu.Item key="dashboard" style={{ margin: '30px 0px 10px 0px'}}>
            <NavLink to="/chr/dashboard" activeClassName="active" >
            <Icon type="dashboard" className="icons-menu" style={{fontSize: '28px', paddingTop: '5px', float: 'left'}}/>
            <span className="icons-menu-label">Dashboard</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="case" onClick={()=>{sessionStorage.clear();}} style={{ margin: '15px 0px'}}>
          <NavLink to="/chr/case">
            <Icon type="folder-open"  className="icons-menu" style={{fontSize: '28px', paddingTop: '5px', float: 'left'}}/>
            <span className="icons-menu-label">Case</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="victim" onClick={()=>{sessionStorage.clear();}} style={{ margin: '15px 0px'}}>
            <NavLink to="/chr/victim">
            <Icon type="solution"  className="icons-menu" style={{fontSize: '28px', paddingTop: '5px', float: 'left'}}/>
          
            <span className="icons-menu-label">Victims</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="document" onClick={()=>{sessionStorage.clear();}} style={{ margin: '15px 0px'}}>
            <NavLink to="/chr/document">
            <Icon type="file"  className="icons-menu" style={{fontSize: '28px', paddingTop: '5px', float: 'left'}}/>
            <span className="icons-menu-label">Documents</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="trash" onClick={()=>{sessionStorage.clear();}} style={{ margin: '15px 0px'}}>
            <NavLink to="/chr/trash">
            <Icon type="delete"  className="icons-menu" style={{fontSize: '28px', paddingTop: '5px', float: 'left'}}/>
            <span className="icons-menu-label">Trash</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="report" onClick={()=>{sessionStorage.clear();}} style={{ margin: '15px 0px'}}>
            <NavLink to="/chr/report">
            <Icon type="line-chart" className="icons-menu"  style={{fontSize: '28px', paddingTop: '5px', float: 'left'}}/>
            <span className="icons-menu-label">Reports</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="user" onClick={()=>{sessionStorage.clear();}} style={{ margin: '15px 0px'}}>
            <NavLink to="/chr/user"> 
            <Icon type="team"  className="icons-menu" style={{fontSize: '28px', paddingTop: '5px', float: 'left'}}/>
            <span className="icons-menu-label">Users</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item className="side-footer" key="footer" style={{ opacity: 0.5, position: 'fixed', width: 'inherit', bottom: 0, marginBottom: '0', backgroundColor: 'white', color:'rgb(34, 86, 143)' }}>
            &copy; 2019 - ACC TECHNOLOGY
          </Menu.Item>
        </Menu>
      
      )
    }
  }

  idLerLogout = () =>{
    window.location = "/#/";
    this.props.logout();
  }


  render() {
     
    if (!this.props.login.token){
        return <Redirect to='/' />
      }
      const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
      const historyPath = this.props.history.location.pathname;
      const victim_id_module = this.props.history.location.pathname.slice(17);

        return (
          <IdleTimer
            onIdle={this.onIdle}
            timeout={500} >
             <Layout >
              
             {
              historyPath == `/chr/document/view` ?
              <div></div>
              :
              <Sider
                trigger={null}
                collapsible
                collapsed={this.state.collapsed}
                style={{backgroundColor: 'rgb(60 94 148)'}}>
           
              {this.userPermissionMenu()}
              </Sider>
             }
              <Layout>
             {
               historyPath == "/chr/document/view" ?
              <Header style={{  background: 'rgb(105 152 220)',  padding: 0,  position: 'fixed', width: '100%', margin: '0px 0px', zIndex: '2'}} >
                <div> { this.headerDeatail() }</div>
              </Header> 
              :
              <Header style={ this.state.collapsed == true ? headerStyleCollapsed : headerStyle} >
                <div> { this.headerDeatail() }</div>
              </Header>
             }
              

              <Content 
                style = {
                   this.props.location.pathname == `/chr/case/view` || 
                   this.props.location.pathname == `/chr/case/victim/view` || 
                   this.props.location.pathname == `/chr/case/victim/account/view` ||
                   this.props.location.pathname == `/chr/case/victim/act/view` ||
                   this.props.location.pathname == `/chr/victim/view/${victim_id_module}` ?  breadCrumpsCollapsed : breadCrumpsStandard 
                  }>
                 <div className="contentDiv">
                <HashRouter>
                    <Switch>
                      <Route path="/chr/dashboard" exact component={Dashboard} />

                      <Route path="/chr/case" exact component={Case} />
                      <Route path="/chr/case/add" exact component={AddCase} />
                      <Route path="/chr/case/add/upload/:case_id" component={AddCaseUpload} />
                      <Route path="/chr/case/view" component={ViewCaseList} />

                      <Route path="/chr/case/add/victim" exact component={AddVictim} />
                      <Route path="/chr/case/add/victim/form" exact component = {FormVictim} />
                      <Route path="/chr/case/add/victim/upload/:id" exact component={FormVictimUpload} />
                      <Route path="/chr/case/add/victim/fileupload" exact component={FilesUpload} />
                      <Route path="/chr/case/victim/view" exact component={ViewVictim} /> 

                      <Route path="/chr/case/victim/account/view" exact component={ViewAccounts} />
                      <Route path="/chr/case/victim/add/source" exact component={ViewVictimAdd} />
                      <Route path="/chr/case/victim/add/account" exact component={ViewVictimAddAccount} /> 
                      <Route path="/chr/case/victim/add/files" exact component={ViewVictimUploadFiles} />

                      <Route path="/chr/configuration/case/status" exact component={CaseStatus} />
                      <Route path="/chr/configuration/case/type" exact component={CaseType} />
                      <Route path="/chr/configuration/case/act" exact component={ActType} />
                      <Route path="/chr/configuration/case/assaillant" exact component={AssaillantType} />
                      <Route path="/chr/configuration/case/account_source" exact component={AccountSource} />
                      <Route path="/chr/configuration/case/email" exact component={Email} />

                      <Route path="/chr/case/victim/act/view" exact component={ViewVictimAct} />
                      <Route path="/chr/case/victim/act/add" exact component={AddVictimAct} />
                      <Route path="/chr/case/victim/act/add/assailant" exact component={AddVictimActAssailant} /> 
                      <Route path="/chr/case/victim/act/add/assailant/upload" exact component={AddVictimActUpload} /> 

                      <Route path="/chr/victim" exact component={Victims} /> 
                      <Route path="/chr/victim/view/module" exact component={VictimView} />
                      <Route path="/chr/victim/view/module/case" exact component={VitctimViewAct} /> 
                      <Route path="/chr/victim/view/module/act/view" exact component={VictimViewActModule} />
                      <Route path="/chr/victim/view/module/account/view" exact component={VictimViewAccoountModule} />
                      
                      <Route path="/chr/victim/add/act/1" exact component={VictimAddActModule} /> 
                      <Route path="/chr/victim/add/act/2" exact component={VictimAddActModule2} />
                      <Route path="/chr/victim/add/act/3" exact component={VictimAddActModule3} />

                      <Route path="/chr/document" exact component={Documents} /> 
                      <Route path="/chr/document/view" exact component={ViewDocuments} /> 

                      <Route path="/chr/trash" exact component={Trash} /> 
                      <Route path="/chr/report" exact component={Reports} /> 
                      
                      <Route path="/chr/user" exact component={Users} /> 
                      <Route path="/chr/user/add" exact component={UsersAdd} /> 
                      <Route path="/chr/user/:id" exact component={UsersAdd} /> 
                      <Route path="/chr/user/view/details" exact component={ViewUserList} />
                      
                      <Route path="/chr/search" exact component={Search} />
                      <Route path="/chr/quick/upload" exact component={QuickUpload} /> 
                      
                      <Route path="/chr/quick/add/victim" exact component={QuickVictim} /> 
                      <Route path="/chr/quick/add/info" exact component={QuickVictimAdd} /> 
                      <Route path="/chr/quick/add/upload/:victim_id" exact component={QuickVictimUpload} /> 

                      <Route path="/chr/quick/add/act" exact component={QuickAct} />
                      <Route path="/chr/quick/add/assaillant" exact component={QuickAssaillant} /> 
                      <Route path="/chr/quick/add/act/upload" exact component={QuickActUpload} /> 

                      <Route path="/chr/quick/add/account" exact component={QuickAccount} /> 
                      <Route path="/chr/quick/add/source" exact component={QuickAccountSource} /> 
                      <Route path="/chr/quick/add/source/upload" exact component={QuickAccountUpload} /> 
                      <Route path="/chr/quick/add/mulitple"  component={TreeNode} /> 

                      <Route path="/password"  component={ForgotPassword} /> 
                      <Route path="/change/password"  component={ChangePassword} /> 

                    </Switch>
                </HashRouter>
                  </div>
              </Content>

              </Layout>
            </Layout>
                  
          </IdleTimer>
        );
    }
}

const breadCrumpsStandard = {
  boxShadow: '1px 1px 7px #D1D1D1',  
  borderRadius: '5px',
  margin: '80px 16px', 
  padding: 24, 
  background: '#fff', 
  minHeight: 300, 
  zIndex: '0',
  fontFamily: 'Montserrat'
  
 }
 
 const breadCrumpsCollapsed = {
   margin:  '60px 0px', 
   background: 'rgb(239 239 239)', 
   minHeight: 300, 
   fontFamily: 'Montserrat'
}
 
 const headerIcon = {
   marginRight: '90px'
 }
 
 const headerIconCollapsed = {
   marginRight: '250px',
 }
 
 const headerStyle = {
   background: 'rgb(105 152 220)', 
   padding: 5,
   position: 'fixed',
   width: '100%',
   margin: '0px 0px',
   zIndex: '2'
 }
 
 const headerStyleCollapsed = {
   background: 'rgb(105 152 220)', 
   padding: 5,
   position: 'fixed',
   width: '100%',
   margin: '0px 0px',
   zIndex: '2'
 }
 
 const style =  {
   background: 'rgb(34 86 143)', 
   height: '100vh', 
   position: 'fixed', 
   width: '95px',
   zIndex: '99'
 }
 
 const style2 = { 
   background: 'rgb(34 86 143)', 
   height: '100vh', 
   position: 'fixed', 
   width: '250px' ,
   zIndex: '99'
 }
 
 
 const mapStateToProps = ({login})=> {
   return {
       login
   }
 }
 
 const mapDispatchToProps = {
   logout
 }
 

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);

