import React, { Component } from 'react'
import TableTypes from './components/typeTable';
import { connect } from 'react-redux';
import { caseTypeList, caseTypeAdd, configTypeEdit } from '../actionCreator';

class TableType extends Component {

componentDidMount(){
    this.props.caseTypeList();
}

componentDidUpdate(prevProps){
    if(prevProps != this.props.status) {
        this.props.caseTypeList();
    }
}

    render() {
        return (
            <div>
                <TableTypes 
                    status = { this.props.status }
                    caseTypeAdd = { this.props.caseTypeAdd }
                    configTypeEdit={this.props.configTypeEdit}
                />
            </div>
        )
    }
}
 const mapStateToProps = (state) => {
    return {
        status: state.case.type.data
    }
}

const mapDispatchToProps = {
    caseTypeList , caseTypeAdd, configTypeEdit
}

export default connect(mapStateToProps, mapDispatchToProps)(TableType);