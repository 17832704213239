import React, { Component } from 'react'
import { Breadcrumb, Icon, Button, Row, Col, Tabs  } from 'antd';
import FilesView from '../_files/filesView';
import HistoryView from '../_history/historyView';
import CaseView from '../_case/caseVictimView';
import BreadcrumbComponent from '../../../../shared/components/breadcrumps';
import {userList} from '../../../users/actionCreators';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { historyList, victimList, FilesList,  caseList, caseGet } from '../../actionCreator';
import _ from 'lodash';

const TabPane = Tabs.TabPane;

 class viewForm extends Component {

  componentDidMount(){
    // this.getVictimList({"sort_key":"id"});
    this.props.victimList();
    this.props.historyList();
    this.props.FilesList();
    this.props.caseGet();
    this.props.userList();
  }

  getVictimList = (params) => {
    this.props.victimList(null,params);
  }

  componentDidUpdate(prevProps){
    if(prevProps.ListVictim !== this.props.ListVictim){
      this.props.victimList();
    }
    
    if(prevProps.files !== this.props.files){
      this.props.FilesList();
    }
  }
 
  userPermission = () => { 
    const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;

      if (userRole === "viewer" || userRole === "contributor" ){
          return(
          <Button 
              className="chr-font-light"
              disabled={true}
              icon="edit" 
              type="primary"
              style={{ float: 'right', margin: '20px 30px', height: '40px'}} 
          >
             Edit
          </Button> 
          ) 
      } else {
          return (
            <Link to={{pathname: '/chr/case/add' }} >  
              <Button 
                className="chr-font-light" 
                onClick={()=>{sessionStorage.setItem('case_edit', true) }} 
                type="primary" ghost  
                style={{ float: 'right', margin: '20px 30px', height: '40px'}} 
                icon="edit"
              >
                Edit
              </Button>
          </Link>
          )
      }
  }

  CaseDetails = () =>{
    const caseData = this.props.caseData;
    return (
      <div style={{width: '97%', height: '100%', boxShadow: '1px 1px 7px #D1D1D1', borderRadius: '5px', margin: '40px 20px 0px 20px ', background: 'white'}}>
          <div style={{padding: "20px 10px"}}>
            <div>
              <Icon type='folder-open' className="icon-folder" theme="filled" style={{margin: '20px 6px 20px 20px', fontSize: '25px', fontWeight: "bold", color: "#22568f" }} />
              
            { 
              this.userPermission()
            }

              <span className="chr-font-sm"> {caseData.case_identifier} </span>  
            </div>

            <div style={{margin: "20px"}}>
              <Row style={{padding: "10px"}}>
                <Col span={5} className="row-label-name">
                  Case Type:
                </Col>
                <Col span={5} className="row-details-name">
                {caseData.case_type}
                </Col>
              </Row>
              <Row style={{padding: "10px"}} className="row-label-name">
                <Col span={5}>
                  Date Created:
                </Col>
                <Col span={5} className="row-details-name">
                  {caseData.created_at}
                </Col>
              </Row>
            </div>

            <div style={{margin: "30px 20px"}}>
                { !caseData.case_tags ? "" :
                  caseData.case_tags.map((e)=>{
                    return <span key={e} className="tags-for-card">
                        {e}
                    </span>
                  })
                }
            </div>
          </div>
      </div> 
    )
  }

  callback = (key) =>{
    this.setState({ tabsDefault: key })
  }


  tabSPrivellege = ()=>{ 
    const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
   
      if(userRole === "viewer" || userRole === "contributor" || userRole === "editor"){
       return ( 
          <Tabs type="card"   onChange={this.callback}>
            
              <TabPane tab="Case Victim's" key="1">
                <CaseView ListVictim ={this.props.ListVictim} history = {this.props.history} users={this.props.users} />
              </TabPane>
            
              <TabPane tab="Files" key="2">
                <FilesView login ={this.props.login} FilesList = { this.props.files } history={this.props.history} downloadFile = {this.props.downloadFile}/>
              </TabPane>

          </Tabs>
         ) 
      } else {
       return ( 
            <Tabs type="card"   onChange={this.callback}>
            
             <TabPane tab="Case Victim's" key="1">
                <CaseView ListVictim ={this.props.ListVictim} history = {this.props.history} users={this.props.users} />
              </TabPane>
              
              <TabPane tab="Files" key="2">
                <FilesView login ={this.props.login} FilesList = { this.props.files } history={this.props.history} downloadFile = {this.props.downloadFile}/>
              </TabPane>
              
              <TabPane tab="History" key="3">
                  <HistoryView users={this.props.users} logs = {this.props.historyLogs} />
              </TabPane>
              
            </Tabs>
        )
      }
  }

    TabsPanesList =()=>{
      return (
         <div className="card-container" style={{margin: "10px 20px"}}>
                { this.tabSPrivellege() }
         </div>
      )
    }

  render() {
    return (
      <div  style={{display: 'flex', flexDirection: 'column'}}> 
           <div><BreadcrumbComponent history={this.props.history}/></div>
           <div >{ this.CaseDetails()    }</div>
           <div>{ this.TabsPanesList()  }</div>
      </div>
    )
  }
}

 const mapStateToProps = (state) => {
  return {
   historyLogs : state.case.history,
   caseData: state.case.caseGet,
   ListVictim: state.case.ListVictim,
   files: state.case.files.data,
   users: state.user.users,
   login: state.login,
  }
}

 const mapDispatchToProps = {
      historyList, 
      victimList, 
      FilesList,  
      caseList, 
      caseGet, 
      userList
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(viewForm);