import React, { Component } from 'react'
import { Table, Button,  Icon, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import IconType from '../../../shared/components/icon_file_type';
import '../../css/style.css';
import moment from 'moment';

class TableUser extends Component {
    constructor(props){
        super(props);

        this.state = {
            numPages: null,
            pageNumber: 1,
          }

        this.columns = [
            {
                title: "File Name",
                dataIndex: "file_name",
                sorter: true,
                width: '300px',
                render: (record, text, index)=>{
            
                    return <div>
                            {/* <a onClick={(e)=>{ e.stopPropagation(); this.donwload(text.file_location, text.file_type, text.file_name, text.id ) }}>  */}
                            <IconType file_type={text.file_type} record = {record}/>  
                            {/* </a> */}
                           </div>
                    }
            }, 
            {
                title: "Type",
                dataIndex: "file_type",
                filters:[{'value': '', 'text': ''}],
                render: (record)=>{
                  
                    if (record === 'txt') {
                        return <span>Text</span>
                    } else if (record === 'xls' || record === 'xlsx') {
                        return <span>Excel</span>
                    } else if (record === "pdf") {
                        return <span>Pdf</span>
                    } else if (record === "doc" || record === "docx") {
                        return <span>Document</span>
                    } else if (record === "jpg" || record === "png" ) {
                        return <span>Image</span>
                    }else {
                        return <span>Other Document</span>
                    }
                }
            }, 
            {
                title: "Path",
                filters:[{'value': '', 'text': ''}],
                render: (record)=>{
                    if (record.case_id >= 1) {
                        return <span>Case</span>
                    } else if (record.victim_id >= 1) {
                        return <span>Victim</span>
                    } else if (record.act_id >= 1) {
                        return <span>Act</span>
                    } else if (record.account_id >= 1) {
                        return <span>Account</span>
                    }
                }
            }, 
            {
                title: "Reference",
                dataIndex: "reference",
                sorter: true,
            },  
            {
                title: "Date Uploaded",
                dataIndex: "created_at",
                sorter: true,
                render: (date) => moment(date).format('MMMM DD, YYYY HH:mm A'),
            }, {
                title: "Version",
                dataIndex: "version",
                sorter: true,
                render: ()=>{
                    return <div>&nbsp; 1</div>;
                }
            }, 
            {
                title: "",
                dataIndex: "action",
                render: (record, text, index)=>{
                    return (
                    <Checkbox 
                        onClick={(e)=> { e.stopPropagation();}}
                        onChange={(e)=>{
                            let status = e.target.checked;
                            this.donwload( text.file_location, text.file_type, text.file_name, text.id, status, index) }}
                        />)
                }
            }
        ];

        this.state = {
            data: []
        }   
    }

    donwload = ( location, type, name, id, status, index)=>{
        let data = this.state.data;

        if (status === false) {
            data.splice(index, 1);
            this.setState({ data: data })
        } else {
            data.push({location, type, name, id})
        }
    }
 
    onClickDownload = () => {
        let dataFiles = this.state.data; 

        for (let a = 0; a < dataFiles.length; a++) {
            this.props.multipleDownloadFile(dataFiles[a])
        }
    }

    DocumentArchived = () => {
        let dataFiles = this.state.data; 

        for (let a = 0; a < dataFiles.length; a++) {
           setTimeout(()=>{
            this.props.DocumentArchived(dataFiles[a].id)
           }, 1000)
        }
    }
    
    onRowClick = (data)=> {
        sessionStorage.setItem("file_location", data.file_location);
        sessionStorage.setItem("file_type", data.file_type);
        sessionStorage.setItem("file_name", data.file_name);
        sessionStorage.setItem("reference", data.reference);
        sessionStorage.setItem("created", data.updated_at); 
        sessionStorage.setItem('docs_id', data.id);

        this.props.history.push(`/chr/document/view`);
    }

    userPermission = () => { 
        const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
    
          if (userRole === "viewer" ){
              return(
                    <div style={{ textAlign: 'right'}}>

                        <Button 
                            style={{margin: "25px 20px",  float: 'right' }}
                            disabled={true}
                            type="primary" 
                            >
                            + Add Files
                        </Button> 

                        <a disabled={true} >
                            <Icon theme="filled" type="delete" style={{margin: "27px 10px",  float: 'right', fontSize: "25px" }}/>
                        </a>

                        <a disabled={true} >
                            <Icon type="download" style={{margin: "27px 10px",  float: 'right', fontSize: "25px"  }}/>
                        </a>
                    
                    </div>
              ) 
            } else if (userRole === "contributor") { 
                return(
                    <div style={{ textAlign: 'right'}}>

                    <Link to='/chr/case/add/victim/fileupload' >
                        <Button  
                            onClick={()=>{sessionStorage.setItem('case_id', 1) }}
                            style={{ margin: '25px 5px', zIndex: '2', float:'right'}} 
                            type="primary"
                            className="add-case"
                            > 
                            + Add Files
                        </Button>
                    </Link>

                        <a disabled={true} >
                            <Icon theme="filled" type="delete" style={{margin: "27px 10px",  float: 'right', fontSize: "25px" }}/>
                        </a>

                        <a disabled={true} >
                            <Icon type="download" style={{margin: "27px 10px",  float: 'right', fontSize: "25px"  }}/>
                        </a>
                    
                    </div>
              ) 

            } else {
              return (
                <div style={{ textAlign: 'right'}}>
                        
                    <Link to='/chr/case/add/victim/fileupload' >
                        <Button  
                            className="chr-font-light"
                            style={{margin:'0px 20px', zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                            onClick={()=>{sessionStorage.setItem('path', "document") }} 
                            type="primary"> 
                            + Add Files
                        </Button>
                    </Link>

                    <a onClick={()=>{ this.onClickDownload()}}>
                        <Icon type="download" style={{ margin: "2px 10px", color: '#77B9FF', fontSize: '30px'}}/>
                    </a>
                   
                    <a onClick={()=>{ this.DocumentArchived()}} style={{zIndex: 10, float: "right", fontSize: '23px' , margin: "0px 10px"}}>
                        <Icon type="delete" theme="filled" style={{color: '#77B9FF', fontSize: '30px'}}/>
                    </a>

                   
                </div>
              )
          }
      }

 
  render() {
      const data = this.props.data.data;
    return (
      <div style={{margin: '30px 0px'}}>
         <h1 className="chr-font">
            {/* <Icon  type="folder-open" theme="filled" style={{marginRight: "10px", fontSize: "25px" }} /> */}
            Documents
        </h1>
        
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          {this.userPermission()}
        </div>

        <Table 
            style={{marginTop: '30px'}}
            columns={this.columns}
            dataSource={data}
            rowKey="id"
            onRowClick={ (data)=>{  this.onRowClick(data) }} />
      </div>
    )
  }
}

export default TableUser;