import { getAuthHeader, axios, getError } from './../../shared/utils/axios';
import { BASE_URL } from './../../shared/constant/url';

export const REPORTS = {
    KILLINGS_START: "KILLINGS_START",
    KILLINGS_SUCCESS: "KILLINGS_SUCCESS",
    KILLINGS_FAILED: "KILLINGS_FAILED",

    PROFILE_START: "PROFILE_START",
    PROFILE_SUCCESS: "PROFILE_SUCCESS",
    PROFILE_FAILED: "PROFILE_FAILED",

    VICTIM_AGE_START: "VICTIM_AGE_START",
    VICTIM_AGE_SUCCESS: "VICTIM_AGE_SUCCESS",
    VICTIM_AGE_FAILED: "VICTIM_AGE_FAILED",

    ILLEGAL_DRUG_TRADE_START: "ILLEGAL_DRUG_TRADE_START",
    ILLEGAL_DRUG_TRADE_SUCCESS: "ILLEGAL_DRUG_TRADE_SUCCESS",
    ILLEGAL_DRUG_TRADE_FAILED: "ILLEGAL_DRUG_TRADE_FAILED",

    PERPETRATOR_START: "PERPETRATOR_START",
    PERPETRATOR_SUCCESS: "PERPETRATOR_SUCCESS",
    PERPETRATOR_FAILED: "PERPETRATOR_FAILED",

    ENFORCEMENT_START: "ENFORCEMENT_START",
    ENFORCEMENT_SUCCESS: "ENFORCEMENT_SUCCESS",
    ENFORCEMENT_FAILED: "ENFORCEMENT_FAILED",
}


export const killingReports = ()=>{
    return (dispatch)=>{
          dispatch({ type: REPORTS.KILLINGS_START })
 
       axios.get(`${BASE_URL}/api/report/investigatedkillings_report`, getAuthHeader())
       .then(response => {  dispatch({type: REPORTS.KILLINGS_SUCCESS, payload: response.data}) })
       .catch(error => { dispatch({ type: REPORTS.KILLINGS_FAILED, payload: getError(error)}) })
    }
 }

 
export const profileReports = ()=>{
    return (dispatch)=>{
          dispatch({ type: REPORTS.PROFILE_START })
 
       axios.get(`${BASE_URL}/api/report/victim_profile_report`, getAuthHeader())
       .then(response => {  dispatch({type: REPORTS.PROFILE_SUCCESS, payload: response.data}) })
       .catch(error => { dispatch({ type: REPORTS.PROFILE_FAILED, payload: getError(error)}) })
    }
 }

 export const drugReport = ()=>{
    return (dispatch)=>{
          dispatch({ type: REPORTS.ILLEGAL_DRUG_TRADE_START })
 
       axios.get(`${BASE_URL}/api/report/drug_trade_report`, getAuthHeader())
       .then(response => {  dispatch({type: REPORTS.ILLEGAL_DRUG_TRADE_SUCCESS, payload: response.data}) })
       .catch(error => { dispatch({ type: REPORTS.ILLEGAL_DRUG_TRADE_FAILED, payload: getError(error)}) })
    }
 }

 export const ageReport = ()=>{
    return (dispatch)=>{
          dispatch({ type: REPORTS.VICTIM_AGE_START })
 
       axios.get(`${BASE_URL}/api/report/victim_age_report`, getAuthHeader())
       .then(response => {  dispatch({type: REPORTS.VICTIM_AGE_SUCCESS, payload: response.data}) })
       .catch(error => { dispatch({ type: REPORTS.VICTIM_AGE_FAILED, payload: getError(error)}) })
    }
 } 

 export const perpetratorReport = ()=>{
    return (dispatch)=>{
          dispatch({ type: REPORTS.PERPETRATOR_START })
 
       axios.get(`${BASE_URL}/api/report/perpetrator_type_report`, getAuthHeader())
       .then(response => { dispatch({type: REPORTS.PERPETRATOR_SUCCESS, payload: response.data}) })
       .catch(error => { dispatch({ type: REPORTS.PERPETRATOR_FAILED, payload: getError(error)}) })
    }
 }

 export const enforcementReport = ()=>{
    return (dispatch)=>{
          dispatch({ type: REPORTS.ENFORCEMENT_START })
 
       axios.get(`${BASE_URL}/api/report/lawenforcement_report`, getAuthHeader())
       .then(response => {  dispatch({type: REPORTS.ENFORCEMENT_SUCCESS, payload: response.data}) })
       .catch(error => { dispatch({ type: REPORTS.ENFORCEMENT_FAILED, payload: getError(error)}) })
    }
 }