import React, { Component } from 'react'
import { Icon, Button, Row } from 'antd';

class Tiles extends Component {
    constructor(props){
    super(props);
        this.state = {
            index: 0,
            array: []
        }
    }

     chunkArray = (array, size)=>{
        var arrayLength = array.length;
        var tempArray = [];
        
        for (var index = 0; index < arrayLength; index += size) {
           var myChunk = array.slice(index, index+size);
           tempArray.push(myChunk);
        }
        
        return tempArray;
    }
 
  
    render() {
        const data = this.props.List;
        const result = this.chunkArray(!data.case.data ? []: data.case.data, 5);
        this.state.array.push(result);
        const minusLength = result ? result.length - 1 : 0;
    
        return (
            
            <div>
                <div className="main-tiles-holder">
                { 
                !data.case.data ? 
                   [] :
                   !result[this.state.index] ? "" :     
                   result[this.state.index].map((e,index)=>{
                      
                    return (
                        <div className="tiles-folder-cases" key={e.case_id+"_"+"case"+"_"+index} style={{display: 'flex', textAlign: 'center'}}> 
                            <Row>
                                <Icon type="folder-open" style={{fontSize: "80px", color: "#a6bbd2"}} theme="filled"/> 
                            </Row>
                            <Row>
                                <span key={e.case_id+"_"+"case"} style={{fontSize: "20px", color: "#22568f"}}>{e.case_identifier}</span>
                            </Row>
                        </div> 
                        )
                    })
                }
              
                <a onClick={()=>{ this.setState({ index: this.state.index - 1 })}} disabled={ this.state.index == 0 ? true : false}>
                    <Icon  type="left" style={{borderRadius: '5px', margin: '70px 10px', fontSize: '30px',padding: '5px', border: '1px solid #a6bbd2'}} />
                </a>

                <a onClick={()=>{ this.setState({ index: this.state.index + 1})}} disabled={ this.state.index == minusLength ? true : false}>
                    <Icon  type="right" style={{ borderRadius: '5px', margin: '70px 0px', fontSize: '30px',padding: '5px', border: '1px solid #a6bbd2'}} />
                </a>
               </div>
            </div>
        )
    }
}

export default Tiles;   