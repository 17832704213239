import React, { Component } from 'react'
import {Row, Col, Icon} from 'antd'

class ViewerPrivileges extends Component {
    render() {
        return (
            <div style={{margin: '20px 20px'}}>
            <Row>
                <div style={{margin: "40px 0px"}}> 
                    <a style={{color: "#22568f"}}>
                        <Icon type="solution"/>&nbsp;&nbsp;&nbsp;
                        <span className="chr-font-sm">Viewer Privileges</span>
                    </a>
                </div>
                <Row style={{padding: '20px'}}>
                    <Col span={6}>
                        <h1 className="chr-font-black">Authentication</h1><br />
                        <ul>    
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Log in</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Log out</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Change Password</p></li>
                        </ul>
                    </Col>
                    <Col span={6}>
                        <h1 className="chr-font-black">Navigation</h1><br />
                        <ul>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Dashboard</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">Search(Case, Victim, Account, Act, File)</p></li>
                        </ul>
                    </Col>
                    <Col span={6}>
                        <h1 className="chr-font-black">Case Management</h1><br />
                        <ul>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Case List</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Case (Victim Tab/File Tab)</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Victim from Case Module (Act Tab/Account Tab/File Tab)</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View List of Accounts</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Account  (Act tab/File Tab)</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View List of Act</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Act (File Tab)</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Act (File Tab)</p></li>
                        </ul>
                    </Col>
                </Row>
            </Row>

            <Row>
                <Col className="chr-row"></Col>
            </Row><br />

            <Row>
                <Row style={{padding: '20px'}}>
                <Col span={6}>
                        <h1 className="chr-font-black">Victim Management</h1><br />
                        <ul>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View List of Victim</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Victim (Enrolled Case Tab/File Tab)</p></li>
                        </ul>
                    </Col>
                    <Col span={6}>
                        <h1 className="chr-font-black">Document Management</h1><br />
                        <ul>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View List of Documents</p></li>
                            <li style={{color: '#77B9FF'}}><p className="chr-font-secondary">View Document</p></li>
                        </ul>
                    </Col>
                </Row>
            </Row>
        </div>
        )
    }
}

export default ViewerPrivileges