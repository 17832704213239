import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, notification, message  } from 'antd';
import { connect } from 'react-redux';
import { caseAddDocument } from '../../../actionCreator';
import FileUpload from '../../../../../shared/components/fileUploadCase';
import CaseTabs from "../../../../../shared/components/addCaseTab";

 
const CaseForm =  Form.create()(

  class extends Component {
    
    handleSubmit = (e) => {
      // e.preventDefault();
      let routeValue = e.routeValue;
      this.props.form.validateFields((err, values) => {

        if (!err) {
          this.props.onSave(values, routeValue) 
        }
      });
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      const path = sessionStorage.getItem('quick_add_victim_module');
      return (
        // <Form onSubmit={this.handleSubmit} >
        <Form>
        <Row style={{padding: '20px 20px 0px 20px'}}> 
             <Col span={12}>
               {
                 path == "true" ?
                 <h1 className="chr-font" style={{color: "#21568E", fontSize: '20px'}}>Upload Attachment </h1>
                 :
                 <h1 className="chr-font" style={{color: "#21568E", fontSize: '20px'}}>Adding Case Information </h1>
               }
             </Col> 
          {
              path == "true" ?
              <Col span={12}>
              <Form.Item className="form-case-add">
                 <Button 
                   className="chr-btn" 
                   type="primary"  
                   onClick={()=>{ this.handleSubmit({routeValue : "null"}) }} 
                   style={{margin: '0px 20px'}} >
                      Save
                 </Button>
                 <Button className="chr-close-btn" type="default"  icon="cross" onClick ={this.props.onCancel}></Button>
              </Form.Item>
          </Col>
          :
          <Col span={12}>
              <Form.Item className="form-case-add">
                <Button 
                  className="chr-btn-gen" 
                  type="primary" 
                  ghost 
                  onClick={()=>{ sessionStorage.setItem('case_id', this.props.dataID); this.handleSubmit({routeValue : "victim"}) }}>
                      Save and Add Victim in the Case
                </Button>
                <Button 
                  className="chr-btn" 
                  type="primary"  
                  onClick={()=>{ this.handleSubmit({routeValue : "null"}) }} 
                  style={{margin: '0px 20px'}} >
                      Save
                </Button>
                {/* <Button className="chr-close-btn" type="default"  icon="cross" onClick ={this.props.onCancel}></Button> */}
              </Form.Item>
          </Col>
          }

         </Row>

         <Row>
          <Col className="form-case-add-row"></Col>
         </Row>

<Row>
  <Col>

  <Row  style={{margin: '50px 10px'}}>
      <Col span={24}>
        { path == "true" ? <div></div> : <CaseTabs history = {this.props.history.location} /> }  
    
      <div style={{  display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
        {/* Form Start */}
              <Row  >
                <Col span={20}>
                  <Form.Item>
                    {getFieldDecorator('dev_notes', {
                      rules: [{ required: false }],
                      initialValue: { file_metadata: null , 1: null}
                    })(
                        <Input  hidden                      
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>          
            
              <Row  >
                <Col span={20}>
                  <Form.Item >
                    
                    {getFieldDecorator('documents', {
                       initialValue: []
                    })(

                      <FileUpload dataID={this.props.dataID}  />

                    )}
                  </Form.Item>
                </Col>
              </Row>

              </div>
              </Col>
          </Row>
        </Col>
      </Row>
    </Form>
      );
    }
  }
)

class CaseAdd extends Component {


  onSave = (params, routeValue)=> {
    var id = this.props.match.params.case_id;
  
    if(routeValue == "victim"){
      this.props.caseAddDocument(params, id, this.routeCallback);
      this.props.history.push('/chr/quick/add/victim');
    } else {
     this.props.caseAddDocument(params, id, this.callback);
    }
  }

  routeCallback = ()=> { }
  
  callback = (response, error) => {
    const path = sessionStorage.getItem('add_case_upload');

    if (!error) {
      notification.success({message: `Successfully added!` })
      // path ? this.props.history.push(path) : this.props.history.push('/chr/case')
      path ? this.props.history.push(path) : this.props.history.goBack();
    } else {
      notification.warning({message: `${error.messages} ` })
    }
  }

  onCancel=()=>{
    this.props.history.goBack();
  }

  render(){
    // var id = this.props.history.location.pathname;
    var dataID = this.props.match.params.case_id;
    var history = this.props.history;
    sessionStorage.setItem('quick_case_id', dataID);
    return(
      
        <div className="contentDiv">
          <CaseForm
            onCancel = {this.onCancel}
            onSave = {this.onSave}
            backButton= {this.backButton}
            dataID = {dataID}
            history = {history}
          />
        </div>
    );
  }
}

const mapDispatchToProps = {
  caseAddDocument
}

export default connect(null, mapDispatchToProps)(CaseAdd); 