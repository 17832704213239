import React, { Component } from 'react'
import {Breadcrumb} from 'antd';

class BreadCrupms extends Component {
    constructor(props){
        super(props);
            this.state = {
                items: [],
                array: []
            }
        }

    render() {
        const history = this.props.history.location.pathname;
        const from_account = sessionStorage.getItem('account_module_act')
        const case_title = sessionStorage.getItem('case_title');
        const act_title = sessionStorage.getItem('act_title');
        const account_title = sessionStorage.getItem('account_title');
        const name = sessionStorage.getItem('victim_title')
        return (
            <div>       
               <div style={{margin: '0px 0px', position : 'fixed'}}  className="breadcrumps-list2"> 
                    <Breadcrumb separator="&#8250;" 
                     style={{fontSize: "18px", fontWeight: "bold"}}>

                    <Breadcrumb.Item >
                        <a 
                        style={{
                            color: "white", 
                            fontSize: "18px",
                            fontWeight: 'bold', 
                            margin: '0px 0px 0px 20px'}} 
                            onClick={()=>{this.props.history.push('/chr/victim'); }}>
                        Victim
                        </a> 
                    </Breadcrumb.Item>
                    {    
                       history == `/chr/victim/view/module` ||
                       history == `/chr/victim/view/module/case` ||
                       history == `/chr/victim/view/module/act/view` ||
                       history == `/chr/victim/view/module/account/view`?
                       <Breadcrumb.Item>
                            <a style={{color:  history == `/chr/victim/view/module` ? '#22568f' : 'white'}} 
                             onClick={()=>{this.props.history.push(`/chr/victim/view/module`); }}>{name}</a>
                        </Breadcrumb.Item>
                        :
                        ""
                    }
                    {    
                       history == `/chr/victim/view/module/case` ||
                       history == `/chr/victim/view/module/act/view` ||
                       history == `/chr/victim/view/module/account/view`?
                       <Breadcrumb.Item>
                            <a style={{color: history == `/chr/victim/view/module/case` ?'#22568f': 'white'}} 
                             onClick={()=>{this.props.history.push(`/chr/victim/view/module/case`); }}>{case_title}</a>
                        </Breadcrumb.Item>
                        :
                        ""
                    }
                    {               
                       history == `/chr/victim/view/module/act/view` && from_account == "false"
                       ?
                       <Breadcrumb.Item>
                            <a style={{color: history == `/chr/victim/view/module/act/view` ?'#22568f': 'white'}} 
                             onClick={()=>{this.props.history.push(`/chr/victim/view/module/act/view`); }}>{act_title}</a>
                        </Breadcrumb.Item>
                        :
                        ""
                    }
                    {               
                       history == `/chr/victim/view/module/account/view` ||
                       history == `/chr/victim/view/module/act/view` && from_account == "true" ? 
                       <Breadcrumb.Item>
                            <a style={{color:  history == `/chr/victim/view/module/account/view` ?'#22568f': 'white'}} 
                             onClick={()=>{this.props.history.push( `/chr/victim/view/module/account/view`); }}>
                             {/* { history == `/chr/victim/view/module/act/view` && from_account ? this.props.history.location.state.account_title : account_title} */}
                             {account_title}
                             </a>
                        </Breadcrumb.Item>
                        :
                        ""
                    }
                    {               
                       history == `/chr/victim/view/module/act/view` && from_account == "true" ? 
                       <Breadcrumb.Item>
                            <a style={{color:  history == `/chr/victim/view/module/act/view` && from_account ?'#22568f': 'white'}} 
                             onClick={()=>{this.props.history.push(`/chr/victim/view/module/act/view`); }}>{act_title}</a>
                        </Breadcrumb.Item>
                        :
                        ""
                    }

                   </Breadcrumb>
                </div>
                
            </div>
        )
    }
}

export default BreadCrupms;