import React, { Component } from 'react';
import { Upload, Select, Button, Icon, Progress } from 'antd';
import { API_FILE_UPLOAD } from '../constant/url';
import AddVersion from './fileAddVersion';

const Dragger = Upload.Dragger; 
const token = localStorage.getItem('token');
const auth = { Authorization: `Bearer ${token}`};

class FilesUpload extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          fileList: [],
          percent : 0
        };
    }  

    onChangehandler = (info, e)=>{
        const response = info.file.response;
        const fileList = this.state.fileList;
        const percent = info.file.percent;
        
    if(response){
          response.document_tags = [];
          response.version = "1.0";
          response.file_metadata = [];
          fileList.push(response); 

          this.setState({ fileList, percent  })          
        }
        this.props.onChange(this.state.fileList)
    }

      onRemove = () => {
          this.setState({ file: '' })
          const onChange = this.props.onChange;
          if (onChange) {
              onChange('');
          }
      }
    
      remove_file = (file) => {
        const { fileList } = this.state;
        
        const indexRemoved = fileList.indexOf(file);
        fileList.splice(indexRemoved, 1);

        this.setState({ fileList })
      }

      add_file_tag = (file, tag) => {
       
        const { fileList } = this.state;
        const index = fileList.indexOf(file);

        fileList[index].document_tags.push(tag);
        // fileList[index].file_metadata.push(null);

        this.setState({ fileList })
      }

      remove_file_tag = (file, tag) => {
        const { fileList } = this.state;

        const fileIndex = fileList.indexOf(file);
        const tagIndex = fileList[fileIndex].document_tags.indexOf(tag);

        fileList[fileIndex].document_tags.splice(tagIndex, 1);

        this.setState({ fileList })
      }

      add_file_version = (file, version_name) => {
        const { fileList } = this.state;

        const fileIndex = fileList.indexOf(file);
        fileList[fileIndex].version = version_name;

        this.setState({ fileList });
      }

      render_custom_file_icon = (type) => {        
        var icon_type = "file-unknown";

        if (type === 'txt') {
          icon_type = "file-text"
        } else if (type === 'xls' || type === 'xlsx') {
          icon_type = "file-excel"
        } else if (type === "pdf") {
          icon_type = "file-pdf"
        } else if (type === "doc" || type === "docx") {
          icon_type = "file-word"
        } else if (type === "jpg") {
          icon_type = "file-jpg"
        }

        return <Icon type={ icon_type } className="file-type-icon"/>        
      }

      stop_propagation = (e) => {        
        e.stopPropagation();
      }


    render() {       
      const files = this.state.fileList.length;
      return (          
            <div className="case-file-dragger"  style={ files > 3 ? less: more}>
                <Dragger                   
                  listType="picture" 
                  multiple='true'  
                  accept= '*'                   
                  onChange= {this.onChangehandler}
                  action= { API_FILE_UPLOAD }
                  headers= { auth }   
                  onClick={(e)=> {e.stopPropagation(); e.preventDefault();}}
                >
                    {
                      this.state.fileList.length > 0 ? 
                      <div className="custom-file-list ant-upload-drag-icon">
                      {
                        this.state.fileList.map((item) => 
                          <div 
                            className="file-list-item" 
                            key={ item.file_name } 
                            onClick={ this.stop_propagation }>
                            <div className="file-name-icon">
                              { this.render_custom_file_icon(item.file_type) }
                              <div style={{ width: '140px', color: 'red' }}>
                                { item.file_name }
                                <AddVersion 
                                  file={ item }
                                  add_file_version={ this.add_file_version }
                                  />
                              </div>
                            </div>
                            <div className="tags-progress" onClick={ this.stop_propagation }>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>                                
                                <Select 
                                  style={{ zIndex: 100}}
                                  onClick ={(e)=> { e.stopPropagation(); e.preventDefault();}}
                                  mode="tags"
                                  onSelect={ (value) => this.add_file_tag(item, value) }
                                  onDeselect={ (value) => this.remove_file_tag(item, value) }
                                  />
                                <Button onClick={ () => this.remove_file(item) } className="remove-case-file">
                                  <Icon type="close" />
                                </Button> 
                              </div>

                              <Progress percent={this.state.percent} className="upload-progress"/>
                            </div> 
                          </div>)
                      } 
                      </div>
                      : 
                        <div  >
                          <p className="ant-upload-drag-icon" > <Icon type="cloud-upload" /> </p>
                          <p className="ant-upload-text">Drop Files to upload or <a>click here </a></p>
                        </div>
                    }
                </Dragger>
          </div>
        )
    }
}

export default FilesUpload;

const less = {
  width: '600px', 
}


const more = {
  width: '600px', 
  height: '400px'
}