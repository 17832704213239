import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, notification, message, Select  } from 'antd';
import { connect } from 'react-redux';
import { caseAddDocument, victimAddDocument, AddAccountDocument, victimActAddDocument, caseList, victimList, victimActList, victimAccountList } from '../../cases/actionCreator';
import FileUpload from '../../../shared/components/fileUploadCase';
// import { victimActAddDocument } from './../cases/actionCreator';

 const account = ["1", "2"];
 const act = ["3", "4"];
const CaseForm =  Form.create()(

  class extends Component {

    handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {

        if (!err) {
          this.props.onSave(values) 
        }
      });
    }
    

    selectHandler = () => {
      const caseAll = this.props.caseAll !== undefined ? this.props.caseAll : [];
             return (
              <Select style={{width: '600px'}} onChange = {(value)=>{this.props.caseOnChange(value); }}> 
                      { 
                          caseAll.map((item)=>{
                              return <Select.Option key={item.id} value={item.id}>
                                          {item.case_identifier}
                                     </Select.Option>
                          })
                      }
              </Select>
          )
    }

    // selectHandler = () => {
    // const caseAll = this.props.caseAll !== undefined ? this.props.caseAll : [];
    // const victimAll = this.props.victimAll !== undefined ? this.props.victimAll : [];

    //     console.log('states', this.props.actStates)
       

    //   if (this.props.states === null) {
    //        return (
             
    //         <Select style={{width: '600px'}} onChange = {(value)=>{this.props.caseOnChange(value); }}> 
    //                 { 
    //                     caseAll.map((item)=>{
    //                         return <Select.Option key={item.id} value={item.id}>
    //                                     {item.case_identifier}
    //                                </Select.Option>
    //                     })
    //                 }
    //         </Select>
    //     )
    // }
    //     else if (this.props.states !== null && this.props.victimStates === null) {
    //         return (
    //             <Select style={{width: '600px'}}  onChange = {(value)=>{this.props.victimOnChange(value); }}> 
    //                 { 
    //                     victimAll.map((item)=>{
    //                         return <Select.Option key={item.id} value={item.id}>
    //                                       {item.first_name} &nbsp; {item.last_name}
    //                                </Select.Option>
    //                     })
    //                 }
    //             </Select>
    //     )
    // }
    
    //     else if (this.props.states !== null && this.props.victimStates !== null && this.props.accountStates === null) {
    //       return (
    //           <Select style={{width: '600px'}}  onChange = {(value)=>{this.props.accountOnChange(value); }}> 
    //               { 
    //                   account.map((item)=>{
    //                       return <Select.Option key={item} value={item}>
    //                                     {item}
    //                              </Select.Option>
    //                   })
    //               }
    //           </Select>
    //     )
    // }
    
    //   else if (this.props.states !== null && this.props.victimStates !== null && this.props.accountStates !== null && this.props.actStates !== null || this.props.actStates === null ) {
    //     return (
    //         <Select style={{width: '600px'}}  onChange = {(value)=>{this.props.actOnChange(value); }}> 
    //             { 
    //                 act.map((item)=>{
    //                     return <Select.Option key={item} value={item}>
    //                                   {item}
    //                           </Select.Option>
    //                 })
    //             }
    //         </Select>
    //     )
    //   } else {
    //     return "nothing"
    //   }
    // }

    render() {
      const { getFieldDecorator } = this.props.form;
      
      return (
        <Form onSubmit={this.handleSubmit} >

          <Row style={{padding: '20px 20px 0px 20px'}}>
             <Col span={12}>
             <h1 className="chr-font">Upload Attachments </h1>
             </Col> 

             <Col span={12}>
                 <Form.Item className="form-case-add">
                    <Button className="chr-btn" type="primary" htmlType="submit" style={{margin: '0px 10px'}} >
                         Save
                    </Button>
                    <Button className="chr-close-btn" icon="cross" onClick={()=>{ this.props.history.goBack(); }} style={{margin: '0px 0px'}}></Button>
                 </Form.Item>
             </Col>
         </Row>

         <Row>
          <Col className="form-case-add-row"></Col>
         </Row>

         <Row>
           <Col>
          <Row  style={{margin: '50px 10px'}}>
              <Col span={24}>
    
      <div style={{  display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
        {/* Form Start */}

            

              <Row >
                <Col span={20}>
                  <Form.Item>
                    {getFieldDecorator('dev_notes', {
                      rules: [{ required: false }],
                      initialValue: { file_metadata: null , 1: null}
                    })(
                        <Input  hidden                      
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>    

              <Row>
                <Col span={24}>
                    <Form.Item label="Choose file directory">
                        {getFieldDecorator('case_id', {
                        rules: [{ required: true }],
                        })(
                          this.selectHandler() 
                        )}
                    </Form.Item>
                </Col>
            </Row>   
              <Row  >
                <Col span={20}>
                  <Form.Item >
                    
                    {getFieldDecorator('documents', {
                    })(

                      <FileUpload dataID={this.props.dataID}  />

                    )}
                  </Form.Item>
                </Col>
              </Row>

              </div>
              </Col>
          </Row>
        </Col>
      </Row>
    </Form>
      );
    }
  }
)

class CaseAdd extends Component {
    state= {
        case_id: null,
        victim_id: null,
        account_id: null,
        act_id: null
    }

  componentDidMount(){
    this.props.caseList();
    this.props.victimList(); 
    this.props.victimActList();
    this.props.victimAccountList();
  }

  componentDidUpdate(a, b){
  const case_id = this.state.case_id;
  const victim_id = this.state.victim_id;
   
   if(b !== this.state.case_id) {
    this.props.victimList(case_id);
    this.props.victimActList(case_id, victim_id);
    this.props.victimAccountList(case_id, victim_id);
    } 
  }
  
  caseOnChange = (id)=> {
    this.setState({ case_id: id })
  }

  victimOnChange = (id)=> {
    this.setState({ victim_id: id })
  }

  accountOnChange = (id)=> {
    this.setState({ account_id: id })
  }

  actOnChange = (id)=> {
    this.setState({ act_id: id })
  }

  onSave = (params)=> {
    var id = this.state.case_id;
 
    if (this.state.case_id !== null && this.state.victim_id !== null) {
      this.props.victimAddDocument(this.state.victim_id);
    }
    else if(this.state.case_id !== null && this.state.victim_id !== null && this.state.account_id !== null) {
      this.props.AddAccountDocument(this.state.account_id);
    }
    else if (this.state.case_id !== null && this.state.victim_id !== null && this.state.account_id !== null && this.state.act_id !== null) {
      this.props.victimActAddDocument(this.state.act_id);
    }
    else {
      this.props.caseAddDocument(params, id, this.callback);
    }
  }

  callback = (response, error) => {
    const path = sessionStorage.getItem('add_case_upload');

    if (!error) {
      notification.success({message: `Successfully added!` })
      path ? this.props.history.push(path) : this.props.history.push('/chr/case')
    } else {
      notification.warning({message: `${error.messages} ` })
    }
  }

  backButton = ()=>{
    this.props.history.push('/chr/case/add');
  }

  render(){
    const caseAll = this.props.caseAll;
    var history = this.props.history;
    return(
        <div className="contentDiv">
          <CaseForm
            onCancel = {this.onCancel}
            onSave = {this.onSave} 
            backButton= {this.backButton}
            history = {history}
            caseAll = {caseAll}
            caseOnChange={this.caseOnChange}
            states = {this.state.case_id}
            victimAll={this.props.victimAll}
            victimOnChange={this.victimOnChange}
            victimStates ={this.state.victim_id}
            accountOnChange={this.accountOnChange}
            accountStates={this.state.account_id}
            actStates={this.state.act_id}
            actOnChange={this.actOnChange}
          />
        </div>
    );
  }
}

const mapStateTopProps = (state) =>{
    return {
        caseAll: state.case.case.data,
        victimAll: state.case.ListVictim.data,
        actAll: state.case.acts.data,
        accountAll: state.case.account.data
    }
}
const mapDispatchToProps = {
  caseAddDocument, caseList, victimList, victimActList, victimAccountList,
  victimAddDocument, AddAccountDocument, victimActAddDocument,
}

export default connect(mapStateTopProps, mapDispatchToProps)(CaseAdd); 