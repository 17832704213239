import { TRASH } from './action';


const INITIAL_STATE = {
    loading: false,
    error: '',
    trash:{}
};



 const trashReducer = (state = INITIAL_STATE, action)=> {
    switch (action.type) {
        
        case TRASH.START:
            return {...state, loading: true, error: ''};
        case TRASH.SUCCESS:
            return {...state, loading: false, trash: action.payload};
        case TRASH.FAILED:
            return {...state, loading: false, error: action.payload};


        default:
            return state;
    }
}

export default trashReducer;