import React, { Component } from 'react'
import { Table, Icon, Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import Tags from '../../../../shared/components/tags';
import FilterDrawer from '../../components/drawer';
import { get } from 'lodash';
import { victimListCase, VicitimArchived } from '../../../victims/actionCreators';
import {connect} from 'react-redux';

class CaseView extends Component {

    componentDidMount(){
        this.props.victimListCase();
        
    }

    componentDidUpdate(props, states){
        if( props.List !== this.props.List){
           setTimeout(()=>{
            this.props.victimListCase();
           }, 1000)
        }
    }

    constructor(props){
        super(props);
        
        this.state ={
            visible: false,
            ShowLocation: false,
            indexToShow: null 
        }

        this.columns = [
            {
                title: "Name",
                key:'name',
                sorter: true,
                render: (record, text)=>{
                    const firstName = !text.first_name ? "" : text.first_name;
                    const middleName = !text.middle_name ? "" : text.middle_name;
                    const lastName = !text.last_name ? "" : text.last_name;

                    const List =  firstName + " "+ middleName+" "+ lastName;
                    return <div>{List}</div>
                }
            },
            {
                title: "Location", //<div style={{margin: "15px 0px"}}>
                // <p>Location <a style={{margin: "10px 10px"}}onClick={()=>{ this.setState({visible: true})}}><Icon type="setting"/></a></p></div>,
                sorter: true,
                dataIndex: "location",
                width : '300px',
                key:'location',
                render: (record, text, index)=>{
                    const region = text.region === null ? " " :  `${text.region}, `;
                    const province = text.province === null ? " " : `${text.province}, `;
                    const city = text.municipality === null ? " " :`${text.municipality}, `;
                    const barangay = text.barangay === null ? " " : `${text.barangay}, `;
                    const district = text.district === null ? " " :`${text.district} `;

                    const ListShow = region+ " " +`${province}`;
                    const ListHide = region+ " " +`${province}`+ " " +`${city}`+ " " +`${barangay}`+ " " +`${district}`;
                    const indexToShow = this.state.indexToShow;

                    return ( 
                            <div>
                             {
                                 this.state.ShowLocation == true && index == indexToShow  ?
                                 <div>
                                 { ListHide } 
                                 <Button  id="show-hide-tags" icon="small-dash" onClick={(e)=>{ e.stopPropagation(); this.setState({ShowLocation: false, indexToShow: index})}}/>
                                 </div> 
                                 :
                                 <div>
                                 { ListShow } 
                                 <Button  id="show-hide-tags" icon="small-dash" onClick={(e)=>{e.stopPropagation(); this.setState({ShowLocation: true, indexToShow: index})}}/>
                                 </div> 
                             }
                            </div>
                    
                        )
                    }
            },
            { 
                title: "Tags",
                dataIndex: "victim_tags",
                width: "200px",
                key:'tags',
                render: (record, text, index)=>{
                    const arr2 = text.victim_tags;
                    const arr1 = arr2.length;
                    const arr3 = arr2.slice(0, 2);
                    const id = text.id;
                return (
                        <Tags 
                            arr2={arr2} 
                            arr1={arr1} 
                            arr3={arr3} 
                            id={id} 
                        />
                      )
               }
            },
            {
                title: "Date Created",
                dataIndex: "created_at",
                key:'craete_at',
                sorter: true
            },
            {
                title: "Last Date Modified",
                dataIndex: "updated_at",
                key:'updated_at',
                sorter: true
            },
            {
                title: "Created By",
                dataIndex: "created_by",
                key:'create_by',
                sorter: true,   
                render: (record, text)=>{
                    const userList = this.props.users.data == undefined ? [] : this.props.users.data;
                    const findData = userList.find(e=> e.id == record);
                
                    return get(findData, 'first_name') + " " + get(findData, 'last_name')
                    
                }
            },
            {
                title: " ",
                dataIndex: "delete_checkbox",
                key:'checkbox',
                render: (record, text, index)=>{
                    return (
                    <Checkbox 
                        onClick={(e)=> { e.stopPropagation();}}
                        onChange={(e)=>{
                            let status = e.target.checked;
                            this.victimArchivedList(text.first_name, text.last_name, text.id, status, index) }}
                        />)
                }
            }
        ];

        this.state = {
            data: []
        }   
    }

    victimArchivedList = ( first, last, id, status, index)=>{
        let data = this.state.data;

        if (status === false) {
            data.splice(index, 1);
            this.setState({ data: data })
        } else {
            data.push({ first, last, id})
        }
    }
   
    VictimArchived = () => {
        let dataFiles = this.state.data; 

        for (let a = 0; a < dataFiles.length; a++) {
           setTimeout(()=>{
            this.props.VicitimArchived(dataFiles[a].id, dataFiles[a].first_name, dataFiles[a].last_name)
           }, 500)
        }
    }

    onCloseDrawer = ()=>{
        this.setState({ visible: false })
    }

    viewVictim = (state)=>{
        const id = state.id;
        sessionStorage.setItem("victim_id", id);
        this.props.history.push(`/chr/case/victim/view`);

    }

    userPermission = () => { 
        const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
        const path = "/chr/case/view";
        
          if (userRole === "viewer"){
              return(
                  <div>
                    <Button 
                        className="chr-font-light"
                        style={{ float: 'right', height: '40px'}} 
                        disabled={true}
                        type="primary" 
                    >
                        + Add Victim 
                    </Button> 

                    <a disabled={true} style={{float: "right", fontSize: '25px' , margin: "0px 20px"}}>
                        <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
                    </a>
                </div>
              ) 
          } else if (userRole === "contributor") {  
            return(
                <div>
                    <Link to={'/chr/case/add/victim'} >
                        <Button
                            className="chr-font-light"
                            style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                            type="primary" 
                            onClick={()=>{
                                sessionStorage.setItem('victim_module_add_path', path);
                                sessionStorage.setItem('victim_edit', false);}}> 
                                + Add Victim 
                        </Button>
                    </Link>

                  <a disabled={true} style={{float: "right", fontSize: '23px' , margin: "0px 20px"}}>
                    <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
                  </a>
              </div>
            ) 
          } else {
              return (
                  <div>
                        <Link to={'/chr/case/add/victim'} >
                            <Button
                                className="chr-font-light"
                                style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                                type="primary" 
                                onClick={()=>{
                                    sessionStorage.setItem('victim_module_add_path', path);
                                    sessionStorage.setItem('victim_edit', false);}}> 
                                    + Add Victim 
                            </Button>
                        </Link>
                        
                        <a onClick={()=>{ this.VictimArchived()}}  style={{zIndex: 10, float: "right", fontSize: '23px' , margin: "0px 20px"}}>
                            <Icon type="delete" theme="filled" style={{color: '#77B9FF', fontSize: '30px'}}/>
                        </a>
                </div>
              )
          }
      }

      handleTableChange = (pagination, filters, sorter) => {
        let params = {
            "sort_key" : sorter.columnKey,
            "sort_dir" : sorter.order            
        }

        for(let [key, value] of Object.entries(filters)) {
            if(value.length > 0) {
                params[`${key}`] = value;
            }
        }

        this.props.getVictimList(params)
    }

      
    render() {
        const data = this.props.List.data;
        const path = "/chr/case/view";
        return (
        <div style={{margin: "20px"}}>
            <div style={{margin: "40px 0px", fontSize: "20px", fontWeight: "bold" }}> 

            <a style={{color: "#22568f"}}>
                <Icon type="solution"/>&nbsp;&nbsp;&nbsp;
                <span className="chr-font-sm">Case Victim's </span>
            </a>
               
            { 
                this.userPermission() 
            }
               
            </div>

          <Table 
            columns = {this.columns}
            dataSource={data}
            rowKey="id"
            onChange={ this.handleTableChange }
            onRowClick = {this.viewVictim}
          />    

          <FilterDrawer visible={this.state.visible} onClose= {this.onCloseDrawer}/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        List: state.victim.caseVictimList,
        login: state.login,
    }   
}

 const mapDispatchToProps =  {
    victimListCase, VicitimArchived
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseView);
