import React, { Component } from 'react'
import { Icon } from 'antd';

export default class icon_file_type extends Component {
  
  icon_type =()=>{
   var icon_type = "file-unknown";

    if (this.props.file_type === 'txt') {
        icon_type = "file-text"
    } else if (this.props.file_type === 'xls' || this.props.file_type === 'xlsx') {
        icon_type = "file-excel"
    } else if (this.props.file_type === "pdf") {
        icon_type = "file-pdf"
    } else if (this.props.file_type === "doc" || this.props.file_type === "docx") {
        icon_type = "file-word"
    } else if (this.props.file_type === "jpg" || this.props.file_type === "png" ) {
        icon_type = "file-jpg"
    }

    return icon_type;
  }

  color=()=>{
    var color = "pink";
  
    if(this.props.file_type === 'txt') {
        color = "blue"
    } else if (this.props.file_type === 'xls' || this.props.file_type === 'xlsx') {
        color = "#97DE95"
    }  else if (this.props.file_type === 'csv' ) {
        color = "#97DE95"
    }else if (this.props.file_type === "pdf") {
        color = "#FC9696"
    } else if (this.props.file_type === "doc" || this.props.file_type === "docx") {
        color = "#65BBFB"
    } else if (this.props.file_type === "jpg" || this.props.file_type === "png" ) {
        color = "#FFBD67"
    }

    return color;
  }

  render() {
      const col = this.color();
    return (
      <div>
          <Icon type={this.icon_type()}  style={{color: `${col}` }}/> {this.props.record}
      </div>
    )
  }
}
