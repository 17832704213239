import React, { Component } from 'react'
import { Table, Icon, Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { AccountArchived } from '../../../actionCreator';
import { connect } from 'react-redux';

 class victimAccount extends Component {
     constructor(props){
         super(props);

         this.columns = [
            {
               title: 'Account Name',
               dataIndex: 'account_name',
               sorter: true,
               key: 'account_name',
               render: (record) =>{
                  return <div><Icon className="case-icon-table" type="folder-open"  theme="filled"/> <span className="name_folder_icon">{record}</span></div>
               }
            },
            {
               title: 'Account Source Name',
               sorter: true,
               key: 'basis',
               render: (record, text) =>{
                  return <div>{get(text, 'account_source_first_name')+" "+ get(text, 'account_source_last_name')}</div>
               }
            },
            {
               title: 'Account Source Type',
               dataIndex: 'account_source_type',
               filters : [{'value': '', 'text': ''}],
               key: 'account_source_type',
              
            },
            {
               title: 'Date Created',
               dataIndex: 'created_at',
               sorter: true,
               key: 'created_at'
            },
            {
               title: 'Created by',
               dataIndex: 'created_by',
               sorter: true,
               key: 'created_by',
               render: (record)=>{
                  const findData = this.props.users.data != undefined ? this.props.users.data.find(e => e.id == record): [];
                  return <div> {get(findData, 'first_name') +" "+ get(findData, "last_name")}</div>
               }
            },
            {
               title: '',
               dataIndex: 'action',
               key: 'action',
               render: (record, text, index)=>{
                  return (
                  <Checkbox 
                      onClick={(e)=> { e.stopPropagation();}}
                      onChange={(e)=>{
                          let status = e.target.checked;
                          this.AccountArchivedList(text.id, status, index) }}
                      />)
                        }
                     }
                  ];
      
      this.state = {
         data: []
      }   
   }
   
   AccountArchivedList = ( id, status, index)=>{
      let data = this.state.data;

      if (status === false) {
            data.splice(index, 1);
            this.setState({ data: data })
      } else {
            data.push({id})
      }
   }

   AccountArchived = () => {
      let dataFiles = this.state.data; 

      for (let a = 0; a < dataFiles.length; a++) {
         setTimeout(()=>{
            this.props.AccountArchived(dataFiles[a].id)
         }, 500)
      }
   }

   headerFiles = () =>{
      return<div style={{margin: "20px"}}>
               <div style={{margin: "40px 0px"}}> 
                  <a style={{color: "#22568f", fontSize:'20px'}}>
                     <Icon type="solution"/>&nbsp;&nbsp;&nbsp;
                     <span className="chr-font-sm">Victim's Account</span>
                  </a>

                  { this.userPermission() }
              </div>
            </div>
    }

    userPermission = () => { 
      const userRole = this.props.login.user.role === undefined ? [] : this.props.login.user.role;
  
        if (userRole === "viewer"){
            return ( 
               <div style={{ textAlign: 'right'}}>
                  <Button 
                     disabled={true}
                     className="chr-font-light"
                     style={{ float: 'right', height: '40px'}} 
                     type="primary" >
                        + Add Account
                  </Button>

               <a disabled={true} style={{float: "right", fontSize: '23px' , margin: "0px 20px"}}>
                  <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
               </a>
           </div>
            ) 
        } else if (userRole === "contributor") {  
         return ( 
            <div style={{ textAlign: 'right'}}>
               <Link to={'/chr/case/victim/add/source'}>
                  <Button 
                     className="chr-font-light"
                     style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                     type="primary" 
                     onClick={()=>{ 
                        sessionStorage.setItem('path_add_act', this.props.history.location.pathname);
                        sessionStorage.setItem('account_edit', false)}}>
                        + Add Account
                  </Button>
               </Link>

            <a disabled={true} style={{float: "right", fontSize: '23px' , margin: "0px 20px"}}>
               <Icon type="delete" theme="filled" style={{color: '#C8C8C8', fontSize: '30px'}}/>
            </a>
        </div>
         ) 
        } else {
            return (
              <div style={{ textAlign: 'right'}}>
                  <Link to={'/chr/case/victim/add/source'}>
                     <Button 
                        className="chr-font-light"
                        style={{zIndex: 10, float: "right", height: "40px", backgroundColor: '#77B9FF', borderColor: '#77B9FF'}}
                        type="primary" 
                        onClick={()=>{ 
                           sessionStorage.setItem('path_add_act', this.props.history.location.pathname);
                           sessionStorage.setItem('account_edit', false)}}>
                           + Add Account
                     </Button>
                  </Link>

                  <a  onClick={()=>{ this.AccountArchived()}} style={{zIndex: 10, float: "right", fontSize: '23px' , margin: "0px 20px"}}> 
                     <Icon type="delete" theme="filled" style={{zIndex: 100, color: '#77B9FF', fontSize: '30px'}}/>
                  </a>
              </div>
            )
        }
    }

   RowClick = (data)=>{
      this.props.history.push('/chr/case/victim/account/view');
      sessionStorage.setItem('account_id', data.id);
      sessionStorage.setItem('account_title', data.account_name);
      sessionStorage.setItem('act_module_table', false);
   }

  render() {
     const data = !this.props.account.data ? [] : this.props.account.data;
    return (
      <div>
          <div >{ this.headerFiles() } </div>
          <Table 
            columns = {this.columns}
            dataSource = {data}
            rowKey="id"
            onRowClick={this.RowClick}
            />
      </div>
    )
  }
}

const mapDispatchToProps = {
   AccountArchived
}
export default connect(null, mapDispatchToProps)(victimAccount);