import React, {Component} from 'react';
import { Form, Input, Button, Row, Col, notification, message, Switch, Select, Radio, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { userAdd, userList, userEdit, generateNewpassword } from '../actionCreators';
import _ from 'lodash';

const RadioGroup = Radio.Group;
const CaseForm =  Form.create()(

  class extends Component {
    
    state = {
        password: null,
        show: false
    }

    handleSubmit = (e) => {
      e.preventDefault();
      const location = this.props.history.location.pathname;

      this.props.form.validateFields((err, values) => {
        
        if (!err) {
            if(location === "/chr/user/add"){
              this.props.onSave(values) 
             } else {
              values.id = this.props.id;
              console.log(values)
              this.props.userEdit(values, this.props.id, this.props.editCallback);
            }       
        }
      });
    }

    generatePassword = ()=> {
       const currentEdit = this.props.currentEdit;
       const randomPassword =  Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);

       return this.setState({ password: randomPassword})
    }
 
    showPassword = (e) => {
        this.setState({ show: e.target.checked})
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      const currentEdit = this.props.currentEdit;
      const location = this.props.history.location.pathname;
      return (
        <Form onSubmit={this.handleSubmit}>

            <Row style={{padding: '20px 20px 0px 20px'}}>
             <Col span={12}>
            
            {
                location === "/chr/user/add" ? 
                <h1 className="chr-font">Adding User Account </h1> 
                :
                <h1 className="chr-font">Modify User Account </h1>
            }  

             </Col> 

             <Col span={12}>
                 <Form.Item className="form-case-add">
                    <Button className="chr-btn" type="primary" htmlType="submit" style={{margin: '0px 10px'}} >
                         Save
                    </Button>
                    <Button className="chr-close-btn" type="default" icon="cross" onClick ={ ()=>{ this.props.onCancel() }} >
                    </Button>
                 </Form.Item>
             </Col>
         </Row>

         <Row>
          <Col className="form-case-add-row"></Col>
         </Row>

{/* Form Start */}

    <Row  style={{margin: '20px 20px'}}>
        <Col span={24}>

            <Row gutter={16} style={{marginTop: "40px"}}>
                <Col span={4}>
                    <h4 className="chr-font-ss"> User Information:</h4>
                </Col>
            
            { 
            location === "/chr/user/add" || this.props.userRole === "viewer" ?
              <div></div> : <Col span={2} style={{float: 'right', zIndex: '2'}}>
                <Form.Item >
                        { getFieldDecorator('is_active', {
                        rules: [{ required: false }],
                        initialValue: true,
                        valuePropName:  !currentEdit ? "checked" : currentEdit.is_active == true ? 'checked' : 'unchecked' 

                        })(
                            <Switch />
                        )}
                    </Form.Item>   
                </Col>
            }
              
            </Row>         

            <Row gutter={16} style={{margin: "40px 0px"}}>
                <Col span={7}>
                    <Form.Item label=" Last Name" className="chr-font-secondary">
                        {getFieldDecorator('last_name', {
                        rules: [{ required: false }],
                        initialValue: !currentEdit ? null : currentEdit.last_name
                        })(
                            <Input placeholder ="Last Name"/>
                        )}
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item label=" First Name" className="chr-font-secondary">
                        {getFieldDecorator('first_name', {
                        rules: [{ required: false }],
                        initialValue: !currentEdit ? null : currentEdit.first_name
                        })(
                            <Input placeholder ="First Name"/>
                        )}
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item label=" Middle Name" className="chr-font-secondary">
                        {getFieldDecorator('middle_name', {
                        rules: [{ required: false }],
                        initialValue: !currentEdit ? null : currentEdit.middle_name
                        })(
                            <Input  placeholder ="Middle Name"/>
                        )}
                    </Form.Item>
                </Col>
            </Row>

             
            <Row gutter={16} style={{margin: "40px 0px"}}>
                <Col span={6}>
                    <Form.Item label="Username" className="chr-font-secondary">
                        {getFieldDecorator('email', {
                        rules: [{ required: false }],
                        initialValue: !currentEdit ? null : currentEdit.email
                        })(
                            <Input placeholder="Username" type="email" disabled={!currentEdit ? false : true}/>
                        )}
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Password" className="chr-font-secondary">
                        {getFieldDecorator('password', {
                        rules: [{ required: false }],
                        initialValue: this.state.password
                        })(
                            <div>
                                {/* <Input type="text" value={this.state.password} placeholder="Password" disabled={!currentEdit ? false : true}/> */}
                                <Input type={this.state.show === false ? "password" : "text"} value={this.state.password} placeholder="Password" disabled={true}/>
                                 &nbsp;
                                <Checkbox onChange = {this.showPassword}/> &nbsp; show password <br />
                                    <a onClick={()=>{ this.generatePassword() }}> 
                                        &nbsp; + new generate password 
                                    </a>
                            </div>
                        )}
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label="Gender" className="chr-font-secondary">
                        {getFieldDecorator('gender', {
                        rules: [{ required: false }],
                        initialValue: !currentEdit ? null : currentEdit.gender
                        })(
                            <Select placeholder="Gender">
                                <Select.Option key="male" value="male">male</Select.Option>
                                <Select.Option key="female" value="female">female</Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item label="Position" className="chr-font-secondary">
                        {getFieldDecorator('position', {
                        rules: [{ required: false }],
                        initialValue: !currentEdit ? null : currentEdit.position
                        })(
                            <Input placeholder="Position"/>
                        )}
                    </Form.Item>
                </Col>
            </Row>  
            <div>
                        
            <Row >
            {
            this.props.userRole === "viewer"  ? <div></div> : 
            <Col>
                <h4 className="chr-font-ss"> User Roles:</h4>
                <Form.Item>
                    {getFieldDecorator('role', {
                    rules: [{ required: false }],
                    initialValue: !currentEdit ? null : currentEdit.role
                    })(
                        <RadioGroup style={{margin: "20px 0px"}}>
                            <Radio value="admin">admin</Radio>
                            <Radio value="editor" style={{margin: "0px 60px"}}>editor</Radio>
                            <Radio value="contributor">contributor</Radio>
                            <Radio value="viewer" style={{margin: "0px 60px"}}>viewer</Radio>
                        </RadioGroup>
                    )}
                </Form.Item>
               
                </Col>
            }
            </Row>
            </div>

        </Col>
    </Row>
</Form>
      );
    }
  }
)

class UserAdd extends Component {

constructor(props){
    super(props);

    this.state = {
        currentEdit: {}
    }
}

componentDidMount(){
    this.editData();
}

editCallback = (response)=>{
    if(response.status == 200){
        notification.success({ message: "User Successfully Edited!"})
        this.props.history.push('/chr/user')
    }
}

callback = (response, error)=>{
    if(!error){
        notification.success({ message: "User Successfully Added!"})
        this.props.history.push('/chr/user')
    }else{
        notification.warning({ message: `${error.message}`})
    }
}


onCancel = ()=>{
    this.props.history.goBack();
}

onSave = (values)=>{
    this.props.userAdd(values, this.callback);
}



editData = ()=>{    
    const id = this.props.history.location.pathname.slice(10);
    const arr1 = this.props.users.users.data;
    const arr2 = _.keyBy(arr1, "id");
    const arr3 = arr2[id];

    this.setState({ currentEdit: arr3 });
}

  render(){
    const id = this.props.history.location.pathname.slice(10);
    let userRole = this.props.login.user.role;
    // console.log(this.props.history.location.state === undefined ? "" : this.props.history.location.state.data)
    return(
      
        <div className="contentDiv">
          <CaseForm
            onSave = {this.onSave}
            onCancel= {this.onCancel}
            history={this.props.history}
            currentEdit = {this.state.currentEdit}
            userEdit = {this.props.userEdit}
            id = {id}
            userRole = {userRole}
            editCallback = {this.editCallback}
            generateNewpassword={this.props.generateNewpassword}
            detailsLogin={this.props.users}
          />
        </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        users: state.user,
        login: state.login
    }
}

const mapDispatchToProps = {
    userAdd, 
    userList, 
    userEdit,
    generateNewpassword
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdd);
