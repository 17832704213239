import React, {Component} from 'react';
import { connect } from 'react-redux';
import { loginNewPassword } from '../ActionCreators'
import { Form, Icon, Input, Button, notification, message, Row, Col } from 'antd';
import Header from './HeaderForm';

const WrapperForm = Form.create()(
class extends Component {
    
    handleSubmit = (e) => {
      e.preventDefault();

      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.loginNewPassword(values, this.props.errorCallback, this.props.successCallback)
          // console.log(values)  
        }
      });
    }
  
    render() {
      const { getFieldDecorator } = this.props.form;

      return (
        <div>

          <Form onSubmit={this.handleSubmit} className="form-main" style={{margin: '80px 0px'}}>
 
            <Form.Item  className="form-fields">
                {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Please input your username!' }],
                })(
                <Input className="form-fields" prefix={<Icon type="user" style={{ fontSize: '20px', color: 'rgba(0,0,0,.25)'}} />} style={{fontSize: '15px', color: '#77B9FF', fontFamily: 'Montserrat' }} placeholder="&nbsp;&nbsp;Email" />
                )}
            </Form.Item>
  
            <Form.Item className="form-fields">
                {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input your Password!' }],
                })(
                <Input className="form-fields" prefix={<Icon type="lock" style={{ fontSize: '20px',color: 'rgba(0,0,0,.25)' }} />} type="password" style={{fontSize: '15px', color: '#77B9FF', fontFamily: 'Montserrat' }} placeholder="&nbsp;&nbsp;Password" />
                )}
            </Form.Item>

            <Form.Item className="form-fields">
                {getFieldDecorator('new_password', {
                rules: [{ required: true, message: 'Please input your New Password!' }],
                })(
                <Input className="form-fields" prefix={<Icon type="lock" style={{ fontSize: '20px',color: 'rgba(0,0,0,.25)' }} />} type="password" style={{fontSize: '15px', color: '#77B9FF', fontFamily: 'Montserrat' }} placeholder="&nbsp;&nbsp;New Password" />
                )}
            </Form.Item>

            <Button  type="primary" htmlType="submit" style={{height: '50px', width: '450px'}}>
                <span className="chr-font-light">LOGIN</span>
            </Button>
          </Form>
        </div>
      );
    }
  }
)

class NormalLoginForm extends Component {
  
  errorCallback (error) {
    if(error){
      notification.warning({ message: `${error.message  }`})
    }
  }

  successCallback(reponse){
   if(reponse.status === 200) {
    notification.success({ message: 'Change Password Success!'})
    window.location = "/#/";
   }
  }

  render() {
    return(
      <div className="relogin-div "> 
        <Header />
        <WrapperForm 
          loginNewPassword={this.props.loginNewPassword} 
          errorCallback={this.errorCallback} 
          successCallback={this.successCallback}
          history={this.props.history}/> 
      </div>
    )
  }
}
  
const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = {
  loginNewPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(NormalLoginForm);