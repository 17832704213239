// LIST OF PROVINCES AND REGIONS FROM
// https://psa.gov.ph/classification/psgc/?q=psgc/provinces

export const regions = [
    { title: 'NCR', key: 13, value:'NCR'},
    { title: 'Region 1 (Ilocos)', key: 1, value:'R01'},
    { title: 'Region 2 (Cagayan Valley)', key: 2, value:'R02'},
    { title: 'Region 3 (Central Luzon)', key: 3, value:'R03'},
    { title: 'Region 4 (CALABARZON)', key: 4, value:'R04'},
    { title: 'Region 5 (Bicol)', key: 5, value:'R05'},
    { title: 'Region 6 (Western Visayas)', key: 6, value:'R06'},
    { title: 'Region 7 (Central Visayas)', key: 7, value:'R07'},
    { title: 'Region 8 (Eastern Visayas)', key: 8, value:'R08'},
    { title: 'Region 9 (Zamboanga Peninsula)', key: 9, value:'R09'},
    { title: 'Region 10 (Northern Mindanao)', key: 10, value:'R010'},
    { title: 'Region 11 (Davao)', key: 11, value:'R11'},
    { title: 'Region 12 (SOCCSKSARGEN)', key: 12, value:'R12' },
    { title: 'Region 13 (Caraga)', key: 16, value:'R13'},
    { title: 'CAR', key: 14, value:'Car'},
    { title: 'ARMM', key: 15, value:'ARMM'},
    { title: 'MIMAROPA Region', key: 17, value:'MIMAROPA'}
  ]

export const provinces = [
    { 
        key: 1, 
        list: ['Ilocos Norte', 'Ilocos Sur', 'La Union', 'Pangasinan']
    },
    { 
        key: 2, 
        list: ['Batanes', 'Cagayan', 'Isabela', 'Nueva Vizcaya', 'Quirino'] 
    },
    { 
        key: 3, 
        list: ['Aurora', 'Bataan', 'Bulacan', 'Nueva Ecija', 'Pampanga', 'Tarlac', 'Zambales'] 
    },
    { 
        key: 4, 
        list: ['Batangas', 'Cavite', 'Laguna', 'Quezon', 'Rizal'] 
    },
    { 
        key: 5, 
        list: ['Albay', 'Camarines Norte', 'Camarines Sur', 'Catanduanes', 'Masbate', 'Sorsogon'] 
    },
    { 
        key: 6, 
        list: ['Aklan', 'Antique', 'Capiz', 'Iloilo', 'Negros Occidental', 'Guimaras'] 
    },
    { 
        key: 7, 
        list: ['Bohol', 'Cebu', 'Negros Oriental', 'Siquijor'] 
    },
    { 
        key: 8, 
        list: ['Eastern Samar', 'Leyte', 'Northern Samar', 'Samar (Western)', 'Southern Leyte', 'Biliran']
    },
    { 
        key: 9, 
        list: ['Zamboanga del Norte', 'Zamboanga del Sur', 'Zamboanga Sibugay'] 
    },
    { 
        key: 10, 
        list: ['Bukidnon', 'Camiguin', 'Lanao del Norte', 'Misamis Occidental', 'Misamis Oriental'] 
    },
    { 
        key: 11, 
        list: ['Davao del Norte', 'Davao del Sur', 'Davao Oriental', 'Compostella Valley', 'Davao Occidental'] 
    },
    { 
        key: 12, 
        list: ['Cotabato (North)', 'South Cotabato', 'Sultan Kudarat', 'Sarangani'] 
    },
    { 
        key: 13, 
        list: ['Metro Manila'] 
    },
    { 
        key: 14, 
        list: ['Abra', 'Benguet', 'Ifugao', 'Kalinga', 'Mountain Province', 'Apayao'] 
    },
    { 
        key: 15, 
        list: ['Basilan', 'Lanao del Sur', 'Maguindanao', 'Sulu', 'Tawi-Tawi'] 
    },
    { 
        key: 16, 
        list: ['Agusan del Norte', 'Agusan del Sur', 'Surigao del Norte', 'Surigao del Sur', 'Dinagat Islands'] 
    },
    { 
        key: 17, 
        list: ['Marinduque', 'Occidental Mindoro', 'Oriental Mindoro', 'Palawan', 'Romblon'] 
    }
]